import { Checkbox, FormControlLabel, Grid, Typography } from "@mui/material";
import { upperFirstLetter } from "../../../utils/qViewerFunctions";

const FinancialAdmin = ({financial}) => {
    return (
        <Grid container spacing={2}>
            <Grid item>
                <Typography variant='h5' color="customYellow.main" sx={{marginBottom:"30px"}}><b>Financial</b></Typography>
                <FormControlLabel checked={financial?.readPolicy} control={<Checkbox/>} label={<Typography variant="subtitle1"><b>I have read Summit’s Financial Policy</b></Typography>}/>
                <Typography variant='subtitle1'><b>Who will be responsible to pay your tuition at Summit?</b></Typography>
                <Typography variant='subtitle1' sx={{marginLeft:"20px"}}>{financial?.responsible}</Typography>
                <Typography variant='subtitle1'><b>Do you have any financial responsibilities such as: student debt, deferment, etc?</b></Typography>
                <Typography variant='subtitle1' sx={{marginLeft:"20px"}}>{upperFirstLetter(financial?.responsabilities)}</Typography>
                <Typography variant='subtitle1'><b>Amount of Debt</b></Typography>
                <Typography variant='subtitle1' sx={{marginLeft:"20px"}}>{financial?.amountDebt}</Typography>
                <Typography variant='subtitle1'><b>Do you have any other sources of income?</b></Typography>
                <Typography variant='subtitle1' sx={{marginLeft:"20px"}}>{upperFirstLetter(financial?.sourceIncome)}</Typography>
                <Typography variant='subtitle1'><b>Will you be receiving financial assistance from parents, church, friends, etc.?</b></Typography>
                <Typography variant='subtitle1' sx={{marginLeft:"20px"}}>{upperFirstLetter(financial?.financialAssistance)}</Typography>
                <Typography variant='subtitle1'><b>Are you interested in information regarding a payment plan?</b></Typography>
                <Typography variant='subtitle1' sx={{marginLeft:"20px"}}>{upperFirstLetter(financial?.paymentPlan)}</Typography>
                <Typography variant='subtitle1'><b>Are you applying for a scholarship?</b></Typography>
                <Typography variant='subtitle1' sx={{marginLeft:"20px"}}>{upperFirstLetter(financial?.scholarship)}</Typography>
            </Grid>
        </Grid>
    )
}

export default FinancialAdmin
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { updateStatusApp } from '../../axios/axiosFunctions';
import { Box, Button, Dialog, DialogContent, Typography } from '@mui/material';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import HighlightOffSharpIcon from '@mui/icons-material/HighlightOffSharp';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';

const DecisionReviewColumn = ({row,id}) => {

    const navigate = useNavigate();
    const [openDialog, setOpenDialog] = useState(false);
    const [openDialogReject, setOpenDialogReject] = useState(false);
    const [openDialogWait, setOpenDialogWait] = useState(false);
    const [openDialogDeferred, setOpenDialogDeferred] = useState(false);

    const handleCloseDialog = ()=>{
        setOpenDialog(false);
    }

    const handleCloseDialogReject = ()=>{
        setOpenDialogReject(false);
    }

    const handleCloseDialogWait = ()=>{
        setOpenDialogWait(false);
    }

    const handleCloseDialogDeferred = ()=>{
        setOpenDialogDeferred(false);
    }

    const handleCheck = ()=>{
        if(row.questionnaire === "reviewed" && row.documents === "reviewed"){
            setOpenDialog(true);
        }
    }
    
    const handleReject = ()=>{
        if(row.questionnaire === "reviewed" && row.documents === "reviewed"){
            setOpenDialogReject(true);
        }
    }

    const handleWaitList = ()=>{
        if(row.questionnaire === "reviewed" && row.documents === "reviewed"){
            setOpenDialogWait(true);
        }
    }

    const handleDeferredAcceptance = ()=>{
        if(row.questionnaire === "reviewed" && row.documents === "reviewed"){
            setOpenDialogDeferred(true);
        }
    }

    const handleUpdateStatus = async(status)=>{
        await updateStatusApp(id,{statusApp:status});
        navigate(0);
        setOpenDialog(false);
        setOpenDialogReject(false);
    }

    return (
        <Box sx={{width: "100%",height: "100%",display: "flex",justifyContent: "space-evenly", alignItems: "center"}}>
            <CheckCircleOutlineIcon onClick={handleCheck} sx={{cursor:"pointer",color:"#00C853"}}/>
            <HighlightOffSharpIcon onClick={handleReject} sx={{cursor:"pointer",color:"#FF3838"}}/>
            <AccessTimeIcon onClick={handleWaitList} sx={{cursor:"pointer",color:"#FBBD08"}}/>
            <ArrowForwardIcon onClick={handleDeferredAcceptance} sx={{cursor:"pointer",color:"#1B2430"}}/>
            <Dialog open={openDialog} onClose={handleCloseDialog}>
                <DialogContent>
                    <Box>
                        <Typography variant='h6'>Are you sure you want to ACCEPT the applicant?</Typography>
                        <Box sx={{display:"flex",justifyContent:"center",marginTop:"20px"}}>
                            <Button variant='contained' color="customGray" sx={{marginRight:"20px"}} onClick={()=>handleUpdateStatus("accepted")}>Yes</Button>
                            <Button variant='contained' onClick={handleCloseDialog}>No</Button>
                        </Box>
                    </Box>
                </DialogContent>
            </Dialog>
            <Dialog open={openDialogReject} onClose={handleCloseDialogReject}>
                <DialogContent>
                    <Box>
                        <Typography variant='h6'>Are you sure you want to DECLINE the applicant?</Typography>
                        <Box sx={{display:"flex",justifyContent:"center",marginTop:"20px"}}>
                            <Button variant='contained' color="customGray" sx={{marginRight:"20px"}} onClick={()=>handleUpdateStatus("declined")}>Yes</Button>
                            <Button variant='contained' onClick={handleCloseDialogReject}>No</Button>
                        </Box>
                    </Box>
                </DialogContent>
            </Dialog>
            <Dialog open={openDialogWait} onClose={handleCloseDialogWait}>
                <DialogContent>
                    <Box>
                        <Typography variant='h6'>Are you sure you want to send to WAITLIST the applicant?</Typography>
                        <Box sx={{display:"flex",justifyContent:"center",marginTop:"20px"}}>
                            <Button variant='contained' color="customGray" sx={{marginRight:"20px"}} onClick={()=>handleUpdateStatus("waitlist")}>Yes</Button>
                            <Button variant='contained' onClick={handleCloseDialogWait}>No</Button>
                        </Box>
                    </Box>
                </DialogContent>
            </Dialog>
            <Dialog open={openDialogDeferred} onClose={handleCloseDialogDeferred}>
                <DialogContent>
                    <Box>
                        <Typography variant='h6'>Are you sure you want to DEFERRED ACCEPTANCE the applicant?</Typography>
                        <Box sx={{display:"flex",justifyContent:"center",marginTop:"20px"}}>
                            <Button variant='contained' color="customGray" sx={{marginRight:"20px"}} onClick={()=>handleUpdateStatus("deferred")}>Yes</Button>
                            <Button variant='contained' onClick={handleCloseDialogDeferred}>No</Button>
                        </Box>
                    </Box>
                </DialogContent>
            </Dialog>
        </Box>
    )
}

export default DecisionReviewColumn
import { useContext, useEffect, useState } from 'react';
import { Alert, Box, Button, Dialog, DialogContent, Grid, TextField, Typography } from '@mui/material'
import PhoneInTalkIcon from '@mui/icons-material/PhoneInTalk';
import MailIcon from '@mui/icons-material/Mail';
import TextSnippetIcon from '@mui/icons-material/TextSnippet';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import HowToVoteIcon from '@mui/icons-material/HowToVote';
import ThumbUpIcon from '@mui/icons-material/ThumbUp';
import ThumbDownIcon from '@mui/icons-material/ThumbDown';
import { createActivity, deleteNotificationWarnings, getActivityByApplicationId, updateStatusColumn } from '../../axios/axiosFunctions';
import { UserContext } from '../../contexts/user';

const defalutActivity = {
    activity: "",
    type:"call",
    date:"",
    hour:"",
    description:"",
    vote:"",
    reviewer:"",
}

const monthsAbbreviation = ["Jan","Feb","Mar","Apr","May","June","July","Aug","Sept","Oct","Nov","Dec"];

const Activity = ({appId,setCurrentActivities}) => {

    const today = new Date();
    const day = today.getDate();
    const year = today.getFullYear();
    const month = today.getMonth();
    const { currentUser } = useContext(UserContext);
    const [activityForm, setActivityForm] = useState(defalutActivity);
    const {activity,type,date,hour,description,vote} = activityForm;
    const [placeholderActivity, setPlaceholderActivity] = useState("Support Call");
    const [placeholderDate,] = useState(`${monthsAbbreviation[month]} ${day},${year}`);
    const [placeholderHour,] = useState(`hh:mm`);
    const [openDialog, setOpenDialog] = useState(false);
    const [activityMessage, setActivityMessage] = useState(false);
    const [severity, setSeverity] = useState('success');
    const [message, setMessage] = useState("");
    
    useEffect(() => {
        if(type === "call"){
            setPlaceholderActivity("Support Call");
        }
        if(type === "email"){
            setPlaceholderActivity("Follow up Email");
        }
        if(type === "notes"){
            setPlaceholderActivity("Note");
        }
        if(type === "vote"){
            setPlaceholderActivity("Vote");
        }
    }, [activityForm,type]);
    
    const handleChangeForm = (e)=>{
        const {name,value} = e.target;
        if(name === "vote"){
            if((vote === "Yes" && value === "No") || (vote === "No" && value === "Yes")){
                setActivityForm({...activityForm, [name]:value,reviewer: `${currentUser.firstName} ${currentUser.lastName}`});
                return;
            }
            if((vote === "Yes" && value === "Yes") || (vote === "No" && value === "No")){
                setActivityForm({...activityForm, [name]:"",reviewer:""});
                return;
            }
            setActivityForm({...activityForm, [name]:value, reviewer: `${currentUser.firstName} ${currentUser.lastName}`});
            return;
        }
        setActivityForm({...activityForm, [name]:value});
    }

    const handleDialogClose = ()=>{
        setOpenDialog(false);
    }

    const handleSaveActivity = async()=>{
        
        const data = {
            ...activityForm,
            application: appId
        }

        const resp = await createActivity(data);
        handleDialogClose();
        setActivityMessage(true);

        if(resp){
            setMessage("Activity Saved Correctly");
            setSeverity("success");
            setTimeout(() => {
                setActivityMessage(false);
            }, 3000);
            setActivityForm(defalutActivity);
            const activities = await getActivityByApplicationId(appId);
            if(activities){
                setCurrentActivities(activities.activities);
            }

            const {msg} = await deleteNotificationWarnings({application:appId});
            if(msg==="Deleted"){
                await updateStatusColumn(appId,{statusColumn:"Up to date"});
            }
            return;
        }

        setMessage("Activity NOT Saved (Check internet connection or talk to a super admin)");
        setSeverity("error");
        setTimeout(() => {
            setActivityMessage(false);
        }, 4000);  
    }

    return (
    <Grid container spacing={2} sx={{width:"100%"}}>
        <Grid item xs={12}>
            <TextField fullWidth value={activity} placeholder={placeholderActivity} onChange={handleChangeForm} name='activity'/>
        </Grid>
        <Grid item xs={12}>
            <Button variant={type === "call" ? "contained":"outlined"} onClick={handleChangeForm} name="type" value="call" startIcon={<PhoneInTalkIcon/>}>Call</Button>
            <Button variant={type === "email" ? "contained":"outlined"} onClick={handleChangeForm} name="type" value="email" startIcon={<MailIcon/>} sx={{margin:"0px 10px"}} >Email</Button>
            <Button variant={type === "notes" ? "contained":"outlined"} onClick={handleChangeForm} name="type" value="notes" startIcon={<TextSnippetIcon/>}>Notes</Button>
            {
                currentUser.rol === "reviewer" &&
                <Button variant={type === "vote" ? "contained":"outlined"} onClick={handleChangeForm} name="type" value="vote" startIcon={<HowToVoteIcon/>} sx={{margin:"0px 10px"}} >Vote</Button>
            }
        </Grid>
        {
            type !== "vote" ?
            <Grid item xs={12} sx={{display:"flex",alignItems:"center"}}>
                <AccessTimeIcon/>
                <TextField value={date} placeholder={placeholderDate} onChange={handleChangeForm} name='date' sx={{margin:"0px 10px"}}/>
                <TextField value={hour} placeholder={placeholderHour} onChange={handleChangeForm} name='hour'/>
            </Grid>
            :
            <Grid item xs={12} sx={{display:"flex",flexDirection:"column", alignItems:"flex-start"}}>
                <Box>
                    <Typography variant='h6'>Desicion</Typography>
                    <Button name="vote" value="Yes" onClick={handleChangeForm} variant={vote === "Yes" ? "contained":"outlined"} sx={{marginRight:"10px"}} endIcon={<ThumbUpIcon/>}>Vote</Button>
                    <Button name="vote" value="No" onClick={handleChangeForm} variant={vote === "No" ? "contained":"outlined"} sx={{marginRight:"10px"}} endIcon={<ThumbDownIcon/>}>Vote</Button>
                </Box>
            </Grid>

        }
        <Grid item xs={12} sx={{display:"flex"}}>
            <TextSnippetIcon sx={{marginRight:"10px"}}/>
            <TextField fullWidth onChange={handleChangeForm} value={description} name="description" multiline rows={4}/>
        </Grid>
        {
            activityMessage && 
            <Grid item xs={12} sx={{display:"flex"}}>
                <Alert severity={severity}>
                    {message}
                </Alert>
            </Grid>
        }
        <Grid item xs={12} sx={{display:"flex", justifyContent:"flex-end"}} >
            {/* <Button variant='contained' color="customGray" sx={{marginRight:"20px"}}>CANCEL</Button> */}
            <Button variant='contained' onClick={()=>{setOpenDialog(true)}} >SAVE</Button>
        </Grid>
        <Dialog open={openDialog} onClose={handleDialogClose}>
            <DialogContent>
                <Typography>Are you sure to save this activity?</Typography>
                <Box sx={{display:"flex",margin:"20px"}}>
                    <Button onClick={handleSaveActivity} variant='contained'sx={{marginRight:"20px",justifyContent:"space-around"}}>SAVE</Button>
                    <Button variant='contained' onClick={handleDialogClose} color="customGray">CANCEL</Button>
                </Box>
            </DialogContent>
        </Dialog>
    </Grid>
    )
}

export default Activity
export const documentsSteps = [
    {
        label:"ID/Passport",
        route:"id_passport",
        uploadedLabel:"idPassport",
    },
    {
        label:"Photo",
        numberSteps: 5,
        route:"photo",
        uploadedLabel:"photo",
    },
    {
        label:"Transcripts",
        numberSteps: 2,
        route:"transcripts",
        uploadedLabel:"transcripts",
    },
    {
        label:"References",
        numberSteps: 1,
        route:"references",
        uploadedLabel:"references"
    },
    {
        label:"Resume (Optional)",
        numberSteps: 1,
        route:"resume",
        uploadedLabel:"resume"
    }
];
import { useEffect, useState } from 'react';
import { Box, Divider, FormLabel, Grid, Typography } from '@mui/material';

const UserInfoProfile = ({name,accountDate,email,phone,wayOfContact,year,imageLetters,photo,birthDate}) => {

    const [fileType, setFileType] = useState();
    const [userAge, setUserAge] = useState(0);

    useEffect(() => {
        if(photo && photo !== ""){
            const type = photo.split(".").pop();
            setFileType(type);
        }
    }, [photo]);

    useEffect(() => {
        if(birthDate && birthDate !== ""){
            
            const date = birthDate.split('-');
            const currentDate = new Date();
            let day = currentDate.getDate();
            let month = currentDate.getMonth() + 1;
            let year = currentDate.getFullYear();
            let age = year-parseInt(date[2]);
            if(parseInt(date[0]) === month){
                if(day < parseInt(date[1])){
                    age -= 1;
                }
            }
            if(month < parseInt(date[0])){
                age -= 1;
            }
            setUserAge(age);
        }
    }, [birthDate])
    

    return (
        <Grid container>
            <Grid item xs={6}>
                {
                    photo && photo !== "" ? <>
                    <Box sx={{width:"220px", height:"220px",color:"white",backgroundColor:"#1A2B50",borderRadius:"50%",display:"flex",alignItems:"center",justifyContent:"center"}}>
                        {
                            fileType !== "pdf" ?
                            <img src={`https://summit-app-edgar.s3.eu-north-1.amazonaws.com/applicants/${photo}`} alt="School Id" style={{width:"100%",borderRadius:"50%"}}/>
                            :
                            <Typography variant="h1">{imageLetters.toUpperCase()}</Typography>
                        }
                    </Box>
                    </>
                    :
                    <Box sx={{width:"220px", height:"220px",color:"white",backgroundColor:"#1A2B50",borderRadius:"50%",display:"flex",alignItems:"center",justifyContent:"center"}}>
                        <Typography variant="h1">{imageLetters.toUpperCase()}</Typography>
                    </Box>
                }
            </Grid>
            <Grid item xs={6}>
                <Typography variant='h5'><b>{name}</b></Typography>
                <FormLabel sx={{fontSize:"1rem"}}>Account Creation {accountDate}</FormLabel>
                <Divider sx={{margin:"30px 0px",borderColor:"#999999"}}/>
                <Typography variant='subtitle1'><b>Email</b></Typography>
                <Typography color="customYellow.main">{email}</Typography>
                <Typography variant='subtitle1' sx={{marginTop:"20px"}}><b>Phone Number</b></Typography>
                <Typography color="customYellow.main">{phone}</Typography>
                <Typography variant='subtitle1' sx={{marginTop:"20px"}}><b>Preferred way of contact</b></Typography>
                <Typography color="customYellow.main">{wayOfContact}</Typography>
                <Typography variant='subtitle1' sx={{marginTop:"20px"}}><b>Year Applying for</b></Typography>
                <Typography color="customYellow.main">{`${year}-${Number(year)+1}`}</Typography>
                {
                    birthDate && birthDate !== "" && <>
                        <Typography variant='subtitle1' sx={{marginTop:"20px"}}><b>Age</b></Typography>
                        <Typography color="customYellow.main">{userAge}</Typography>
                    </>
                }
            </Grid>
        </Grid>
    )
}

export default UserInfoProfile
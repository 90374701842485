import { List, ListItem, ListItemButton, ListItemText, Typography } from "@mui/material";
import { feeSteps } from "../../utils/feeSteps";
import CheckCircleIcon from '@mui/icons-material/CheckCircle';

const SStepsAdmin = ({application,setCurrentTab,currentTab}) => {

    const handleClick = (tab)=>{
        setCurrentTab(tab);
    }

    return (
        <>
            <Typography variant="h5">Submit:</Typography>
            <List sx={{width:"100%"}}>
                {
                    feeSteps.map(({label,uploadedLabel,route},i)=>(
                        <ListItem key={i} onClick={()=>handleClick(route)} sx={{padding:{xs:"0px"},borderBottom:"1px solid #9E9E9E",backgroundColor:currentTab ===route?'customYellow.main':'white'}}>
                            <ListItemButton>
                                <ListItemText primary={label}/>
                                <Typography edge="end">{application ? (application[uploadedLabel]?.submitted || application[uploadedLabel]?.payed) ? <CheckCircleIcon/> : "" : ""}</Typography>
                            </ListItemButton>
                        </ListItem>
                    ))
                }   
                <ListItem onClick={()=>handleClick("confirmation_fee")} sx={{padding:{xs:"0px"},borderBottom:"1px solid #9E9E9E",backgroundColor:currentTab ==="confirmation_fee"?'customYellow.main':'white'}}>
                    <ListItemButton>
                        <ListItemText primary={"Confirmation Fee"}/>
                        <Typography edge="end"></Typography>
                    </ListItemButton>
                </ListItem>
            </List>
        </>
    )
}

export default SStepsAdmin
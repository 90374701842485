import { List, ListItem, ListItemButton, ListItemText, Typography } from "@mui/material"
import { Link } from "react-router-dom";
import CheckCircleIcon from '@mui/icons-material/CheckCircle';

const QuestionaireStep = ({active,requiredCount,questionaireSteps}) => {
     
    return (
        <>
            <Typography variant="h5">Questionnaire:</Typography>
            <List sx={{width:"100%"}}>
                {
                    questionaireSteps.map(({label,numberSteps,route,requiredStepsLabel},i)=>(
                        <Link to={`/${route}`} key={i}>
                            <ListItem sx={{padding:{xs:"0px"}, borderBottom:"1px solid #9E9E9E" ,backgroundColor:active ===route?'customYellow.main':'white'}}>
                                <ListItemButton>
                                    <ListItemText primary={label}/>
                                    <Typography edge="end">{ requiredCount ? requiredCount[requiredStepsLabel]?.progress === numberSteps ? <CheckCircleIcon/> : `${requiredCount[requiredStepsLabel]?.progress}/${numberSteps} Required` : `0/${numberSteps}Required`}</Typography>
                                </ListItemButton>
                            </ListItem>
                        </Link>
                    ))
                }
            </List>
        </>
    )
}

export default QuestionaireStep
import clsx from 'clsx';
import AssignToColumn from "../components/GridTableUtils/AssignToColumn";
import ImmunizationRecordsColumn from "../components/GridTableUtils/ImmunizationRecordsColumn";
import PhysicaExaminationColumn from "../components/GridTableUtils/PhysicaExaminationColumn";
import ScholarshipColumn from "../components/GridTableUtils/ScholarshipColumn";

export const columnsConfirmed = [
    {
        field:'lastName',
        headerName: 'LAST NAME',
        flex:1
    },
    {
        field:'firstName',
        headerName: 'FIRST NAME',
        flex:1
    },
    {
        field:'assignedTo',
        headerName: 'ASSIGNED TO',
        flex:1,
        renderCell: AssignToColumn,
        cellClassName:()=>{
            return clsx('nopadding');
        }
    },
    {
        field:'program',
        headerName: 'PROGRAM',
        flex:1,
        valueGetter:(value)=>{  
            if(value && value !== ""){
                return value.charAt(0).toUpperCase() + value.slice(1);
            }
            return "";
        }
    },
    {
        field:'citizenship',
        headerName: 'CITIZENSHIP',
        flex:1
    },
    {
        field:'physicalExamination',
        headerName: 'PHYSICAL EXAMINATION',
        flex:1,
        renderCell: PhysicaExaminationColumn,
        cellClassName:()=>{
            return clsx('nopadding');
        }
    },
    {
        field:'immunizationRecords',
        headerName: 'IMMUNIZATION RECORDS',
        flex:1,
        renderCell: ImmunizationRecordsColumn,
        cellClassName:()=>{
            return clsx('nopadding');
        }
    },
    {
        field:'scholarship',
        headerName: 'SCHOLARSHIP',
        flex:1,
        renderCell: ScholarshipColumn
    },
]
import { changeDateFormat } from "./dateFormat";

export const columnsReporting = [
    {
        field:'lastName',
        headerName: 'LAST NAME',
        flex:1
    },
    {
        field:'firstName',
        headerName: 'FIRST NAME',
        flex:1
    },
    {
        field:'email',
        headerName: 'EMAIL',
        flex:1,
    },
    {
        field:'status',
        headerName: 'STATUS',
        flex:1,
        valueGetter:(value)=>{  
            if(value && value !== ""){
                return value.charAt(0).toUpperCase() + value.slice(1);
            }
            return "";
        }
    },
    {
        field:'accountCreated',
        headerName: 'ACCOUNT CREATED',
        flex:1,
        valueGetter:(value)=>{  
            if(value && value !== ""){
                return changeDateFormat(value.slice(0,10));
            }
            return "";
        }
    },
    {
        field:'citizenship',
        headerName: 'CITIZENSHIP',
        flex:1,
        valueGetter:(value)=>{ 
            if(value && value !== ""){
                return value
            }
           return "Not set"; 
        }
    }
]
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { countries } from '../utils/countries';
import { Alert, Autocomplete, Box, Button, FormControlLabel, Grid, MobileStepper, Radio, RadioGroup, TextField, Typography } from "@mui/material"
import BannerStep from "./BannerStep";

const defaultLegalForm = {
    arrested:"",
    explainArrested:"",
    parole:"",
    officerName:"",
    countryCode:"",
    officerNumber:"",
}

const Legal = ({data,updateApplication}) => {
    
    const navigate = useNavigate();
    const [legalForm, setLegalForm] = useState(defaultLegalForm);
    const [countryObj, setCountryObj] = useState();
    const [showError, setShowError] = useState(false);
    const [activeStep, setActiveStep] = useState(0);
    const steps = 1;
    const {
        arrested,
        explainArrested,
        parole,
        officerName,
        countryCode,
        officerNumber
    } = legalForm;

    useEffect(() => {
        if(data){
          setLegalForm({
                arrested: data.arrested || "",
                explainArrested: data.explainArrested || "",
                parole: data.parole || "",
                officerName: data.officerName || "",
                countryCode: data.countryCode || "",
                officerNumber: data.officerNumber || "",
          });
          const findCountryCode = countries.find(country=>country.phone === data.countryCode) || {code: 'US', label: 'United States', phone: '1', suggested: true};
          setCountryObj(findCountryCode);
        }
      }, [data])

    const handleNext = () => {
        handleSubmit();
        if(!checkRequired()){
            setShowError(true);
            setTimeout(() => {
                setShowError(false);
            }, 2000);
            return;
        }
        if(activeStep === steps-1){
            navigate('/financial');
            return;
        }
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
    };
    
    const handleBack = () => {
        handleSubmit();
        if(activeStep === 0)
        {
            navigate('/health');
            return; 
        }
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const checkRequired = ()=>{
        if(activeStep === 0){
            return  ((arrested === "yes" && explainArrested !== "" && ((parole === "yes" && officerName !== "" && officerNumber !== "" && countryCode !== "") || parole === "no")) || arrested === "no")
        }
        return true;
    }

    const handleChangeForm = (e)=>{
        const {name,value} = e.target;
        setLegalForm({...legalForm, [name]:value});
    }

    const handleCountryCode = (_,country)=>{
        if(country){
            const {phone} = country;
            setCountryObj(country);
            setLegalForm({...legalForm, countryCode:phone});
        }else{
            setCountryObj("");
            setLegalForm({...legalForm, countryCode:""});
        }
    }
    
    const handleSubmit = ()=>{
        const dataSubmit={
            legal:{
                ...legalForm
            }
          }
          updateApplication(dataSubmit);
    }

    return (
        <Grid container flexDirection="column-reverse">
            <Grid item xs={12}>
                <MobileStepper
                    variant="dots"
                    steps={steps}
                    position="static"
                    activeStep={activeStep}
                    sx={{ width:"100%", flexGrow: 1 , marginTop:"50px"}}
                    nextButton={
                        <Button size="small" onClick={handleNext}>
                            {activeStep === steps-1 ? 'Next' : 'Next' }
                        </Button>
                    }
                    backButton={
                        <Button size="small" onClick={handleBack}>
                            {activeStep === 0 ? 'Back' : 'Back'}
                        </Button>
                    }
                />
            </Grid>
            {
                showError &&
                <Grid item xs={12}>
                    <Alert severity='error' sx={{marginTop:"20px"}}>
                        One or more REQUIRED fields are empty.
                    </Alert>
                </Grid>
            }
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Typography variant='subtitle1' sx={{marginTop:"20px"}}>Have you ever been arrested?*</Typography> 
                    <RadioGroup sx={{flexDirection:"row"}} value={arrested} onChange={handleChangeForm}>
                        <FormControlLabel value="yes" name="arrested" control={<Radio />} label="Yes" />
                        <FormControlLabel value="no" name="arrested" control={<Radio />} label="No" />
                    </RadioGroup>
                </Grid>
                {
                    arrested === "yes" &&
                    <>
                        <Grid item xs={12}>
                            <Typography variant='subtitle1'>Please Elaborate*</Typography>
                            <TextField fullWidth onChange={handleChangeForm} inputProps={{maxLength:500}} helperText={`Max Length ${explainArrested.length}/500`} value={explainArrested} name="explainArrested" sx={{width:{xs:"100%",sm:"100%"}}} placeholder="Text" multiline rows={4}/>
                        </Grid>
                        <Grid item xs={12}>
                            <Typography variant='subtitle1' sx={{marginTop:"20px"}}>Are you currently on parole?*</Typography> 
                            <RadioGroup sx={{flexDirection:"row"}} value={parole} onChange={handleChangeForm}>
                                <FormControlLabel value="yes" name="parole" control={<Radio />} label="Yes" />
                                <FormControlLabel value="no" name="parole" control={<Radio />} label="No" />
                            </RadioGroup>
                        </Grid>
                        {
                            parole === "yes" &&
                            <>
                                <Grid item xs={12}>
                                    <Typography variant='subtitle1'>Parole Officer Name*</Typography>
                                    <TextField fullWidth required value={officerName} onChange={handleChangeForm} name='officerName'/>
                                </Grid>
                                <Grid item xs={12}>
                                    <Typography variant='subtitle1'>Parole officer phone number*</Typography>
                                    <Grid container spacing={2}>
                                        <Grid item sm={2}>
                                            <Autocomplete onChange={handleCountryCode} options={countries} value={countryObj?.phone} renderOption={({key,...optionProps},option)=>{
                                                return(
                                                        <Box key={key} component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...optionProps}>
                                                            <img loading="lazy" width="20"
                                                            srcSet={`https://flagcdn.com/w40/${option.code.toLowerCase()}.png 2x`}
                                                            src={`https://flagcdn.com/w20/${option.code.toLowerCase()}.png`}
                                                            alt=""/>
                                                            +{option.phone} / {option.label}
                                                        </Box>
                                                    )
                                                }}
                                                renderInput={params=>(
                                                    <TextField
                                                        {...params}
                                                        label="Country Code"
                                                    />
                                                )}
                                            />
                                        </Grid>
                                        <Grid item sm={10}>
                                            <TextField required fullWidth value={officerNumber} onChange={handleChangeForm} name='officerNumber' label="Phone Number"/>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </>

                        }
                    </>
                } 
            </Grid>
            <Grid item xs={12} sx={{margin:{xs:"0px 0px 50px 0px",sm:"50px 0px"}}}>
                <BannerStep title="Legal" stepNumber={activeStep+1} totalSteps={1}/>
            </Grid>
        </Grid>
    )
}

export default Legal
import { useContext, useEffect, useState } from "react"
import { Box, Grid } from "@mui/material"
import HomeCards from "./HomeCards"
import { getHomeInfo, getHomeNotifications } from "../../axios/axiosFunctions"
import Filter from "./Filter"
import HomeNotification from "./HomeNotification"
import HomeCharts from "./HomeCharts"
import { UserContext } from "../../contexts/user"

const Home = ({currentYear,setCurrentYear}) => {
    
    const {currentUser}  = useContext(UserContext);
    const [homeInfo, setHomeInfo] = useState();
    const [homeNotifications, setHomeNotifications] = useState();

    useEffect(() => {
        const getInformation= async()=>{
            const info = await getHomeInfo({year:currentYear});
            setHomeInfo(info);
        }
        const getNotifications = async()=>{
            const notifications = await getHomeNotifications(currentYear);
            setHomeNotifications(notifications);
        }
        getInformation();
        getNotifications();
    }, [currentYear]);

    return (
        <Box>
            <Filter currentYear={currentYear} setCurrentYear={setCurrentYear}/>
            <Grid container spacing={2}>
                <Grid item xs={6}>
                    <HomeCards homeInfo={homeInfo}/> 
                </Grid>
                <Grid item xs={6}>
                    <Box sx={{maxHeight:"500px",overflow:"hidden",overflowY:"scroll"}}>
                        <HomeNotification notifications={homeNotifications}/>
                    </Box>
                </Grid>
                {
                    currentUser.rol === "admin" && 
                    <Grid item xs={12}>
                        <HomeCharts currentYear={currentYear} homeInfo={homeInfo}/>
                    </Grid>
                }
            </Grid>
        </Box>
    )
}

export default Home
import { Alert, Button, Checkbox, Container, FormControlLabel, FormGroup, Grid, Radio, RadioGroup, TextField, Typography } from '@mui/material'
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { getApplicationById, updateApplication } from '../../axios/axiosFunctions';
import BannerStep from '../BannerStep';

const defaultAcademicForm = {
    firstName:"",
    lastName:"",
    academicRole:"",
    schoolName:"",
    phoneNumber:"",
    email:"",
    relatioship:"",
    howLongKnowApplicant:"",
    howWellKnowApplicant:"",
    writtenCommunicationSkillls:"",
    verbalCommunicationSkills:"",
    intellect:"",
    motivationInitiative:"",
    attendancePunctuality:"",
    punctualityAsignments:"",
    interpersonalSocialSkills:"",
    leadershipAbility:"",
    cooperativeness:"",
    honesty:"",
    emotionalStability:"",
    personalObservations:"",
    assessment:"",
    factors:"",
    recommend:"",
    reservations:"",
    certify:false,
}

const Academic = () => {
    
    const {id}=useParams();
    const navigate = useNavigate();
    const [fullName, setFullName] = useState("User");
    const [showMsg, setShowMsg] = useState(false);
    const [severity, setSeverity] = useState('error');
    const [message, setMessage] = useState(" REQUIRED FIELDS MISSING");
    const [academicForm, setAcademicForm] = useState(defaultAcademicForm);
    const {firstName,lastName,academicRole,schoolName,phoneNumber,email,relatioship,howLongKnowApplicant,howWellKnowApplicant,writtenCommunicationSkillls,
        verbalCommunicationSkills,intellect,motivationInitiative,attendancePunctuality,punctualityAsignments,interpersonalSocialSkills,leadershipAbility,cooperativeness,
        honesty,emotionalStability,personalObservations,assessment,factors,recommend,reservations,certify} = academicForm;
    

    useEffect(() => {
        const checkApplication = async()=>{
            const resp = await getApplicationById(id);
            if(!resp){
                navigate('/');
                return
            }
            const {application} = resp;
            if(application.academic?.uploaded){
                navigate('/');
                return
            }
            const appName = `${application.personal.lastName} ${application.personal.firstName}`;
            setFullName(appName);
        }
        checkApplication()
    }, [id,navigate]);
    
    const handleChangeForm = (e)=>{
        const {name,value} = e.target;
        if(name=== "certify"){
            setAcademicForm({...academicForm, [name]:!academicForm[name]});
            return
        }
        setAcademicForm({...academicForm, [name]:value});
    }

    const checkRequired = ()=>{
        return firstName !== "" && lastName !== "" && academicRole !== "" && schoolName !== "" && phoneNumber !== "" && email !== "" &&
        relatioship !== "" && howLongKnowApplicant !== "" && howWellKnowApplicant !== "" && writtenCommunicationSkillls !== "" && verbalCommunicationSkills !== "" && intellect !== "" &&
        motivationInitiative !== "" && attendancePunctuality !== "" && punctualityAsignments !== "" && interpersonalSocialSkills !== "" && leadershipAbility !== "" && cooperativeness !== "" && 
        honesty !== "" && emotionalStability !== ""  && personalObservations !== "" && assessment !== "" && factors !== "" && recommend !== "" && certify
    }

    const handleSubmit = async()=>{
        
        if(!checkRequired()){
            setShowMsg(true);
            setTimeout(() => {
                setShowMsg(false);
            }, 2000);
            return;
        } 
        
        const data = {
            academic:{
                ...academicForm,
                uploaded:true
            }
        }

        await updateApplication(data,id);
        setShowMsg(true)
        setMessage("THANK YOU, YOU'LL BE REDRECTED TO THE MAIN PAGE.")
        setSeverity("success")
        setTimeout(() => {
            navigate('/')
        }, 5000);
    }

    return (
        <Container>
            <Grid container spacing={2} sx={{marginBottom:"50px"}}>
                <Grid item xs={12} sx={{margin:{xs:"0px 0px 50px 0px",sm:"50px 0px 0px 0px"}}}>
                    <BannerStep title={`Academic Reference for ${fullName}`} stepNumber={1} totalSteps={1}/>
                </Grid>
                <Grid item xs={12}>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <Typography variant='h5'><b>Contact Information</b></Typography>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField required fullWidth value={firstName} onChange={handleChangeForm} name='firstName' label="First name"/>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField required fullWidth value={lastName} onChange={handleChangeForm} name='lastName' label="Last name"/>
                        </Grid>
                        <Grid item xs={12} sm={12}>
                            <TextField required fullWidth value={academicRole} onChange={handleChangeForm} name='academicRole' label="Academic Role"/>
                        </Grid>
                        <Grid item xs={12} sm={12}>
                            <TextField required fullWidth value={schoolName} onChange={handleChangeForm} name='schoolName' label="School Name"/>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField required fullWidth value={phoneNumber} onChange={handleChangeForm} name='phoneNumber' label="Phone Number"/>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField required fullWidth value={email} onChange={handleChangeForm} name='email' label="E-mail Address"/>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12} sx={{marginTop:"20px", borderTop:"solid #E1A30B 5px"}}>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <Typography variant='h5'><b>Reference Information</b></Typography>
                        </Grid>
                        <Grid item xs={12} sm={12}>
                            <TextField required fullWidth value={relatioship} onChange={handleChangeForm} name='relatioship' label="Relationship to Applicant"/>
                        </Grid>
                        <Grid item xs={12} sm={12}>
                            <TextField fullWidth required value={howLongKnowApplicant} onChange={handleChangeForm} name='howLongKnowApplicant' label="How long have you known the applicant?"/>
                        </Grid>
                        <Grid item xs={12} sm={12}>
                            <Typography variant='subtitle1'><b>How well do you know the applicant*</b></Typography> 
                            <RadioGroup onChange={handleChangeForm} value={howWellKnowApplicant} sx={{flexDirection:"row"}}>
                                <FormControlLabel name="howWellKnowApplicant" value="slightly" control={<Radio />} label="Slightly" />
                                <FormControlLabel name="howWellKnowApplicant" value="casually" control={<Radio />} label="Casually"/>
                                <FormControlLabel name="howWellKnowApplicant" value="well" control={<Radio />} label="Well"/>
                                <FormControlLabel name="howWellKnowApplicant" value="veryWell" control={<Radio />} label="Very Well"/>
                            </RadioGroup>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12} sx={{marginTop:"20px", borderTop:"solid #E1A30B 5px"}}>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <Typography variant='h5'><b>Academic Assessment</b></Typography>
                        </Grid>
                        <Grid className="referenceRadio" item xs={12} sm={12}>
                            <Typography variant='subtitle1'><b>Written Communication Skills*</b></Typography> 
                            <RadioGroup onChange={handleChangeForm} value={writtenCommunicationSkillls} sx={{flexDirection:"row"}}>
                                <FormControlLabel name="writtenCommunicationSkillls" value="excellent" control={<Radio />} label="Excellent" />
                                <FormControlLabel name="writtenCommunicationSkillls" value="aboveAverage" control={<Radio />} label="Above Average" />
                                <FormControlLabel name="writtenCommunicationSkillls" value="average" control={<Radio />} label="Average" />
                                <FormControlLabel name="writtenCommunicationSkillls" value="belowAverage" control={<Radio />} label="Below Average" />
                                <FormControlLabel name="writtenCommunicationSkillls" value="poor" control={<Radio />} label="Poor" />
                                <FormControlLabel name="writtenCommunicationSkillls" value="notKnow" control={<Radio />} label="Not known" />
                            </RadioGroup>
                        </Grid>
                        <Grid className="referenceRadio" item xs={12} sm={12}>
                            <Typography variant='subtitle1'><b>Verbal Communication Skills*</b></Typography> 
                            <RadioGroup onChange={handleChangeForm} value={verbalCommunicationSkills} sx={{flexDirection:"row"}}>
                                <FormControlLabel name="verbalCommunicationSkills" value="excellent" control={<Radio />} label="Excellent" />
                                <FormControlLabel name="verbalCommunicationSkills" value="aboveAverage" control={<Radio />} label="Above Average" />
                                <FormControlLabel name="verbalCommunicationSkills" value="average" control={<Radio />} label="Average" />
                                <FormControlLabel name="verbalCommunicationSkills" value="belowAverage" control={<Radio />} label="Below Average" />
                                <FormControlLabel name="verbalCommunicationSkills" value="poor" control={<Radio />} label="Poor" />
                                <FormControlLabel name="verbalCommunicationSkills" value="notKnow" control={<Radio />} label="Not known" />
                            </RadioGroup>
                        </Grid>
                        <Grid className="referenceRadio" item xs={12} sm={12}>
                            <Typography variant='subtitle1'><b>Intellect*</b></Typography> 
                            <RadioGroup onChange={handleChangeForm} value={intellect} sx={{flexDirection:"row"}}>
                                <FormControlLabel name="intellect" value="excellent" control={<Radio />} label="Excellent" />
                                <FormControlLabel name="intellect" value="aboveAverage" control={<Radio />} label="Above Average" />
                                <FormControlLabel name="intellect" value="average" control={<Radio />} label="Average" />
                                <FormControlLabel name="intellect" value="belowAverage" control={<Radio />} label="Below Average" />
                                <FormControlLabel name="intellect" value="poor" control={<Radio />} label="Poor" />
                                <FormControlLabel name="intellect" value="notKnow" control={<Radio />} label="Not known" />
                            </RadioGroup>
                        </Grid>
                        <Grid className="referenceRadio" item xs={12} sm={12}>
                            <Typography variant='subtitle1'><b>Motivation/Initiative*</b></Typography> 
                            <RadioGroup onChange={handleChangeForm} value={motivationInitiative} sx={{flexDirection:"row"}}>
                                <FormControlLabel name="motivationInitiative" value="excellent" control={<Radio />} label="Excellent" />
                                <FormControlLabel name="motivationInitiative" value="aboveAverage" control={<Radio />} label="Above Average" />
                                <FormControlLabel name="motivationInitiative" value="average" control={<Radio />} label="Average" />
                                <FormControlLabel name="motivationInitiative" value="belowAverage" control={<Radio />} label="Below Average" />
                                <FormControlLabel name="motivationInitiative" value="poor" control={<Radio />} label="Poor" />
                                <FormControlLabel name="motivationInitiative" value="notKnow" control={<Radio />} label="Not known" />
                            </RadioGroup>
                        </Grid>
                        <Grid className="referenceRadio" item xs={12} sm={12}>
                            <Typography variant='subtitle1'><b>Attendance/Punctuality*</b></Typography> 
                            <RadioGroup onChange={handleChangeForm} value={attendancePunctuality} sx={{flexDirection:"row"}}>
                                <FormControlLabel name="attendancePunctuality" value="excellent" control={<Radio />} label="Excellent" />
                                <FormControlLabel name="attendancePunctuality" value="aboveAverage" control={<Radio />} label="Above Average" />
                                <FormControlLabel name="attendancePunctuality" value="average" control={<Radio />} label="Average" />
                                <FormControlLabel name="attendancePunctuality" value="belowAverage" control={<Radio />} label="Below Average" />
                                <FormControlLabel name="attendancePunctuality" value="poor" control={<Radio />} label="Poor" />
                                <FormControlLabel name="attendancePunctuality" value="notKnow" control={<Radio />} label="Not known" />
                            </RadioGroup>
                        </Grid>
                        <Grid className="referenceRadio" item xs={12} sm={12}>
                            <Typography variant='subtitle1'><b>Punctuality of Asignments*</b></Typography> 
                            <RadioGroup onChange={handleChangeForm} value={punctualityAsignments} sx={{flexDirection:"row"}}>
                                <FormControlLabel name="punctualityAsignments" value="excellent" control={<Radio />} label="Excellent" />
                                <FormControlLabel name="punctualityAsignments" value="aboveAverage" control={<Radio />} label="Above Average" />
                                <FormControlLabel name="punctualityAsignments" value="average" control={<Radio />} label="Average" />
                                <FormControlLabel name="punctualityAsignments" value="belowAverage" control={<Radio />} label="Below Average" />
                                <FormControlLabel name="punctualityAsignments" value="poor" control={<Radio />} label="Poor" />
                                <FormControlLabel name="punctualityAsignments" value="notKnow" control={<Radio />} label="Not known" />
                            </RadioGroup>
                        </Grid>
                        <Grid className="referenceRadio" item xs={12} sm={12}>
                            <Typography variant='subtitle1'><b>Interpersonal/Social Skills*</b></Typography> 
                            <RadioGroup onChange={handleChangeForm} value={interpersonalSocialSkills} sx={{flexDirection:"row"}}>
                                <FormControlLabel name="interpersonalSocialSkills" value="excellent" control={<Radio />} label="Excellent" />
                                <FormControlLabel name="interpersonalSocialSkills" value="aboveAverage" control={<Radio />} label="Above Average" />
                                <FormControlLabel name="interpersonalSocialSkills" value="average" control={<Radio />} label="Average" />
                                <FormControlLabel name="interpersonalSocialSkills" value="belowAverage" control={<Radio />} label="Below Average" />
                                <FormControlLabel name="interpersonalSocialSkills" value="poor" control={<Radio />} label="Poor" />
                                <FormControlLabel name="interpersonalSocialSkills" value="notKnow" control={<Radio />} label="Not known" />
                            </RadioGroup>
                        </Grid>
                        <Grid className="referenceRadio" item xs={12} sm={12}>
                            <Typography variant='subtitle1'><b>Leadership Ability*</b></Typography> 
                            <RadioGroup onChange={handleChangeForm} value={leadershipAbility} sx={{flexDirection:"row"}}>
                                <FormControlLabel name="leadershipAbility" value="excellent" control={<Radio />} label="Excellent" />
                                <FormControlLabel name="leadershipAbility" value="aboveAverage" control={<Radio />} label="Above Average" />
                                <FormControlLabel name="leadershipAbility" value="average" control={<Radio />} label="Average" />
                                <FormControlLabel name="leadershipAbility" value="belowAverage" control={<Radio />} label="Below Average" />
                                <FormControlLabel name="leadershipAbility" value="poor" control={<Radio />} label="Poor" />
                                <FormControlLabel name="leadershipAbility" value="notKnow" control={<Radio />} label="Not known" />
                            </RadioGroup>
                        </Grid>
                        <Grid className="referenceRadio" item xs={12} sm={12}>
                            <Typography variant='subtitle1'><b>Cooperativeness*</b></Typography> 
                            <RadioGroup onChange={handleChangeForm} value={cooperativeness} sx={{flexDirection:"row"}}>
                                <FormControlLabel name="cooperativeness" value="excellent" control={<Radio />} label="Excellent" />
                                <FormControlLabel name="cooperativeness" value="aboveAverage" control={<Radio />} label="Above Average" />
                                <FormControlLabel name="cooperativeness" value="average" control={<Radio />} label="Average" />
                                <FormControlLabel name="cooperativeness" value="belowAverage" control={<Radio />} label="Below Average" />
                                <FormControlLabel name="cooperativeness" value="poor" control={<Radio />} label="Poor" />
                                <FormControlLabel name="cooperativeness" value="notKnow" control={<Radio />} label="Not known" />
                            </RadioGroup>
                        </Grid>
                        <Grid className="referenceRadio" item xs={12} sm={12}>
                            <Typography variant='subtitle1'><b>Honesty*</b></Typography> 
                            <RadioGroup onChange={handleChangeForm} value={honesty} sx={{flexDirection:"row"}}>
                                <FormControlLabel name="honesty" value="excellent" control={<Radio />} label="Excellent" />
                                <FormControlLabel name="honesty" value="aboveAverage" control={<Radio />} label="Above Average" />
                                <FormControlLabel name="honesty" value="average" control={<Radio />} label="Average" />
                                <FormControlLabel name="honesty" value="belowAverage" control={<Radio />} label="Below Average" />
                                <FormControlLabel name="honesty" value="poor" control={<Radio />} label="Poor" />
                                <FormControlLabel name="honesty" value="notKnow" control={<Radio />} label="Not known" />
                            </RadioGroup>
                        </Grid>
                        <Grid className="referenceRadio" item xs={12} sm={12}>
                            <Typography variant='subtitle1'><b>Emotional Stability*</b></Typography> 
                            <RadioGroup onChange={handleChangeForm} value={emotionalStability} sx={{flexDirection:"row"}}>
                                <FormControlLabel name="emotionalStability" value="excellent" control={<Radio />} label="Excellent" />
                                <FormControlLabel name="emotionalStability" value="aboveAverage" control={<Radio />} label="Above Average" />
                                <FormControlLabel name="emotionalStability" value="average" control={<Radio />} label="Average" />
                                <FormControlLabel name="emotionalStability" value="belowAverage" control={<Radio />} label="Below Average" />
                                <FormControlLabel name="emotionalStability" value="poor" control={<Radio />} label="Poor" />
                                <FormControlLabel name="emotionalStability" value="notKnow" control={<Radio />} label="Not known" />
                            </RadioGroup>
                        </Grid>
                        <Grid item xs={12} sm={12}>
                            <Typography variant='subtitle1'><b>Please describe your personal observations about the applicant’s emotional stability?*</b></Typography>
                            <TextField fullWidth onChange={handleChangeForm} value={personalObservations} name="personalObservations" multiline rows={4}/>
                        </Grid>
                        <Grid item xs={12} sm={12}>
                            <Typography variant='subtitle1'><b>What is your assessment of the applicant's ability, motivation and past performance?*</b></Typography>
                            <TextField fullWidth onChange={handleChangeForm} value={assessment} name="assessment" multiline rows={4} placeholder="Please include a candid assessment of the applicant's strengths and weaknesses."/>
                        </Grid>
                        <Grid item xs={12} sm={12}>
                            <Typography variant='subtitle1'><b>Are there any factors that might hinder the applicant's academic performance and/or interpersonal relationships?*</b></Typography>
                            <TextField fullWidth onChange={handleChangeForm} value={factors} name="factors" multiline rows={4}/>
                        </Grid>
                        <Grid item xs={12} sm={12}>
                            <Typography variant='subtitle1'><b>Would you recommend this applicant to be enrolled at Summit?*</b></Typography> 
                            <RadioGroup onChange={handleChangeForm} value={recommend} sx={{flexDirection:"row"}}>
                                <FormControlLabel name="recommend" value="higly" control={<Radio />} label="Highly recommended" />
                                <FormControlLabel name="recommend" value="recommended" control={<Radio />} label="Recommended" />
                                <FormControlLabel name="recommend" value="withReservations" control={<Radio />} label="Recommended, but with reservations" />
                                <FormControlLabel name="recommend" value="notRecommended" control={<Radio />} label="Not recommended" />
                            </RadioGroup>
                        </Grid>
                        <Grid item xs={12} sm={12}>
                            <Typography variant='subtitle1'><b>If you have any reservations or do not recommend this applicant, please explain:</b></Typography>
                            <TextField fullWidth onChange={handleChangeForm} value={reservations} name="reservations" multiline rows={4}/>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12}  sx={{marginTop:"20px", borderTop:"solid #E1A30B 5px"}} >
                    <FormGroup onChange={handleChangeForm}>
                        <FormControlLabel checked={certify} name ="certify" value={!certify} required control={<Checkbox/>} label="I hereby certify that all information herein is accurate and true to the best of my knowledge."/>                        
                        <Typography variant="caption">The mission of Summit International School of Ministry is to train Christian young people from national and international backgrounds to become spiritually free, entirely useful to local churches and organizations, and to become a mobilized, spiritual army. We appreciate your time in completing this reference form. If you have any questions, please e-mail us at admissions@summitpa.org.</Typography>
                    </FormGroup>
                </Grid>
                {
                    showMsg && 
                    <Grid item xs={12}>
                        <Alert severity={severity}>
                            {message}
                        </Alert>
                    </Grid>
                }
                <Grid item xs={12}>
                    <Button onClick={handleSubmit} variant="contained" color="customYellow">Submit</Button>
                </Grid>
            </Grid>
        </Container>
    )
}

export default Academic
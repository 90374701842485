import FilterListIcon from '@mui/icons-material/FilterList';
import { Box, Button, Menu, MenuItem } from '@mui/material';
import { useState } from 'react';

const Filter = ({currentYear,setCurrentYear}) => {

    const [anchorEl, setAnchorEl] = useState(null);

    const handleOpenFilter = (event)=>{
        setAnchorEl(event.currentTarget);
    }

    const handleCloseFilter = (e) => {
        const {value} = e.target;
        if(value){
            setCurrentYear(value);
        }else{
            setCurrentYear(currentYear);
        }
        setAnchorEl(null);
      };

    return (
    <Box sx={{marginBottom:"30px",marginTop:"50px"}}>
        <FilterListIcon sx={{cursor:"pointer",marginRight:"20px"}}/>
        <Button onClick={handleOpenFilter} variant='contained'>{currentYear}-{Number(currentYear)+1}</Button>
        {/* {
            filterOpen && <>
                <Box sx={{marginTop:"30px"}}>
                    OPEN
                </Box>
            </>
        } */}
        <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleCloseFilter}>
            <MenuItem onClick={handleCloseFilter} value={2023}>2023</MenuItem>
            <MenuItem onClick={handleCloseFilter} value={2024}>2024</MenuItem>
            <MenuItem onClick={handleCloseFilter} value={2025}>2025</MenuItem>
            <MenuItem onClick={handleCloseFilter} value={2026}>2026</MenuItem>
        </Menu>
    </Box>
    )
}

export default Filter
import { FormControlLabel, Grid, Radio, RadioGroup, Typography } from '@mui/material'
import { upperFirstLetter } from '../../../utils/qViewerFunctions'

const PersonalAdmin = ({personal}) => {

    return (
        <Grid container spacing={2}>
            <Grid item>
                <Typography variant='h5' color="customYellow.main" sx={{marginBottom:"30px"}}><b>Personal</b></Typography>
                <Typography variant='subtitle1'><b>First Name</b></Typography>
                <Typography variant='subtitle1' sx={{marginLeft:"20px"}}>{upperFirstLetter(personal?.firstName)}</Typography>
                <Typography variant='subtitle1'><b>Middle Name</b></Typography>
                <Typography variant='subtitle1' sx={{marginLeft:"20px"}}>{upperFirstLetter(personal?.middleName)}</Typography>
                <Typography variant='subtitle1'><b>Last Name</b></Typography>
                <Typography variant='subtitle1' sx={{marginLeft:"20px"}}>{upperFirstLetter(personal?.lastName)}</Typography>
                <Typography variant='subtitle1'><b>Phone Number</b></Typography>
                <Typography variant='subtitle1' sx={{marginLeft:"20px"}}>(+{personal?.countryCode}) {personal?.phoneNumber}</Typography>
                <Typography variant='subtitle1'><b>Email</b></Typography>
                <Typography variant='subtitle1' sx={{marginLeft:"20px"}}>{personal?.email}</Typography>
                <Typography variant='subtitle1'><b>Gender</b></Typography>
                <Typography variant='subtitle1' sx={{marginLeft:"20px"}}>{ upperFirstLetter(personal?.gender)}</Typography>
                <Typography variant='subtitle1'><b>Date of Birth</b></Typography>
                <Typography variant='subtitle1' sx={{marginLeft:"20px"}}>{personal?.dateOfBirth}</Typography>
                <Typography variant='subtitle1'><b>Country of Birth</b></Typography>
                <Typography variant='subtitle1' sx={{marginLeft:"20px"}}>{personal?.countryOfBirth}</Typography>
                <Typography variant='subtitle1'><b>Citizenship</b></Typography>
                <Typography variant='subtitle1' sx={{marginLeft:"20px"}}>{personal?.citizenship}</Typography>
                <Typography variant='subtitle1'><b>Preferred way of contact</b></Typography>
                <Typography variant='subtitle1' sx={{marginLeft:"20px"}}>{upperFirstLetter(personal?.preferredWayOfContact)}</Typography>
                <Typography variant='subtitle1'><b>Address</b></Typography>
                <Typography variant='subtitle1' sx={{marginLeft:"20px"}}>{upperFirstLetter(personal?.address)}</Typography>
                {
                    personal?.citizenship !== "United States" && <>
                        <Typography variant='subtitle1'><b>Do you plan on being in the US on a F1 Student Visa?</b></Typography>
                        <Typography variant='subtitle1' sx={{marginLeft:"20px"}}>{upperFirstLetter(personal?.f1Visa)}</Typography>
                        {
                            personal?.f1Visa === "no" && <>
                                <Typography variant='subtitle1'><b>Please Elaborate</b></Typography>
                                <Typography variant='subtitle1' sx={{marginLeft:"20px"}}>{personal?.f1VisaExplain}</Typography>
                            </>
                        }
                    </>
                }
                <Typography variant='subtitle1'><b>What country are you currently living in?</b></Typography>
                <Typography variant='subtitle1' sx={{marginLeft:"20px"}}>{personal?.countryLivingIn}</Typography>
                <Typography variant='subtitle1'><b>Is English your first language?</b></Typography>
                <Typography variant='subtitle1' sx={{marginLeft:"20px"}}>{upperFirstLetter(personal?.englishFistLanguage)}</Typography>
                {
                    personal?.englishFistLanguage === "no" && <>
                        <Typography variant='subtitle1'><b>Will this be your first time living in an English-speaking community?</b></Typography>
                        <Typography variant='subtitle1' sx={{marginLeft:"20px"}}>{upperFirstLetter(personal?.firstTimeEnglishCommunity)}</Typography>
                        <Typography variant='subtitle1'><b>How well do you speak English?</b></Typography>
                        <RadioGroup value={personal?.speakEnglish} sx={{flexDirection:"row",marginLeft:"20px"}}>
                            <FormControlLabel disabled value="1" control={<Radio />} label="1" />
                            <FormControlLabel disabled value="2" control={<Radio />} label="2" />
                            <FormControlLabel disabled value="3" control={<Radio />} label="3" />
                            <FormControlLabel disabled value="4" control={<Radio />} label="4" />
                            <FormControlLabel disabled value="5" control={<Radio />} label="5" />
                        </RadioGroup>
                        <Typography variant='subtitle1'><b>How well do you understand English?</b></Typography>
                        <RadioGroup value={personal?.understandEnglish} sx={{flexDirection:"row",marginLeft:"20px"}}>
                            <FormControlLabel disabled value="1" control={<Radio />} label="1" />
                            <FormControlLabel disabled value="2" control={<Radio />} label="2" />
                            <FormControlLabel disabled value="3" control={<Radio />} label="3" />
                            <FormControlLabel disabled value="4" control={<Radio />} label="4" />
                            <FormControlLabel disabled value="5" control={<Radio />} label="5" />
                        </RadioGroup>
                        <Typography variant='subtitle1'><b>How well do you read English?</b></Typography>
                        <RadioGroup value={personal?.readEnglish} sx={{flexDirection:"row",marginLeft:"20px"}}>
                            <FormControlLabel disabled value="1" control={<Radio />} label="1" />
                            <FormControlLabel disabled value="2" control={<Radio />} label="2" />
                            <FormControlLabel disabled value="3" control={<Radio />} label="3" />
                            <FormControlLabel disabled value="4" control={<Radio />} label="4" />
                            <FormControlLabel disabled value="5" control={<Radio />} label="5" />
                        </RadioGroup>
                        <Typography variant='subtitle1'><b>How well do you write English?</b></Typography>
                        <RadioGroup value={personal?.writeEnglish} sx={{flexDirection:"row",marginLeft:"20px"}}>
                            <FormControlLabel disabled value="1" control={<Radio />} label="1" />
                            <FormControlLabel disabled value="2" control={<Radio />} label="2" />
                            <FormControlLabel disabled value="3" control={<Radio />} label="3" />
                            <FormControlLabel disabled value="4" control={<Radio />} label="4" />
                            <FormControlLabel disabled value="5" control={<Radio />} label="5" />
                        </RadioGroup>
                    </>
                }
            </Grid>
        </Grid>
    )
}

export default PersonalAdmin
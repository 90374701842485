import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter } from 'react-router-dom';
import { UserProvider } from './contexts/user';
import { ThemeProvider, createTheme } from '@mui/material';
import { ApplicationProvider } from './contexts/application';

const root = ReactDOM.createRoot(document.getElementById('root'));

const theme = createTheme({
  palette:{
    primary:{
      main:"#1A2B50"
    } ,
    secondary:{
      main:"#FBBD08",
    },
    customGray:{
      main:"#E0E0E0"
    },
    customYellow:{
      main:"#FBBD08",
      contrastText:"white"
    },

  },
  typography:{
    fontFamily:['Lato','Helvetica Neue','Arial','Helvetica'].join(',')
  }
})

root.render(
  <React.StrictMode>
    <BrowserRouter>
      <UserProvider>
        <ApplicationProvider>
          <ThemeProvider theme={theme}>
            <App />
          </ThemeProvider>
        </ApplicationProvider>
      </UserProvider>
    </BrowserRouter>
  </React.StrictMode>
);

reportWebVitals();

import { useState } from "react";
import { updateFinancialDocumentsColumn } from "../../axios/axiosFunctions";
import { Box, MenuItem, Select } from "@mui/material";

const FinancialDocumentsColumn = (props) => {
    
    const [currentValue, setCurrentValue] = useState(props.value);

    const handleSelectChange = async(e)=>{
        const {value} = e.target;
        setCurrentValue(value);
        const data = {
            financialDocuments: value
        }
        await updateFinancialDocumentsColumn(props.row.id,data);
        props.api.updateRows([{id:props.row.id,financial:value}]);
    }

    return (
        <Box>
            <Select onChange={handleSelectChange} value={currentValue} fullWidth sx={{boxShadow: 'none', '.MuiOutlinedInput-notchedOutline': { border: 0 }}} >
                <MenuItem value="pending">Pending Review</MenuItem>
                <MenuItem value="reviewed">Reviewed</MenuItem>
                <MenuItem value="followUp">Follow Up</MenuItem>
            </Select>
        </Box>
    )
}

export default FinancialDocumentsColumn
import { useState } from 'react';
import { Box, MenuItem, Select } from '@mui/material'
import { updateProgramColumn } from '../../axios/axiosFunctions';

const ProgramColumn = (props) => {

    const [currentValue, setCurrentValue] = useState(props.value);

    const handleSelectChange = async(e)=>{
        const {value} = e.target;
        setCurrentValue(value);
        const data = {
            program: value
        }
        await updateProgramColumn(props.row.id,data);
        props.api.updateRows([{id:props.row.id,program:value}]);
    }

    return (
        <Box>
            <Select onChange={handleSelectChange} value={currentValue} fullWidth sx={{boxShadow: 'none', '.MuiOutlinedInput-notchedOutline': { border: 0 }}} >
                <MenuItem value="traditional">Traditional</MenuItem>
                <MenuItem value="sabbatical">Sabbatical</MenuItem>
                <MenuItem value="high school gap year">One-Year Program</MenuItem>
                <MenuItem value="open">Open</MenuItem>
            </Select>
        </Box>
    )
}

export default ProgramColumn
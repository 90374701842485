import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Alert, Box, Button, Container, FormControl, Grid, IconButton, InputAdornment, InputLabel, OutlinedInput, Typography } from '@mui/material';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { getUserById, updateUser } from '../../axios/axiosFunctions';

const defaultForgotPasswordForm = {
    password:"",
    confirmPassword:""
}  

const ForgotPassword = () => {

    const {id}=useParams();
    const navigate = useNavigate();
    const [forgorPasswordForm, setForgorPasswordForm] = useState(defaultForgotPasswordForm);
    const [showPassword, setShowPassword] = useState(false);
    const [fullUser, setFullUser] = useState();
    const [showPasswordRetype, setShowPasswordRetype] = useState(false);
    const [showError, setShowError] = useState(false);
    const [error, setError] = useState("Password not valid");
    const { password,confirmPassword} = forgorPasswordForm;

    useEffect(() => {
        const checkUser = async()=>{
            const resp = await getUserById(id);
            if(!resp){
                navigate('/');
                return
            }
            setFullUser(resp.userInfo);
        }
        checkUser()
    }, [id,navigate]);
    
    const handleClickShowPassword = () => setShowPassword((show) => !show);
    const handleClickShowPasswordRetype = () => setShowPasswordRetype((show) => !show);

    const passwordMatch = ()=>{
        return confirmPassword !== "" && confirmPassword !== password; 
    }

    const passwordRequirements = ()=>{
        const regex = /^(?=.*[A-Z])(?=.*[!@#$%^&*()_+{}\[\]:;<>,.?~\\/-]).*$/;
        return !regex.test(password);
    }

    const handleChangeForm = (e)=>{
        const {name,value} = e.target;
        setForgorPasswordForm({...forgorPasswordForm, [name]:value});
    }

    const handleSubmit = async(e)=>{
        e.preventDefault();
        if(passwordMatch() || passwordRequirements()){
            setShowError(true);
            setTimeout(() => {
                setError("Password not valid");
                setShowError(false);
            }, 2000);
            return;
        }

        const data ={
            ...fullUser,
            password
        }
        const {user} = await updateUser(fullUser._id,data);

        if(!user){
            setShowError(true);
            setTimeout(() => {
                setError("Error while updating password, please try later or check your internet connettion");
                setShowError(false);
            }, 2000);
            return;
        }

        navigate('/');
    }

    return (
        <Container>
            <Box display="flex" alignItems="center" flexDirection="column" sx={{height:{xs:"calc(100vh - 88px)", sm:"calc(100vh - 96px)"}}} justifyContent="center">
                <Typography variant='h4' sx={{marginBottom:{xs:"20px",sm:"100px"}}}>Forgot Password</Typography>
                <form onSubmit={handleSubmit} style={{display:'flex',flexDirection:"column"}}>
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={6}>
                            <FormControl fullWidth variant="outlined"> 
                            <InputLabel>Password*</InputLabel>
                            <OutlinedInput
                                type={showPassword ? 'text' : 'password'} 
                                fullWidth
                                label="Password*"
                                name='password'
                                required
                                onChange={handleChangeForm}
                                endAdornment={
                                <InputAdornment position="end">
                                    <IconButton
                                    onClick={handleClickShowPassword}
                                    >
                                    {showPassword ? <VisibilityOff /> : <Visibility />}
                                    </IconButton>
                                </InputAdornment>
                                }
                            />
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <FormControl fullWidth variant="outlined"> 
                            <InputLabel>Retype Password*</InputLabel>
                            <OutlinedInput
                                required
                                type={showPasswordRetype ? 'text' : 'password'}
                                label="Retype Password*"
                                fullWidth
                                name='confirmPassword'
                                onChange={handleChangeForm}
                                endAdornment={
                                <InputAdornment position="end">
                                    <IconButton
                                    onClick={handleClickShowPasswordRetype}
                                    >
                                    {showPassword ? <VisibilityOff /> : <Visibility />}
                                    </IconButton>
                                </InputAdornment>
                                }
                            />
                            </FormControl>
                        </Grid>
                        {
                            passwordMatch() &&
                            <Grid item xs={12} sm={12}>
                            <Alert severity="error">
                                Passwords do NOT match.
                            </Alert>
                            </Grid>
                        }
                        {
                            passwordRequirements() &&
                            <Grid item xs={12} sm={12}>
                            <Alert severity="info">
                                The password must include at least one special character and a capital letter.
                            </Alert>
                            </Grid>
                        }
                    </Grid>
                    <Button type='submit' sx={{marginRight:"20px",alignSelf:"center",marginTop:{xs:"20px",sm:"100px"}}} size="large" variant="contained">UPDATE PASSWORD</Button>
                    {
                        showError &&
                        <Alert sx={{marginTop:"20px"}} severity="error">
                            {error}
                        </Alert>
                    }
                </form>
            </Box>
        </Container>
    )
}

export default ForgotPassword
import { useEffect, useState } from 'react';
import { Alert, Autocomplete, Box, Button, FormControl, FormControlLabel, FormLabel, Grid, InputLabel, MenuItem, MobileStepper, Radio, RadioGroup, Select, TextField, Typography } from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { countries } from '../utils/countries';
import { useNavigate } from 'react-router-dom';
import { questionaireSteps as defaultQuestionaireSteps  } from '../utils/questionaireSteps';
import dayjs from 'dayjs';
import BannerStep from './BannerStep';

const defaultPersonalForm = {
    firstName: "",
    middleName :"",
    lastName: "",
    countryCode:"",
    phoneNumber: "",
    email:"",
    gender:"",
    dateOfBirth:"",
    countryOfBirth:"",
    citizenship:"",
    preferredWayOfContact:"",
    f1Visa:"",
    f1VisaExplain:"",
    countryLivingIn:"",
    englishFistLanguage:"",
    firstTimeEnglishCommunity:"",
    speakEnglish:"",
    understandEnglish:"",
    readEnglish:"",
    writeEnglish:"",
}

const PersonalInformation = ({data,updateApplication,userInfo,questionaireSteps,setQuestionaireSteps}) => {

    const navigate = useNavigate();
    const [personalForm, setPersonalForm] = useState(defaultPersonalForm);
    const [countryOfBirth, setCountryOfBirth] = useState("");
    const [citizenship, setCitizenship] = useState("");
    const [countryLivingIn, setCountryLivingIn] = useState("");
    const [, setCountryCodeS] = useState();
    const [showError, setShowError] = useState(false);
    const [activeStep, setActiveStep] = useState(0);
    const steps = 2;

    const {firstName,middleName,lastName,phoneNumber,countryCode,email,gender,dateOfBirth,preferredWayOfContact,f1Visa,
        f1VisaExplain,englishFistLanguage,firstTimeEnglishCommunity,speakEnglish,understandEnglish,readEnglish,writeEnglish,} = personalForm;

    useEffect(() => {

        if(data){
            setPersonalForm({
                firstName: data.firstName || userInfo.firstName || "", 
                middleName: data.middleName || userInfo.middleName || "", 
                lastName: data.lastName || userInfo.lastName || "",
                phoneNumber: data.phoneNumber || userInfo.phoneNumber || "",
                countryCode : data.countryCode || userInfo.countryCode || "",
                email: data.email || userInfo.email || "",
                gender: data.gender || "",
                dateOfBirth: data.dateOfBirth || "",
                countryOfBirth: data.countryOfBirth || "",
                citizenship: data.citizenship || "",
                preferredWayOfContact: data.preferredWayOfContact || "",
                f1Visa: data.f1Visa || "",
                f1VisaExplain: data.f1VisaExplain || "",
                countryLivingIn: data.countryLivingIn || "",
                englishFistLanguage: data.englishFistLanguage || "",
                firstTimeEnglishCommunity: data.firstTimeEnglishCommunity || "",
                speakEnglish: data.speakEnglish || "",
                understandEnglish: data.understandEnglish || "",
                readEnglish: data.readEnglish || "",
                writeEnglish: data.writeEnglish || "",
            });
            const findCountryOfBirth = countries.find(country=>country.label === data.countryOfBirth) || {code: 'US', label: 'United States', phone: '1', suggested: true};
            const findCitizenship = countries.find(country=>country.label === data.citizenship) || {code: 'US', label: 'United States', phone: '1', suggested: true};
            const findCountryLivingIn = countries.find(country=>country.label === data.countryLivingIn);
            const findCountryCode = countries.find(country=>country.phone === data.countryCode || country.phone === userInfo.countryCode);

            setCountryOfBirth(findCountryOfBirth);
            setCitizenship(findCitizenship);
            setCountryLivingIn(findCountryLivingIn);
            setCountryCodeS(findCountryCode);
        }
    }, [data,userInfo]);

    useEffect(() => {
        if(f1Visa === "" || f1Visa === "no"){
            setQuestionaireSteps(defaultQuestionaireSteps);
        }else{
            if(questionaireSteps.length === 10){
                let newArray = [...questionaireSteps];
                newArray.push({
                    label:"Visa",
                    numberSteps: 1,
                    route:"visa",
                    requiredStepsLabel: "visa",
                });
                setQuestionaireSteps(newArray)
            }
        }
    }, [f1Visa,setQuestionaireSteps,questionaireSteps])
    

    const handleNext = () => {
        
        handleSubmit();
        if(!checkRequired()){
            setShowError(true);
            setTimeout(() => {
                setShowError(false);
            }, 2000);
            return;
        }

        if(activeStep === steps-1){
            navigate('/summit_and_you');
            return;
        }
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
    };
    
    const handleBack = () => {
        handleSubmit();
        if(activeStep === 0)
        {
            navigate('/application');
            return;
        }
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const checkRequired = ()=>{
        if(activeStep === 0){
            return firstName !== "" && lastName !== "" && countryCode !== "" && phoneNumber !== "" && email !== "";
        }
        if(activeStep === 1){
            return gender !== "" && ((citizenship.label !== "United States" && ((f1Visa === "no" && f1VisaExplain !== "") || f1Visa === "yes" )) || citizenship.label === "United States") && preferredWayOfContact !== "" 
            && (englishFistLanguage === "yes" || (englishFistLanguage === "no" && firstTimeEnglishCommunity !== "" && speakEnglish !== "" 
            && understandEnglish !== "" && readEnglish !== "" && writeEnglish !== ""))
        }
        return true;
    }

    const handleChangeForm = (e)=>{
        const {name,value} = e.target;
        setPersonalForm({...personalForm, [name]:value});
    }

    const handleDateofBirht = ({$y,$M,$D})=>{
        const m = $M < 9 ? `0${$M+1}` : $M+1; 
        const d = $D < 10 ? `0${$D}` : $D;
        setPersonalForm({...personalForm, dateOfBirth:`${$y}-${m}-${d}`});
    }
    
    const handleCountryOfBirth = (_,country)=>{
        if(country){
            const {label} = country;
            setCountryOfBirth(country);
            setPersonalForm({...personalForm, countryOfBirth:label});
        }else{
            setCountryOfBirth("");
            setPersonalForm({...personalForm, countryOfBirth:""});
        }
    }

    const handleCitizenship = (_,country)=>{
        if(country){
            const {label} = country;
            setCitizenship(country);
            setPersonalForm({...personalForm, citizenship:label,f1Visa:""});
        }else{
            setCitizenship("");
            setPersonalForm({...personalForm, citizenship:"",f1Visa:""});
        }
    }

    const handleCountryLivingIn = (_,country)=>{
        if(country){
            const {label} = country;
            setCountryLivingIn(country);
            setPersonalForm({...personalForm, countryLivingIn:label});
        }else{
            setCountryLivingIn("");
            setPersonalForm({...personalForm, countryLivingIn:""});
        }
    }

    const handleCountryCode = (_,country)=>{
        if(country){
            const {phone} = country;
            setCountryCodeS(country);
            setPersonalForm({...personalForm, countryCode:phone});
        }else{
            setCountryCodeS("");
            setPersonalForm({...personalForm, countryCode:""});
          }
    }

    const handleSubmit = async()=>{

        const dataSubmit={
            personal:{
                ...personalForm,
            }
        }    
        updateApplication(dataSubmit);
    }

    return (
        
        <Grid container flexDirection="column-reverse" sx={{paddingBottom:"20px"}}>
            <Grid item xs={12}>
                <MobileStepper
                    variant="dots"
                    steps={steps}
                    position="static"
                    activeStep={activeStep}
                    sx={{ width:"100%", flexGrow: 1 , marginTop:"50px"}}
                    nextButton={
                        <Button size="small" onClick={handleNext}>
                            {activeStep === steps-1 ? 'Next' : 'Next' }
                        </Button>
                    }
                    backButton={
                        <Button size="small" onClick={handleBack}>
                            {activeStep === 0 ? '' : 'Back'}
                        </Button>
                    }
                />
            </Grid>
            {
                showError &&
                <Grid item xs={12}>
                    <Alert severity='error' sx={{marginTop:"20px"}}>
                        One or more REQUIRED fields are empty.
                    </Alert>
                </Grid>
            }
            <Grid item xs={12}>
                <>
                {
                    activeStep === 0 && <>
                        <Grid container spacing={2}>
                            <Grid item xs={12} sm={12}>
                                <TextField required fullWidth value={firstName} onChange={handleChangeForm} name='firstName' label="First name"/>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <TextField fullWidth value={middleName} onChange={handleChangeForm} name='middleName' label="Middle name"/>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <TextField required fullWidth value={lastName} onChange={handleChangeForm} name='lastName' label="Last name"/>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                            <Grid container spacing={2}>
                                <Grid item sm={4}>
                                    <Autocomplete onChange={handleCountryCode} options={countries} value={countryCode} renderOption={({key,...optionProps},option)=>{
                                        return(
                                                <Box key={key} component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...optionProps}>
                                                    <img loading="lazy" width="20"
                                                    srcSet={`https://flagcdn.com/w40/${option.code.toLowerCase()}.png 2x`}
                                                    src={`https://flagcdn.com/w20/${option.code.toLowerCase()}.png`}
                                                    alt=""/>
                                                    +{option.phone} / {option.label}
                                                </Box>
                                            )
                                        }}
                                        renderInput={params=>(
                                            <TextField
                                                {...params}
                                                label="Country Code*"
                                            />
                                        )}
                                    />
                                </Grid>
                                <Grid item sm={8}>
                                <TextField required fullWidth value={phoneNumber} onChange={handleChangeForm} name='phoneNumber' label="Phone Number"/>
                                </Grid>
                            </Grid>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <TextField required fullWidth value={email} onChange={handleChangeForm} name='email' label="Email"/>
                            </Grid>
                        </Grid>
                    </>
                }
                {
                    activeStep === 1 && <>
                        <Grid container spacing={2}>    
                            <Grid item xs={12}>
                                <Typography variant='subtitle1'>Gender*</Typography> 
                                <RadioGroup sx={{flexDirection:"row"}} value={gender} onChange={handleChangeForm}>
                                    <FormControlLabel value="male" name="gender" control={<Radio />} label="Male" />
                                    <FormControlLabel value="female" name="gender" control={<Radio />} label="Female" />
                                </RadioGroup>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                    <DatePicker onChange={handleDateofBirht} value={ dateOfBirth !== "" ? dayjs(dateOfBirth) : null}  sx={{width:"100%"}} label="Date of Birth"/>
                                </LocalizationProvider>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <Autocomplete onChange={handleCountryOfBirth} options={countries} value={countryOfBirth} renderOption={({key,...optionProps},option)=>{
                                    return(
                                            <Box key={key} component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...optionProps}>
                                                <img loading="lazy" width="20"
                                                srcSet={`https://flagcdn.com/w40/${option.code.toLowerCase()}.png 2x`}
                                                src={`https://flagcdn.com/w20/${option.code.toLowerCase()}.png`}
                                                alt=""/>
                                                {option.label}
                                            </Box>
                                        )
                                    }}
                                    renderInput={params=>(
                                        <TextField
                                            {...params}
                                            label="Country of Birth"
                                        />
                                    )}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <Autocomplete required onChange={handleCitizenship} options={countries} value={citizenship} renderOption={({key,...optionProps},option)=>{
                                    return(
                                        <Box
                                            key={key}
                                            component="li"
                                            sx={{ '& > img': { mr: 2, flexShrink: 0 } }}
                                            {...optionProps}
                                        >
                                            <img
                                            loading="lazy"
                                            width="20"
                                            srcSet={`https://flagcdn.com/w40/${option.code.toLowerCase()}.png 2x`}
                                            src={`https://flagcdn.com/w20/${option.code.toLowerCase()}.png`}
                                            alt=""
                                            />
                                            {option.label}
                                        </Box>
                                    )
                                    }}
                                    renderInput={params=>(
                                        <TextField
                                            {...params}
                                            label="Citizenship*"
                                        />
                                    )}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <FormControl fullWidth>
                                    <InputLabel>Preferred way of contact*</InputLabel>
                                    <Select onChange={handleChangeForm} value={preferredWayOfContact} name='preferredWayOfContact' label="Preferred way of contact*">
                                        <MenuItem value="email">Email</MenuItem>
                                        <MenuItem value="text">Text (US and Canada only)</MenuItem>
                                        <MenuItem value="whatsapp">WhatsApp</MenuItem>
                                        <MenuItem value="phone">Phone</MenuItem>
                                    </Select>
                                </FormControl>
                            </Grid>
                            {
                                citizenship && citizenship.label !== "United States" &&
                                <Grid item xs={12}>
                                    <Typography variant='subtitle1'>Do you plan on being in the US on a F1 Student Visa?*</Typography> 
                                    <RadioGroup onChange={handleChangeForm} value={f1Visa} sx={{flexDirection:"row"}}>
                                        <FormControlLabel name="f1Visa" value="yes" control={<Radio />} label="Yes" />
                                        <FormControlLabel name="f1Visa" value="no" control={<Radio />} label="No" />
                                    </RadioGroup>
                                </Grid>
                            }
                            {
                                f1Visa === "no" && 
                                 <Grid item xs={12}>
                                    <Typography variant='subtitle1'>Please Elaborate*</Typography>
                                    <TextField fullWidth onChange={handleChangeForm} inputProps={{maxLength:500}} helperText={`Max Length ${f1VisaExplain.length}/500`} value={f1VisaExplain} name="f1VisaExplain" multiline rows={4}/>
                                </Grid>
                            }
                            <Grid item xs={12} sm={6}>
                                <Typography variant='subtitle1' sx={{marginBottom:"10px"}}>What country are you currently living in?</Typography> 
                                <Autocomplete onChange={handleCountryLivingIn} options={countries} value={countryLivingIn} renderOption={({key,...optionProps},option)=>{
                                        return(
                                            <Box
                                                key={key}
                                                component="li"
                                                sx={{ '& > img': { mr: 2, flexShrink: 0 } }}
                                                {...optionProps}
                                            >
                                                <img
                                                loading="lazy"
                                                width="20"
                                                srcSet={`https://flagcdn.com/w40/${option.code.toLowerCase()}.png 2x`}
                                                src={`https://flagcdn.com/w20/${option.code.toLowerCase()}.png`}
                                                alt=""
                                                />
                                                {option.label}
                                            </Box>
                                        )
                                    }}
                                    renderInput={params=>(
                                        <TextField
                                            {...params}
                                            label="Choose one"
                                        />
                                    )}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <Typography variant='subtitle1'>Is English your first language?*</Typography> 
                                <RadioGroup onChange={handleChangeForm} value={englishFistLanguage} sx={{flexDirection:"row"}}>
                                    <FormControlLabel name="englishFistLanguage" value="yes" control={<Radio />} label="Yes" />
                                    <FormControlLabel name="englishFistLanguage" value="no" control={<Radio />} label="No" />
                                </RadioGroup>
                            </Grid>
                            {
                                englishFistLanguage === "no" &&
                                <>
                                    <Grid item xs={12}>
                                        <Typography variant='subtitle1'>Will this be your first time living in an English-speaking community?*</Typography> 
                                        <RadioGroup onChange={handleChangeForm} value={firstTimeEnglishCommunity} sx={{flexDirection:"row"}}>
                                            <FormControlLabel name="firstTimeEnglishCommunity" value="yes" control={<Radio />} label="Yes" />
                                            <FormControlLabel name="firstTimeEnglishCommunity" value="no" control={<Radio />} label="No" />
                                        </RadioGroup>
                                    </Grid>
                                    <Grid item>
                                        <FormLabel sx={{fontSize:"0.85rem"}}>
                                        Please check on the scale below the number that best answers the question, 1 being basic English and 5 being fluent English.
                                        </FormLabel>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Typography variant='subtitle1'>How well do you speak English?*</Typography> 
                                        <RadioGroup onChange={handleChangeForm} value={speakEnglish} sx={{flexDirection:"row"}}>
                                            <FormControlLabel name="speakEnglish" value="1" control={<Radio />} label="1" />
                                            <FormControlLabel name="speakEnglish" value="2" control={<Radio />} label="2" />
                                            <FormControlLabel name="speakEnglish" value="3" control={<Radio />} label="3" />
                                            <FormControlLabel name="speakEnglish" value="4" control={<Radio />} label="4" />
                                            <FormControlLabel name="speakEnglish" value="5" control={<Radio />} label="5" />
                                        </RadioGroup>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Typography variant='subtitle1'>How well do you understand English?*</Typography> 
                                        <RadioGroup onChange={handleChangeForm} value={understandEnglish} sx={{flexDirection:"row"}}>
                                            <FormControlLabel name="understandEnglish" value="1" control={<Radio />} label="1" />
                                            <FormControlLabel name="understandEnglish" value="2" control={<Radio />} label="2" />
                                            <FormControlLabel name="understandEnglish" value="3" control={<Radio />} label="3" />
                                            <FormControlLabel name="understandEnglish" value="4" control={<Radio />} label="4" />
                                            <FormControlLabel name="understandEnglish" value="5" control={<Radio />} label="5" />
                                        </RadioGroup>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Typography variant='subtitle1'>How well do you read English?*</Typography> 
                                        <RadioGroup onChange={handleChangeForm} value={readEnglish} sx={{flexDirection:"row"}}>
                                            <FormControlLabel name="readEnglish" value="1" control={<Radio />} label="1" />
                                            <FormControlLabel name="readEnglish" value="2" control={<Radio />} label="2" />
                                            <FormControlLabel name="readEnglish" value="3" control={<Radio />} label="3" />
                                            <FormControlLabel name="readEnglish" value="4" control={<Radio />} label="4" />
                                            <FormControlLabel name="readEnglish" value="5" control={<Radio />} label="5" />
                                        </RadioGroup>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Typography variant='subtitle1'>How well do you write English?*</Typography> 
                                        <RadioGroup onChange={handleChangeForm} value={writeEnglish} sx={{flexDirection:"row"}}>
                                            <FormControlLabel name="writeEnglish" value="1" control={<Radio />} label="1" />
                                            <FormControlLabel name="writeEnglish" value="2" control={<Radio />} label="2" />
                                            <FormControlLabel name="writeEnglish" value="3" control={<Radio />} label="3" />
                                            <FormControlLabel name="writeEnglish" value="4" control={<Radio />} label="4" />
                                            <FormControlLabel name="writeEnglish" value="5" control={<Radio />} label="5" />
                                        </RadioGroup>
                                    </Grid>
                                </>
                            }
                        </Grid>
                    </>
                }
                </>        
            </Grid>
            <Grid item xs={12} sx={{margin:{xs:"0px 0px 50px 0px",sm:"50px 0px"}}}>
                <BannerStep title="Personal" stepNumber={activeStep+1} totalSteps={2}/>
            </Grid>
        </Grid>
    )
}

export default PersonalInformation

import { Accordion, AccordionDetails, AccordionSummary, Checkbox, Divider, FormControlLabel, Grid, Typography } from "@mui/material";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';

const ReferencesAdmin = ({academic,pastoral,employer,marriage,family}) => {
    
    const mapHowWell = (answer)=>{
        if(answer === "veryWell"){
            return "Very Well"
        }
        return answer.toUpperCase();
    }

    const mapDescribeCommitmentInChurch = (answer)=>{

        const map = {
            veryInvolved: "Attends regularly, and is very involved with church activities.",
            seldomParticipates: "Attends regularly, but seldom participates in church activities.",
            irregular: "Irregular in attendance."
        };
        return map[answer] || "";
    }

    const mapChristianWalk = (answer) =>{
        const map ={
            high: "Maintains high moral & spiritual standards",
            consistent: "Maintains consistent moral & spiritual standards",
            inconsistent: "Inconsistent in their Christian walk",
            notAtAllCommited: "Not at all committed to a consistent Christian walk",
            notKnow: "Not known"
        };

        return map[answer] || "";
    }

    const mapChoiceOfFriends = (answer) =>{
        const map = {
            discerning:"Discerning in friendships",
            carefully:"Chooses friends carefully",
            lacksDiscretion:"Lacks discretion",
            questionable:"Questionable",
            notKnow:"Not known",
        }
        return map[answer] || "";
    }

    const mapChristianAttitude = (answer)=>{
        const map ={
            consistent:"Consistent Christ-like attitude",
            somewhatConsistent:"Somewhat consistent attitude",
            inconsistent:"Inconsistent attitude",
            notReflectChrist:"Attitude does not reflect Christ",
            notKnow:"Not known",
        }

        return map[answer] || "";
    }
    
    const mapCooperation = (answer)=>{
        const map ={
            wellWithOthers:"Works well with others",
            cooperate:"Generally willing to cooperate",
            avoidParticipation:"Avoids group participation",
            notWellWithOthers:"Does not work well with others",
            notKnow:"Not known",
        }

        return map[answer] || "";
    }

    const mapDesireToLearn = (answer) =>{
        const map = {
            strong:"Strong desire",
            showsInterest:"Shows interest",
            needsEncouragement:"Needs encouragement",
            lacksInterest:"Lacks interest",
            notKnow:"Not known",
        }
        return map[answer] || "";
    }

    const mapDiligence = (answer) =>{
        const map = {
            very:"Very diligent",
            somewhat:"Somewhat diligent",
            onlyWhatisAsked:"Does only what is asked",
            procrastinates:"Procrastinates often, does not follow through",
            notKnow:"Not known",
        }
        return map[answer] || "";
    }

    const mapLeadersAbility = (answer)=>{
        const map ={
            excellent:"Excellent leader",
            initiative:"Takes initiative to lead",
            onlyWhenAsked:"Leads only when asked",
            noEffort:"Makes no effort to lead",
            notKnow:"Not known",
        }
        return map[answer] || "";
    }

    const mapRespectForAutority = (answer)=>{
        const map ={
            very:"Very respectful",
            generally:"Generally respectful",
            oftenCritical:"Often critical",
            disrespectful:"Disrespectful",
            notKnow:"Not known",
        };

        return map[answer] || "";
    }

    const mapSocialSkills = (answer)=>{
        const map ={
            excellent:"Excellent",
            wellAdjusted:"Well adjusted",
            withdraw:"Withdrawn",
            poor:"Poor social skills",
            notKnow:"Not known",
        };

        return map[answer] || "";
    }

    const mapWorkEthic = (answer)=>{
        const map ={
            outstanding:"Outstanding",
            veryGood:"Very good",
            good:"Good",
            poor:"Poor",
            notKnow:"Not known",
        };

        return map[answer] || "";
    }

    const mapEmotionalStability = (answer)=>{
        const map ={
            veryStable:"Very stable",
            stableButStruggles:"Stable, but struggles at times",
            struggles:"Struggles emotionally",
            unstable:"Emotionally unstable",
            notKnow:"Not known",
        };

        return map[answer] || "";
    }


    const mapRecommend = (answer)=>{
        const map = {
            higly:"Highly recommended",
            recommended:"Recommended",
            withReservations:"Recommended, but with reservations",
            notRecommended:"Not recommended",
        }
        return map[answer] || "";
    }

    const mapAssessment = (answer)=>{
        const map = {
            excellent:"Excellent",
            aboveAverage:"Above Average",
            average:"Average",
            belowAverage:"Below Average",
            poor:"Poor",
            notKnow:"Not Know",
        }
        return map[answer]
    }

    return (
    <Grid container spacing={2}>
        <Grid item xs={10}>
            <Typography variant='h5' color="customYellow.main" sx={{marginBottom:"30px"}}><b>References</b></Typography>
        </Grid>
        <Grid item xs={10}>
            <Accordion>
                <AccordionSummary expandIcon={<ExpandMoreIcon/>}>
                <Typography>Pastoral Reference</Typography>
                {
                    pastoral.uploaded &&
                    <CheckCircleIcon sx={{marginLeft:"20px"}}/>
                }
                </AccordionSummary>
                <AccordionDetails>
                    <Typography variant='h6' color="customYellow.main"><b>Pastor's or ministry leader's information</b></Typography>
                    <Typography variant='subtitle1'><b>First Name</b></Typography>
                    <Typography variant='subtitle1' sx={{marginLeft:"20px"}}>{pastoral?.firstNamePastor}</Typography>
                    <Typography variant='subtitle1'><b>Last Name</b></Typography>
                    <Typography variant='subtitle1' sx={{marginLeft:"20px"}}>{pastoral?.lastNamePastor}</Typography>
                    <Typography variant='subtitle1'><b>Pastoral Role</b></Typography>
                    <Typography variant='subtitle1' sx={{marginLeft:"20px"}}>{pastoral?.pastoralRole}</Typography>
                    <Typography variant='subtitle1'><b>Church Name</b></Typography>
                    <Typography variant='subtitle1' sx={{marginLeft:"20px"}}>{pastoral?.churchName}</Typography>
                    <Typography variant='subtitle1'><b>Church Website</b></Typography>
                    <Typography variant='subtitle1' sx={{marginLeft:"20px"}}>{pastoral?.churchWebsite}</Typography>
                    <Typography variant='h6' color="customYellow.main"><b>Name of Senior or Lead Pastor</b></Typography>
                    <Typography variant='subtitle1'><b>First Name</b></Typography>
                    <Typography variant='subtitle1' sx={{marginLeft:"20px"}}>{pastoral?.firstNameSenior}</Typography>
                    <Typography variant='subtitle1'><b>Last Name</b></Typography>
                    <Typography variant='subtitle1' sx={{marginLeft:"20px"}}>{pastoral?.lastNameSenior}</Typography>
                    <Typography variant='subtitle1'><b>Phone Number</b></Typography>
                    <Typography variant='subtitle1' sx={{marginLeft:"20px"}}>{pastoral?.phoneNumber}</Typography>
                    <Typography variant='subtitle1'><b>E-mail</b></Typography>
                    <Typography variant='subtitle1' sx={{marginLeft:"20px"}}>{pastoral?.email}</Typography>
                    <Divider/>
                    <Typography variant='h6' color="customYellow.main"><b>Reference Information</b></Typography>
                    <Typography variant='subtitle1'><b>How long have you known the applicant?</b></Typography>
                    <Typography variant='subtitle1' sx={{marginLeft:"20px"}}>{pastoral?.howLongKnowApplicant}</Typography>
                    <Typography variant='subtitle1'><b>How well do you know the applicant?</b></Typography>
                    <Typography variant='subtitle1' sx={{marginLeft:"20px"}}>{mapHowWell(pastoral?.howWellKnowApplicant)}</Typography>
                    <Typography variant='subtitle1'><b>How long has the applicant attended your church?</b></Typography>
                    <Typography variant='subtitle1' sx={{marginLeft:"20px"}}>{pastoral?.howLongAttendChurch}</Typography>
                    <Typography variant='subtitle1'><b>How would you describe the applicant's commitment and involvement in church?</b></Typography>
                    <Typography variant='subtitle1' sx={{marginLeft:"20px"}}>{mapDescribeCommitmentInChurch(pastoral?.describeCommitmentInChurch)}</Typography>
                    <Divider/>
                    <Typography variant='h6' color="customYellow.main"><b>Applicant Assessment</b></Typography>
                    <Typography variant='subtitle1'><b>Christian Walk</b></Typography>
                    <Typography variant='subtitle1' sx={{marginLeft:"20px"}}>{mapChristianWalk(pastoral?.christianWalk)}</Typography>
                    <Typography variant='subtitle1'><b>Christian Attitude</b></Typography>
                    <Typography variant='subtitle1' sx={{marginLeft:"20px"}}>{mapChristianAttitude(pastoral?.christianAttitude)}</Typography>
                    <Typography variant='subtitle1'><b>Choice of Friends</b></Typography>
                    <Typography variant='subtitle1' sx={{marginLeft:"20px"}}>{mapChoiceOfFriends(pastoral?.choiceOfFriends)}</Typography>
                    <Typography variant='subtitle1'><b>Cooperation</b></Typography>
                    <Typography variant='subtitle1' sx={{marginLeft:"20px"}}>{mapCooperation(pastoral?.cooperation)}</Typography>
                    <Typography variant='subtitle1'><b>Desire to Learn</b></Typography>
                    <Typography variant='subtitle1' sx={{marginLeft:"20px"}}>{mapDesireToLearn(pastoral?.desireToLearn)}</Typography>
                    <Typography variant='subtitle1'><b>Diligence</b></Typography>
                    <Typography variant='subtitle1' sx={{marginLeft:"20px"}}>{mapDiligence(pastoral?.diligence)}</Typography>
                    <Typography variant='subtitle1'><b>Leadership Ability</b></Typography>
                    <Typography variant='subtitle1' sx={{marginLeft:"20px"}}>{mapLeadersAbility(pastoral?.leadersAbility)}</Typography>
                    <Typography variant='subtitle1'><b>Respect for Authority</b></Typography>
                    <Typography variant='subtitle1' sx={{marginLeft:"20px"}}>{mapRespectForAutority(pastoral?.respectForAutority)}</Typography>
                    <Typography variant='subtitle1'><b>Social Skills</b></Typography>
                    <Typography variant='subtitle1' sx={{marginLeft:"20px"}}>{mapSocialSkills(pastoral?.socialSkills)}</Typography>
                    <Typography variant='subtitle1'><b>Work Ethic</b></Typography>
                    <Typography variant='subtitle1' sx={{marginLeft:"20px"}}>{mapWorkEthic(pastoral?.workEthic)}</Typography>
                    <Typography variant='subtitle1'><b>Emotional Stability</b></Typography>
                    <Typography variant='subtitle1' sx={{marginLeft:"20px"}}>{mapEmotionalStability(pastoral?.emotionalStability)}</Typography>
                    <Typography variant='subtitle1'><b>Please describe your personal observations about the applicant’s emotional stability?</b></Typography>
                    <Typography variant='subtitle1' sx={{marginLeft:"20px"}}>{pastoral?.personalObservations}</Typography>
                    <Typography variant='subtitle1'><b>What is your assessment of the applicant's ability, motivation and past performance?</b></Typography>
                    <Typography variant='subtitle1' sx={{marginLeft:"20px"}}>{pastoral?.assesmentOfApplicant}</Typography>
                    <Typography variant='subtitle1'><b>Are there any factors that might hinder the applicant's academic performance and/or interpersonal relationships?</b></Typography>
                    <Typography variant='subtitle1' sx={{marginLeft:"20px"}}>{pastoral?.factors}</Typography>
                    <Typography variant='subtitle1'><b>Would you recommend this applicant to be enrolled at Summit?</b></Typography>
                    <Typography variant='subtitle1' sx={{marginLeft:"20px"}}>{mapRecommend(pastoral?.recommend)}</Typography>
                    <Typography variant='subtitle1'><b>If you have any reservations or do not recommend this applicant, please explain:</b></Typography>
                    <Typography variant='subtitle1' sx={{marginLeft:"20px"}}>{pastoral?.reservations}</Typography>
                    <FormControlLabel checked={pastoral?.certify} control={<Checkbox/>} label={<Typography variant="subtitle1"><b>I hereby certify that all information herein is accurate and true to the best of my knowledge.</b></Typography>}/>
                </AccordionDetails>
            </Accordion>
        </Grid>
        <Grid item xs={10}>
            <Accordion>
                <AccordionSummary expandIcon={<ExpandMoreIcon/>}>
                <Typography>Employer Reference</Typography>
                {
                    employer.uploaded &&
                    <CheckCircleIcon sx={{marginLeft:"20px"}}/>
                }
                </AccordionSummary>
                <AccordionDetails>
                    <Typography variant='h6' color="customYellow.main"><b>Contact Information</b></Typography>
                    <Typography variant='subtitle1'><b>First Name</b></Typography>
                    <Typography variant='subtitle1' sx={{marginLeft:"20px"}}>{employer?.firstName}</Typography>
                    <Typography variant='subtitle1'><b>Last Name</b></Typography>
                    <Typography variant='subtitle1' sx={{marginLeft:"20px"}}>{employer?.lastName}</Typography>
                    <Typography variant='subtitle1'><b>Employer Position</b></Typography>
                    <Typography variant='subtitle1' sx={{marginLeft:"20px"}}>{employer?.employerPosition}</Typography>
                    <Typography variant='subtitle1'><b>Company Name</b></Typography>
                    <Typography variant='subtitle1' sx={{marginLeft:"20px"}}>{employer?.companyName}</Typography>
                    <Typography variant='subtitle1'><b>Company Phone Number</b></Typography>
                    <Typography variant='subtitle1' sx={{marginLeft:"20px"}}>{employer?.phoneNumber}</Typography>
                    <Typography variant='subtitle1'><b>Company E-mail Address</b></Typography>
                    <Typography variant='subtitle1' sx={{marginLeft:"20px"}}>{employer?.email}</Typography>
                    <Divider/>
                    <Typography variant='h6' color="customYellow.main"><b>Reference Information</b></Typography>
                    <Typography variant='subtitle1'><b>Relationship to Applicant</b></Typography>
                    <Typography variant='subtitle1' sx={{marginLeft:"20px"}}>{employer?.relatioship}</Typography>
                    <Typography variant='subtitle1'><b>How long have you known the applicant?</b></Typography>
                    <Typography variant='subtitle1' sx={{marginLeft:"20px"}}>{employer?.howLongKnowApplicant}</Typography>
                    <Typography variant='subtitle1'><b>How well do you know the applicant?</b></Typography>
                    <Typography variant='subtitle1' sx={{marginLeft:"20px"}}>{mapHowWell(employer?.howWellKnowApplicant)}</Typography>
                    <Divider/>
                    <Typography variant='h6' color="customYellow.main"><b>Employee Assessment</b></Typography>
                    <Typography variant='subtitle1'><b>Concern for Others</b></Typography>
                    <Typography variant='subtitle1' sx={{marginLeft:"20px"}}>{mapAssessment(employer?.concernForOthers)}</Typography>
                    <Typography variant='subtitle1'><b>Cooperativeness</b></Typography>
                    <Typography variant='subtitle1' sx={{marginLeft:"20px"}}>{mapAssessment(employer?.cooperativeness)}</Typography>
                    <Typography variant='subtitle1'><b>Desire to Learn Job</b></Typography>
                    <Typography variant='subtitle1' sx={{marginLeft:"20px"}}>{mapAssessment(employer?.desireToLearn)}</Typography>
                    <Typography variant='subtitle1'><b>Diligence</b></Typography>
                    <Typography variant='subtitle1' sx={{marginLeft:"20px"}}>{mapAssessment(employer?.diligence)}</Typography>
                    <Typography variant='subtitle1'><b>Honesty</b></Typography>
                    <Typography variant='subtitle1' sx={{marginLeft:"20px"}}>{mapAssessment(employer?.honesty)}</Typography>
                    <Typography variant='subtitle1'><b>Leadership Ability</b></Typography>
                    <Typography variant='subtitle1' sx={{marginLeft:"20px"}}>{mapAssessment(employer?.leadershipAbility)}</Typography>
                    <Typography variant='subtitle1'><b>Maturity</b></Typography>
                    <Typography variant='subtitle1' sx={{marginLeft:"20px"}}>{mapAssessment(employer?.maturity)}</Typography>
                    <Typography variant='subtitle1'><b>Respect for Authority</b></Typography>
                    <Typography variant='subtitle1' sx={{marginLeft:"20px"}}>{mapAssessment(employer?.respectForAuthority)}</Typography>
                    <Typography variant='subtitle1'><b>Social Skills</b></Typography>
                    <Typography variant='subtitle1' sx={{marginLeft:"20px"}}>{mapAssessment(employer?.socialSkills)}</Typography>
                    <Typography variant='subtitle1'><b>Work Ethic</b></Typography>
                    <Typography variant='subtitle1' sx={{marginLeft:"20px"}}>{mapAssessment(employer?.workEthic)}</Typography>
                    <Typography variant='subtitle1'><b>Emotional Stability</b></Typography>
                    <Typography variant='subtitle1' sx={{marginLeft:"20px"}}>{mapAssessment(employer?.emotionalStability)}</Typography>
                    <Typography variant='subtitle1'><b>Please describe your personal observations about the applicant’s emotional stability?</b></Typography>
                    <Typography variant='subtitle1' sx={{marginLeft:"20px"}}>{employer?.personalObservations}</Typography>
                    <Typography variant='subtitle1'><b>What is your assessment of the applicant's ability, motivation and past performance?</b></Typography>
                    <Typography variant='subtitle1' sx={{marginLeft:"20px"}}>{employer?.assessment}</Typography>
                    <Typography variant='subtitle1'><b>Are there any factors that might hinder the applicant's academic performance and/or interpersonal relationships?</b></Typography>
                    <Typography variant='subtitle1' sx={{marginLeft:"20px"}}>{employer?.factors}</Typography>
                    <Typography variant='subtitle1'><b>Would you recommend this applicant to be enrolled at Summit?</b></Typography>
                    <Typography variant='subtitle1' sx={{marginLeft:"20px"}}>{mapRecommend(employer?.recommend)}</Typography>
                    <Typography variant='subtitle1'><b>If you have any reservations or do not recommend this applicant, please explain:</b></Typography>
                    <Typography variant='subtitle1' sx={{marginLeft:"20px"}}>{employer?.reservations}</Typography>
                    <FormControlLabel checked={employer?.certify} control={<Checkbox/>} label={<Typography variant="subtitle1"><b>I hereby certify that all information herein is accurate and true to the best of my knowledge.</b></Typography>}/>
                </AccordionDetails>
            </Accordion>
        </Grid>
        <Grid item xs={10}>
            <Accordion>
                <AccordionSummary expandIcon={<ExpandMoreIcon/>}>
                <Typography>Academic Reference</Typography>
                {
                    academic.uploaded &&
                    <CheckCircleIcon sx={{marginLeft:"20px"}}/>
                }
                </AccordionSummary>
                <AccordionDetails>
                    <Typography variant='h6' color="customYellow.main"><b>Contact Information</b></Typography>
                    <Typography variant='subtitle1'><b>First Name</b></Typography>
                    <Typography variant='subtitle1' sx={{marginLeft:"20px"}}>{academic?.firstName}</Typography>
                    <Typography variant='subtitle1'><b>Last Name</b></Typography>
                    <Typography variant='subtitle1' sx={{marginLeft:"20px"}}>{academic?.lastName}</Typography>
                    <Typography variant='subtitle1'><b>Academic Role</b></Typography>
                    <Typography variant='subtitle1' sx={{marginLeft:"20px"}}>{academic?.academicRole}</Typography>
                    <Typography variant='subtitle1'><b>School Name</b></Typography>
                    <Typography variant='subtitle1' sx={{marginLeft:"20px"}}>{academic?.schoolName}</Typography>
                    <Typography variant='subtitle1'><b>Phone Number</b></Typography>
                    <Typography variant='subtitle1' sx={{marginLeft:"20px"}}>{academic?.phoneNumber}</Typography>
                    <Typography variant='subtitle1'><b>E-mail Address</b></Typography>
                    <Typography variant='subtitle1' sx={{marginLeft:"20px"}}>{academic?.email}</Typography>
                    <Divider/>
                    <Typography variant='h6' color="customYellow.main"><b>Reference Information</b></Typography>
                    <Typography variant='subtitle1'><b>Relationship to Applicant</b></Typography>
                    <Typography variant='subtitle1' sx={{marginLeft:"20px"}}>{academic?.relatioship}</Typography>
                    <Typography variant='subtitle1'><b>How long have you known the applicant?</b></Typography>
                    <Typography variant='subtitle1' sx={{marginLeft:"20px"}}>{academic?.howLongKnowApplicant}</Typography>
                    <Typography variant='subtitle1'><b>How well do you know the applicant?</b></Typography>
                    <Typography variant='subtitle1' sx={{marginLeft:"20px"}}>{mapHowWell(academic?.howWellKnowApplicant)}</Typography>
                    <Divider/>
                    <Typography variant='h6' color="customYellow.main"><b>Academic Assessment</b></Typography>
                    <Typography variant='subtitle1'><b>Written Communication Skills</b></Typography>
                    <Typography variant='subtitle1' sx={{marginLeft:"20px"}}>{mapAssessment(academic?.writtenCommunicationSkillls)}</Typography>
                    <Typography variant='subtitle1'><b>Verbal Communication Skills</b></Typography>
                    <Typography variant='subtitle1' sx={{marginLeft:"20px"}}>{mapAssessment(academic?.verbalCommunicationSkills)}</Typography>
                    <Typography variant='subtitle1'><b>Intellect</b></Typography>
                    <Typography variant='subtitle1' sx={{marginLeft:"20px"}}>{mapAssessment(academic?.intellect)}</Typography>
                    <Typography variant='subtitle1'><b>Motivation/Initiative</b></Typography>
                    <Typography variant='subtitle1' sx={{marginLeft:"20px"}}>{mapAssessment(academic?.motivationInitiative)}</Typography>
                    <Typography variant='subtitle1'><b>Attendance/Punctuality</b></Typography>
                    <Typography variant='subtitle1' sx={{marginLeft:"20px"}}>{mapAssessment(academic?.attendancePunctuality)}</Typography>
                    <Typography variant='subtitle1'><b>Punctuality of Asignments</b></Typography>
                    <Typography variant='subtitle1' sx={{marginLeft:"20px"}}>{mapAssessment(academic?.punctualityAsignments)}</Typography>
                    <Typography variant='subtitle1'><b>Interpersonal/Social Skills</b></Typography>
                    <Typography variant='subtitle1' sx={{marginLeft:"20px"}}>{mapAssessment(academic?.interpersonalSocialSkills)}</Typography>
                    <Typography variant='subtitle1'><b>Leadership Ability</b></Typography>
                    <Typography variant='subtitle1' sx={{marginLeft:"20px"}}>{mapAssessment(academic?.leadershipAbility)}</Typography>
                    <Typography variant='subtitle1'><b>Cooperativeness</b></Typography>
                    <Typography variant='subtitle1' sx={{marginLeft:"20px"}}>{mapAssessment(academic?.cooperativeness)}</Typography>
                    <Typography variant='subtitle1'><b>Honesty</b></Typography>
                    <Typography variant='subtitle1' sx={{marginLeft:"20px"}}>{mapAssessment(academic?.honesty)}</Typography>
                    <Typography variant='subtitle1'><b>Emotional Stability</b></Typography>
                    <Typography variant='subtitle1' sx={{marginLeft:"20px"}}>{mapAssessment(academic?.emotionalStability)}</Typography>
                    <Typography variant='subtitle1'><b>Please describe your personal observations about the applicant’s emotional stability?</b></Typography>
                    <Typography variant='subtitle1' sx={{marginLeft:"20px"}}>{academic?.personalObservations}</Typography>
                    <Typography variant='subtitle1'><b>What is your assessment of the applicant's ability, motivation and past performance?</b></Typography>
                    <Typography variant='subtitle1' sx={{marginLeft:"20px"}}>{academic?.assessment}</Typography>
                    <Typography variant='subtitle1'><b>Are there any factors that might hinder the applicant's academic performance and/or interpersonal relationships?</b></Typography>
                    <Typography variant='subtitle1' sx={{marginLeft:"20px"}}>{academic?.factors}</Typography>
                    <Typography variant='subtitle1'><b>Would you recommend this applicant to be enrolled at Summit?</b></Typography>
                    <Typography variant='subtitle1' sx={{marginLeft:"20px"}}>{mapRecommend(academic?.recommend)}</Typography>
                    <Typography variant='subtitle1'><b>If you have any reservations or do not recommend this applicant, please explain:</b></Typography>
                    <Typography variant='subtitle1' sx={{marginLeft:"20px"}}>{academic?.reservations}</Typography>
                    <FormControlLabel checked={academic?.certify} control={<Checkbox/>} label={<Typography variant="subtitle1"><b>I hereby certify that all information herein is accurate and true to the best of my knowledge.</b></Typography>}/>
                </AccordionDetails>
            </Accordion>    
        </Grid>
        {
            family.maritalStatus === "married" && 
            <Grid item xs={10}>
                <Accordion>
                    <AccordionSummary expandIcon={<ExpandMoreIcon/>}>
                    <Typography>Marriage Reference</Typography>
                    {
                        marriage.uploaded &&
                        <CheckCircleIcon sx={{marginLeft:"20px"}}/>
                    }
                    </AccordionSummary>
                    <AccordionDetails>
                        <Typography variant='h6' color="customYellow.main"><b>Contact Information</b></Typography>
                        <Typography variant='subtitle1'><b>First Name</b></Typography>
                        <Typography variant='subtitle1' sx={{marginLeft:"20px"}}>{marriage?.firstName}</Typography>
                        <Typography variant='subtitle1'><b>Last Name</b></Typography>
                        <Typography variant='subtitle1' sx={{marginLeft:"20px"}}>{marriage?.lastName}</Typography>
                        <Typography variant='subtitle1'><b>Letter Stating Marriage is Stable</b></Typography>
                        <Typography variant='subtitle1' sx={{marginLeft:"20px"}}>{marriage?.letter}</Typography>
                    </AccordionDetails>
                </Accordion>
            </Grid>
        }
    </Grid>
    )
}

export default ReferencesAdmin
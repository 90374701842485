import { Alert, Button, Checkbox, FormControlLabel, FormGroup, Grid, MobileStepper, Typography } from "@mui/material"
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import BannerStep from "./BannerStep";
const defaultAgreementForm = {
    readHandbookAndPolicies:false,
    accurateInfo:false,
    incompleteInfo:false,
    notDiscriminate:false
}

const Agreement = ({data,updateApplication,currentApplication}) => {

    const navigate = useNavigate();
    const [agreementForm, setAgreementForm] = useState(defaultAgreementForm);
    const [showError, setShowError] = useState(false);
    const [activeStep, setActiveStep] = useState(0);
    const steps = 1;
    const {accurateInfo,incompleteInfo,notDiscriminate,readHandbookAndPolicies} = agreementForm;

    useEffect(() => {
      if(data){
        setAgreementForm({
            readHandbookAndPolicies: data.readHandbookAndPolicies || false,
            accurateInfo: data.accurateInfo || false ,
            incompleteInfo: data.incompleteInfo || false,
            notDiscriminate: data.notDiscriminate || false,
        });
      }
    }, [data])
    

    const handleNext = () => {
        handleSubmit();
        if(!checkRequired()){
            setShowError(true);
            setTimeout(() => {
                setShowError(false);
            }, 2000);
            return;
        }
        if(activeStep === steps-1){
            if(currentApplication.personal.f1Visa !== "" && currentApplication.personal.f1Visa === "yes"){
                navigate('/visa');
            }else{
                navigate('/id_passport');
            }
            return;
        }
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
    };
    
    const handleBack = () => {
        handleSubmit();
        if(activeStep === 0)
        {
            navigate('/financial');
            return;
        }
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const checkRequired = ()=>{
        if(activeStep === 0){
            return accurateInfo && incompleteInfo && notDiscriminate && readHandbookAndPolicies
        }
        return true;
    }

    const handleCheckBoxHandbookPolicies = ({target})=>{
        const {name} = target;
        setAgreementForm({...agreementForm,[name]:!agreementForm[name]})
    }

    const handleSubmit = ()=>{
        const dataSubmit={
            agreement:{
                ...agreementForm
            }
          }
        
          updateApplication(dataSubmit);
    }

    return (
        <Grid container flexDirection="column-reverse">
            <Grid item xs={12}>
                <MobileStepper
                    variant="dots"
                    steps={steps}
                    position="static"
                    activeStep={activeStep}
                    sx={{ width:"100%", flexGrow: 1 , marginTop:"50px"}}
                    nextButton={
                        <Button size="small" onClick={handleNext}>
                            {activeStep === steps-1 ? 'Next' : 'Next' }
                        </Button>
                    }
                    backButton={
                        <Button size="small" onClick={handleBack}>
                            {activeStep === 0 ? 'Back' : 'Back'}
                        </Button>
                    }
                />
            </Grid>
            {
                showError &&
                <Grid item xs={12}>
                    <Alert severity='error' sx={{marginTop:"20px"}}>
                        One or more REQUIRED fields are empty.
                    </Alert>
                </Grid>
            }
            <Grid item xs={12}>
                <Grid container spacing={2}>
                    <Grid item>
                        <FormGroup onChange={handleCheckBoxHandbookPolicies}>
                            <FormControlLabel checked={readHandbookAndPolicies} name ="readHandbookAndPolicies" value={0} control={<Checkbox/>} label={<Typography>
                                I am aware that I am responsible to read the <a href="https://www.summitpa.org/_files/ugd/37192c_f38c89e8e32740faaf94ddfd550eab56.pdf" target="_blank" rel="noreferrer" style={{textDecoration:"underline"}}>Summit Handbook</a> and be aware of  Summit policies and guidelines.*
                            </Typography>}/>                        
                            <FormControlLabel checked={accurateInfo} name ="accurateInfo" required value={1} control={<Checkbox/>} label="I hereby certify that all information herein recorded is accurate and true to the best of my knowledge."/>                        
                            <FormControlLabel checked={incompleteInfo} name ="incompleteInfo" required value={2} control={<Checkbox/>} label="I understand that any further or incomplete information may result in the disqualification any application for entrance."/>                        
                            <FormControlLabel checked={notDiscriminate} name ="notDiscriminate" required value={3} control={<Checkbox/>} label="I understand that Summit International School of Ministry does not discriminate on the basis of sex, race, color, national or ethnic origin."/>                        
                        </FormGroup>
                    </Grid>
                </Grid>
            </Grid>
            <Grid item xs={12} sx={{margin:{xs:"0px 0px 50px 0px",sm:"50px 0px"}}}>
                <BannerStep title="Agreement" stepNumber={activeStep+1} totalSteps={1}/>
            </Grid>
        </Grid> 
    )
}

export default Agreement
import { Link } from "react-router-dom";
import { List, ListItem, ListItemButton, ListItemText, Typography } from "@mui/material"
import {scholarshipSteps} from '../utils/scholarshipSteps';

const ScholarshipSteps = ({active,app}) => {
    return (
        <>
            <Typography variant="h5">Scholarship:</Typography>
            <List sx={{width:"100%"}}>
                {
                    scholarshipSteps.map(({label,requiredSteps,route},i)=>(
                        <Link to={`/${route}`} key={i}>
                            <ListItem sx={{padding:{xs:"0px"},borderBottom:"1px solid #9E9E9E",backgroundColor:active ===route?'customYellow.main':'white'}}>
                                <ListItemButton>
                                    <ListItemText primary={label}/>
                                    <Typography edge="end">{app ? app[route].progress: "0" }/{requiredSteps}</Typography>
                                </ListItemButton>
                            </ListItem>
                        </Link>
                    ))
                }
                <Link to={`/extra`}>
                    <ListItem sx={{padding:{xs:"0px"},borderBottom:"1px solid #9E9E9E",backgroundColor:active ==="extra"?'customYellow.main':'white'}}>
                        <ListItemButton>
                            <ListItemText primary="Medical and Transportation Liability"/>
                            <Typography edge="end"></Typography>
                        </ListItemButton>
                    </ListItem>
                </Link>
                <Link to={`/medical_records`}>
                    <ListItem sx={{padding:{xs:"0px"},borderBottom:"1px solid #9E9E9E",backgroundColor:active ==="medical"?'customYellow.main':'white'}}>
                        <ListItemButton>
                            <ListItemText primary="Medical Records"/>
                            <Typography edge="end"></Typography>
                        </ListItemButton>
                    </ListItem>
                </Link>
            </List>
        </>
    )
}

export default ScholarshipSteps
import { Alert, Button, FormControlLabel, FormLabel, Grid, MobileStepper, Radio, RadioGroup, TextField, Typography } from "@mui/material"
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import BannerStep from "./BannerStep";

const defaultHealthForm = {
    healthChallenges:"",
    emotionalMentalHealth:"",
    explainMentaHealth:"",
    onMedication:"",
    medicationList:"",
    learningLimitation:"",
    explainLimitations:"",
    consumedAlcohol:"",
    lastTimeAlcohol:"",
    smoked:"",
    lastTimeSmoked:"",
    drugs:"",
    lastTimeDrugs:"",
    selfDestructive:"",
    explainSelfDestructive:"",
    foodAllergy:"",
    foodList:"",
}

const Health = ({data,updateApplication}) => {

    const navigate = useNavigate();
    const [healthForm, setHealthForm] = useState(defaultHealthForm);
    const [showError, setShowError] = useState(false);
    const [activeStep, setActiveStep] = useState(0);
    const steps = 1;

    const {
        healthChallenges,
        emotionalMentalHealth,
        explainMentaHealth,
        onMedication,
        medicationList,
        learningLimitation,
        explainLimitations,
        consumedAlcohol,
        lastTimeAlcohol,
        smoked,
        lastTimeSmoked,
        drugs,
        lastTimeDrugs,
        selfDestructive,
        explainSelfDestructive,
        foodAllergy,
        foodList
    } = healthForm;

    useEffect(() => {
      if(data){
        setHealthForm({
            healthChallenges: data.healthChallenges || "",
            emotionalMentalHealth: data.emotionalMentalHealth || "",
            explainMentaHealth : data.explainMentaHealth || "",
            onMedication: data.onMedication || "",
            medicationList: data.medicationList || "",
            learningLimitation: data.learningLimitation || "",
            explainLimitations: data.explainLimitations || "",
            consumedAlcohol: data.consumedAlcohol || "",
            lastTimeAlcohol: data.lastTimeAlcohol || "",
            smoked: data.smoked || "",
            lastTimeSmoked: data.lastTimeSmoked || "",
            drugs: data.drugs || "",
            lastTimeDrugs: data.lastTimeDrugs || "",
            selfDestructive: data.selfDestructive || "",
            explainSelfDestructive: data.explainSelfDestructive || "",
            foodAllergy: data.foodAllergy || "",
            foodList: data.foodList || "",
        }); 
      }
    }, [data])
    

    const handleNext = () => {
        handleSubmit();
        if(!checkRequired()){
            setShowError(true);
            setTimeout(() => {
                setShowError(false);
            }, 2000);
            return;
        }
        if(activeStep === steps-1){
            navigate('/legal');
            return;
        }
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
    };
    
    const handleBack = () => {
        handleSubmit();
        if(activeStep === 0)
        {
            navigate('/family');
            return;
        }
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const checkRequired = ()=>{
        if(activeStep === 0){
            return ((emotionalMentalHealth === "yes" && explainMentaHealth !== "") || emotionalMentalHealth === "no") && 
            ((onMedication === "yes" && medicationList !== "") || onMedication === "no") &&
            ((learningLimitation === "yes" && explainLimitations !== "") || learningLimitation === "no") &&
            ((consumedAlcohol === "yes" && lastTimeAlcohol !== "") || consumedAlcohol === "no") && 
            ((smoked === "yes" && lastTimeSmoked !== "") || smoked === "no") && 
            ((drugs === "yes" && lastTimeDrugs !== "") || drugs === "no") && 
            ((selfDestructive === "yes" && explainSelfDestructive !== "") || selfDestructive === "no") && 
            ((foodAllergy === "yes" && foodList !== "") || foodAllergy === "no");
        }
        return true;
    }

    const handleChangeForm = (e)=>{
        const {name,value} = e.target;
        setHealthForm({...healthForm, [name]:value});
    }
    
    const handleSubmit = ()=>{
        const dataSubmit={
            health:{
                ...healthForm
            }
          }
          updateApplication(dataSubmit);
    }

    return (
        <Grid container flexDirection="column-reverse">
            <Grid item xs={12}>
                <MobileStepper
                    variant="dots"
                    steps={steps}
                    position="static"
                    activeStep={activeStep}
                    sx={{ width:"100%", flexGrow: 1 , marginTop:"50px"}}
                    nextButton={
                        <Button size="small" onClick={handleNext}>
                            {activeStep === steps-1 ? 'Next' : 'Next' }
                        </Button>
                    }
                    backButton={
                        <Button size="small" onClick={handleBack}>
                            {activeStep === 0 ? 'Back' : 'Back'}
                        </Button>
                    }
                />
            </Grid>
            {
                showError &&
                <Grid item xs={12}>
                    <Alert severity='error' sx={{marginTop:"20px"}}>
                        One or more REQUIRED fields are empty.
                    </Alert>
                </Grid>
            }
            <Grid item>
                <Grid container spacing={2}> 
                    <Grid item xs={12}>
                        <FormLabel sx={{fontSize:"0.85rem"}}>Please note that your answers are confidential</FormLabel>
                        <Typography variant='subtitle1' sx={{marginTop:"20px"}}>Are you currently on medication (either prescribed or non-prescribed)?*</Typography>
                        <RadioGroup sx={{flexDirection:"row"}} value={onMedication} onChange={handleChangeForm}>
                            <FormControlLabel value="yes" name="onMedication" control={<Radio />} label="Yes" />
                            <FormControlLabel value="no" name="onMedication" control={<Radio />} label="No" />
                        </RadioGroup>
                    </Grid>
                    {
                        onMedication === "yes" && 
                        <Grid item xs={12}>
                            <Typography variant='subtitle1'>Please list any medications you take*</Typography>
                            <TextField fullWidth onChange={handleChangeForm} inputProps={{maxLength:500}} helperText={`Max Length ${medicationList.length}/500`} value={medicationList} name="medicationList" sx={{width:{xs:"100%",sm:"100%"}}}  multiline rows={4}/>
                        </Grid>
                    }
                    <Grid item xs={12}>
                        <Typography variant='subtitle1' sx={{marginTop:"20px"}}>Do you have any learning limitations such as Dyslexia, ADD, ADHD, etc.?*</Typography>
                        <RadioGroup sx={{flexDirection:"row"}} value={learningLimitation} onChange={handleChangeForm}>
                            <FormControlLabel value="yes" name="learningLimitation" control={<Radio />} label="Yes" />
                            <FormControlLabel value="no" name="learningLimitation" control={<Radio />} label="No" />
                        </RadioGroup>
                    </Grid>
                    {
                        learningLimitation === "yes" && 
                        <Grid item xs={12}>
                            <Typography variant='subtitle1'>Please describe*</Typography>
                            <TextField fullWidth onChange={handleChangeForm} inputProps={{maxLength:500}} helperText={`Max Length ${explainLimitations.length}/500`} value={explainLimitations} name="explainLimitations" sx={{width:{xs:"100%",sm:"100%"}}}  multiline rows={4}/>
                        </Grid>
                    }
                    <Grid item xs={12}>
                        <Typography variant='subtitle1' sx={{marginTop:"20px"}}>Have you consumed alcohol in the last 12 months?*</Typography>
                        <RadioGroup sx={{flexDirection:"row"}} value={consumedAlcohol} onChange={handleChangeForm}>
                            <FormControlLabel value="yes" name="consumedAlcohol" control={<Radio />} label="Yes" />
                            <FormControlLabel value="no" name="consumedAlcohol" control={<Radio />} label="No" />
                        </RadioGroup>
                    </Grid>
                    {
                        consumedAlcohol === "yes" && 
                        <Grid item xs={12}>
                            <Typography variant='subtitle1'>When was the last time and under what circumstance?*</Typography>
                            <TextField fullWidth onChange={handleChangeForm} inputProps={{maxLength:500}} helperText={`Max Length ${lastTimeAlcohol.length}/500`} value={lastTimeAlcohol} name="lastTimeAlcohol" sx={{width:{xs:"100%",sm:"100%"}}}  multiline rows={4}/>
                        </Grid>
                    }
                    <Grid item xs={12}>
                        <Typography variant='subtitle1' sx={{marginTop:"20px"}}>Have you smoked cigarettes in the last 12 months?*</Typography>
                        <RadioGroup sx={{flexDirection:"row"}} value={smoked} onChange={handleChangeForm}>
                            <FormControlLabel value="yes" name="smoked" control={<Radio />} label="Yes" />
                            <FormControlLabel value="no" name="smoked" control={<Radio />} label="No" />
                        </RadioGroup>
                    </Grid>
                    {
                        smoked === "yes" && 
                        <Grid item xs={12}>
                            <Typography variant='subtitle1'>When was the last time and under what circumstance?*</Typography>
                            <TextField fullWidth onChange={handleChangeForm} inputProps={{maxLength:500}} helperText={`Max Length ${lastTimeSmoked.length}/500`} value={lastTimeSmoked} name="lastTimeSmoked" sx={{width:{xs:"100%",sm:"100%"}}}  multiline rows={4}/>
                        </Grid>
                    }
                    <Grid item xs={12}>
                        <Typography variant='subtitle1' sx={{marginTop:"20px"}}>Are you using or have you ever used drugs?*</Typography>
                        <RadioGroup sx={{flexDirection:"row"}} value={drugs} onChange={handleChangeForm}>
                            <FormControlLabel value="yes" name="drugs" control={<Radio />} label="Yes" />
                            <FormControlLabel value="no" name="drugs" control={<Radio />} label="No" />
                        </RadioGroup>
                    </Grid>
                    {
                        drugs === "yes" && 
                        <Grid item xs={12}>
                            <Typography variant='subtitle1'>When was the last time and under what circumstance?*</Typography>
                            <TextField fullWidth onChange={handleChangeForm} inputProps={{maxLength:500}} helperText={`Max Length ${lastTimeDrugs.length}/500`} value={lastTimeDrugs} name="lastTimeDrugs" sx={{width:{xs:"100%",sm:"100%"}}}  multiline rows={4}/>
                        </Grid>
                    }
                    <Grid item xs={12}>
                        <Typography variant='subtitle1' sx={{marginTop:"20px"}}>Have you struggled with self-destructive behavior in the last two years?*</Typography>
                        <RadioGroup sx={{flexDirection:"row"}} value={selfDestructive} onChange={handleChangeForm}>
                            <FormControlLabel value="yes" name="selfDestructive" control={<Radio />} label="Yes" />
                            <FormControlLabel value="no" name="selfDestructive" control={<Radio />} label="No" />
                        </RadioGroup>
                        <FormLabel sx={{fontSize:"0.85rem"}}>ie: cutting, eating disorders, etc.</FormLabel>
                    </Grid>
                    {
                        selfDestructive === "yes" && 
                        <Grid item xs={12}>
                            <Typography variant='subtitle1'>When was the last time and under what circumstance?*</Typography>
                            <TextField fullWidth onChange={handleChangeForm} inputProps={{maxLength:500}} helperText={`Max Length ${explainSelfDestructive.length}/500`} value={explainSelfDestructive} name="explainSelfDestructive" sx={{width:{xs:"100%",sm:"100%"}}}  multiline rows={4}/>
                        </Grid>
                    }
                    <Grid item xs={12}>
                        <Typography variant='subtitle1' sx={{marginTop:"20px"}}>Have you been treated by a doctor for any emotional or mental health challeges?*</Typography>
                        <RadioGroup sx={{flexDirection:"row"}} value={emotionalMentalHealth} onChange={handleChangeForm}>
                            <FormControlLabel value="yes" name="emotionalMentalHealth" control={<Radio />} label="Yes" />
                            <FormControlLabel value="no" name="emotionalMentalHealth" control={<Radio />} label="No" />
                        </RadioGroup>
                    </Grid>
                    {
                        emotionalMentalHealth === "yes" && 
                        <Grid item xs={12}>
                            <Typography variant='subtitle1'>Please describe*</Typography>
                            <TextField fullWidth onChange={handleChangeForm} inputProps={{maxLength:500}} helperText={`Max Length ${explainMentaHealth.length}/500`} value={explainMentaHealth} name="explainMentaHealth" sx={{width:{xs:"100%",sm:"100%"}}}  multiline rows={4}/>
                        </Grid>
                    }  
                    <Grid item xs={12}>
                        <Typography variant='subtitle1' sx={{marginTop:"20px"}}>Do you currently have a food allergy?*</Typography>
                        <RadioGroup sx={{flexDirection:"row"}} value={foodAllergy} onChange={handleChangeForm}>
                            <FormControlLabel value="yes" name="foodAllergy" control={<Radio />} label="Yes" />
                            <FormControlLabel value="no" name="foodAllergy" control={<Radio />} label="No" />
                        </RadioGroup>
                    </Grid>
                    {
                        foodAllergy === "yes" && 
                        <Grid item xs={12}>
                            <Typography variant='subtitle1'>Please list all known food allergies*</Typography>
                            <TextField fullWidth onChange={handleChangeForm} inputProps={{maxLength:500}} helperText={`Max Length ${foodList.length}/500`} value={foodList} name="foodList" sx={{width:{xs:"100%",sm:"100%"}}}  multiline rows={4}/>
                        </Grid>
                    }
                    <Grid item xs={12}>
                        <Typography variant='subtitle1' sx={{marginTop:"20px"}}>Is there anything else regarding your health you would like us to know?</Typography>
                        <TextField required onChange={handleChangeForm} inputProps={{maxLength:500}} helperText={`Max Length ${healthChallenges.length}/500`} fullWidth value={healthChallenges} name="healthChallenges" multiline rows={4}/>
                        <FormLabel sx={{fontSize:"0.85rem"}}>ie: allergies, dietary precautions, epilepsy, chronic depression, diabetes, communicable diseases, etc.</FormLabel>
                    </Grid>
                </Grid>
            </Grid>
            <Grid item xs={12} sx={{margin:{xs:"0px 0px 50px 0px",sm:"50px 0px"}}}>
                <BannerStep title="Health" stepNumber={activeStep+1} totalSteps={1}/>
            </Grid>
        </Grid>
    )
}

export default Health
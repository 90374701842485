import { Button } from "@mui/material"
import { saveAs } from 'file-saver';
import * as XLSX from 'xlsx';
import { upperFirstLetter } from "../../utils/qViewerFunctions";
import { changeDateFormat } from "../../utils/dateFormat";

const ExcelExport = ({ data, fileName }) => {

  const exportToExcel = () => {

    const formatedData = data.map((item)=>{
        return {
            Last_Name : item.lastName, //HeaderName : data
            First_Name: item.firstName,
            Email : item.email,
            Status : upperFirstLetter(item.status),
            Account_Create: changeDateFormat(item.accountCreated.slice(0,10)),
            Citizenship: item.citizenship
        }
    })
    const worksheet = XLSX.utils.json_to_sheet(formatedData);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');
    const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
    const blob = new Blob([excelBuffer], {type: 'application/octet-stream'});
    saveAs(blob, `${fileName}.xlsx`);
  };
  
  return (
    <Button variant="contained" color="customYellow" onClick={exportToExcel}>Export to Excel</Button>
  );
}

export default ExcelExport;
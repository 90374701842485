import { Box, Card, CardActions, CardContent, Typography } from "@mui/material"

const CardCustom = ({title,value,image}) => {
  return (
    <Card sx={{position:"relative",cursor:"pointer"}}>
        <Box sx={{position:"absolute",right:"0px",bottom:"0px"}}>
            <img src={image} alt={title}/>
        </Box>
        <CardContent>
            <Box>
                <Typography variant="h6">{title}</Typography>
                <Typography variant="h5" sx={{margin:"20px 0px"}}><b>{value}</b></Typography>
            </Box>
        </CardContent>
        <CardActions>
            {/* <Typography variant="subtitle1"><span style={{color:"green"}}>0</span> since last login</Typography> */}
        </CardActions>
    </Card>
  )
}

export default CardCustom
import SpaceDashboardOutlinedIcon from '@mui/icons-material/SpaceDashboardOutlined';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';

export const scholarshipCommiteeMenu = [
    {
      title: "All Applications", 
      icon: <SpaceDashboardOutlinedIcon sx={{color:"white"}}/>,
      openIcon: <KeyboardArrowUpIcon sx={{color:"white"}}/>,
      closeIcon: <KeyboardArrowDownIcon sx={{color:"white"}}/>,
      href: "",
      pages: [
        {
          title: "Scholarship",
          locationRef: "scholarship",
          href:"/scholarship"
        }
      ], //pagesSection
    },
];
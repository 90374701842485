import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Box, Button, CircularProgress, Grid, Typography, styled } from "@mui/material"
import { updateApplication, uploadImmuno, uploadPhysical } from "../../../axios/axiosFunctions";

const VisuallyHiddenInput = styled('input')({
    clip: 'rect(0 0 0 0)',
    clipPath: 'inset(50%)',
    height: 1,
    overflow: 'hidden',
    position: 'absolute',
    bottom: 0,
    left: 0,
    whiteSpace: 'nowrap',
    width: 1,
});

const MedicalRecordsAdmin = ({immunoInfo,physicalInfo,appId,setCurrentApplication}) => {

    const BASE_URL = "https://summit-app-edgar.s3.eu-north-1.amazonaws.com/";
    const [fileType, setFileType] = useState();
    const [fileType2, setFileType2] = useState();
    const [urlImmuno, setUrlImmuno] = useState(BASE_URL + immunoInfo?.image);
    const [urlPhysical, setUrlPhysical] = useState(BASE_URL + physicalInfo?.image);

    useEffect(() => {
        if(immunoInfo){
            const type = immunoInfo.image.split(".").pop();
            setFileType(type);
            const folderNameImmuno = immunoInfo.image.split("/")[0];
            if (folderNameImmuno !== "applications") {
                setUrlImmuno(BASE_URL + "applicants/" + immunoInfo.image);
            }
        }
        if(physicalInfo){
            const type2 = physicalInfo.image.split(".").pop();
            setFileType2(type2);
            const folderNamePhysical = physicalInfo.image.split("/")[0];
            if (folderNamePhysical !== "applications") {
                setUrlPhysical(BASE_URL + "applicants/" + physicalInfo.image);
            }
        }
    }, [immunoInfo,physicalInfo]);

    const handleUploadImmunoFile = (e)=>{
        const {length} = e.target.files;
        if(length !== 0){
            handleSubmitImmuno(true,e.target.files[0],"IMMUNO");
        }
    }

    const handleUploadPhysicalFile = (e)=>{
        const {length} = e.target.files;
        if(length !== 0){
            handleSummitPhysical(true,e.target.files[0],"PHYSICAL");
        }
    }

    const handleSummitPhysical = async(uploaded,submitFile,imagePhysicalName)=>{
        setUrlPhysical("");
        let physicalN = imagePhysicalName;
    
        if(uploaded && submitFile){
            const formData = new FormData();
            formData.append('fileImage',submitFile);
            const {name} = await uploadPhysical(formData,appId);
            physicalN = name;
        }
    
        const dataSubmit2 = {
            physicalExamination:{
                uploaded,
                image: physicalN
            }
        }
        const resp = await updateApplication(dataSubmit2,appId);
        setCurrentApplication(resp.application);
        setUrlPhysical(BASE_URL + physicalInfo.image);
      }
    
      const handleSubmitImmuno = async(uploaded,submitFile,imageImmunoName)=>{
        setUrlImmuno("");
        let immunoN = imageImmunoName;
        
        if(uploaded && submitFile){
            const formData = new FormData();
            formData.append('fileImage',submitFile);
            const {name} = await uploadImmuno(formData,appId);
            immunoN = name;
        }
        
        const dataSubmit = {
            immunoRecord:{
                uploaded,
                image: immunoN
            }
        }
        const resp = await updateApplication(dataSubmit,appId);
        setCurrentApplication(resp.application);
        setUrlImmuno(BASE_URL + immunoInfo?.image);          
      }

    return (
    <Grid container spacing={2}>
        <Grid item>
            <Typography variant='h5' color="customYellow.main" sx={{marginBottom:"30px"}}><b>Medical Records</b></Typography>
            {
                urlImmuno === "" ? 
                <CircularProgress/>
                :
                <Box sx={{width:"400px"}}>
                {
                    fileType && fileType !== "" && <>
                    <Typography variant='subtitle1'><b>Immunization Records (which includes two MMR & three Hepatitis B)</b></Typography>
                    {
                        fileType !== "pdf" && fileType !== "docx" && <img src={urlImmuno} alt="If the element has not loaded, please refresh the page." style={{width:"100%"}}/>
                    }
                    <Link to={urlImmuno} target="_blank">
                        <Button variant="contained">
                        DOWNLOAD FILE
                        </Button>
                    </Link>
                    </>
                }
                </Box>
            }
            {
                urlPhysical === "" ? 
                <CircularProgress/>
                :
                <Box>
                {
                    fileType2 && fileType2 !== "" && <>
                    <Typography variant='subtitle1'><b>Physical Examination Report</b></Typography>
                    {
                        fileType2 !== "pdf" && fileType2 !== "docx" && <img src={urlPhysical} alt="If the element has not loaded, please refresh the page." style={{width:"100%"}}/>
                    }
                    <Link to={urlPhysical} target="_blank">
                        <Button variant="contained">
                        DOWNLOAD FILE
                        </Button>
                    </Link>
                    </>
                }
                </Box>
            }
        </Grid>
        <Grid item xs={12}>
            <Button variant="contained" component="label">
                Upload Immunization Record
                <VisuallyHiddenInput onChange={handleUploadImmunoFile} type="file" />
            </Button>
            <Typography variant="subtitle2">(By uploading a new image, you will overwrite the existing image.)</Typography>
        </Grid>
        <Grid item xs={12}>
            <Button variant="contained" component="label">
                Upload Physical Examination Report
                <VisuallyHiddenInput onChange={handleUploadPhysicalFile} type="file" />
            </Button>
            <Typography variant="subtitle2">(By uploading a new image, you will overwrite the existing image.)</Typography>
        </Grid>
    </Grid>
)
}

export default MedicalRecordsAdmin
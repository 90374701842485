import './App.css';
import { Navigate, Route, Routes } from 'react-router-dom';
import Applicant from './routes/applicant/applicant';
import Navigation from './routes/navigation/navigation';
import { useContext } from 'react';
import { UserContext } from './contexts/user';
import CreateAccount from './components/CreateAccount';
import Welcome from './components/Welcome';
import Program from './components/Program';
import Resume from './components/Resume';
import LoginForm from './components/LoginForm';
import Dashboard from './components/Admin/Dashboard';
import Admin from './routes/admin/admin';
import Pastoral from './components/Reference/Pastoral';
import Employer from './components/Reference/Employer';
import Academic from './components/Reference/Academic';
import Marriage from './components/Reference/Marriage';
import ForgotPassword from './components/Password/ForgotPassword';


function App() {
  
  const {currentUser} = useContext(UserContext);

  return (
    <>
      {/* <Navigation/> */}
      <Routes>
        {
          !currentUser && <>
          <Route path="/admin" element={<Admin/>}/>
          <Route path="/" element={<Navigation/>}>
            <Route index element={<Applicant/>}/>
            <Route path="/login" element={<LoginForm/>}/>
            <Route path="/createAccount" element={<CreateAccount/>}/>
            <Route path="/pastoral/:id" element={<Pastoral/>}/>
            <Route path="/employer/:id" element={<Employer/>}/>
            <Route path="/academic/:id" element={<Academic/>}/>
            <Route path="/marriage/:id" element={<Marriage/>}/>
            <Route path="/forgotPassword/:id" element={<ForgotPassword/>}/>
          </Route>
          <Route path='*' element={<Navigate to="/"/>}/>
          </>
        }
        {
          currentUser && currentUser.rol === "user" && <>
            <Route path="/" element={<Navigation/>}>
              <Route index element={<Resume active="personal"/>}/>
              <Route path='/menu' element={<Resume/>}/>
              <Route path='/welcome' element={<Welcome/>}/>
              <Route path='/program' element={<Program/>}/>
              <Route path='/personal' element={<Resume active="personal"/>}/>
              <Route path='/summit_and_you' element={<Resume active="summit_and_you"/>}/>
              <Route path='/faith' element={<Resume active="faith"/>}/>
              <Route path='/education' element={<Resume active="education"/>}/>
              <Route path='/work' element={<Resume active="work"/>}/>
              <Route path='/family' element={<Resume active="family"/>}/>
              <Route path='/health' element={<Resume active="health"/>}/>
              <Route path='/legal' element={<Resume active="legal"/>}/>
              <Route path='/financial' element={<Resume active="financial"/>}/>
              <Route path='/agreement' element={<Resume active="agreement"/>}/>
              <Route path='/visa' element={<Resume active="visa"/>}/>
              <Route path='/review_and_submit' element={<Resume active="review_and_submit"/>}/>
              <Route path='/id_passport' element={<Resume active="id_passport"/>}/>
              <Route path='/photo' element={<Resume active="photo"/>}/>
              <Route path='/transcripts' element={<Resume active="transcripts"/>}/>
              <Route path='/references' element={<Resume active="references"/>}/>
              <Route path='/resume' element={<Resume active="resume"/>}/>
              <Route path='/confirmation_fee' element={<Resume active="confirmation_fee"/>}/>
              <Route path='/fees' element={<Resume active="fees"/>}/>
              <Route path='/scholarship' element={<Resume active="scholarship"/>}/>
              <Route path='/extra' element={<Resume active="extra"/>}/>
              <Route path='/medical_records' element={<Resume active="medical"/>}/>
              <Route path='*' element={<Navigate to="/"/>}/>
            </Route>
          </>
        }
        {
          currentUser && currentUser.rol !== "user" && <>
            <Route path="/" element={<Navigation/>}>
              <Route index element={<Dashboard active="dashboard"/>}/>
              <Route path='/prospects' element={<Dashboard active="prospects"/>}/>
              <Route path='/prospects/:id' element={<Dashboard active="profile"/>}/>
              <Route path='/reviewed' element={<Dashboard active="reviewed"/>}/>
              <Route path='/reviewed/:id' element={<Dashboard active="profile"/>}/>
              <Route path='/accepted' element={<Dashboard active="accepted"/>}/>
              <Route path='/accepted/:id' element={<Dashboard active="profile"/>}/>
              <Route path='/confirmed' element={<Dashboard active="confirmed"/>}/>
              <Route path='/confirmed/:id' element={<Dashboard active="profile"/>}/>
              <Route path='/enrolled' element={<Dashboard active="enrolled"/>}/>
              <Route path='/enrolled/:id' element={<Dashboard active="profile"/>}/>
              <Route path='/declined' element={<Dashboard active="declined"/>}/>
              <Route path='/declined/:id' element={<Dashboard active="profile"/>}/>
              <Route path='/archived' element={<Dashboard active="archived"/>}/>
              <Route path='/archived/:id' element={<Dashboard active="profile"/>}/>
              <Route path='/waitlist' element={<Dashboard active="waitlist"/>}/>
              <Route path='/waitlist/:id' element={<Dashboard active="profile"/>}/>
              <Route path='/deferred' element={<Dashboard active="deferred"/>}/>
              <Route path='/deferred/:id' element={<Dashboard active="profile"/>}/>
              <Route path='/scholarship' element={<Dashboard active="scholarship"/>}/>
              <Route path='/scholarship/:id' element={<Dashboard active="profile"/>}/>
              <Route path='/notification' element={<Dashboard active="notification"/>}/>
              <Route path='/reporting' element={<Dashboard active="reporting"/>}/>
              <Route path='/reporting/:id' element={<Dashboard active="profile"/>}/>
              <Route path='/user' element={<Dashboard active="user"/>}/>
              <Route path='*' element={<Navigate to="/"/>}/>
            </Route>
          </>
        }
      </Routes>
    </>
  );
}

export default App;

import { Grid } from '@mui/material'
import QStepsAdmin from './QStepsAdmin'
import { useState } from 'react'
import PersonalAdmin from './QViewerTabs/PersonalAdmin';
import SummitAndYouAdmin from './QViewerTabs/SummitAndYouAdmin';
import FaithAdmin from './QViewerTabs/FaithAdmin';
import EducationAdmin from './QViewerTabs/EducationAdmin';
import WorkAdmin from './QViewerTabs/WorkAdmin';
import FamilyAdmin from './QViewerTabs/FamilyAdmin';
import HealthAdmin from './QViewerTabs/HealthAdmin';
import LegalAdmin from './QViewerTabs/LegalAdmin';
import FinancialAdmin from './QViewerTabs/FinancialAdmin';
import AgreementAdmin from './QViewerTabs/AgreementAdmin';
import VisaAdmin from './QViewerTabs/VisaAdmin';
import DStepsAdmin from './DStepsAdmin';
import IdPassportAdmin from './QViewerTabs/IdPassportAdmin';
import PhotoAdmin from './QViewerTabs/PhotoAdmin';
import ResumeStepAdmin from './QViewerTabs/ResumeStepAdmin';
import ReferencesAdmin from './QViewerTabs/ReferencesAdmin';
import TranscriptsAdmin from './QViewerTabs/TranscriptsAdmin';

const ResumeAdmin = ({application,sideBarOpts,setCurrentApplication}) => {

    const [currentTab, setCurrentTab] = useState('personal');

    return (
        <Grid container spacing={2}>
            <Grid item xs={3} sx={{borderRight:"solid #999999",paddingRight:"30px"}}>
                <QStepsAdmin application={application} steps={sideBarOpts} currentTab={currentTab} setCurrentTab={setCurrentTab}/>
                <DStepsAdmin application={application} currentTab={currentTab} setCurrentTab={setCurrentTab}/>
            </Grid>
            <Grid item xs={9}>
                {
                    currentTab === "personal" && <PersonalAdmin personal={application?.personal}/>
                }
                {
                    currentTab === "summit_and_you" && <SummitAndYouAdmin summitAndYou={application?.summitAndYou}/>
                }
                {
                    currentTab === "faith" && <FaithAdmin faith={application?.faith}/>
                }
                {
                    currentTab === "education" && <EducationAdmin education={application?.education}/>
                }
                {
                    currentTab === "work" && <WorkAdmin work={application?.work}/>
                }
                {
                    currentTab === "family" && <FamilyAdmin family={application?.family}/>
                }
                {
                    currentTab === "health" && <HealthAdmin health={application?.health}/>
                }
                {
                    currentTab === "legal" && <LegalAdmin legal={application?.legal}/>
                }
                {
                    currentTab === "financial" && <FinancialAdmin financial={application?.financial} />
                }
                {
                    currentTab === "agreement" && <AgreementAdmin agreement={application?.agreement} />
                }
                {
                    currentTab === "visa" && <VisaAdmin visa={application?.visa}/>
                }
                {
                    currentTab === "id_passport" && <IdPassportAdmin appId={application?._id} fileName={application?.idPassport?.image} setCurrentApplication={setCurrentApplication}/>
                }
                {
                    currentTab === "photo" && <PhotoAdmin appId={application?._id} fileName={application?.photo.image} setCurrentApplication={setCurrentApplication}/>
                }
                {
                    currentTab === "transcripts" && <TranscriptsAdmin appId={application?._id} transcripts={application?.transcripts} fileName={application?.transcripts?.image}/>
                }
                {
                    currentTab === "references" && <ReferencesAdmin pastoral={application?.pastoral} employer={application?.employer} academic={application?.academic} marriage={application?.marriage} family={application?.family}/>
                }
                {
                    currentTab === "resume" && <ResumeStepAdmin appId={application?._id} fileName={application?.resume.image}/>
                }
            </Grid>
        </Grid>
    )
}

export default ResumeAdmin
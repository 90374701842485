import axios from "axios";

//RENDER TEST URL
// export const BASE_URL = "https://summitback.onrender.com/api/";

//DEVELOPMENT URL
//export const BASE_URL = "http://localhost:8080/api/";
//export const FRONT_URL = "http://localhost:3000/";

//Production URL
//export const BASE_URL = "http://13.50.198.134:8080/api/";
//export const FRONT_URL = "http://13.50.198.134:3000/";

//Production URL
export const BASE_URL = "https://backend.apply.summitpa.org/api/";
export const FRONT_URL = "https://apply.summitpa.org/";

export const loginUser = async(data)=>{
    try {
        const resp = await axios.post(BASE_URL+"auth/login",data);
        return resp.data;
    } catch (error) {
        //TO DO: HANDLE ERROR
        return {
            user:undefined,
            msg:error.response.data.msg
        }
    }
}

export const loginAdminUser = async(data)=>{
    try {
        const resp = await axios.post(BASE_URL+"auth/loginAdmin",data);
        return resp.data;
    } catch (error) {
        //TO DO: HANDLE ERROR
        return {
            user:undefined,
            msg:error.response.data.msg
        }
    }
}

export const createUser = async(data)=>{
    try {
        const resp = await axios.post(BASE_URL+"user",data);
        return resp.data;
    } catch (error) {
        return {
            user:undefined,
            msg:error.response.data.msg
        }
    }
}

export const updateUser = async(id,data)=>{
    try {
        const resp = await axios.put(BASE_URL+"user/"+id,data);
        return resp.data;
    } catch (error) {
        return {
            user:undefined,
            msg:error.response.data.msg
        }
    }
}

export const deleteUser = async(id)=>{
    try {
        const resp = await axios.delete(BASE_URL+"user/"+id);
        return resp.data;
    } catch (error) {
        console.log("AXIOS ERROR",error)
    }
}

export const getUserById = async(id)=>{
    try {
        const resp = await axios.get(BASE_URL+"user/"+id);
        return resp.data;
    } catch (error) {
        console.log("AXIOS ERROR",error);
        return undefined
    }
}

export const createApplication = async(data)=>{
    try {
        const resp = await axios.post(BASE_URL+"application",data);
        return resp.data;
    } catch (error) {
        console.log(error)
        return {
            msg:"ERROR"
        }
    }
}

export const updateApplication = async(data,id)=>{
    try {
        const resp = await axios.put(BASE_URL+"application/"+id,data);
        return resp.data;
    } catch (error) {
        console.log(error)
        return {
            msg:"ERROR"
        }
    }
}

export const getAllApplications = async()=>{
    try {
        const resp = await axios.get(BASE_URL+"application/");
        return resp.data;
    } catch (error) {
        console.log("AXIOS ERROR",error);
        return undefined;
    }
}

export const getApplicationByStatus = async(status,year)=>{
    try {
        const resp = await axios.get(BASE_URL+"application/byStatus/"+status+"/"+year);
        return resp.data;
    } catch (error) {
        console.log("AXIOS ERROR",error);
        return undefined;
    }
}

export const getApplicationById = async(id)=>{
    try {
        const resp = await axios.get(BASE_URL+"application/"+id);
        return resp.data;
    } catch (error) {
        console.log("AXIOS ERROR",error);
        return undefined;
    }
}

export const getApplicationScholarship = async(year)=>{
    try {
        const resp = await axios.get(BASE_URL+"application/scholarship/"+year);
        return resp.data;
    } catch (error) {
        console.log("AXIOS ERROR",error);
        return undefined;
    }
}

export const getApplicationUserYear = async(user,year)=>{
    try {
        const resp = await axios.get(BASE_URL+"application/"+user+"/"+year);
        return resp.data;
    } catch (error) {
        console.log(error);
        return undefined;
    }
}

export const uploadResume = async(data,id) =>{
    try {
        const resp = await axios.post(BASE_URL+"upload/workResume/"+id,data,{
            headers:{
                "Contect-Type":"multipart/form-data"
            }
        })
        return resp.data;
    } catch (error) {
        console.log(error);
    }
}

export const uploadIdPassport = async(data,id) =>{
    try {
        const resp = await axios.post(BASE_URL+"upload/idPassport/"+id,data,{
            headers:{
                "Contect-Type":"multipart/form-data"
            }
        })
        return resp.data;
    } catch (error) {
        console.log(error);
    }
}

export const uploadPhoto = async(data,id) =>{
    try {
        const resp = await axios.post(BASE_URL+"upload/photo/"+id,data,{
            headers:{
                "Contect-Type":"multipart/form-data"
            }
        })
        return resp.data;
    } catch (error) {
        console.log(error);
    }
}

export const uploadTranscripts = async(data,id) =>{
    try {
        const resp = await axios.post(BASE_URL+"upload/transcripts/"+id,data,{
            headers:{
                "Contect-Type":"multipart/form-data"
            }
        })
        return resp.data;
    } catch (error) {
        console.log(error);
    }
}

export const uploadImmuno = async(data,id) =>{
    try {
        const resp = await axios.post(BASE_URL+"upload/immuno/"+id,data,{
            headers:{
                "Contect-Type":"multipart/form-data"
            }
        })
        return resp.data;
    } catch (error) {
        console.log(error);
    }
}

export const uploadPhysical = async(data,id) =>{
    try {
        const resp = await axios.post(BASE_URL+"upload/physical/"+id,data,{
            headers:{
                "Contect-Type":"multipart/form-data"
            }
        })
        return resp.data;
    } catch (error) {
        console.log(error);
    }
}

export const uploadEssay = async(data,id) =>{
    try {
        const resp = await axios.post(BASE_URL+"upload/essay/"+id,data,{
            headers:{
                "Contect-Type":"multipart/form-data"
            }
        })
        return resp.data;
    } catch (error) {
        console.log(error);
    }
}

export const uploadFinancial = async(data,id)=>{
    try {
        const resp = await axios.post(BASE_URL+"upload/financial/"+id,data,{
            headers:{
                "Contect-Type":"multipart/form-data"
            }
        })
        return resp.data;
    } catch (error) {
        console.log(error);
    }
}

export const reviewAndSubmit = async(id)=>{
    try {
        const resp = await axios.get(BASE_URL+"application/review/"+id);
        return resp.data;
    } catch (error) {
        console.log(error)
    }
}

export const getAllConsultants = async()=>{
    try {
        const resp = await axios.get(BASE_URL+"user/getConsultants/");
        return resp.data;
    } catch (error) {
        console.log("ERROR (AXIOS)",error)
    }
}

export const updateAssignTo = async(id,data)=>{
    try {
        const resp = await axios.put(BASE_URL+"/application/assignTo/"+id,data);
        return resp.data;
    } catch (error) {
        console.log("ERROR (AXIOS)",error)
    }
}

export const updateQuetionnaireColumn = async(id,data)=>{
    try {
        const resp = await axios.put(BASE_URL+"/application/questionaireColumn/"+id,data);
        return resp.data;
    } catch (error) {
        console.log("ERROR (AXIOS)",error)
    }
}
export const updateDocumentsColumn = async(id,data)=>{
    try {
        const resp = await axios.put(BASE_URL+"/application/documentsColumn/"+id,data);
        return resp.data;
    } catch (error) {
        console.log("ERROR (AXIOS)",error)
    }
}
export const updateFeeColumn = async(id,data)=>{
    try {
        const resp = await axios.put(BASE_URL+"/application/feeColumn/"+id,data);
        return resp.data;
    } catch (error) {
        console.log("ERROR (AXIOS)",error)
    }
}
export const updateVisaColumn = async(id,data)=>{
    try {
        const resp = await axios.put(BASE_URL+"/application/visaColumn/"+id,data);
        return resp.data;
    } catch (error) {
        console.log("ERROR (AXIOS)",error)
    }
}
export const updatePhysicalColumn = async(id,data)=>{
    try {
        const resp = await axios.put(BASE_URL+"/application/physicalColumn/"+id,data);
        return resp.data;
    } catch (error) {
        console.log("ERROR (AXIOS)",error)
    }
}

export const updateImmunizationColumn = async(id,data)=>{
    try {
        const resp = await axios.put(BASE_URL+"/application/immunizationColumn/"+id,data);
        return resp.data;
    } catch (error) {
        console.log("ERROR (AXIOS)",error)
    }
}

export const updateEssayColumn = async(id,data)=>{
    try {
        const resp = await axios.put(BASE_URL+"/application/essayColumn/"+id,data);
        return resp.data;
    } catch (error) {
        console.log("ERROR (AXIOS)",error)
    }
}

export const updateFinancialDocumentsColumn = async(id,data)=>{
    try {
        const resp = await axios.put(BASE_URL+"/application/financialDocumentsColumn/"+id,data);
        return resp.data;
    } catch (error) {
        console.log("ERROR (AXIOS)",error)
    }
}

export const updateStatusApp = async(id,data)=>{
    try {
        const resp = await axios.put(BASE_URL+"/application/statusApp/"+id,data);
        return resp.data;
    } catch (error) {
        console.log("ERROR (AXIOS)",error)
    }
}

export const updateStatusColumn = async(id,data)=>{
    try {
        const resp = await axios.put(BASE_URL+"/application/statusColumn/"+id,data);
        return resp.data;
    } catch (error) {
        console.log("ERROR (AXIOS)",error)
    }
}

export const updateStatusScholarshipColumn = async(id,data)=>{
    try {
        const resp = await axios.put(BASE_URL+"/application/statusScholarshipColumn/"+id,data);
        return resp.data;
    } catch (error) {
        console.log("ERROR (AXIOS)",error)
    }
}

export const updateMedicTransportColumn = async(id,data)=>{
    try {
        const resp = await axios.put(BASE_URL+"/application/medicTransportColumn/"+id,data);
        return resp.data;
    } catch (error) {
        console.log("ERROR (AXIOS)",error)
    }
}

export const updateProgramColumn = async(id,data)=>{
    try {
        const resp = await axios.put(BASE_URL+"/application/program/"+id,data);
        return resp.data;
    } catch (error) {
        console.log("ERROR (AXIOS)",error)
    }
}



export const getActivityByApplicationId = async(id)=>{
    try {
        const resp = await axios.get(BASE_URL+"activity/"+id);
        return resp.data;
    } catch (error) {
        console.log("AXIOS ERROR",error);
        return undefined
    }
}

export const createActivity = async(data)=>{
    try {
        const resp = await axios.post(BASE_URL+"activity/",data);
        return resp.data;
    } catch (error) {
        console.log("AXIOS ERROR",error);
        return undefined
    }
}

export const updateActivity = async(id,data)=>{
    try {
        const resp = await axios.put(BASE_URL+"activity/"+id,data);
        return resp.data;
    } catch (error) {
        console.log("AXIOS ERROR",error);
        return undefined
    }
}

export const deleteActivity = async(id)=>{
    try {
        const resp = await axios.delete(BASE_URL+"activity/"+id);
        return resp.data;
    } catch (error) {
        console.log("AXIOS ERROR",error);
        return undefined
    }
}

export const getHomeInfo = async(data)=>{
    try {
        const resp = await axios.post(BASE_URL+"admin/homeInfo",data);
        return resp.data;
    } catch (error) {
        console.log("AXIOS ERROR",error);
        return undefined
    }

}

export const createNotification = async(data)=>{
    try {
        const resp = await axios.post(BASE_URL+"notification",data);
        return resp.data;
    } catch (error) {
        console.log("AXIOS ERROR",error);
        return undefined
    }
}

export const getHomeNotifications = async(year)=>{
    try {    
        const resp = await axios.get(BASE_URL+"notification/byYear/"+year);
        return resp.data;
    } catch (error) {
        console.log("AXIOS ERROR",error);
    }
}

export const deleteNotificationByStatus = async(data)=>{
    try {    
        const resp = await axios.delete(BASE_URL+"notification/byStatus",{data});
        return resp.data;
    } catch (error) {
        console.log("AXIOS ERROR",error);
    }
}

export const deleteNotificationWarnings = async(data)=>{
    try {    
        const resp = await axios.delete(BASE_URL+"notification/warnings",{data});
        return resp.data;
    } catch (error) {
        console.log("AXIOS ERROR",error);
    }
}

//REPORTING
export const getReportingInfo = async(data)=>{
    try{
        const resp = await axios.post(BASE_URL+"reporting",data);
        return resp.data;
    }catch(error){
        console.log("AXIOS ERROR",error)
    }
}

//Stripe
export const getStripeKey = async()=>{
    try{
        const resp = await axios.get(BASE_URL+"stripe/getPublicKey");
        return resp.data;
    }catch(error){
        console.log("AXIOS ERROR",error)
    }
}

export const getStripeClientSecretKey = async(data)=>{
    try{        
        const resp = await axios.post(BASE_URL+"stripe/create_payment_intent",data);
        return resp.data;
    }catch(error){
        console.log("AXIOS ERROR",error)
    }
}

//EMAIL NOTIFICATION

export const forgotPasswordEmail = async(data)=>{
    try {
        const resp = await axios.post(BASE_URL+"email/forgotPassword",data);
        return resp.data;
    } catch (error) {
        console.log("AXIOS ERROR",error) 
    }
}
import { Grid, Typography } from "@mui/material"
import { upperFirstLetter } from "../../../utils/qViewerFunctions"

const LegalAdmin = ({legal}) => {
    return (
        <Grid container spacing={2}>
            <Grid item>
                <Typography variant='h5' color="customYellow.main" sx={{marginBottom:"30px"}}><b>Legal</b></Typography>
                <Typography variant='subtitle1'><b>Have you ever been arrested?</b></Typography>
                <Typography variant='subtitle1' sx={{marginLeft:"20px"}}>{upperFirstLetter(legal?.arrested)}</Typography>
                {
                    legal?.arrested === "yes" && <>
                        <Typography variant='subtitle1'><b>Please Elaborate</b></Typography>
                        <Typography variant='subtitle1' sx={{marginLeft:"20px"}}>{legal?.explainArrested}</Typography>
                        <Typography variant='subtitle1'><b>Are you currently on parole?</b></Typography>
                        <Typography variant='subtitle1' sx={{marginLeft:"20px"}}>{upperFirstLetter(legal?.parole)}</Typography>
                        {
                            legal?.parole === "yes" && <>
                                <Typography variant='subtitle1'><b>Parole Officer Name</b></Typography>
                                <Typography variant='subtitle1' sx={{marginLeft:"20px"}}>{legal?.officerName}</Typography>
                                <Typography variant='subtitle1'><b>Parole officer phone number</b></Typography>
                                <Typography variant='subtitle1' sx={{marginLeft:"20px"}}>(+{legal?.countryCode}){legal?.officerNumber}</Typography>
                            </>
                        }
                    </>
                }
            </Grid>
        </Grid>
    )
}

export default LegalAdmin
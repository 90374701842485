import { List, ListItem, ListItemButton, ListItemText, Typography } from '@mui/material'
import CheckCircleIcon from '@mui/icons-material/CheckCircle';

const QStepsAdmin = ({application,steps,currentTab,setCurrentTab}) => {

    const handleClick = (tab)=>{
        setCurrentTab(tab);
    }

    return (
        <>
            <Typography variant="h5">Questionnaire:</Typography>
            <List sx={{width:"100%"}}>
                {
                    steps.map(({label,numberSteps,route,requiredStepsLabel},i)=>(
                        <ListItem key={i} onClick={()=>handleClick(route)} sx={{padding:{xs:"0px"}, borderBottom:"1px solid #9E9E9E",backgroundColor:currentTab ===route?'customYellow.main':'white'}}>
                            <ListItemButton>
                                <ListItemText primary={label}/>
                                <Typography edge="end">{ application && application[requiredStepsLabel]?.progress ? application[requiredStepsLabel]?.progress === numberSteps ? <CheckCircleIcon/> : `${application[requiredStepsLabel]?.progress}/${numberSteps}`: `0/${numberSteps}` }</Typography>
                            </ListItemButton>
                        </ListItem>
                    ))
                }
            </List>
        </>
    )
}

export default QStepsAdmin
import { useContext, useEffect, useState } from 'react'
import { Box, Button, Dialog, DialogContent, Grid, MenuItem, Select, Tab, Tabs, Typography } from '@mui/material'
import EditIcon from '@mui/icons-material/Edit';
import SaveIcon from '@mui/icons-material/Save';
import { deleteNotificationByStatus, getAllConsultants, updateAssignTo, updateStatusApp, updateStatusColumn } from '../../axios/axiosFunctions';
import { UserContext } from '../../contexts/user';
import Activity from './Activity';
import History from './QViewerTabs/History';

const Activities = ({activities,counselor,appId,setCurrentActivities,statusColumn,statusApp}) => {

    const {currentUser} =useContext(UserContext);
    const [tabValue, setTabValue] = useState(0);
    const [openDialog, setOpenDialog] = useState(false);
    const [selectOpt, setSelectOpt] = useState([]);
    const [currentValue, setCurrentValue] = useState(counselor);
    const [editState, setEditState] = useState(false)

    useEffect(() => {
        const getSelectOptions = async()=>{
            const res = await getAllConsultants();
            setSelectOpt(res);
            setCurrentValue(counselor);
        } 
        getSelectOptions();
    }, [counselor]);

    const handleDialogClose = ()=>{
        setOpenDialog(false);
    }

    const handleTabChange = (_,newValue)=>{
        setTabValue(newValue);
    }

    const handleArchiveActivity = async()=>{
        await updateStatusApp(appId,{statusApp:"archived"});
        handleDialogClose();
    }

    const handleEditState = ()=>{
        setEditState(!editState);
    }

    const handleSelectChange = async(e)=>{
        const {value} = e.target;
        setCurrentValue(value);
        const data = {
            assign: value
        }
        await updateAssignTo(appId,data);
        const deleteData = {
            application:appId,
            status:"New"
        }
        const {msg} = await deleteNotificationByStatus(deleteData);
        if(msg === "Deleted"){
            await updateStatusColumn(appId,{statusColumn:"Up to date"});
        }
    } 

    return (
    <Grid container spacing={2} sx={{padding:"10px"}}>
        <Grid item xs={12} sx={{display:"flex",justifyContent:"space-between"}}>
            <Box sx={{display:"flex",alignItems:"center"}}>
                {
                    editState === false && <>
                        <Typography><b>Counselor:</b> {currentValue}</Typography>
                        <EditIcon onClick={handleEditState} sx={{marginLeft:"20px"}}/>
                    </>
                }
                {
                    editState === true && <>
                        <Typography><b>Counselor:</b></Typography>
                        <Select onChange={handleSelectChange} value={currentValue} fullWidth sx={{boxShadow: 'none', '.MuiOutlinedInput-notchedOutline': { border: 0 }}} >
                            {
                                selectOpt.map((opt,i)=>(
                                    <MenuItem key={i} value={`${opt.firstName} ${opt.lastName}`}>{opt.firstName} {opt.lastName}</MenuItem>
                                ))
                            }
                        </Select>
                        <SaveIcon onClick={handleEditState} sx={{marginLeft:"20px"}}/>
                    </>
                }
            </Box>
        </Grid>
        <Grid item sx={{width:"100%"}}>
            <Box sx={{marginBottom:"10px",display:"flex",justifyContent:"space-between"}}>
                <Tabs value={tabValue} onChange={handleTabChange}>
                    <Tab label="ACTIVITY" value={0}/>
                    {
                        currentUser.rol !== "reviewer" && 
                        <Tab label="HISTORY" value={1}/>
                    }
                </Tabs>
                {
                    statusColumn === "Archive" && statusApp !== "archived" && <>
                        <Button variant='contained' onClick={()=>{setOpenDialog(true)}} sx={{backgroundColor:'#864FCC',color:"white"}}>ARCHIVE</Button>
                        <Dialog open={openDialog} onClose={handleDialogClose}>
                            <DialogContent>
                                <Typography variant='h6' sx={{textAlign:"center"}}>Are you sure to ARCHIVE this application?</Typography>
                                <Typography variant='subtitle2' sx={{textAlign:"center"}}>(Remember to add an activity explaining why)</Typography>
                                <Box sx={{display:"flex",margin:"20px",justifyContent:"center"}}>
                                    <Button onClick={handleArchiveActivity} variant='contained'sx={{marginRight:"20px",justifyContent:"space-around"}}>SAVE</Button>
                                    <Button variant='contained' onClick={handleDialogClose} color="customGray">CANCEL</Button>
                                </Box>
                            </DialogContent>
                        </Dialog>
                    </>
                }
            </Box>
            <Box sx={{maxHeight:"400px",overflow:"hidden",overflowY:tabValue===1 ? "scroll" : ""}}>
            {
                tabValue === 0 && <Activity appId={appId} setCurrentActivities={setCurrentActivities}/>
            }
            {
                tabValue === 1 && <History activities={activities} setCurrentActivities={setCurrentActivities}/>
            }
            </Box>
        </Grid>
    </Grid>
    )
}

export default Activities
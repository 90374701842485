import { Grid } from "@mui/material";
import EditProfile from "./EditProfile";
import CreateUser from "./CreateUser";
import { useContext } from "react";
import { UserContext } from "../../contexts/user";
import UsersList from "./UsersList";

const UserTab = () => {

  const{currentUser}=useContext(UserContext);
  
  return (
    <Grid container maxWidth={"xl"} sx={{marginTop:"50px"}} spacing={4}>
        <Grid item xs={12}>
            <EditProfile/>
        </Grid>
        {
          currentUser.rol === "admin" && <>
            <Grid item xs={12}>
                <CreateUser/>
            </Grid>
            <Grid item xs={12}>
                <UsersList/>
            </Grid>
          </>
        }
    </Grid>
  )
}

export default UserTab
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Alert, Button, Checkbox, Dialog, DialogContent, DialogTitle, FormControlLabel, FormGroup, Grid, MobileStepper, Radio, RadioGroup, TextField, Typography } from "@mui/material";
import BannerStep from "./BannerStep";

const defaultFaithForm = {
    whenAcceptJesus : "",
    howKnowJesus: "",
    attendRegularly :"",
    explain:"",
    nameChurch:"",
    timeAttending:"",
    churchWebsite:"",
    isInvolved:"",
    readStatement:false
}

const Faith = ({data,updateApplication}) => {

    const navigate = useNavigate();
    const [faithForm, setFaithForm] = useState(defaultFaithForm);
    const [statementOpen, setStatementOpen] = useState(false);
    const [activeStep, setActiveStep] = useState(0);
    const [showError, setShowError] = useState(false);
    const steps = 2;
    const {
        whenAcceptJesus,
        howKnowJesus,
        attendRegularly,
        explain,
        nameChurch,
        timeAttending,
        churchWebsite,
        isInvolved,
        readStatement
    }=faithForm;

    useEffect(() => {
      if(data){
        setFaithForm({
            whenAcceptJesus: data.whenAcceptJesus || "",
            howKnowJesus: data.howKnowJesus || "",
            attendRegularly: data.attendRegularly || "",
            explain: data.explain || "",
            nameChurch: data.nameChurch || "",
            timeAttending: data.timeAttending || "",
            churchWebsite: data.churchWebsite || "",
            isInvolved: data.isInvolved || "",
            readStatement: data.readStatement || false
        })
      }
    }, [data])
    
    const handleStatementOpen = ()=>{
        setStatementOpen(true);
    }

    const handleStatementClose = ()=>{
        setStatementOpen(false);
    }

    const handleNext = () => {

        handleSubmit();
        if(!checkRequired()){
            setShowError(true);
            setTimeout(() => {
                setShowError(false);
            }, 2000);
            return;
        }

        if(activeStep === steps-1){
            navigate('/education');
            return;
        }
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
    };
    
    const handleBack = () => {
        handleSubmit();
        if(activeStep === 0)
        {
            navigate('/summit_and_you');
            return;
        }
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const checkRequired = ()=>{
        if(activeStep === 0){
            return whenAcceptJesus !== "" && howKnowJesus !== ""
        }
        if(activeStep === 1){
            return (attendRegularly === "no" && explain !== "" && readStatement) || (attendRegularly === "yes" && nameChurch !== ""  && isInvolved !== "" && readStatement)
        }
        return true;
    }

    const handleChangeForm = (e)=>{
        const {name,value} = e.target;
        setFaithForm({...faithForm, [name]:value});
    }

    const handleCheckBox = ()=>{
        setFaithForm({...faithForm,readStatement:!readStatement})
    }

    const handleSubmit = ()=>{
        const dataSubmit = {
            faith:{
                ...faithForm
            }
        }
        updateApplication(dataSubmit);
    }

    return (
        <Grid container flexDirection="column-reverse">
            <Grid item xs={12}>
                <MobileStepper
                    variant="dots"
                    steps={steps}
                    position="static"
                    activeStep={activeStep}
                    sx={{ width:"100%", flexGrow: 1 , marginTop:"50px"}}
                    nextButton={
                        <Button size="small" onClick={handleNext}>
                            {activeStep === steps-1 ? 'Next' : 'Next' }
                        </Button>
                    }
                    backButton={
                        <Button size="small" onClick={handleBack}>
                            {activeStep === 0 ? 'Back' : 'Back'}
                        </Button>
                    }
                />
            </Grid>
            {
                showError &&
                <Grid item xs={12}>
                    <Alert severity='error' sx={{marginTop:"20px"}}>
                        One or more REQUIRED fields are empty.
                    </Alert>
                </Grid>
            }
            <Grid item xs={12}>
                <>
                    {
                        activeStep === 0 && <>
                            <Grid container spacing={2}>
                                <Grid item xs={12} sm={8}>
                                    <Typography variant='subtitle1' sx={{marginBottom:"10px"}}>When did you accept Jesus Christ as your personal Savior?*</Typography>
                                    <TextField required fullWidth value={whenAcceptJesus} onChange={handleChangeForm} name='whenAcceptJesus'/>
                                </Grid>
                                <Grid item xs={12} sm={8}>
                                    <Typography variant='subtitle1' sx={{marginBottom:"10px"}}>Tell us how you came to know Christ*</Typography>
                                    <TextField required onChange={handleChangeForm} inputProps={{maxLength:500}} helperText={`Max Length ${howKnowJesus.length}/500`}  fullWidth value={howKnowJesus} name="howKnowJesus" multiline rows={4}/>
                                </Grid>
                            </Grid>
                        </>
                    }
                    {
                        activeStep === 1 && <>
                            <Grid container spacing={2}>    
                                <Grid item xs={12}>
                                    <Typography variant='subtitle1'>Do you attend church regularly?*</Typography>
                                    <RadioGroup sx={{flexDirection:"row"}} value={attendRegularly} onChange={handleChangeForm}>
                                        <FormControlLabel value="yes" name="attendRegularly" control={<Radio />} label="Yes" />
                                        <FormControlLabel value="no" name="attendRegularly" control={<Radio />} label="No" />
                                    </RadioGroup>
                                </Grid>
                                {
                                    attendRegularly === "yes" && <>
                                        <Grid item xs={12}>
                                            <Typography variant='subtitle1' sx={{marginBottom:"10px"}}>What is the name of the church you currently attend?*</Typography>
                                            <TextField required fullWidth value={nameChurch} onChange={handleChangeForm} name='nameChurch'/>
                                        </Grid>
                                        <Grid item xs={12} sm={12}>
                                            <Typography variant='subtitle1' sx={{marginBottom:"10px"}}>How long have you been attending there?</Typography>
                                            <TextField fullWidth value={timeAttending} onChange={handleChangeForm} name='timeAttending'/>
                                        </Grid>
                                        <Grid item xs={12} sm={12}>
                                            <Typography variant='subtitle1' sx={{marginBottom:"10px"}}>Church Website</Typography>
                                            <TextField required fullWidth value={churchWebsite} onChange={handleChangeForm} name='churchWebsite'/>
                                        </Grid>
                                        <Grid item xs={12} sm={12}>
                                            <Typography variant='subtitle1' sx={{marginBottom:"10px"}}>How are you currently involved in ministry in your church?*</Typography>
                                            <TextField required fullWidth value={isInvolved} onChange={handleChangeForm} name='isInvolved' multiline rows={4}/>
                                        </Grid>
                                    </>
                                }
                                {
                                    attendRegularly === "no" && <>
                                        <Grid item xs={12}>
                                            <Typography variant='subtitle1'>Please Elaborate*</Typography>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <TextField onChange={handleChangeForm} inputProps={{maxLength:500}} helperText={`Max Length ${explain.length}/500`}  value={explain} name="explain" sx={{width:{xs:"100%",sm:"65%"}}} placeholder="Text" multiline rows={4}/>
                                        </Grid>
                                    </>
                                }   
                                <Grid item xs={12} sm={12}>
                                    <Button onClick={handleStatementOpen} variant="contained" sx={{padding:"10px 40px"}}>CLICK TO READ SUMMIT'S STATEMENT OF FAITH</Button>
                                    <Dialog open={statementOpen} onClose={handleStatementClose} >
                                        <DialogTitle>
                                            <Typography variant='h5'>
                                                Statement of Faith  
                                            </Typography>
                                        </DialogTitle>
                                        <DialogContent>
                                            <ul>
                                                <li style={{marginBottom:"10px"}}>
                                                    The Bible is the inspired and only infallible Word of God.
                                                </li>
                                                <li style={{marginBottom:"10px"}}>
                                                    There is one true God, eternally existent in three persons: God the Father, God the Son, God the Holy Spirit.
                                                </li>
                                                <li style={{marginBottom:"10px"}}>
                                                    In the deity of our Lord Jesus Christ, in His virgin birth, in His sinless life, in His miracles, in His vicarious and atoning death, in His bodily resurrection, in His ascension to the right hand of the Father, in His personal future return to the earth in power and glory to rule for a thousand years.
                                                </li>
                                                <li style={{marginBottom:"10px"}}>
                                                    In the blessed hope, the imminent return of Christ for overcoming believers.
                                                </li>
                                                <li style={{marginBottom:"10px"}}>
                                                    In the fall and sinfulness of man and that the only means of being cleansed from sin is through repentance and faith in the redeeming blood of Christ.
                                                </li>
                                                <li style={{marginBottom:"10px"}}>
                                                    Regeneration by the Holy Spirit is absolutely essential to personal salvation.
                                                </li>
                                                <li style={{marginBottom:"10px"}}>
                                                    Divine healing is available through the redemptive work of Christ on the cross.
                                                </li>
                                                <li style={{marginBottom:"10px"}}>
                                                    The Baptism of the Holy Spirit, according to Acts 2:4, is given to believers who ask for it. The Holy Spirit in a person’s life is evidenced by changes in his life, including a renewed love for God, and people, a commitment to the scriptures and holy living. The spirit filled believer has the capability of speaking with new tongues whether or not he or she chooses to exercise this gifting.
                                                </li>
                                                <li style={{marginBottom:"10px"}}>
                                                    The Church of Jesus Christ is the universal, spiritual body of believers from every tribe, tongue, kindred and race of peoples, and is indwelt by God through the Holy Spirit and divinely empowered to fulfill ministry and her Great Commission on earth.
                                                </li>
                                                <li style={{marginBottom:"10px"}}>
                                                    In the sanctifying power of the Holy Spirit by whose indwelling the Christian is enabled to live a holy life.
                                                </li>
                                                <li style={{marginBottom:"10px"}}>
                                                    In the resurrection and final judgment of both the saved and the lost, the saved to everlasting life and the lost to everlasting damnation.
                                                </li>
                                                <li style={{marginBottom:"10px"}}>
                                                    In the new heavens and the new earth and the holy Jerusalem, the city of God, descending out of heaven and filled with the glory of God.
                                                </li>
                                                <li style={{marginBottom:"10px"}}>
                                                    Christian growth can only be realized by faith in the promises of God.
                                                </li>
                                                <li style={{marginBottom:"10px"}}>
                                                    Marriage is God-ordained. “The creator made them male and female, and said, “For this reason a man will leave his father and mother and be united to his wife, and the two will become one flesh” (Matthew 19:4-5). Marriage is to be an exclusive relationship, a lifelong faithful union between a man and a woman. This relationship between a husband and wife should parallel the relationship between Christ and the Church (Ephesians 5:23-30).
                                                </li>
                                                <li style={{marginBottom:"10px"}}>
                                                    We practice two ordinances: (1) Water Baptism by immersion after repenting of one’s sins and receiving the gift of salvation. And (2) Holy Communion (the Lord’s Supper) as a symbolic remembrance of Christ’s suffering and death for our salvation.
                                                </li>
                                            </ul>
                                        </DialogContent>
                                    </Dialog>
                                </Grid>
                                <Grid item xs={12} sm={12}>
                                    <FormGroup onChange={handleCheckBox}>
                                        <FormControlLabel checked={readStatement} required control={<Checkbox/>} value={0} label="I have read Summit's Statement of Faith" />
                                    </FormGroup>
                                </Grid> 
                            </Grid>
                        </>
                    }
                </>        
            </Grid>
            <Grid item xs={12} sx={{margin:{xs:"0px 0px 50px 0px",sm:"50px 0px"}}}>
                <BannerStep title="Faith" stepNumber={activeStep+1} totalSteps={2}/>
            </Grid>
        </Grid>
    )
}

export default Faith
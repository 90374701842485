export const getFilteredStatus = (statusCheckboxes)=>{
    const allStatusApps = ["prospect","reviewed","accepted","confirmed","enrolled","waitlist","deferred","declined"];  //UPDATE IF ANY OTHER;
    const resp = [];
    statusCheckboxes.forEach((elem,i)=>{
        if(elem){
            resp.push(allStatusApps[i]);
        }
    })
    if(resp.length === 0){
        return allStatusApps;
    }
    return resp;
}

export const getFilteredProgram = (programCheckboxes)=>{
    const allPrograms = ["traditional","sabbatical","gapYear"];  //UPDATE IF ANY OTHER;
    const resp = [];
    programCheckboxes.forEach((elem,i)=>{
        if(elem){
            resp.push(allPrograms[i]);
        }
    })
    if(resp.length === 0){
        return allPrograms;
    }
    return resp;
}

export const getFilteredCitizenship = (interOrDom,citizenship)=>{
    if(interOrDom === "all"){
        return true;
    }
    if(interOrDom === "International"){
        return citizenship !== "United States";
    }

    if(interOrDom === "Domestic"){
        return citizenship === "United States";
    }
}

export const getFilteredGender = (genderForm,genderApp)=>{
    if(genderForm === "all"){
        return true;
    }
    if(genderForm === "male"){
        return genderApp === "male"
    }
    if(genderForm === "female"){
        return genderApp === "female"
    }
}
import { Alert, Button, Checkbox, Dialog, DialogContent, DialogTitle, FormControlLabel, FormGroup, FormLabel, Grid, MobileStepper, Radio, RadioGroup, TextField, Typography } from "@mui/material"
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import BannerStep from "./BannerStep";

const defaultFinancialForm = {
    readPolicy:false,
    responsible:"",
    responsabilities:"",
    amountDebt:"",
    sourceIncome:"",
    financialAssistance:"",
    paymentPlan:"",
    scholarship:"",
}

const Financial = ({data,updateApplication,setSteps,defaultSteps,fee}) => {

    const navigate = useNavigate();
    const [financialForm, setFinancialForm] = useState(defaultFinancialForm);
    const [policyOpen, setPolicyOpen] = useState(false);
    const [showError, setShowError] = useState(false);
    const [activeStep, setActiveStep] = useState(0);
    const steps = 1;
    const {readPolicy,responsabilities,sourceIncome,amountDebt,financialAssistance,paymentPlan,scholarship,responsible} = financialForm;

    useEffect(() => {
      if(data){
        setFinancialForm({
            readPolicy: data.readPolicy || false,
            responsible: data.responsible || "",
            responsabilities: data.responsabilities || "",
            amountDebt: data.amountDebt || "",
            sourceIncome: data.sourceIncome || "",
            financialAssistance: data.financialAssistance || "",
            paymentPlan: data.paymentPlan || "",
            scholarship : data.scholarship || "",
        });
      }
    }, [data])
    
    const handleNext = () => {
        handleSubmit();
        if(!checkRequired()){
            setShowError(true);
            setTimeout(() => {
                setShowError(false);
            }, 2000);
            return;
        }
        if(activeStep === steps-1){
            navigate('/agreement');
            return;
        }
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
    };
    
    const handleBack = () => {
        handleSubmit();
        if(activeStep === 0)
        {
            navigate('/health');
            return;
        }
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const checkRequired = ()=>{
        if(activeStep === 0){
            return readPolicy && responsible !== ""
        }
        return true;
    }

    const handlePolicyOpen = ()=>{
        setPolicyOpen(true);
    }

    const handlePolicyClose = ()=>{
        setPolicyOpen(false);
    }

    const handleChangeForm = (e)=>{
        const {name,value} = e.target;
        const newSteps = [...defaultSteps];
        if(name === "scholarship" && value === "yes"){
            if(defaultSteps.length === 2){
                newSteps.push({label:"Scholarship",disabled:!fee,completed:false});
                setSteps(newSteps)
            }
        }
        if(name === "scholarship" && value === "no" && newSteps.length === 3){
            newSteps.pop();
            setSteps(newSteps);
        }

        setFinancialForm({...financialForm, [name]:value});
    }

    const handleCheckBox = ()=>{
        setFinancialForm({...financialForm,readPolicy:!readPolicy})
    }

    const handleSubmit = ()=>{
        const dataSubmit={
            financial:{
                ...financialForm
            }
          }
        
          updateApplication(dataSubmit);
    }

    return (
        <Grid container flexDirection="column-reverse">
            <Grid item xs={12}>
                <MobileStepper
                    variant="dots"
                    steps={steps}
                    position="static"
                    activeStep={activeStep}
                    sx={{ width:"100%", flexGrow: 1 , marginTop:"50px"}}
                    nextButton={
                        <Button size="small" onClick={handleNext}>
                            {activeStep === steps-1 ? 'Next' : 'Next' }
                        </Button>
                    }
                    backButton={
                        <Button size="small" onClick={handleBack}>
                            {activeStep === 0 ? 'Back' : 'Back'}
                        </Button>
                    }
                />
            </Grid>
            {
                showError &&
                <Grid item xs={12}>
                    <Alert severity='error' sx={{marginTop:"20px"}}>
                        One or more REQUIRED fields are empty.
                    </Alert>
                </Grid>
            }
            <Grid item xs={12}>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <Button onClick={handlePolicyOpen} variant="contained" color="customYellow" sx={{padding:"10px 40px"}}>CLICK TO READ SUMMIT’S FINANCIAL POLICY</Button>
                        <Dialog open={policyOpen} onClose={handlePolicyClose}>
                            <DialogTitle>
                                <Typography variant='h5'>
                                    <b><u>Summit International School of Ministry Financial Policy</u></b>
                                </Typography>
                            </DialogTitle>
                            <DialogContent>
                                Summit International School of Ministry, Inc. (“Summit”) is a non-accredited 2-year School of Ministry.  
                                <br/>
                                <br/>
                                Summit does not participate in State or Federal Financial Aid programs or Federal Student Loans and therefore is not required and does not issue Form 1098-T, Tuition Statement, for tuition paid in any given year. However, in addition to the automatic reduction in tuition rate given to every student, Summit has two options to assist students and their families with financing while the student is enrolled. These options include:
                                <ul>
                                    <li>
                                        Payment plans
                                    </li>
                                    <li> 
                                        Scholarships
                                    </li>
                                </ul>
                                <b>By accepting admission to attend Summit, the student and or their guarantor(s) are accepting full responsibility for the payment of all tuition and all other related fees when due.</b>
                                <br/>
                                <br/>
                                <b><u>PROCEDURES AND REGISTRATION:</u></b>
                                <br/>
                                <br/>
                                All tuition and school fees must be paid, or payment arrangements made, before students can register for and attend classes and enter the dormitory. Payments can be conveniently made by cash, check, money order, or online via credit card at summitpa.quickschools.com. If writing a check, please make sure student’s name is included in the memo line. 
                                <br/>
                                <br/>
                                Please make checks payable to Summit and mail to the address below:
                                <br/>
                                <br/>
                                Summit International School of Ministry 
                                <br/>
                                Attn: Finance Office 
                                <br/>
                                74 Harrison School Road 
                                <br/>
                                Grantville, PA 17028 
                                <br/>
                                <br/>
                                <b>All debit/credit card payments will be charged a processing fee on each transaction amount.</b>
                                <br/>
                                <br/>
                                If a student arrives on Summit’s campus on registration day and has not already paid the required fees, the student will be directed to the Finance Department to pay. If the student is unable to pay the required fees, or make payment arrangements, the student will not be considered enrolled for the semester and will not be permitted to attend class or move into the dormitories.
                                <br/>
                                <br/>
                                Each semester must be paid in full before a student can be permitted to enroll into the next semester.
                                <br/>
                                <br/>
                                Failure to pay tuition or other associated school costs on time may deter the student from participating in school events or activities including but not limited to Graduation and/or Summit Internship Positions. Repeated non-payment may result in dismissal from Summit and future withholding of transcripts or diploma.
                                <br/>
                                <br/>
                                <b><u>PAYMENT PLAN OPTION:</u></b>
                                <br/>
                                <br/>
                                Summit works with each student on an individualized basis to make the payment process as simple as possible and ensure that each student has a chance to reap the benefits of a full Summit experience. To help ease the financial burden, a Payment Plan is available to students upon request via cash, check or debit/credit card. Completion of a Payment Plan Form is required which can be obtained by contacting the Finance Office.
                                <br/>
                                <br/>
                                All students are required to pay upfront annual fees, dorm fees and 25% of the first semester tuition, room and board two weeks before arrival on campus. If this first payment is not paid by arrival day, the student will not be considered enrolled for the semester and will not be permitted to attend class or move into the dormitories.
                                <br/>
                                <br/>
                                Payments will be due by the 10th of each month (October through April) and if payment is not received by the 10th of the month, a late fee of $35 will be assessed. Students will be charged a $35 NSF fee for any returned/declined payments.
                                <br/>
                                <br/>
                                <b><u>SCHOLARSHIPS:</u></b>
                                <br/>
                                <br/>
                                There are several scholarships available to students. Please visit Summit’s website (summitpa.org) which lists the criteria for each scholarship.
                                <br/>
                                <br/>
                                The awarded scholarship is credited to the student’s account per semester. If the student does not maintain the required criteria for the scholarship, the scholarship may be revoked and may not be awarded for the following semester.
                                <br/>
                                <br/>
                                Please note that a scholarship application must be submitted with required supporting financial documentation before the Scholarship Committee will review the application.
                                <br/>
                                <br/>
                                <b><u>RECEIPT OF PAYMENT/QUICKSCHOOLS PROGRAM:</u></b>
                                <br/>
                                <br/>
                                All student payments for school fees are recorded into our school management system called QuickSchools. Each payment is credited to the student’s account. Receipts of payment and financial statements are available through each student’s QuickSchools account.
                                <br/>
                                <br/>
                                If students have questions regarding their tuition balance or financial statements, they can contact the Finance Department.
                                <br/>
                                <br/>
                                <b><u>STUDENT WITHDRAWAL/ RELEASE:</u></b>
                                <br/>
                                <br/>
                                If a student withdraws from the school or is released by the school within the first 30-days, they will forfeit any scholarship awarded. The student will be refunded 50% of their room and board, if the amount paid is over the scholarship amount forfeited. Tuition is nonrefundable. 
                                <br/>
                                <br/>
                                <b>The following fees are non-refundable: initial dorm/housing fee, tuition, registration, student life, technology-library, ministry, facilities and equipment, and LBC enrollment.</b>
                                <br/>
                                <br/>
                                If a student withdraws from school or is released by the school in a 60-day period, they will be refunded 25% of their room and board if the amount paid is over the scholarship forfeited. There will be no refund given after 60 days. 
                                <br/>
                                <br/>
                                <b><u>DORM/HOUSING FEE:</u></b>
                                <br/>
                                <br/>
                                There is an annual dorm/housing fee due on or before fall registration. This fee is for cleaning, repairs and any damage incurred. Dorm rooms will be checked at the end of each semester for damages and additional fees may be incurred if cost of damages exceeds the dorm/housing fee.
                                <br/>
                                <br/>
                                <br/>
                                Summit International School of Ministry reserves the right to amend fees and tuition at its discretion. 
                            </DialogContent>
                        </Dialog>
                    </Grid>
                    <Grid item xs={12}>
                        <FormGroup onChange={handleCheckBox}>
                            <FormControlLabel checked={readPolicy} required control={<Checkbox/>} label="I have read Summit’s Financial Policy"/>
                        </FormGroup>
                    </Grid>
                    <Grid item xs={12} sm={9}>
                        <Typography variant='subtitle1'>Who will be responsible to pay your tuition at Summit?*</Typography>
                        <TextField required fullWidth value={responsible} onChange={handleChangeForm} name='responsible'/>
                    </Grid>
                    <Grid item xs={12} sm={9}>
                        <Typography variant='subtitle1' >Do you have any financial responsibilities such as: student debt, deferment, etc?</Typography>
                        <RadioGroup sx={{flexDirection:"row"}} value={responsabilities} onChange={handleChangeForm}>
                            <FormControlLabel value="yes" name="responsabilities" control={<Radio />} label="Yes" />
                            <FormControlLabel value="no" name="responsabilities" control={<Radio />} label="No" />
                        </RadioGroup>
                    </Grid>
                    <Grid item xs={12} sm={9}>
                        <Typography variant='subtitle1'>Amount of Debt</Typography>
                        <TextField required fullWidth value={amountDebt} onChange={handleChangeForm} name='amountDebt'/>
                    </Grid>
                    <Grid item xs={12} sm={9}>
                        <Typography variant='subtitle1' >Do you have any other sources of income?</Typography>
                        <RadioGroup sx={{flexDirection:"row"}} value={sourceIncome} onChange={handleChangeForm}>
                            <FormControlLabel value="yes" name="sourceIncome" control={<Radio />} label="Yes" />
                            <FormControlLabel value="no" name="sourceIncome" control={<Radio />} label="No" />
                        </RadioGroup>
                    </Grid>
                    <Grid item xs={12} sm={9}>
                        <Typography variant='subtitle1' >Will you be receiving financial assistance from parents, church, friends, etc.?</Typography>
                        <RadioGroup sx={{flexDirection:"row"}} value={financialAssistance} onChange={handleChangeForm}>
                            <FormControlLabel value="yes" name="financialAssistance" control={<Radio />} label="Yes" />
                            <FormControlLabel value="no" name="financialAssistance" control={<Radio />} label="No" />
                        </RadioGroup>
                    </Grid>
                    <Grid item xs={12} sm={9}>
                        <Typography variant='subtitle1' >Are you interested in information regarding a payment plan?</Typography>
                        <RadioGroup sx={{flexDirection:"row"}} value={paymentPlan} onChange={handleChangeForm}>
                            <FormControlLabel value="yes" name="paymentPlan" control={<Radio />} label="Yes" />
                            <FormControlLabel value="no" name="paymentPlan" control={<Radio />} label="No" />
                        </RadioGroup>
                    </Grid>
                    <Grid item xs={12} sm={9}>
                        <Typography variant='subtitle1' >Are you applying for a scholarship?</Typography>
                        <RadioGroup sx={{flexDirection:"row"}} value={scholarship} onChange={handleChangeForm}>
                            <FormControlLabel value="yes" name="scholarship" control={<Radio />} label="Yes" />
                            <FormControlLabel value="no" name="scholarship" control={<Radio />} label="No" />
                        </RadioGroup>
                    </Grid>
                    {
                        scholarship === "yes" &&
                        <Grid item xs={12} sm={9}>
                            <FormLabel sx={{fontSize:"0.85rem"}}>Scholarship awards are awarded based on financial need, merit, and availability of funds. To qualify, applicants must demonstrate substantial financial need, submit a written essay, and provide copies of financial documentation. The scholarship application can be accessed once your application for admission is complete and application fee is paid.</FormLabel>
                        </Grid>
                    }
                </Grid>
            </Grid>
            <Grid item xs={12} sx={{margin:{xs:"0px 0px 50px 0px",sm:"50px 0px"}}}>
                <BannerStep title="Financial" stepNumber={activeStep+1} totalSteps={1}/>
            </Grid>
        </Grid>
    )
}

export default Financial
import { Grid } from '@mui/material';
import AdminLogin from '../../assets/images/AdminLogin.png';
import LoginAdmin from '../../components/Admin/LoginAdmin';

const Admin = () => {
  return (
    <Grid container spacing={2} style={{minHeight:"100vh"}}>
      <Grid item xs={6}>
        <div style={{backgroundImage:`url(${AdminLogin})`,backgroundSize: "cover",backgroundPosition: "center",width:"100%",height:"100vh"}}/>
      </Grid>
      <Grid item xs={6}>
        <LoginAdmin/>
      </Grid>
    </Grid>
  )
}

export default Admin
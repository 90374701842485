import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Button, Dialog, DialogContent, FormControlLabel, FormLabel, Grid, MobileStepper, Radio, RadioGroup, TextField, Typography, styled } from "@mui/material"
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import { uploadEssay, uploadFinancial } from "../axios/axiosFunctions";
import BannerStep from "./BannerStep";
import DocumentCard from "./DocumentCard";

const defaultScholarshipFrom = {
    responsabilities : "",
    amountDebt : "",
    sourceIncome : "",
    responsible:"",
    financialAssistance:"",
    essay:"",
    otherDocuments: []
};

const VisuallyHiddenInput = styled('input')({
    clip: 'rect(0 0 0 0)',
    clipPath: 'inset(50%)',
    height: 1,
    overflow: 'hidden',
    position: 'absolute',
    bottom: 0,
    left: 0,
    whiteSpace: 'nowrap',
    width: 1,
});

const Scholarship = ({data,updateApplication,appId}) => {
    
    const navigate = useNavigate();
    const steps = 2;
    const [activeStep, setActiveStep] = useState(0);
    const [openDialog, setOpenDialog] = useState(false);
    const [scholarshipForm,setScholarshipForm] = useState(defaultScholarshipFrom);
    const [essayUploaded, setEssayUploaded] = useState(false);
    const [othersUploaded, setOthersUploaded] = useState(false);
    const { responsabilities, amountDebt, 
            sourceIncome, responsible, 
            financialAssistance, essay, otherDocuments } = scholarshipForm;
    
    useEffect(() => {
        if(data){
            setScholarshipForm({
                responsabilities: data.responsabilities || "", 
                amountDebt: data.amountDebt || "",
                sourceIncome: data.sourceIncome || "",
                responsible: data.responsible || "",
                financialAssistance: data.financialAssistance || "",
                essay: data.essay || "",
                otherDocuments: data.otherDocuments || "",
            })
            if(data.essay !== ""){
                setEssayUploaded(true);
            }
            if(data.otherDocuments !== ""){
                setOthersUploaded(true);
            }
        }
    }, [data]);

    const handleDialogClose = ()=>{
        setOpenDialog(false);
        navigate('/extra')
    }

    const handleNext = () => {
        handleSubmit(false,undefined,"");
        if(activeStep === steps-1){
            setOpenDialog(true);
            navigate('/scholarship');
            return;
        }
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
    };
    
    const handleBack = () => {
        handleSubmit(false,undefined,"");
        if(activeStep === 0)
        {
            navigate('/review_and_submit');
            return;
        }
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const handleChangeForm = (e)=>{
        const {name,value} = e.target;
        setScholarshipForm({...scholarshipForm, [name]:value}); 
    }

    const handleEssayUploadFile = (e)=>{
        const {length} = e.target.files;
        if(length !== 0){
            handleSubmit(true,e.target.files[0],"essay");
            setEssayUploaded(true);
        }
    }

    const handleFinancialUploadFiles = (e)=>{
        const {length} = e.target.files;
        if(length !== 0){
            handleSubmit(true,e.target.files[0],"financial");
            setOthersUploaded(true);
        }
    }

    const handleEssayDeleteFile = ()=>{
        setEssayUploaded(false);
        handleSubmit(false,undefined,"essay",true);
    }

    const handleFinancialDeleteFiles = ()=>{
        setOthersUploaded(false);
        handleSubmit(false,undefined,"financial",true);
    }

    const handleSubmit = async(uploaded,submitFile,type,deleteFile=false)=>{
        
        let essayName = essay;
        let financialName = otherDocuments;
        
        if(deleteFile && type === "essay"){
            essayName = "";
        }

        if(deleteFile && type === "financial"){
            financialName = "";
        }
        
        if(uploaded && submitFile && type==="essay"){
            const formData = new FormData();
            formData.append('fileImage',submitFile);
            const {name} = await uploadEssay(formData,appId);
            essayName = name;
        }

        if(uploaded && submitFile && type==="financial"){
            const formData = new FormData();
            formData.append('fileImage',submitFile);
            const {name} = await uploadFinancial(formData,appId);
            financialName = name;
        }
        
        const dataSubmit = {
            scholarship:{
                ...scholarshipForm,
                essay: essayName,
                otherDocuments: financialName
            }
        }

        updateApplication(dataSubmit);

    }

    return (
        <Grid container flexDirection="column-reverse">
            <Grid item xs={12}>
            <MobileStepper
            variant="dots"
            steps={steps}
            position="static"
            activeStep={activeStep}
            sx={{ width:"100%", flexGrow: 1 , marginTop:"50px"}}
            nextButton={
                <Button size="small" onClick={handleNext}>
                    {activeStep === steps-1 ? 'Next' : 'Next' }
                </Button>
            }
            backButton={
                <Button size="small" onClick={handleBack}>
                    {activeStep === 0 ? 'Back' : 'Back'}
                </Button>
            }
            />
        </Grid>
        <Grid item xs={12}>
            {
                activeStep === 0 && 
                <Grid container spacing={2}>
                    <Grid item>
                        <FormLabel>
                            INCOMING STUDENT/ FRESHMEN SCHOLARSHIPS: <br/>
                            Scholarship awards are typically between $500 – $4,000 and are awarded based on financial need, merit, and availability of funds.
                        </FormLabel>
                    </Grid>
                    <Grid item>
                        <Typography variant='subtitle1' sx={{marginTop:"20px"}}>Do you have any financial responsibilities such as: student debt, deferment, etc?</Typography>
                        <RadioGroup sx={{flexDirection:"row"}} value={responsabilities} onChange={handleChangeForm}>
                            <FormControlLabel value="yes" name="responsabilities" control={<Radio />} label="Yes" />
                            <FormControlLabel value="no" name="responsabilities" control={<Radio />} label="No" />
                        </RadioGroup>
                    </Grid>
                    <Grid item xs={12} sm={9}>
                        <Typography variant='subtitle1'>Amount of Debt</Typography>
                        <TextField required fullWidth value={amountDebt} onChange={handleChangeForm} name='amountDebt'/>
                    </Grid>
                    <Grid item xs={12} sm={9}>
                        <Typography variant='subtitle1' >Do you have any other sources of income?</Typography>
                        <RadioGroup sx={{flexDirection:"row"}} value={sourceIncome} onChange={handleChangeForm}>
                            <FormControlLabel value="yes" name="sourceIncome" control={<Radio />} label="Yes" />
                            <FormControlLabel value="no" name="sourceIncome" control={<Radio />} label="No" />
                        </RadioGroup>
                    </Grid>
                    <Grid item xs={12} sm={9}>
                        <Typography variant='subtitle1'>Who will be responsible to pay your tuition at Summit?*</Typography>
                        <TextField required fullWidth value={responsible} onChange={handleChangeForm} name='responsible'/>
                    </Grid>
                    <Grid item xs={12} sm={9}>
                        <Typography variant='subtitle1' >Will you be receiving financial assistance from parents, church, friends, etc.?</Typography>
                        <RadioGroup sx={{flexDirection:"row"}} value={financialAssistance} onChange={handleChangeForm}>
                            <FormControlLabel value="yes" name="financialAssistance" control={<Radio />} label="Yes" />
                            <FormControlLabel value="no" name="financialAssistance" control={<Radio />} label="No" />
                        </RadioGroup>
                    </Grid>
                </Grid>
            }
            {
                activeStep === 1 &&
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <Typography variant='h6'>Essay</Typography>
                        <Typography variant='subtitle1'>Please submit a double spaced typed essay of 300-500 words, explaining your qualifications for any scholarship for which you are applying.
                            <br/>One file only.
                            <br/>325 MB limit.
                            <br/>Allowed types: pdf doc docx jpg jpeg png.</Typography>
                            <br/>
                        {
                        !essayUploaded ? 
                        <Button variant="contained" component="label" startIcon={<CloudUploadIcon/>}>
                            UPLOAD FILE
                            <VisuallyHiddenInput onChange={handleEssayUploadFile} type="file" />
                        </Button>
                        :
                        <DocumentCard name="ESSAY UPLOADED" deleteFile={handleEssayDeleteFile}/>
                        }
                    </Grid>
                    <Grid item xs={12}>
                        <Typography variant='subtitle1' >Upload financial documents showing proof of need (e.g. W2s, tax documents, bank statements, etc.)</Typography>
                        {
                        !othersUploaded ? 
                        <Button variant="contained" component="label" startIcon={<CloudUploadIcon/>}>
                            UPLOAD FILE
                            <VisuallyHiddenInput onChange={handleFinancialUploadFiles} type="file" />
                        </Button>
                        :
                        <DocumentCard name="FILE UPLOADED" deleteFile={handleFinancialDeleteFiles}/>
                        }
                    </Grid>
                    <Dialog open={openDialog} onClose={handleDialogClose}>
                        <DialogContent>
                            <Typography variant="h3" sx={{whiteSpace:"break-spaces",textAlign:"center"}}>
                                Your scolarship application has been submitted!
                                <br/>
                                Thank you.
                            </Typography>
                        </DialogContent>
                    </Dialog>
                </Grid>
            }
        </Grid>
        <Grid item xs={12} sx={{margin:{xs:"0px 0px 50px 0px",sm:"50px 0px"}}}>
            <BannerStep title="Scholarship" stepNumber={activeStep+1} totalSteps={1}/>
        </Grid>
        </Grid>    
    )
}

export default Scholarship
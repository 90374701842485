import { Box, MenuItem, Select } from "@mui/material";
import { updatePhysicalColumn } from "../../axios/axiosFunctions";
import { useState } from "react";

const PhysicaExaminationColumn = (props) => {

    const [currentValue, setCurrentValue] = useState(props.value);

    const handleSelectChange = async(e)=>{
        const {value} = e.target;
        setCurrentValue(value);
        const data = {
            physicalExamination: value
        }
        await updatePhysicalColumn(props.row.id,data);
        props.api.updateRows([{id:props.row.id,physicalExamination:value}]);
    }

    return (
        <Box>
            <Select onChange={handleSelectChange} value={currentValue} fullWidth sx={{boxShadow: 'none', '.MuiOutlinedInput-notchedOutline': { border: 0 }}} >
                <MenuItem value="notReceived">Not Received</MenuItem>
                <MenuItem value="received">Received</MenuItem>
            </Select>
        </Box>
    )
}

export default PhysicaExaminationColumn
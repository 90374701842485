import { useEffect, useState } from 'react';
import { Box, Checkbox, FormControlLabel, FormGroup, Grid, MenuItem, Select, Typography } from '@mui/material';
import dayjs from 'dayjs';
import FilterListIcon from '@mui/icons-material/FilterList';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider/LocalizationProvider';
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { getReportingInfo } from '../../../axios/axiosFunctions';
import { DataGrid, GridToolbar } from '@mui/x-data-grid';
import { useNavigate } from 'react-router-dom';
import { columnsReporting } from '../../../utils/reportingTable';
import { getFilteredCitizenship, getFilteredGender, getFilteredProgram, getFilteredStatus } from '../../../utils/reportingFunctions';
import ExcelExport from '../../ExcelExport/ExcelExport';

const formDefault = {
    status:[false,false,false,false,false,false,false,false],
    interOrDom:"all",
    program:[false,false,false],
    gender:"all"
};

const Reporting = ({setActiveTab}) => {
    
    const navigate = useNavigate();
    const currentYear = new Date().getFullYear();
    const [reportForm, setReportForm] = useState(formDefault);
    const [filterOpen, setFilterOpen] = useState(false);
    const [startDate, setStartDate] = useState(`${currentYear}-01-01`); // YY/MM/DD
    const [endDate, setEndDate] = useState(`${currentYear}-12-31`); // YY/MM/DD
    const {status,interOrDom,program,gender} = reportForm;
    const [prospects,reviewed,accepted,confirmed,enrolled,waitlist,deferred,declined] = status;
    const [traditional,sabbatical,gapYear] = program;
    const [allApplications, setAllApplications] = useState();
    const [rowsReporting, setRowsReporting] = useState([{id:1}]);

    useEffect(() => {
      const getApplication = async()=>{
        const apps = await getReportingInfo({startDate,endDate});
        if(apps){
            const {applications} = apps;
            const tempRows = applications.map((app)=>({
                id:app._id,
                lastName:app.user?.lastName || "",
                firstName:app.user?.firstName || "",
                email:app.user?.email || "",
                status:app.statusApp,
                accountCreated: app.createdAt,
                citizenship:app.personal.citizenship,
            }));
            setRowsReporting(tempRows);
            setAllApplications(applications);
        }
      }
      getApplication();
    }, [startDate,endDate]);

    useEffect(() => {
        if(reportForm){
            const statusFilter = getFilteredStatus(status);
            const programFiter = getFilteredProgram(program);
            const newRows = allApplications?.filter((app)=>statusFilter.includes(app.statusApp) && programFiter.includes(app.program) && getFilteredCitizenship(interOrDom,app.personal.citizenship) && getFilteredGender(gender,app.personal.gender)).map((app)=>({
                id:app._id,
                lastName:app.user?.lastName || "",
                firstName:app.user?.firstName || "",
                email:app.user?.email || "",
                status:app.statusApp,
                accountCreated: app.createdAt,
                citizenship:app.personal.citizenship,
            }));
            setRowsReporting(newRows);
        }
    }, [reportForm,allApplications,gender,interOrDom,program,status]);
    
    const handleCheckBoxStatus = ({target})=>{
        const {value} = target;
        const newStatus = [...status];
        newStatus[value] = !newStatus[value];
        setReportForm({...reportForm,status:newStatus});
    }

    const handleCheckBoxProgram = ({target})=>{
        const {value} = target;
        const newProgram = [...program];
        newProgram[value] = !newProgram[value];
        setReportForm({...reportForm,program:newProgram});
    }
    
    const handleChangeForm = (e)=>{
        const {name,value} = e.target;
        setReportForm({...reportForm, [name]:value});
    }

    const handleStartDate = ({$y,$M,$D})=>{
        const m = $M < 9 ? `0${$M+1}` : $M+1; 
        const d = $D < 10 ? `0${$D}` : $D;
        setStartDate(`${$y}-${m}-${d}`);
    }

    const handleEndDate = ({$y,$M,$D})=>{
        const m = $M < 9 ? `0${$M+1}` : $M+1; 
        const d = $D < 10 ? `0${$D}` : $D;
        setEndDate(`${$y}-${m}-${d}`);
    }

    const handleOpenFilters = ()=>{
        setFilterOpen(!filterOpen);
    }

    const handleCellClick = (e)=>{
        const {row,colDef} = e;
        if(colDef.field === "lastName" || colDef.field === "firstName"){
            setActiveTab('reporting');
            navigate(`${row.id}`);
        }
    }

    return (
        <Box>
            <Box sx={{display:"flex",alignItems:"center",marginBottom:"30px",marginTop:"50px"}}>
                <FilterListIcon onClick={handleOpenFilters} sx={{cursor:"pointer",marginRight:"20px",scale:filterOpen?"-1":"1"}}/>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker onChange={handleStartDate} value={ startDate !== "" ? dayjs(startDate):null}/>
                </LocalizationProvider>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker onChange={handleEndDate} value={ endDate !== "" ? dayjs(endDate) : null} sx={{marginLeft:"30px"}}/>
                </LocalizationProvider>
            </Box>
            {
                filterOpen && <>
                    <Grid container spacing={2}>
                        <Grid item xs={8}>
                            <Typography variant='subtitle1'><b>Status</b></Typography>
                            <FormGroup onChange={handleCheckBoxStatus} sx={{flexDirection:"row"}}>
                                <FormControlLabel checked={prospects} name ="prospects" value={0} control={<Checkbox/>} label="Prospects"/>
                                <FormControlLabel checked={reviewed} name ="reviewed" value={1} control={<Checkbox/>} label="To be reviewed"/>
                                <FormControlLabel checked={accepted} name ="accepted" value={2} control={<Checkbox/>} label="Accepted"/>
                                <FormControlLabel checked={confirmed} name ="confirmed" value={3} control={<Checkbox/>} label="Confirmed"/>
                                <FormControlLabel checked={enrolled} name ="enrolled" value={4} control={<Checkbox/>} label="Enrolled"/>
                                <FormControlLabel checked={waitlist} name ="waitlist" value={5} control={<Checkbox/>} label="Waitlist"/>
                                <FormControlLabel checked={deferred} name ="deferred" value={6} control={<Checkbox/>} label="Acceptance Deferred"/>
                                <FormControlLabel checked={declined} name ="declined" value={7} control={<Checkbox/>} label="Declined"/>
                            </FormGroup>
                        </Grid>
                        <Grid item xs={4}>
                            <Typography variant='subtitle1'><b>International or Domestic</b></Typography>
                            <Select onChange={handleChangeForm} value={interOrDom} name='interOrDom' fullWidth>
                                <MenuItem value="all">All</MenuItem>
                                <MenuItem value="International">International</MenuItem>
                                <MenuItem value="Domestic">Domestic</MenuItem>
                            </Select>
                        </Grid>
                        <Grid item xs={4}>
                            <Typography variant='subtitle1'><b>Program</b></Typography>
                            <FormGroup onChange={handleCheckBoxProgram} sx={{flexDirection:"row"}}>
                                <FormControlLabel checked={traditional} name ="traditional" value={0} control={<Checkbox/>} label="Traditional"/>
                                <FormControlLabel checked={sabbatical} name ="sabbatical" value={1} control={<Checkbox/>} label="Sabbatical"/>
                                <FormControlLabel checked={gapYear} name ="gapYear" value={2} control={<Checkbox/>} label="High School Gap Year"/>
                            </FormGroup>
                        </Grid>
                        <Grid item xs={3}>
                            <Typography variant='subtitle1'><b>Gender</b></Typography>
                            <Select onChange={handleChangeForm} value={gender} name='gender' fullWidth>
                                <MenuItem value="all">All</MenuItem>
                                <MenuItem value="male">Male</MenuItem>
                                <MenuItem value="female">Female</MenuItem>
                            </Select>
                        </Grid>
                    </Grid>
                </>
            }
            <Box sx={{margin:"20px",'& .nopadding': {padding:"0px !important"}}}>
                <DataGrid initialState={{pagination:{paginationModel: {pageSize:13,page:0}}}}
                columns={columnsReporting} rows={rowsReporting} rowSpacingType="border" disableMultipleRowSelection={true} onCellClick={handleCellClick}
                slots={{toolbar: GridToolbar}} slotProps={{toolbar:{showQuickFilter:true}}}>
                </DataGrid>
            </Box>
            <Box sx={{display:"flex",justifyContent:"flex-end"}}>
                <ExcelExport data={rowsReporting} fileName="Reporting"/>
            </Box>
        </Box>
    )
}

export default Reporting
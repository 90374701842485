import { Grid, Typography } from "@mui/material";

const EducationAdmin = ({education}) => {

    return (
        <Grid container spacing={2}>
            <Grid item>
                <Typography variant='h5' color="customYellow.main" sx={{marginBottom:"30px"}}><b>Education</b></Typography>
                <Typography variant='subtitle1'><b>Highest Level of Education Completed</b></Typography>
                <Typography variant='subtitle1' sx={{marginLeft:"20px"}}>{education?.educationLevel}</Typography>
                {
                    education?.educationLevel !== "GED" && <>
                        <Typography variant='subtitle1'><b>Name of Institution</b></Typography>
                        <Typography variant='subtitle1' sx={{marginLeft:"20px"}}>{education?.institution}</Typography>
                    </>
                }
                {
                    (education?.educationLevel === "Some College/University" || education?.educationLevel === "Associate Degree" || education?.educationLevel === "Bachelors" || education?.educationLevel === "Masters") && <>
                        <Typography variant='subtitle1'><b>Degree / Major</b></Typography>
                        <Typography variant='subtitle1' sx={{marginLeft:"20px"}}>{education?.degreeMajor}</Typography>
                    </>
                }
                {
                    education?.educationLevel === "Others"  && <>
                        <Typography variant='subtitle1'><b>Please Specify</b></Typography>
                        <Typography variant='subtitle1' sx={{marginLeft:"20px"}}>{education?.otherInstitution}</Typography>
                    </>
                }
                <Typography variant='subtitle1'><b>Start date</b></Typography>
                <Typography variant='subtitle1' sx={{marginLeft:"20px"}}>{education?.startDate}</Typography>
                <Typography variant='subtitle1'><b>End date</b></Typography>
                <Typography variant='subtitle1' sx={{marginLeft:"20px"}}>{education?.endDate}</Typography>
                <Typography variant='subtitle1'><b>Grade Point Average</b></Typography>
                <Typography variant='subtitle1' sx={{marginLeft:"20px"}}>{education?.gradePointAverage}</Typography>
            </Grid>
        </Grid>
    )
}

export default EducationAdmin
import { Checkbox, FormControlLabel, Grid, Typography } from "@mui/material";

const AgreementAdmin = ({agreement}) => { 
    return (
        <Grid container spacing={2}>
            <Grid item>
                <Typography variant='h5' color="customYellow.main" sx={{marginBottom:"30px"}}><b>Agreement</b></Typography>
                <FormControlLabel checked={agreement?.readHandbookAndPolicies} control={<Checkbox/>} label={<Typography variant='subtitle1'><b>I am aware that I am responsible to read the Summit Handbook and be aware of Summit policies and guidelines.</b></Typography>}/>
                <FormControlLabel checked={agreement?.accurateInfo} control={<Checkbox/>} label={<Typography variant='subtitle1'><b>I hereby certify that all information herein recorded is accurate and true to the best of my knowledge.</b></Typography>}/>
                <FormControlLabel checked={agreement?.incompleteInfo} control={<Checkbox/>} label={<Typography variant='subtitle1'><b>I understand that any further or incomplete information may result in the disqualification any application for entrance. </b></Typography>}/>
                <FormControlLabel checked={agreement?.notDiscriminate} control={<Checkbox/>} label={<Typography variant='subtitle1'><b>I understand that Summit International School of Ministry does not discriminate on the basis of sex, race, color, national or ethnic origin.</b></Typography>}/>
            </Grid>
        </Grid>
    )
}

export default AgreementAdmin
import { Grid, Typography } from "@mui/material"
import { upperFirstLetter } from "../../../utils/qViewerFunctions"

const FamilyAdmin = ({family}) => { 

    return (
        <Grid container spacing={2}>
            <Grid item>
                <Typography variant='h5' color="customYellow.main" sx={{marginBottom:"30px"}}><b>Family</b></Typography>
                <Typography variant='subtitle1'><b>Marital Status</b></Typography>
                <Typography variant='subtitle1' sx={{marginLeft:"20px"}}>{upperFirstLetter(family?.maritalStatus)}</Typography>
                <Typography variant='subtitle1'><b>Do you have children?</b></Typography>
                <Typography variant='subtitle1' sx={{marginLeft:"20px"}}>{upperFirstLetter(family?.hasChildren)}</Typography>
                {
                    family?.hasChildren === "yes" && <>
                        <Typography variant='subtitle1'><b>Number of Children</b></Typography>
                        <Typography variant='subtitle1' sx={{marginLeft:"20px"}}>{family?.numberChildren}</Typography>
                        <Typography variant='subtitle1'><b>Children's Ages</b></Typography>
                        <Typography variant='subtitle1' sx={{marginLeft:"20px"}}>{family?.agesChildren}</Typography>
                        <Typography variant='subtitle1'><b>For any dependent children, what is your plan for their care while you're attending Summit?</b></Typography>
                        <Typography variant='subtitle1' sx={{marginLeft:"20px"}}>{family?.carePlan}</Typography>
                    </>
                }
            </Grid>
        </Grid>
    )

}

export default FamilyAdmin
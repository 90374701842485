import { Box, MenuItem, Select } from "@mui/material"
import { useEffect, useState } from "react"
import { deleteNotificationByStatus, getAllConsultants, updateAssignTo, updateStatusColumn } from "../../axios/axiosFunctions";

const AssignToColumn = (props) => {

    const [selectOpt, setSelectOpt] = useState([]);
    const [currentValue, setCurrentValue] = useState(props.value);

    useEffect(() => {
        const getSelectOptions = async()=>{
            const res = await getAllConsultants();
            setSelectOpt(res);
            setCurrentValue(props.value)
        } 
        getSelectOptions();
    }, [props.value]);

    const handleSelectChange = async(e)=>{
        const {value} = e.target;
        setCurrentValue(value);
        const data = {
            assign: value
        }
        await updateAssignTo(props.row.id,data);
        const deleteData = {
            application:props.row.id,
            status:"New"
        }
        const {msg} = await deleteNotificationByStatus(deleteData);
        if(msg === "Deleted"){
            await updateStatusColumn(props.row.id,{statusColumn:"Up to date"});
            props.api.updateRows([{id:props.row.id,status:"Up to date"}]);
        }
    }

    return (
        <Box>
            <Select onChange={handleSelectChange}  value={currentValue} fullWidth sx={{boxShadow: 'none', '.MuiOutlinedInput-notchedOutline': { border: 0 }}} >
                {
                    selectOpt.map((opt,i)=>(
                        <MenuItem key={i} value={`${opt.firstName} ${opt.lastName}`}>{opt.firstName} {opt.lastName}</MenuItem>
                    ))
                }
            </Select>
        </Box>
    )
}

export default AssignToColumn
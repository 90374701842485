import FilePresentIcon from '@mui/icons-material/FilePresent';
import { Box, Typography } from '@mui/material';

const DocumentCard = ({name,deleteFile}) => {

  const elementWidth = 200;

  return (
    <Box>
        <Box sx={{position:"relative",width:`${elementWidth}px`}}>
          <Box sx={{position:"absolute", left:"100%"}}>
            <Typography sx={{cursor:"pointer"}} onClick={deleteFile}> X </Typography>
          </Box>
          <FilePresentIcon fontSize='large' sx={{fontSize:`${elementWidth}px`}}/>
          <Typography textAlign="center" > {name} </Typography>
        </Box>
    </Box>
  )
}

export default DocumentCard
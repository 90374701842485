import { Alert, Autocomplete, Box, Button, Container, FormControl, Grid, IconButton, InputAdornment, InputLabel, OutlinedInput, TextField, Typography } from '@mui/material';
import { Visibility, VisibilityOff } from '@mui/icons-material'
import { useContext, useState } from 'react';
import { createUser, loginUser } from '../axios/axiosFunctions';
import { useNavigate } from 'react-router-dom';
import { countries } from '../utils/countries';
import { UserContext } from '../contexts/user';

const defaultCreateAccountForm = {
  firstName:"",
  middleName:"",
  lastName:"",
  countryCode:"1",
  phoneNumber:"",
  email:"",
  password:"",
  confirmPassword:""
}

const CreateAccount = () => {
  
  const navigate = useNavigate();
  const [createAccountForm, setCreateAccountForm] = useState(defaultCreateAccountForm);
  const [, setCountry] = useState();
  const [showPassword, setShowPassword] = useState(false);
  const [showPasswordRetype, setShowPasswordRetype] = useState(false);
  const [showError, setShowError] = useState(false);
  const [error, setError] = useState("");
  const { password,confirmPassword,email, countryCode} = createAccountForm;
  const {setCurrentUser}  = useContext(UserContext);

  const handleClickShowPassword = () => setShowPassword((show) => !show);
  const handleClickShowPasswordRetype = () => setShowPasswordRetype((show) => !show);

  
  const showAlert = ()=>{
    return confirmPassword !== "" && confirmPassword !== password; 
  }
  
  const handleChangeForm = (e)=>{
    const {name,value} = e.target;
    setCreateAccountForm({...createAccountForm, [name]:value});
  }
  
  const handleCountryCode = (_,country)=>{
    if(country){
        const {phone} = country;
        setCountry(country);
        setCreateAccountForm({...createAccountForm, countryCode:phone});
    }else{
        setCountry("");
        setCreateAccountForm({...createAccountForm, countryCode:""});
      }
  }

  const handleSubmit = async(e)=>{
    e.preventDefault();
    if(showAlert()){
      setShowError(true);
      setTimeout(() => {
        setError("Form not valid");
        setShowError(false);
      }, 2000);
      return;
    }
  
    const {user,msg} = await createUser(createAccountForm);
    if(!user){
      setError(msg);
      setShowError(true);
      setTimeout(() => {
        setShowError(false);
      }, 2000);
      return;
    }
    const data = {
      email,
      password
    }

    const logUser = await loginUser(data);
    setCurrentUser(user);
    setCreateAccountForm(defaultCreateAccountForm);
    localStorage.setItem('userSummit',JSON.stringify(logUser.user));
    navigate("/welcome");
  }

  return (
    <Container>
      <Box display="flex" alignItems="flex-start" flexDirection="column" sx={{height:{xs:"calc(100vh - 88px)", sm:"calc(100vh - 96px)"}}} justifyContent="center">
        <Typography variant='h4' sx={{marginBottom:{xs:"20px",sm:"100px"}}}>Create Account</Typography>
        <form onSubmit={handleSubmit} style={{display:'flex',flexDirection:"column"}}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                  <TextField required fullWidth onChange={handleChangeForm} name='firstName' label="First name"/>
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField fullWidth onChange={handleChangeForm} name='middleName' label="Middle name" />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField required fullWidth onChange={handleChangeForm} name='lastName' label="Last name / Surname"/>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Grid container spacing={2}>
                    <Grid item sm={3}>
                      <Autocomplete onChange={handleCountryCode} options={countries} value={countryCode} renderOption={({key,...optionProps},option)=>{
                          return(
                                  <Box key={key} component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...optionProps}>
                                      <img loading="lazy" width="20"
                                      srcSet={`https://flagcdn.com/w40/${option.code.toLowerCase()}.png 2x`}
                                      src={`https://flagcdn.com/w20/${option.code.toLowerCase()}.png`}
                                      alt=""/>
                                      +{option.phone} / {option.label}
                                  </Box>
                              )
                          }}
                          renderInput={params=>(
                              <TextField
                                  {...params}
                                  label="Country Code"
                              />
                          )}
                      />
                    </Grid>
                    <Grid item sm={9}>
                      <TextField required fullWidth onChange={handleChangeForm} name='phoneNumber' label="Phone Number"/>
                    </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12} sm={12}>
                <TextField required fullWidth onChange={handleChangeForm} name='email' label="Email"/>
              </Grid>
              <Grid item xs={12} sm={6}>
                <FormControl fullWidth variant="outlined"> 
                  <InputLabel>Password*</InputLabel>
                  <OutlinedInput
                    type={showPassword ? 'text' : 'password'} 
                    fullWidth
                    label="Password*"
                    name='password'
                    required
                    onChange={handleChangeForm}
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton
                          onClick={handleClickShowPassword}
                        >
                          {showPassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                      </InputAdornment>
                    }
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6}>
                <FormControl fullWidth variant="outlined"> 
                  <InputLabel>Retype Password*</InputLabel>
                  <OutlinedInput
                    required
                    type={showPasswordRetype ? 'text' : 'password'}
                    label="Retype Password*"
                    fullWidth
                    name='confirmPassword'
                    onChange={handleChangeForm}
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton
                          onClick={handleClickShowPasswordRetype}
                        >
                          {showPassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                      </InputAdornment>
                    }
                  />
                </FormControl>
              </Grid>
              {
                showAlert() &&
                <Grid item xs={12} sm={12}>
                  <Alert severity="error">
                    Passwords do NOT match
                  </Alert>
                </Grid>
              }
            </Grid>
            <Button type='submit' sx={{marginRight:"20px",alignSelf:"center",marginTop:{xs:"20px",sm:"100px"}}} size="large" variant="contained">CREATE ACCOUNT</Button>
            {
              showError &&
              <Alert sx={{marginTop:"20px"}} severity="error">
                {error}
              </Alert>
            }
        </form>
      </Box>
    </Container>
  )
}

export default CreateAccount
import { Grid, Typography } from '@mui/material';
import { upperFirstLetter } from '../../../utils/qViewerFunctions';

const SummitAndYouAdmin = ({summitAndYou}) => {

    return (
        <Grid container spacing={2}>
            <Grid item>
                <Typography variant='h5' color="customYellow.main" sx={{marginBottom:"30px"}}><b>Summit and You</b></Typography>
                <Typography variant='subtitle1'><b>How did you hear about Summit?</b></Typography>
                {
                    summitAndYou.hearAbout.map((item,i)=>{
                        switch (i) {
                            case 0:
                                return item && <Typography variant='subtitle1' sx={{marginLeft:"20px"}}>-Times Square Church</Typography>
                            case 1:
                                return item && <>
                                    <Typography variant='subtitle1' sx={{marginLeft:"20px"}}>-Church/Organization</Typography>
                                    <Typography variant='subtitle1' sx={{marginLeft:"40px"}}><b>Name of the Church/Organization</b></Typography>
                                    <Typography variant='subtitle1' sx={{marginLeft:"40px"}}>{summitAndYou?.churchElaborate}</Typography>
                                </> 
                            case 2:
                                return item && <Typography variant='subtitle1' sx={{marginLeft:"20px"}}>-Friend/Family</Typography>
                            case 3:
                                return item && <Typography variant='subtitle1' sx={{marginLeft:"20px"}}>-Current Student</Typography>
                            case 4:
                                return item && <Typography variant='subtitle1' sx={{marginLeft:"20px"}}>-Alumni</Typography>
                            case 5:
                                return item && <Typography variant='subtitle1' sx={{marginLeft:"20px"}}>-Summit Event</Typography>
                            case 6:
                                return item && <>
                                    <Typography variant='subtitle1' sx={{marginLeft:"20px"}}>-Other</Typography>
                                    <Typography variant='subtitle1' sx={{marginLeft:"40px"}}>{summitAndYou?.otherElaborate}</Typography>
                                </> 
                            default:
                                return "";
                        }
                    })
                }
                <Typography variant='subtitle1'><b>Have you ever applied to Summit before?</b></Typography>
                <Typography variant='subtitle1' sx={{marginLeft:"20px"}}>{upperFirstLetter(summitAndYou?.appliedTo)}</Typography>
                {
                    summitAndYou?.appliedTo === "yes" && <>
                        <Typography variant='subtitle1'><b>Please Elaborate</b></Typography>
                        <Typography variant='subtitle1' sx={{marginLeft:"20px"}}>{summitAndYou?.appliedToExtraInfo}</Typography>
                    </>
                }
                <Typography variant='subtitle1'><b>Tell us why you want to attend Summit</b></Typography>
                <Typography variant='subtitle1' sx={{marginLeft:"20px"}}>{summitAndYou?.whyAttend}</Typography>
                <Typography variant='subtitle1'><b>What do you want God to do in your life?</b></Typography>
                <Typography variant='subtitle1' sx={{marginLeft:"20px"}}>{summitAndYou?.godInYourLife}</Typography>
                <Typography variant='subtitle1'><b>What are you passionate about?</b></Typography>
                <Typography variant='subtitle1' sx={{marginLeft:"20px"}}>{summitAndYou?.passions}</Typography>
            </Grid>
        </Grid>
    )
}

export default SummitAndYouAdmin
import ProspectsImage from "../assets/images/Prospects.png";
import Accepted from "../assets/images/Accepted.png";
import Confirmed from "../assets/images/Confirmed.png";
import Reviewed from "../assets/images/Reviewed.png";
import Enrolled from "../assets/images/Enrolled.png";
import Scholarship from "../assets/images/Scholarship.png";

export const cardsHome = [
    {
        title:"Prospects",
        defaultValue: 0,
        homeInfoLabel:"prospects",
        image: ProspectsImage,
    },
    {
        title:"To be reviewed",
        defaultValue: 0,
        homeInfoLabel:"reviewed",
        image: Reviewed,
    },
    {
        title:"Accepted",
        defaultValue: 0,
        homeInfoLabel:"accepted",
        image: Accepted,
    },
    {
        title:"Confirmed",
        defaultValue: 0,
        homeInfoLabel:"confirmed",
        image: Confirmed,
    },
    {
        title:"Enrolled",
        defaultValue: 0,
        homeInfoLabel:"enrolled",
        image: Enrolled,
    },
    {
        title:"Scholarship Review",
        defaultValue: 0,
        homeInfoLabel:"scholarship",
        image: Scholarship,
    }
];
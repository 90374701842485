import { Alert, Button } from '@mui/material';
import { PaymentElement, useElements, useStripe } from '@stripe/react-stripe-js';
import React, { useState } from 'react'

const StripeForm = ({updateApplication,setFeesForm}) => {
    
    const stripe = useStripe();
    const elements = useElements();

    const [isProcessing, setIsProcessing] = useState(false);
    const [error, setError] = useState(false);
    const [message, setMessage] = useState("Error");
    
    const handleSubmit = async(e)=>{
        e.preventDefault();

        if(!stripe || !elements){
            return;
        }

        setIsProcessing(true);
        const {error,paymentIntent} = await stripe.confirmPayment({
            elements,
            confirmParams:{
                return_url:`${window.location.origin}/review_and_submit`
            },
            redirect: "if_required"
        });

        if(error){
            setError(true);
            setMessage(error.message);
            setTimeout(() => {
                setError(false);
            }, 5000);
        }else if(paymentIntent && paymentIntent.status === "succeeded"){
            setFeesForm({
                payed: true,
              })
            const dataSubmit={
                fees:{
                    payed:true
                }
            }
            updateApplication(dataSubmit);
        }else{
            setError(true);
            setMessage("Unexpected Error");
            setTimeout(() => {
                setError(false);
            }, 5000);
        }

        setIsProcessing(false);
    }

    return (
        <form onSubmit={handleSubmit}>
            <PaymentElement/>
            <Button type='submit' variant="contained" color="customYellow" disabled={isProcessing} sx={{marginTop:"20px"}}>
                {isProcessing ? "Processing" : "Pay now"}
            </Button>
            {
                error &&
                <Alert severity='error'>
                    {message}
                </Alert>
            }
        </form>
    );
}

export default StripeForm
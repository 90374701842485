import { Grid } from "@mui/material";
import { useState } from "react";
import ScholarshipStepsAdmin from "./ScholarshipStepsAdmin";
import ScholarshipTabAdmin from "./QViewerTabs/ScholarshipTabAdmin";

const ScholarshipAdmin = ({application}) => {
    
    const [currentTab, setCurrentTab] = useState('scholarship');
    console.log(application);


    return (
        <Grid container spacing={2}>
            <Grid item xs={3} sx={{borderRight:"solid #999999",paddingRight:"30px"}}>
                <ScholarshipStepsAdmin application={application} setCurrentTab={setCurrentTab} currentTab={currentTab}/>
            </Grid>
            <Grid item xs={9}>
                {
                    currentTab === "scholarship" && <ScholarshipTabAdmin scholarship={application?.scholarship}/>
                }
            </Grid>
        </Grid>
    )
}

export default ScholarshipAdmin
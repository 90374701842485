import { useState } from "react";
import { Box, MenuItem, Select } from "@mui/material";
import { updateEssayColumn } from "../../axios/axiosFunctions";

const EssayColumn = (props) => {
 
    const [currentValue, setCurrentValue] = useState(props.value);

    const handleSelectChange = async(e)=>{
        const {value} = e.target;
        setCurrentValue(value);
        const data = {
            essay: value
        }
        await updateEssayColumn(props.row.id,data);
        props.api.updateRows([{id:props.row.id,essay:value}]);
    }

    return (
        <Box>
            <Select onChange={handleSelectChange} value={currentValue} fullWidth sx={{boxShadow: 'none', '.MuiOutlinedInput-notchedOutline': { border: 0 }}} >
                <MenuItem value="pending">Pending Review</MenuItem>
                <MenuItem value="reviewed">Reviewed</MenuItem>
                <MenuItem value="followUp">Follow Up</MenuItem>
            </Select>
        </Box>
    )
}

export default EssayColumn
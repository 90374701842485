import { useEffect, useState } from 'react'
import { Alert, Button, FormControlLabel, FormLabel, Grid, MobileStepper, Radio, RadioGroup, TextField, Typography } from '@mui/material'
import { useNavigate } from 'react-router-dom';
import BannerStep from './BannerStep';

const defaultVisaForm = {
    validPassport: "",
    funds :"",
    statement:""
}

const Visa = ({data,updateApplication}) => {

    const navigate = useNavigate();
    const [visaForm, setVisaForm] = useState(defaultVisaForm);
    const [showError, setShowError] = useState(false);
    const [activeStep, setActiveStep] = useState(0);
    const steps = 1;
    const {validPassport,funds,statement} = visaForm;

    useEffect(() => {
        if(data){
          setVisaForm({
            validPassport : data.validPassport || "",
            funds : data.funds || "",
            statement: data.statement || ""
          });
        }
      }, [data]);

    const handleNext = () => {
        handleSubmit();
        if(!checkRequired()){
            setShowError(true);
            setTimeout(() => {
                setShowError(false);
            }, 2000);
            return;
        }
        if(activeStep === steps-1){
            navigate('/id_passport');
            return;
        }
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
    };
    
    const handleBack = () => {
        handleSubmit();
        if(activeStep === 0)
        {
            navigate('/agreement');
            return;
        }
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const checkRequired = ()=>{
        if(activeStep === 0){
            return validPassport !== "" && funds !== "" && statement !== ""
        }
        return true;
    }

    const handleChangeForm = (e)=>{
        const {name,value} = e.target;
        setVisaForm({...visaForm, [name]:value});
      }

    const handleSubmit = ()=>{
        const dataSubmit={
            visa:{
                ...visaForm
            }
          }
        
          updateApplication(dataSubmit);
    }

    return (
        <Grid container flexDirection="column-reverse">
            <Grid item xs={12}>
                <MobileStepper
                    variant="dots"
                    steps={steps}
                    position="static"
                    activeStep={activeStep}
                    sx={{ width:"100%", flexGrow: 1 , marginTop:"50px"}}
                    nextButton={
                        <Button size="small" onClick={handleNext}>
                            {activeStep === steps-1 ? 'Next' : 'Next' }
                        </Button>
                    }
                    backButton={
                        <Button size="small" onClick={handleBack}>
                            {activeStep === 0 ? 'Back' : 'Back'}
                        </Button>
                    }
                />
            </Grid>
            {
                showError &&
                <Grid item xs={12}>
                    <Alert severity='error' sx={{marginTop:"20px"}}>
                        One or more REQUIRED fields are empty.
                    </Alert>
                </Grid>
            }
            <Grid item xs={12}>
                <Grid container spacing={2}>
                    <Grid item>
                        <Typography variant='subtitle1' sx={{marginTop:"20px"}}>Do you hold a valid passport with at least 6 months validity?*</Typography>
                        <RadioGroup sx={{flexDirection:"row"}} value={validPassport} onChange={handleChangeForm}>
                            <FormControlLabel value="yes" name="validPassport" control={<Radio />} label="Yes" />
                            <FormControlLabel value="no" name="validPassport" control={<Radio />} label="No" />
                        </RadioGroup>
                    </Grid>
                    <Grid item>
                        <FormLabel sx={{fontSize:"0.85rem"}}>
                            VISA<br/>
                            List the total amount of personal funds that will be available to you while you are attending Summit International School of Ministry, This information is required by the U.S. Government and without it, we are unable to issue an I-20 form. When you appear before an embassy official, you will be required to show evidence that this amount is correct.
                        </FormLabel>
                    </Grid>
                    <Grid item xs={12} sm={8}>
                        <Typography variant='subtitle1' >Total Personal Funds (in US Dollars)*</Typography>
                        <TextField required fullWidth value={funds} onChange={handleChangeForm} name='funds'/>
                    </Grid>
                    <Grid item xs={12} sm={8}>
                        <Typography variant='subtitle1' >Future Endeavor Statement</Typography>
                        <TextField fullWidth onChange={handleChangeForm} inputProps={{maxLength:300}} helperText={`Max Length ${statement.length}/300`}  value={statement} name="statement" placeholder="Text" multiline rows={4}/>
                    </Grid>
                </Grid>
            </Grid>
            <Grid item xs={12} sx={{margin:{xs:"0px 0px 50px 0px",sm:"50px 0px"}}}>
                <BannerStep title="Visa" stepNumber={activeStep+1} totalSteps={1}/>
            </Grid>
        </Grid>
    )
}

export default Visa
import { Checkbox, FormControlLabel, Grid, Typography } from "@mui/material";
import { upperFirstLetter } from '../../../utils/qViewerFunctions';

const FaithAdmin = ({faith}) => {

    return (
        <Grid container spacing={2}>
            <Grid item>
                <Typography variant='h5' color="customYellow.main" sx={{marginBottom:"30px"}}><b>Faith</b></Typography>
                <Typography variant='subtitle1'><b>When did you accept Jesus Christ as your personal Savior?</b></Typography>
                <Typography variant='subtitle1' sx={{marginLeft:"20px"}}>{faith?.whenAcceptJesus}</Typography>
                <Typography variant='subtitle1'><b>Tell us how you came to know Christ</b></Typography>
                <Typography variant='subtitle1' sx={{marginLeft:"20px"}}>{faith?.howKnowJesus}</Typography>
                <Typography variant='subtitle1'><b>Do you attend church regularly?</b></Typography>
                <Typography variant='subtitle1' sx={{marginLeft:"20px"}}>{upperFirstLetter(faith?.attendRegularly)}</Typography>
                {
                    faith?.attendRegularly === "no" && <>
                        <Typography variant='subtitle1'><b>Please Elaborate</b></Typography>
                        <Typography variant='subtitle1' sx={{marginLeft:"20px"}}>{faith?.explain}</Typography>
                    </>
                }
                {
                    faith?.attendRegularly === "yes" && <>
                        <Typography variant='subtitle1'><b>What is the name of the church you currently attend?</b></Typography>
                        <Typography variant='subtitle1' sx={{marginLeft:"20px"}}>{faith?.nameChurch}</Typography>
                        <Typography variant='subtitle1'><b>How long have you been attending there?</b></Typography>
                        <Typography variant='subtitle1' sx={{marginLeft:"20px"}}>{faith?.timeAttending}</Typography>
                        <Typography variant='subtitle1'><b>Church Website</b></Typography>
                        <Typography variant='subtitle1' sx={{marginLeft:"20px"}}>{faith?.churchWebsite}</Typography>
                        <Typography variant='subtitle1'><b>How are you currently involved in ministry in your church?</b></Typography>
                        <Typography variant='subtitle1' sx={{marginLeft:"20px"}}>{faith?.isInvolved}</Typography>
                    </>
                }
                <FormControlLabel checked={faith?.readStatement} control={<Checkbox/>} label={<Typography variant="subtitle1"><b>I have read Summit's Statement of Faith</b></Typography>}/>
            </Grid>
        </Grid>
    )
}

export default FaithAdmin
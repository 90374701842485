export const questionaireSteps = [
    {
        label:"Personal",
        numberSteps: 2,
        route:"personal",
        requiredStepsLabel: "personal",
    },
    {
        label:"Summit and You",
        numberSteps: 5,
        route:"summit_and_you",
        requiredStepsLabel: "summitAndYou",
    },
    {
        label:"Faith",
        numberSteps: 2,
        route:"faith",
        requiredStepsLabel: "faith",
    },
    {
        label:"Education",
        numberSteps: 1,
        route:"education",
        requiredStepsLabel: "education",
    },
    {
        label:"Work",
        numberSteps: 1,
        route:"work",
        requiredStepsLabel: "work",
    },
    {
        label:"Family",
        numberSteps: 1,
        route:"family",
        requiredStepsLabel: "family",
    },
    {
        label:"Health",
        numberSteps: 1,
        route:"health",
        requiredStepsLabel: "health",
    },
    {
        label:"Legal",
        numberSteps: 1,
        route:"legal",
        requiredStepsLabel: "legal",
    },
    {
        label:"Financial",
        numberSteps: 1,
        route:"financial",
        requiredStepsLabel: "financial",
    },
    {
        label:"Agreement",
        numberSteps: 1,
        route:"agreement",
        requiredStepsLabel: "agreement",
    }
];
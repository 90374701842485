import clsx from 'clsx';
import AssignToColumn from "../components/GridTableUtils/AssignToColumn";
import ProgramColumn from '../components/GridTableUtils/ProgramColumn';

export const columnsDeclined = [
    {
        field:'lastName',
        headerName: 'LAST NAME',
        flex:1
    },
    {
        field:'firstName',
        headerName: 'FIRST NAME',
        flex:1
    },
    {
        field:'assignedTo',
        headerName: 'ASSIGNED TO',
        flex:1,
        renderCell: AssignToColumn,
        cellClassName:()=>{
            return clsx('nopadding');
        }
    },
    {
        field:'program',
        headerName: 'PROGRAM',
        flex:1,
        renderCell: ProgramColumn
    },
    {
        field:'citizenship',
        headerName: 'CITIZENSHIP',
        flex:1
    }
]
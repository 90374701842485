import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Box, Button, CircularProgress, Grid, Typography, styled } from "@mui/material"
import { upperFirstLetter } from "../../../utils/qViewerFunctions"
import { updateApplication, uploadEssay, uploadFinancial } from "../../../axios/axiosFunctions";

const VisuallyHiddenInput = styled('input')({
  clip: 'rect(0 0 0 0)',
  clipPath: 'inset(50%)',
  height: 1,
  overflow: 'hidden',
  position: 'absolute',
  bottom: 0,
  left: 0,
  whiteSpace: 'nowrap',
  width: 1,
});


const ScholarshipTabAdmin = ({scholarship,appId,setCurrentApplication}) => {

  const BASE_URL = "https://summit-app-edgar.s3.eu-north-1.amazonaws.com/";
  const [fileType, setFileType] = useState();
  const [fileType2, setFileType2] = useState();
  const [urlEssay, setUrlEssay] = useState(BASE_URL + scholarship.essay);
  const [urlOther, setUrlOther] = useState(BASE_URL + scholarship.otherDocuments);

  useEffect(() => {
    if(scholarship){
      const type = scholarship.essay.split(".").pop().toLowerCase();
      setFileType(type);
      const type2 = scholarship.otherDocuments.split(".").pop();
      setFileType2(type2);
      const folderNameEssay = scholarship.essay.split("/")[0];
      const folderNameOther = scholarship.otherDocuments.split("/")[0];
      if (folderNameEssay !== "applications") {
        setUrlEssay(BASE_URL + "applicants/" + scholarship.essay);
      }
      if (folderNameOther !== "applications") {
        setUrlOther(BASE_URL + "applicants/" + scholarship.otherDocuments);
      }
    }
  }, [scholarship]);

  const handleUploadEssay = (e)=>{
    const {length} = e.target.files;
    if(length !== 0){
        handleSubmit(true,e.target.files[0],"essay");
    }
  }

  const handleUploadFinancial = (e)=>{
      const {length} = e.target.files;
      if(length !== 0){
          handleSubmit(true,e.target.files[0],"financial");
      }
  }

  const handleSubmit = async(uploaded,submitFile,type)=>{

    let essayName = scholarship.essay;
    let financialName = scholarship.otherDocuments;
    
    if(uploaded && submitFile && type==="essay"){
        setUrlEssay("");
        const formData = new FormData();
        formData.append('fileImage',submitFile);
        const {name} = await uploadEssay(formData,appId);
        essayName = name;
    }

    if(uploaded && submitFile && type==="financial"){
        setUrlOther("")
        const formData = new FormData();
        formData.append('fileImage',submitFile);
        const {name} = await uploadFinancial(formData,appId);
        financialName = name;
    }
    
    const dataSubmit = {
        scholarship:{
            ...scholarship,
            essay: essayName,
            otherDocuments: financialName
        }
    }

    const resp = await updateApplication(dataSubmit,appId);
    setCurrentApplication(resp.application);
    if(type === "essay"){
      setUrlEssay(BASE_URL + BASE_URL + scholarship.essay);
    }
    if(type === "financial"){
      setUrlOther(BASE_URL + BASE_URL + scholarship.otherDocuments);
    }

  }

  return (
    <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography variant='h5' color="customYellow.main" sx={{marginBottom:"30px"}}><b>Scholarship</b></Typography>
          <Typography variant='subtitle1'><b>Do you have any financial responsibilities such as: student debt, deferment, etc?</b></Typography>
          <Typography variant='subtitle1' sx={{marginLeft:"20px"}}>{upperFirstLetter(scholarship?.responsabilities)}</Typography>
          <Typography variant='subtitle1'><b>Amount of Debt</b></Typography>
          <Typography variant='subtitle1' sx={{marginLeft:"20px"}}>{upperFirstLetter(scholarship?.amountDebt)}</Typography>
          <Typography variant='subtitle1'><b>Do you have any other sources of income?</b></Typography>
          <Typography variant='subtitle1' sx={{marginLeft:"20px"}}>{upperFirstLetter(scholarship?.sourceIncome)}</Typography>
          <Typography variant='subtitle1'><b>Who will be responsible to pay your tuition at Summit?*</b></Typography>
          <Typography variant='subtitle1' sx={{marginLeft:"20px"}}>{upperFirstLetter(scholarship?.responsible)}</Typography>
          <Typography variant='subtitle1'><b>Will you be receiving financial assistance from parents, church, friends, etc.?</b></Typography>
          <Typography variant='subtitle1' sx={{marginLeft:"20px"}}>{upperFirstLetter(scholarship?.financialAssistance)}</Typography>
          {
            urlEssay === "" ? 
            <CircularProgress/>
            :
            <Box sx={{width:"400px"}}>
            {
              fileType && fileType !== "" && <>
                <Typography variant='subtitle1'><b>Essay</b></Typography>
                {
                  fileType !== "pdf" && fileType !== "docx" && <img src={urlEssay} alt="If the element has not loaded, please refresh the page." style={{width:"100%"}}/>
                }
                <Link to={urlEssay} target="_blank">
                  <Button variant="contained">
                    DOWNLOAD FILE
                  </Button>
                </Link>
              </>
            }
            </Box>

          }
          {
            urlOther === "" ? 
            <CircularProgress/>
            :
            <Box>
            {
              fileType2 && fileType2 !== "" && <>
                <Typography variant='subtitle1'><b>Financial documents showing proof of need</b></Typography>
                {
                  fileType2 !== "pdf" && fileType2 !== "docx" && <img src={urlOther} alt="If the element has not loaded, please refresh the page." style={{width:"100%"}}/>
                }
                <Link to={urlOther} target="_blank">
                  <Button variant="contained">
                    DOWNLOAD FILE
                  </Button>
                </Link>
              </>
            }
            </Box>
          }
        </Grid>
        <Grid item xs={12}>
            <Button variant="contained" component="label">
              Upload Essay
              <VisuallyHiddenInput onChange={handleUploadEssay} type="file" />
            </Button>
            <Typography variant="subtitle2">(By uploading a new image, you will overwrite the existing image.)</Typography>
        </Grid>
        <Grid item xs={12}>
            <Button variant="contained" component="label">
              Upload Financial documents showing proof of need
              <VisuallyHiddenInput onChange={handleUploadFinancial} type="file" />
            </Button>
            <Typography variant="subtitle2">(By uploading a new image, you will overwrite the existing image.)</Typography>
        </Grid>
    </Grid>
  )
}

export default ScholarshipTabAdmin
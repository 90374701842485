import { useState } from 'react'
import { useNavigate } from 'react-router-dom';
import { FRONT_URL } from '../axios/axiosFunctions';
import { Box, Button, Grid, MobileStepper, TextField, Typography } from '@mui/material';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import BannerStep from './BannerStep';
import { useEffect } from 'react';

const References = ({appId,family,updateApplication,references}) => {

    const steps = 1;
    const navigate = useNavigate();
    const [activeStep, setActiveStep] = useState(0);
    const [referenceButton, setReferenceButton] = useState(false);
    const [copyTextPastoral, setCopyTextPastoral] = useState("COPY LINK");
    const [copyTextAcademic, setCopyTextAcademic] = useState("COPY LINK");
    const [copyTextEmployer, setCopyTextEmployer] = useState("COPY LINK");
    const [copyTextMarriage, setCopyTextMarriage] = useState("COPY LINK");

    useEffect(() => {
      if(references){
        setReferenceButton(references.uploaded);
      }
    }, [references])
    

    const handleNext = () => {
        handleSubmit();
        if(activeStep === steps-1){
            navigate('/Resume');
            return;
        }
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
    };

    const handleBack = () => {
        handleSubmit();
        if(activeStep === 0)
        {
            navigate('/transcripts');
            return;
        }
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const handleSubmit = ()=>{
        const dataSubmit={
            references:{
                uploaded:referenceButton 
            }
        }
        updateApplication(dataSubmit);
    }

    const handleCheckBox = ()=>{
        setReferenceButton(!referenceButton);
    }

    const handleCopyText = (type)=>{
        
        navigator.clipboard?.writeText(`${FRONT_URL}${type}/${appId}`).then(() => {
            switch (type) {
                case "pastoral":
                    setCopyTextPastoral("COPIED")
                    setTimeout(() => {
                        setCopyTextPastoral("COPY TEXT");
                    }, 1000);
                    break;
                case "employer":
                    setCopyTextEmployer("COPIED")
                    setTimeout(() => {
                        setCopyTextEmployer("COPY TEXT");
                    }, 1000);
                    break;
                case "academic":
                    setCopyTextAcademic("COPIED")
                    setTimeout(() => {
                        setCopyTextAcademic("COPY TEXT");
                    }, 1000);
                    break;
                case "marriage":
                    setCopyTextMarriage("COPIED")
                    setTimeout(() => {
                        setCopyTextMarriage("COPY TEXT");
                    }, 1000);
                    break;
            
                default:
                    break;
            }
        })
        .catch((err) => {
              switch (type) {
                  case "pastoral":
                      setCopyTextPastoral("ERROR, COPY MANUALLY")
                      setTimeout(() => {
                          setCopyTextPastoral("COPY TEXT");
                      }, 1000);
                      break;
                  case "employer":
                      setCopyTextEmployer("ERROR, COPY MANUALLY")
                      setTimeout(() => {
                          setCopyTextEmployer("COPY TEXT");
                      }, 1000);
                      break;
                  case "academic":
                      setCopyTextAcademic("ERROR, COPY MANUALLY")
                      setTimeout(() => {
                          setCopyTextAcademic("COPY TEXT");
                      }, 1000);
                      break;
                  case "marriage":
                      setCopyTextMarriage("ERROR, COPY MANUALLY")
                      setTimeout(() => {
                          setCopyTextMarriage("COPY TEXT");
                      }, 1000);
                      break;
                  default:
                      break;
              }
          });

    }

    return (
        <Grid container flexDirection="column-reverse">
            <Grid item xs={12}>
                <MobileStepper
                    variant="dots"
                    steps={steps}
                    position="static"
                    activeStep={activeStep}
                    sx={{ width:"100%", flexGrow: 1 , marginTop:"50px"}}
                    nextButton={
                        <Button size="small" onClick={handleNext}>
                            {activeStep === steps-1 ? 'Next' : 'Next' }
                        </Button>
                    }
                    backButton={
                        <Button size="small" onClick={handleBack}>
                            {activeStep === 0 ? 'Back' : 'Back'}
                        </Button>
                    }
                />
            </Grid>
            <Grid item xs={12}>
                <Grid container>
                    <Grid item>
                        <Typography variant="h6" color="customGray" sx={{marginBottom:"10px"}}><b>Complete your application</b></Typography>
                        <Typography variant="subtitle1" color="customGray" sx={{marginBottom:"10px"}}>You will need 2 references <span style={{color:"#FBBD08"}}>(3 references if you are a married applicant)</span></Typography>
                    </Grid>
                    <Grid item>
                        <Grid container spacing={5}>
                            <Grid item xs={0.7}>
                                <Box sx={{display:"flex",borderRadius:"50%",width:"40px",height:"40px",backgroundColor:"#1A2B50",alignItems:"center",justifyContent:"space-around",color:"white"}}>
                                    <Typography variant="h5">1</Typography>
                                </Box>
                            </Grid>
                            <Grid item xs={11.3} sx={{display:"flex",alignItems:"center"}}>
                                <Typography variant="subtitle1">Christian Experience Reference to be completed by pastor, ministry leader, spiritual mentor, church elder, or small group leader. References should not be completed by a family member of the applicant.</Typography>
                            </Grid>
                            <Grid item xs={0.7}>
                                <Box sx={{display:"flex",borderRadius:"50%",width:"40px",height:"40px",backgroundColor:"#1A2B50",alignItems:"center",justifyContent:"space-around",color:"white"}}>
                                    <Typography variant="h5">2</Typography>
                                </Box>
                            </Grid>
                            <Grid item xs={11.3} sx={{display:"flex",alignItems:"center"}}>
                                <Typography variant="subtitle1">Academic or Employer Reference.</Typography>
                            </Grid>
                            <Grid item xs={0.7}>
                                <Box sx={{display:"flex",borderRadius:"50%",width:"40px",height:"40px",backgroundColor:"#1A2B50",alignItems:"center",justifyContent:"space-around",color:"white"}}>
                                    <Typography variant="h5">3</Typography>
                                </Box>
                            </Grid>
                            <Grid item xs={11.3} sx={{display:"flex",alignItems:"center"}}>
                                <Typography variant="subtitle1"><b>Married Applicants Only</b>: Pastoral or Marriage Counselor References Letter.</Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item>
                        <Typography variant="h6" color="customGray" sx={{margin:"40px 0px"}}><b>Send your references</b></Typography>
                    </Grid>
                    <Grid item>
                        <Grid container spacing={5}>
                            <Grid item xs={0.7}>
                                <Box sx={{display:"flex",borderRadius:"50%",width:"40px",height:"40px",backgroundColor:"#1A2B50",alignItems:"center",justifyContent:"space-around",color:"white"}}>
                                    <Typography variant="h5">1</Typography>
                                </Box>
                            </Grid>
                            <Grid item xs={11.3} sx={{display:"flex",alignItems:"center"}}>
                                <Typography variant="subtitle1">Click on the "<span style={{color:"#FBBD08"}}>Copy link</span>” button below.</Typography>
                            </Grid>
                            <Grid item xs={12} sx={{display:"flex",flexDirection:"column",marginLeft:"50px"}}>
                                <Typography variant="subtitle1" color="customGray" >Christian Experience Reference</Typography>
                                <TextField value={`${FRONT_URL}pastoral/${appId}`} name="relationships" fullWidth/>
                                <Button onClick={()=>handleCopyText("pastoral")} sx={{alignSelf:"flex-end",marginTop:"20px"}} variant='contained' endIcon={<ContentCopyIcon/>}>
                                    {copyTextPastoral}
                                </Button>
                            </Grid>
                            <Grid item xs={12} sx={{display:"flex",flexDirection:"column",marginLeft:"50px"}}>
                                <Typography variant="subtitle1" color="customGray">Employer Reference</Typography>
                                <TextField value={`${FRONT_URL}employer/${appId}`} name="relationships" fullWidth/>
                                <Button onClick={()=>handleCopyText("employer")} sx={{alignSelf:"flex-end",marginTop:"20px"}} variant='contained' endIcon={<ContentCopyIcon/>}>
                                    {copyTextEmployer}
                                </Button>
                            </Grid>
                            <Grid item xs={12} sx={{display:"flex",flexDirection:"column",marginLeft:"50px"}}>
                                <Typography variant="subtitle1" color="customGray">Academic Reference</Typography>
                                <TextField value={`${FRONT_URL}academic/${appId}`} name="relationships" fullWidth/>
                                <Button onClick={()=>handleCopyText("academic")} sx={{alignSelf:"flex-end",marginTop:"20px"}} variant='contained' endIcon={<ContentCopyIcon/>}>
                                    {copyTextAcademic}
                                </Button>
                            </Grid>
                            {
                                family?.maritalStatus === "married" &&
                                <Grid item xs={12} sx={{display:"flex",flexDirection:"column",marginLeft:"50px"}}>
                                    <Typography variant="subtitle1" color="customGray">Marriage Reference</Typography>
                                    <TextField value={`${FRONT_URL}marriage/${appId}`} name="relationships" fullWidth/>
                                    <Button onClick={()=>handleCopyText("marriage")} sx={{alignSelf:"flex-end",marginTop:"20px"}} variant='contained' endIcon={<ContentCopyIcon/>}>
                                        {copyTextMarriage}
                                    </Button>
                                </Grid>
                            }
                            <Grid item xs={0.7}>
                                <Box sx={{display:"flex",borderRadius:"50%",width:"40px",height:"40px",backgroundColor:"#1A2B50",alignItems:"center",justifyContent:"space-around",color:"white"}}>
                                    <Typography variant="h5">2</Typography>
                                </Box>
                            </Grid>
                            <Grid item xs={11.3} sx={{display:"flex",alignItems:"center"}}>
                                <Typography variant="subtitle1">Open your <span style={{color:"#FBBD08"}}>email</span>.</Typography>
                            </Grid>
                            <Grid item xs={0.7}>
                                <Box sx={{display:"flex",borderRadius:"50%",width:"40px",height:"40px",backgroundColor:"#1A2B50",alignItems:"center",justifyContent:"space-around",color:"white"}}>
                                    <Typography variant="h5">3</Typography>
                                </Box>
                            </Grid>
                            <Grid item xs={11.3} sx={{display:"flex",alignItems:"center"}}>
                                <Typography variant="subtitle1"><span style={{color:"#FBBD08"}}>Paste the link</span> in the body of your email.</Typography>
                            </Grid>
                            <Grid item xs={0.7}>
                                <Box sx={{display:"flex",borderRadius:"50%",width:"40px",height:"40px",backgroundColor:"#1A2B50",alignItems:"center",justifyContent:"space-around",color:"white"}}>
                                    <Typography variant="h5">4</Typography>
                                </Box>
                            </Grid>
                            <Grid item xs={11.3} sx={{display:"flex",alignItems:"center"}}>
                                <Typography variant="subtitle1"><span style={{color:"#FBBD08"}}>Send</span> to whoever will be filling out the form for you. Your recipient will be able click the link and send the reference directly to Summit.</Typography>
                            </Grid>
                            <Grid item xs={12}>
                            {
                                referenceButton ?
                                <Button onClick={handleCheckBox} variant="text" sx={{padding:"10px 20px 10px 20px",marginTop:"30px"}} startIcon={<CheckBoxIcon/>}>I have sent my references via email to at least 2 people (3 if applying as a married applicant)</Button>
                                :
                                <Button onClick={handleCheckBox} variant="text" sx={{padding:"10px 20px 10px 20px",marginTop:"30px"}} startIcon={<CheckBoxOutlineBlankIcon/>}>I have sent my references via email to at least 2 people (3 if applying as a married applicant)</Button>
                            }
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            <Grid item xs={12} sx={{margin:{xs:"0px 0px 50px 0px",sm:"50px 0px"}}}>
                <BannerStep title="References" stepNumber={activeStep+1} totalSteps={1}/>
            </Grid>
        </Grid>
    )
}

export default References
import { Grid } from "@mui/material";
import { useState } from "react";
import ScholarshipStepsAdmin from "./ScholarshipStepsAdmin";
import ScholarshipTabAdmin from "./QViewerTabs/ScholarshipTabAdmin";
import ExtraAdmin from "./QViewerTabs/ExtraAdmin";
import MedicalRecordsAdmin from "./QViewerTabs/MedicalRecordsAdmin";

const ScholarshipAdmin = ({application,setCurrentApplication}) => {
    
    const [currentTab, setCurrentTab] = useState('scholarship');

    return (
        <Grid container spacing={2}>
            <Grid item xs={3} sx={{borderRight:"solid #999999",paddingRight:"30px"}}>
                <ScholarshipStepsAdmin application={application} setCurrentTab={setCurrentTab} currentTab={currentTab}/>
            </Grid>
            <Grid item xs={9}>
                {
                    currentTab === "scholarship" && <ScholarshipTabAdmin scholarship={application?.scholarship} appId={application?._id} setCurrentApplication={setCurrentApplication}/>
                }
                {
                    currentTab === "extra" && <ExtraAdmin data={application?.medicalAndTransportation} />
                }
                {
                    currentTab === "medical_records" && <MedicalRecordsAdmin immunoInfo={application?.immunoRecord} physicalInfo={application?.physicalExamination} appId={application?._id}
                                                        setCurrentApplication={setCurrentApplication}/>
                }
            </Grid>
        </Grid>
    )
}

export default ScholarshipAdmin
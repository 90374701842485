import AssignToColumn from "../components/GridTableUtils/AssignToColumn";
import DecisionReviewColumn from "../components/GridTableUtils/DecisionReviewColumn";
import DocumentsColumn from "../components/GridTableUtils/DocumentsColumn";
import ProgramColumn from "../components/GridTableUtils/ProgramColumn";
import QuestionaireColumn from "../components/GridTableUtils/QuestionaireColumn";
import clsx from 'clsx';

export const columnsReviewed = [
    {
        field:'lastName',
        headerName: 'LAST NAME',
        flex:1
    },
    {
        field:'firstName',
        headerName: 'FIRST NAME',
        flex:1
    },
    {
        field:'assignedTo',
        headerName: 'ASSIGNED TO',
        flex:1,
        renderCell: AssignToColumn,
        cellClassName:()=>{
            return clsx('nopadding');
        }
    },
    {
        field:'program',
        headerName: 'PROGRAM',
        flex:1,
        renderCell: ProgramColumn
    },
    {
        field:'citizenship',
        headerName: 'CITIZENSHIP',
        flex:1
    },
    {
        field:'questionnaire',
        headerName: 'QUESTIONNAIRE',
        flex:1,
        renderCell: QuestionaireColumn,
        cellClassName:()=>{
            return clsx('nopadding');
        }
    },
    {
        field:'documents',
        headerName: 'DOCUMENTS',
        flex:1,
        renderCell: DocumentsColumn,
        cellClassName:()=>{
            return clsx('nopadding');
        }
    },
    {
        field:'decision',
        headerName: 'DECISION',
        flex:1,
        renderCell: DecisionReviewColumn
    },
]
import { useContext, useState } from 'react';
import { UserContext } from '../../contexts/user';
import MenuIcon from '@mui/icons-material/Menu';
import Logo from '../../assets/images/summit-logo.png';
import { AppBar, Box, Button, Container, IconButton, Menu, MenuItem, Toolbar, Typography } from '@mui/material';
import { Link, Outlet, useLocation } from 'react-router-dom';

const buttonsNotLogIn = [
  {page:'CREATE ACCOUNT',color:"customGray",fontColor:"#1A2B50",toPage:"/createAccount"},
  {page:'LOG IN',color:"primary",fontColor:"white",toPage:"/login"}, 
  {page:'CONTACT',color:"customYellow",fontColor:"white",toPage:"https://www.summitpa.org/contact-us"}
];

const Navigation = () => {

  const {currentUser,setCurrentUser} = useContext(UserContext);
  const [anchorElNav, setAnchorElNav] = useState(null);
  const {pathname} = useLocation();

  const handleLogOut = ()=>{
    setCurrentUser(null);
    localStorage.setItem("lastPage",pathname.slice(1));
    localStorage.removeItem('userSummit');
  }

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  return (
    <>
    <AppBar sx={{backgroundColor:"white",p:2}} position='static'>
      <Container maxWidth="xl">
        <Toolbar disableGutters sx={{justifyContent:"space-between"}} >          
          {
            /* DESKTOP LOGO */  
            <Box sx={{ display: { xs: 'none', md: 'flex' } }}>
                <Link to="/">
                  <img src={Logo} className='logoImage' alt='LOGO_SUMMIT'/>
                </Link>
            </Box>

          }
          {
            /* MOBILE LOGO */
            currentUser ?
            <Box sx={{ display: { xs: 'flex', md: 'none' } }}>
              <Link to="/menu">
                <img src={Logo} className='logoImage' alt='LOGO_SUMMIT'/>
              </Link>
            </Box>
            :
            <Box sx={{ display: { xs: 'flex', md: 'none' } }}>
              <Link to="/">
                <img src={Logo} className='logoImage' alt='LOGO_SUMMIT'/>
              </Link>
            </Box>
          }
          {/* DESKTOP MENU */}
          {
            currentUser ? 
            <Box sx={{ display: { xs: 'none', md: 'flex',alignItems:"center" } }}>
              <Typography color="primary">{currentUser.firstName}</Typography>
              <Link to={currentUser.rol !== "user"? "/admin" :"/"}>
                <Button variant='contained' color="primary" sx={{ m: 1, display: 'block',color:"white" }} onClick={handleLogOut}>
                  LOG OUT
                </Button>
              </Link>
            </Box>
            :
            <Box sx={{ display: { xs: 'none', md: 'flex' } }}>
              {buttonsNotLogIn.map(({page,color,fontColor,toPage}) => (
                <Link key={page} to={toPage}>
                  <Button variant='contained' color={color} sx={{ m: 1, display: 'block',color:fontColor }}>
                    {page}
                  </Button>
                </Link>
              ))}
            </Box>
          }
          {/* MOBILE MENU */}
          {
            currentUser ? 
            <Box sx={{ display: { xs: 'flex', md: 'none' } }}>
              <IconButton
                size="large"
                onClick={handleOpenNavMenu}
                color="primary"
              >
                <MenuIcon/>
              </IconButton>
              <Menu
                anchorEl={anchorElNav}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'left',
                }}
                keepMounted
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'left',
                }}
                open={Boolean(anchorElNav)}
                onClose={handleCloseNavMenu}
                sx={{
                  display: { xs: 'block', md: 'none' },
                }}
              >
                <MenuItem onClick={handleCloseNavMenu}>
                  <Typography textAlign="center" onClick={handleLogOut}>LOG OUT</Typography>
                </MenuItem>
              </Menu>
            </Box>
            :
            <Box sx={{ display: { xs: 'flex', md: 'none' } }}>
              <IconButton
                size="large"
                onClick={handleOpenNavMenu}
                color="primary"
              >
                <MenuIcon/>
              </IconButton>
              <Menu
                anchorEl={anchorElNav}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'left',
                }}
                keepMounted
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'left',
                }}
                open={Boolean(anchorElNav)}
                onClose={handleCloseNavMenu}
                sx={{
                  display: { xs: 'block', md: 'none' },
                }}
              >
                {buttonsNotLogIn.map(({page,toPage}) => (
                  <MenuItem key={page} onClick={handleCloseNavMenu}>
                    <Link to={toPage}>
                      <Typography textAlign="center">{page}</Typography>
                    </Link>
                  </MenuItem>
                ))}
              </Menu>
            </Box>
          }
        </Toolbar>
      </Container>
    </AppBar>
    <Outlet/>
    </>
  )
}

export default Navigation
import { Button, FormControlLabel, FormGroup, Grid, MobileStepper, Switch, Typography } from "@mui/material"
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

const defaultFeesForm = {
    payed: false,
}

const Fees = ({data,updateApplication,scholarship,stepsNumber}) => {

    const navigate = useNavigate();
    const [activeStep, setActiveStep] = useState(0);
    const [feesForm, setFeesForm] = useState(defaultFeesForm);
    const { payed } = feesForm;
    const steps = 1;

    useEffect(() => {
      if(data){
        setFeesForm({
            payed:data?.payed || false
        });
      }
    
    }, [data])

    const handleNext = () => {
        handleSubmit();
        if(activeStep === steps-1){
            if(scholarship === "yes"){
                navigate('/scholarship');
                return;
            }
            navigate('/fees');
            return;
        }
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
    };
    
    const handleBack = () => {
        handleSubmit();
        if(activeStep === 0)
        {
            navigate('/review_and_submit');
            return;
        }
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const handlePayment = ({target})=>{
        setFeesForm({payed:target.checked});
        const dataSubmit={
            fees:{
                payed:target.checked
            }
        }
        updateApplication(dataSubmit);
    }

    const handleSubmit = ()=>{
    const dataSubmit={
        fees:{
            ...feesForm
        }
    }
    
    updateApplication(dataSubmit);
    }

    return (
        <Grid container flexDirection="column-reverse">
            <Grid item xs={12}>
                <MobileStepper
                variant="dots"
                steps={steps}
                position="static"
                activeStep={activeStep}
                sx={{ width:"100%", flexGrow: 1 , marginTop:"50px"}}
                nextButton={
                    <Button size="small" disabled={!payed} onClick={handleNext}>
                        {activeStep === steps-1 ? 'Next' : 'Next' }
                    </Button>
                }
                backButton={
                    <Button size="small" onClick={handleBack}>
                        {activeStep === 0 ? 'Back' : 'Back'}
                    </Button>
                }
                />
            </Grid>
            <Grid item xs={12}>
                <FormGroup onChange={handlePayment}>
                    <FormControlLabel checked={payed} control={<Switch/>} label="Pay"/>
                </FormGroup>
            </Grid>
            <Grid item xs={12} sx={{margin:{xs:"0px 0px 50px 0px",sm:"50px 0px"}}}>
                <Typography variant='h6' sx={{backgroundColor:"#E1A30B",padding:{xs:"20px 0px",sm:"20px 0px 20px 50px"}, color:"#FFFFFF",textAlign:{xs:"center",sm:"left"} }}>Fees</Typography>
            </Grid>
        </Grid>
    )
}

export default Fees
import { useContext } from "react";
import { Link } from "react-router-dom"
import { UserContext } from "../contexts/user";
import { Box, Button, Container, Grid, Typography } from "@mui/material";
import { createApplication, createNotification } from "../axios/axiosFunctions";

const Program = () => {

    const {currentUser} = useContext(UserContext);    

    const handleClick = async(program)=>{
        const data = {
            program,
            year: new Date().getFullYear(),
            user: currentUser._id
        }
        const {application} = await createApplication(data);
        const notification = {
            application: application._id,
            code:"Create Account",
            title:`${currentUser?.firstName} ${currentUser?.lastName} created an account on ${currentUser?.accountCreatedDate}`,
            description:"Assign a counselor now.",
            severity:"#E5F6FD"
        } 

        await createNotification(notification);

    }

    return (
        <Container>
            <Box display="flex" alignItems="center" flexDirection="column" sx={{height:{xs:"calc(100vh - 88px)", sm:"calc(100vh - 96px)"}}} justifyContent="center">
                <Typography variant="h5" sx={{width:{xs:"100%",sm:"50%"},textAlign:{xs:"left",sm:"center"},marginBottom:"100px"}}>
                At this time, how long do you see yourself studying with us?
                </Typography>
                <Grid container spacing={2}>
                    <Grid item xs={6}>
                        <Link to="/application">
                            <Button fullWidth onClick={()=>handleClick("traditional")} sx={{marginRight:{sm:"20px"}, marginBottom:{xs:"30px",sm:"0px"}, minWidth:{xs:"100%",sm:"300px"}, padding:"10px 30px",height:"100%", display:"flex",flexDirection:"column",textTransform:"none"}} size="large" variant="contained">
                                <Typography variant="h6">TRADITIONAL</Typography>
                                <Typography variant="subtitle2">I am interested in coming for two years <br/>(diploma in Biblical Studies and Ministry, 60 credits)</Typography>
                            </Button>
                        </Link>
                    </Grid>
                    <Grid item xs={6}>
                        <Link to="/application">
                            <Button fullWidth onClick={()=>handleClick("sabbatical")} sx={{marginRight:{sm:"20px"}, marginBottom:{xs:"30px",sm:"0px"}, minWidth:{xs:"100%",sm:"300px"}, padding:"10px 30px",height:"100%", display:"flex",flexDirection:"column",textTransform:"none"}} size="large" variant="contained">
                                <Typography variant="h6">SABBATICAL</Typography>
                                <Typography variant="subtitle2">I am applying for the sabbatical program</Typography>
                            </Button>
                        </Link>
                    </Grid>
                    <Grid item xs={6}>
                        <Link to="/application">
                            <Button fullWidth onClick={()=>handleClick("high school gap year")} sx={{marginRight:{sm:"20px"}, marginBottom:{xs:"30px",sm:"0px"}, minWidth:{xs:"100%",sm:"300px"}, padding:"10px 30px",height:"100%", display:"flex",flexDirection:"column",textTransform:"none"}} size="large" variant="contained">
                                <Typography variant="h6">ONE-YEAR PROGRAM</Typography>
                                <Typography variant="subtitle2">I am interested in coming for one year <br/>(certificate in Biblical Foundations, 30 credits)</Typography>
                            </Button>
                        </Link>
                    </Grid>
                    <Grid item xs={6}>
                        <Link to="/application">
                            <Button fullWidth onClick={()=>handleClick("open")} sx={{marginRight:{sm:"20px"}, marginBottom:{xs:"30px",sm:"0px"}, minWidth:{xs:"100%",sm:"300px"}, padding:"10px 30px",height:"100%", display:"flex",flexDirection:"column",textTransform:"none"}} size="large" variant="contained">
                            <Typography variant="h6">OPEN</Typography>
                            <Typography variant="subtitle2">I haven’t decided yet, I’m open to where God leads</Typography>
                            </Button>
                        </Link>
                    </Grid>
                </Grid>
            </Box>
        </Container>
    )
}

export default Program
import { useContext, useState } from "react";
import { UserContext } from "../contexts/user";
import {forgotPasswordEmail, loginUser} from '../axios/axiosFunctions';
import { Alert, Box, Button, Checkbox, Container, Dialog, DialogContent, DialogTitle, FormControl, FormControlLabel, Grid, IconButton, InputAdornment, InputLabel, OutlinedInput, TextField, Typography } from "@mui/material";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { Link, useNavigate } from "react-router-dom";
const defaultLoginForm = {
    username : "",
    password : "",
    terms: false,
}
  
const defatulForgotPasswordForm = {
  email:""
}

const LoginForm = ({setLevel}) => {

    const navigate = useNavigate();
    const {setCurrentUser}  = useContext(UserContext);
    const [loginForm, setLoginForm] = useState(defaultLoginForm);
    const [forgorPasswordForm, setForgorPasswordForm] = useState(defatulForgotPasswordForm);
    const [showPassword, setShowPassword] = useState(false);
    const {username,password,terms} = loginForm;
    const [error, setError] = useState("");
    const [showError, setShowError] = useState(false);
    const [openDialog, setOpenDialog] = useState(false);
    const [openTermsAndConditions, setOpenTermsAndConditions] = useState(false);

    const handleClickShowPassword = () => setShowPassword((show) => !show);
    
    const handleForgotPassword = async()=>{
      const data = {
        ...forgorPasswordForm
      }
      await forgotPasswordEmail(data);
      handleDialogClose();
    }

    const handleDialogClose = ()=>{
      setOpenDialog(false);
    }

    const handleChangeForm = (e)=>{
      const {name,value} = e.target;
      setForgorPasswordForm({...forgorPasswordForm, [name]:value});
    }

    const handleLoginChange = (e)=>{
        const {name,value} = e.target;
        setLoginForm({...loginForm,[name]:value});
    };

    const handleCheckbox = (e)=>{
        const {name} = e.target;
        setLoginForm({...loginForm,[name]:!terms});
    };

    const handleOpenTermsAndConditions = ()=>{
      setOpenTermsAndConditions(true);
    }

    const handleCloseTermsAndConditions = ()=>{
      setOpenTermsAndConditions(false);
    }

    
    const handleLoginSubmit = async(e)=>{
      e.preventDefault();
      
      if(!loginForm.terms){
        setError("Fill required fields");
        setShowError(true);
        setTimeout(() => {
          setShowError(false);
        }, 4000);
        return;
      }

      const data = {
        email: username,
        password
      };
      
      const {user,msg} = await loginUser(data);  
      
      if(!user){
        setError(msg);
        setShowError(true);
        setTimeout(() => {
          setShowError(false);
        }, 4000);
        return;
      }

      
      setLoginForm(defaultLoginForm);
      setCurrentUser(user);
      localStorage.setItem('userSummit',JSON.stringify(user));
      const lastPage = localStorage.getItem('lastPage');
      if(lastPage && lastPage !== ""){
        navigate(`/${lastPage}`)
        return;
      }
      navigate("/welcome");
  
    }

    return (
      <Container>
        <Box display="flex" alignItems="center" flexDirection="column" sx={{height:{xs:"calc(100vh - 88px)", sm:"calc(100vh - 96px)"}}} justifyContent="center">
          <Typography variant='h4' sx={{marginBottom:{xs:"20px",sm:"100px"},alignSelf:"flex-start"}}>Login</Typography>
          <form onSubmit={handleLoginSubmit}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <TextField required fullWidth onChange={handleLoginChange} name='username' label="Email address"/>
              </Grid>
              <Grid item xs={12}>
                <FormControl fullWidth variant="outlined">
                  <InputLabel>Password*</InputLabel>
                  <OutlinedInput
                    type={showPassword ? 'text' : 'password'}
                    fullWidth
                    name='password'
                    required
                    onChange={handleLoginChange}
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton
                          onClick={handleClickShowPassword}
                        >
                          {showPassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                      </InputAdornment>
                    }
                    label="Password*"
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                <FormControlLabel onChange={handleCheckbox} name="terms" checked={terms} control={<Checkbox/>} label={<Typography>I accept the <span onClick={handleOpenTermsAndConditions}><u>Terms and Conditions</u></span>*</Typography>}/>
                <Dialog maxWidth="md" open={openTermsAndConditions} onClose={handleCloseTermsAndConditions}>
                  <DialogTitle>
                    <Typography variant="h4">
                      <b>Privacy Policy</b>
                    </Typography>
                  </DialogTitle>
                  <DialogContent>
                    We are committed to protecting your privacy. We only gather personal information from you if you provide it voluntarily, and we use it only to send you communications from Summit International School of Ministry and Times Square Church. We maintain the privacy of your information using security technologies and adhere to policies that prevent unauthorized use of your personal information. We do not share, rent or sell any contact information you have submitted to us.
                    <br/>
                    <br/>
                    <b><u>WHAT PERSONAL INFORMATION DO WE COLLECT, AND HOW IS IT USED?</u></b>
                    <br/>
                    <br/>
                    We collect personal information such as name, address and phone numbers whenever you send us an inquiry, make a donation or apply to be a student. Such information is always voluntarily submitted by you (e.g., when you fill out the application, fill out the donation form or use our contact form). The information collected is used to communicate with you, and generally to serve you.
                    <br/>
                    We also collect broad and more comprehensive information on which portions of our website visitors access or visit. We monitor and track information such as usage patterns of visitors to our website. We use this information to improve our website, our operations and our client service.
                    <br/>
                    <br/>
                    <b><u>PROTECTING YOUR PRIVACY</u></b>
                    <br/>
                    <br/>
                    As part of our commitment to ensuring the privacy of subscribers, we do not sell or exchange names, email addresses or any other personally identifiable information with any third party.
                    <br/>
                    We may share information with governmental agencies or other companies assisting us in fraud prevention or investigation. We may do so when permitted or required by law; or trying to protect against/prevent actual or potential fraud or unauthorized transactions; or investigating fraud that has already taken place. Information is not provided to these companies for marketing purposes.
                    <br/>
                    <br/>
                    <b><u>USE OF COOKIES</u></b>
                    <br/>
                    <br/>
                    Cookies are pieces of information that are stored on your computer to help identify you while you visit our website. We use cookies to make visiting and using our website easier so that you don’t have to continually re-enter form information, and so that we can tailor your visit to your voluntarily expressed preferences.
                    <br/>
                    <br/>
                    <b><u>LINKS TO OTHER SITES</u></b>
                    <br/>
                    <br/>
                    To offer you still more choices and information, this website provides some links to third party websites. While we make efforts to choose our links carefully, we take no responsibility for the content or privacy policy of linked websites.
                    <br/>
                    <br/>
                    <b><u>PRIVACY POLICY CHANGES</u></b>
                    <br/>
                    <br/>
                    We maintain the option to change this privacy policy at any time by posting a new version of it to our website. Please review this privacy policy periodically to stay abreast of any changes that may have been made to it. Your continued use of our website after the date that such changes are posted will be deemed to be your agreement to the changed terms.
                  </DialogContent>
                </Dialog>
              </Grid>
            </Grid>
            <Button type='submit' sx={{marginTop:{xs:"20px",sm:"50px"}, marginBottom:"10px"}} size="large" fullWidth variant="contained">LOG IN </Button>
            {
              showError &&
              <Alert sx={{marginTop:"20px"}} severity="error">
                {error}
              </Alert>
            }
          </form>
          <Box sx={{display:"flex"}}>
            <Link to="/createAccount" style={{marginRight:"20px"}}>
              <Typography variant="body2" color="customYellow.main">Create Account</Typography>
            </Link>
            <Link onClick={()=>{setOpenDialog(true)}}>
              <Typography variant="body2" color="customYellow.main">Forgot Password?</Typography>
            </Link>
          </Box>
          <Dialog open={openDialog} onClose={handleDialogClose}>
              <DialogContent>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={12}>
                      <Typography>Enter your email to search for your account.</Typography>
                      <Typography>An email with instructions will arrive to you.</Typography>
                  </Grid>
                  <Grid item xs={12} sm={12}>
                      <TextField required fullWidth onChange={handleChangeForm} name='email' label="Email"/>
                  </Grid>
                </Grid>
                <Box sx={{display:"flex",margin:"20px"}}>
                    <Button onClick={handleForgotPassword} variant='contained'sx={{marginRight:"20px",justifyContent:"space-around"}}>SEND EMAIL</Button>
                    <Button variant='contained' onClick={handleDialogClose} color="customGray">CANCEL</Button>
                </Box>
              </DialogContent>
          </Dialog>
        </Box>
      </Container>
    )
}

export default LoginForm
import { useEffect, useState } from "react";
import { Button, Grid, MobileStepper, Typography, styled } from "@mui/material"
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import DocumentCard from "./DocumentCard";
import { uploadImmuno, uploadPhysical } from "../axios/axiosFunctions";
import BannerStep from "./BannerStep";
import { useNavigate } from "react-router-dom";

const VisuallyHiddenInput = styled('input')({
  clip: 'rect(0 0 0 0)',
  clipPath: 'inset(50%)',
  height: 1,
  overflow: 'hidden',
  position: 'absolute',
  bottom: 0,
  left: 0,
  whiteSpace: 'nowrap',
  width: 1,
});

const MedicalRecords = ({dataImmuno,dataPhysical,appId,updateApplication}) => {

  const steps = 1;
  const navigate = useNavigate();
  const [activeStep, setActiveStep] = useState(0);
  const [fileImmunoUploaded, setFileImmunoUploaded] = useState(false);
  const [imageImmunoName, setImageImmunoName] = useState("");
  const [filePhysicalUploaded, setFilePhysicalUploaded] = useState(false);
  const [imagePhysicalName, setImagePhysicalName] = useState("");

  useEffect(() => {
    if(dataImmuno){
        setFileImmunoUploaded(dataImmuno.uploaded || false);
      setImageImmunoName(dataImmuno.image || ""); 
    } 
    if(dataPhysical){
        setFilePhysicalUploaded(dataPhysical.uploaded || false);
      setImagePhysicalName(dataPhysical.image || ""); 
    } 
  }, [dataPhysical,dataImmuno]);

  const handleNext = () => {
    if(activeStep === steps-1){
      navigate('/');
      return;
    }
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    if(activeStep === 0)
    {
        navigate('/extra');
        return;
    }
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleImmunoUploadFile = (e)=>{
    const {length} = e.target.files;
    if(length !== 0){
      handleSubmitImmuno(true,e.target.files[0],"immuno");
      setFileImmunoUploaded(true);
    }
  }

const handlePhysicalUploadFiles = (e)=>{
    const {length} = e.target.files;
    if(length !== 0){
        handleSummitPhysical(true,e.target.files[0],"physical");
        setFilePhysicalUploaded(true);
    }
}

  const handleImmunoDeleteFile = ()=>{
    setFileImmunoUploaded(false);
    handleSubmitImmuno(false,undefined,"immuno",true);
  }

  const handlePhysicalDeleteFiles = ()=>{
      setFilePhysicalUploaded(false);
      handleSummitPhysical(false,undefined,"physical",true);
  }

  const handleSummitPhysical = async(uploaded,submitFile,type,deleteFile=false)=>{
    let physicalN = imagePhysicalName;
    
    if(deleteFile && type === "physical"){
        physicalN = "";
    }

    if(uploaded && submitFile && type==="physical"){
        const formData = new FormData();
        formData.append('fileImage',submitFile);
        const {name} = await uploadPhysical(formData,appId);
        physicalN = name;
    }

    const dataSubmit2 = {
        physicalExamination:{
            uploaded,
            image: physicalN
        }
    }
    updateApplication(dataSubmit2);
  }

  const handleSubmitImmuno = async(uploaded,submitFile,type,deleteFile=false)=>{

      let immunoN = imageImmunoName;
      
      if(deleteFile && type === "immuno"){
          immunoN = "";
      }
      
      if(uploaded && submitFile && type==="immuno"){
          const formData = new FormData();
          formData.append('fileImage',submitFile);
          const {name} = await uploadImmuno(formData,appId);
          immunoN = name;
      }
      
      const dataSubmit = {
          immunoRecord:{
              uploaded,
              image: immunoN
          }
      }
      updateApplication(dataSubmit);
      
  }

  return (
    <Grid container flexDirection="column-reverse">
      <Grid item xs={12}>
        <MobileStepper
            variant="dots"
            steps={steps}
            position="static"
            activeStep={activeStep}
            sx={{ width:"100%", flexGrow: 1 , marginTop:"50px"}}
            nextButton={
                <Button size="small" disabled onClick={handleNext}>
                    {activeStep === steps-1 ? 'Next' : 'Next' }
                </Button>
            }
            backButton={
                <Button size="small" onClick={handleBack}>
                    {activeStep === 0 ? 'Back' : 'Back'}
                </Button>
            }
        />
      </Grid>
      <Grid item xs={12}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
              <Typography variant='h6'>Immunization Records (which includes two MMR & three Hepatitis B)</Typography>
          </Grid>
          <Grid item xs={12}>
              {
                  !fileImmunoUploaded ? 
                  <Button variant="contained" component="label" startIcon={<CloudUploadIcon/>}>
                      UPLOAD FILE
                      <VisuallyHiddenInput onChange={handleImmunoUploadFile} type="file" />
                  </Button>
                  :
                  <DocumentCard name="IMMUNIZATION RECORDS UPLOADED" deleteFile={handleImmunoDeleteFile}/>
              }
          </Grid>
          <Grid item xs={12}>
              <Typography variant='h6'>Physical Examination Report</Typography>
          </Grid>
          <Grid item xs={12}>
              {
                  !filePhysicalUploaded ? 
                  <Button variant="contained" component="label" startIcon={<CloudUploadIcon/>}>
                      UPLOAD FILE
                      <VisuallyHiddenInput onChange={handlePhysicalUploadFiles} type="file" />
                  </Button>
                  :
                  <DocumentCard name="PHYSICAL EXAMINATION UPLOADED" deleteFile={handlePhysicalDeleteFiles}/>
              }
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12} sx={{margin:{xs:"0px 0px 50px 0px",sm:"50px 0px"}}}>
        <BannerStep title="Medical Records" stepNumber={activeStep+1} totalSteps={1}/>
      </Grid>
    </Grid>
)
}

export default MedicalRecords
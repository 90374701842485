import { useEffect,useState } from "react"
import { Box, Typography } from "@mui/material";
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import PendingIcon from '@mui/icons-material/Pending';

const ConfirmationFeeAdmin = ({confirmationFee}) => {

    const [paid, setPaid] = useState(false);

    useEffect(() => {
      if(confirmationFee){
        setPaid(confirmationFee.payed);
      }
    }, [confirmationFee]);
    
    return (<>
        <Typography variant='h5' color="customYellow.main" sx={{marginBottom:"30px"}}><b>Confirmation Fee</b></Typography>
        {
            !paid ? <Box sx={{display:"flex"}}>
                <Typography variant='h4'>Payment pending</Typography>
                <PendingIcon sx={{fontSize:"45px",color:"customYellow.main",marginLeft:"10px"}}/>
            </Box>
            :
            <Box display={"flex"}>
                <Typography variant='h4'>Application paid</Typography>
                <CheckCircleIcon sx={{fontSize:"45px",color:"green",marginLeft:"10px"}}/>
            </Box>
            

        }
    </>
    )
}

export default ConfirmationFeeAdmin
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Box } from "@mui/material";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { getApplicationByStatus } from "../../axios/axiosFunctions";
import { columnsDeclined } from "../../utils/declinedTable";

const Declined = ({setActiveTab,currentYear}) => {

    const navigate = useNavigate();
    const [rowsApplications, setRowsApplications] = useState([{id:1}]);

    useEffect(() => {
        const getApplications = async()=>{
            const res = await getApplicationByStatus("declined",currentYear);
            const tempRows = res.map((app)=>({
                id:app._id,
                lastName:app.user?.lastName || "",
                firstName:app.user?.firstName || "",
                assignedTo:app.assignTo,
                program: app.program || "Not set",
                citizenship:app.personal.citizenship,
            }));
            setRowsApplications(tempRows);
        }
        getApplications();
    }, [currentYear]);

    const handleCellClick = (e)=>{
        const {row,colDef} = e;
        if(colDef.field === "lastName" || colDef.field === "firstName"){
            setActiveTab('declined');
            navigate(`${row.id}`);
        }
    }

    return (
        <Box sx={{margin:"20px",'& .nopadding': {padding:"0px !important"}}}>
            <DataGrid initialState={{pagination:{paginationModel: {pageSize:13,page:0}}}}
            columns={columnsDeclined} rows={rowsApplications} rowSpacingType="border" disableMultipleRowSelection={true} onCellClick={handleCellClick}
            slots={{toolbar: GridToolbar}} slotProps={{toolbar:{showQuickFilter:true}}}>
            </DataGrid>
        </Box>
    )
}

export default Declined
import { Alert, Button, Checkbox, Container, FormControlLabel, FormGroup, Grid, Radio, RadioGroup, TextField, Typography } from "@mui/material"
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import BannerStep from "../BannerStep";
import { getApplicationById, updateApplication } from "../../axios/axiosFunctions";

const defaultPastoralForm = {
    firstNamePastor: "",
    lastNamePastor:"",
    pastoralRole: "",
    churchName:"",
    churchWebsite:"",
    firstNameSenior:"",
    lastNameSenior:"",
    phoneNumber:"",
    email:"",
    howLongKnowApplicant:"",
    howWellKnowApplicant:"",
    howLongAttendChurch:"",
    describeCommitmentInChurch:"",
    christianWalk:"",
    christianAttitude:"",
    choiceOfFriends:"",
    cooperation:"",
    desireToLearn:"",
    diligence:"",
    leadersAbility:"",
    respectForAutority:"",
    socialSkills:"",
    workEthic:"",
    emotionalStability:"",
    personalObservations:"",
    assesmentOfApplicant:"",
    factors:"",
    recommend:"",
    reservations:"",
    certify:false,
}

const Pastoral = () => {

    const {id}=useParams(); 
    const navigate = useNavigate();
    const [fullName, setFullName] = useState("User");
    const [showMsg, setShowMsg] = useState(false);
    const [severity, setSeverity] = useState('error');
    const [message, setMessage] = useState(" REQUIRED FIELDS MISSING")
    const [pastoralForm, setPastoralForm] = useState(defaultPastoralForm);
    const {firstNamePastor,lastNamePastor,pastoralRole,churchName,churchWebsite,firstNameSenior,lastNameSenior,phoneNumber,email,
        howLongKnowApplicant,howWellKnowApplicant,howLongAttendChurch,describeCommitmentInChurch,
        christianWalk,christianAttitude,choiceOfFriends,cooperation,desireToLearn,diligence,
        leadersAbility,respectForAutority,socialSkills,workEthic,emotionalStability,personalObservations,
        assesmentOfApplicant,factors,recommend,reservations,certify
    } = pastoralForm;

    useEffect(() => {
        const checkApplication = async()=>{
            const resp = await getApplicationById(id);
            console.log(resp);
            if(!resp){
                navigate('/');
                return
            }
            const {application} = resp;
            if(application.pastoral?.uploaded){
                navigate('/');
                return
            }
            const appName = `${application.personal.lastName} ${application.personal.firstName}`;
            setFullName(appName);
        }
        checkApplication()
    }, [id,navigate]);
    
    const handleChangeForm = (e)=>{
        const {name,value} = e.target;
        if(name=== "certify"){
            setPastoralForm({...pastoralForm, [name]:!pastoralForm[name]});
            return
        }
        setPastoralForm({...pastoralForm, [name]:value});
    }

    const checkRequired = ()=>{
        return firstNamePastor !== "" && lastNamePastor !== "" && pastoralRole !== "" && churchName !== "" && firstNameSenior !== "" && lastNameSenior !== "" &&
        phoneNumber !== "" && email !== "" && howWellKnowApplicant !== "" && howLongAttendChurch !== "" && describeCommitmentInChurch !== "" && christianWalk !== "" &&
        christianAttitude !== "" && choiceOfFriends !== "" && cooperation !== "" && desireToLearn !== "" && diligence !== "" && leadersAbility !== "" && respectForAutority !== "" &&
        socialSkills !== "" && workEthic !== "" && emotionalStability !== "" && personalObservations !== "" && assesmentOfApplicant !== "" && factors !== "" && recommend !== "" && certify
    }

    const handleSubmit = async()=>{
        
        if(!checkRequired()){
            setShowMsg(true);
            setTimeout(() => {
                setShowMsg(false);
            }, 2000);
            return;
        } 
        
        const data = {
            pastoral:{
                ...pastoralForm,
                uploaded:true
            }
        }

        await updateApplication(data,id);
        setShowMsg(true)
        setMessage("THANK YOU, YOU'LL BE REDIRECTED TO THE MAIN PAGE.")
        setSeverity("success")
        setTimeout(() => {
            navigate('/')
        }, 5000);
    }

    return (
        <Container>
            <Grid container spacing={2} sx={{marginBottom:"50px"}}>
                <Grid item xs={12} sx={{margin:{xs:"0px 0px 50px 0px",sm:"50px 0px 0px 0px"}}}>
                    <BannerStep title={`Christian Experience Reference for ${fullName}`} stepNumber={1} totalSteps={1}/>
                </Grid>
                <Grid item xs={12}>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <Typography variant='h5'><b>Pastor's or ministry leader's information</b></Typography>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField required fullWidth value={firstNamePastor} onChange={handleChangeForm} name='firstNamePastor' label="First name"/>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField required fullWidth value={lastNamePastor} onChange={handleChangeForm} name='lastNamePastor' label="Last name"/>
                        </Grid>
                        <Grid item xs={12} sm={12}>
                            <TextField required fullWidth value={pastoralRole} onChange={handleChangeForm} name='pastoralRole' label="Pastoral Role"/>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField required fullWidth value={churchName} onChange={handleChangeForm} name='churchName' label="Church Name"/>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField fullWidth value={churchWebsite} onChange={handleChangeForm} name='churchWebsite' label="Church Website" placeholder="This must be an external URL such as http://example.com."/>
                        </Grid>
                        <Grid item xs={12}>
                            <Typography variant='subtitle1'><b>Name of Senior or Lead Pastor</b></Typography>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField required fullWidth value={firstNameSenior} onChange={handleChangeForm} name='firstNameSenior' label="First name"/>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField required fullWidth value={lastNameSenior} onChange={handleChangeForm} name='lastNameSenior' label="Last name"/>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField required fullWidth value={phoneNumber} onChange={handleChangeForm} name='phoneNumber' label="Phone Number"/>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField required fullWidth value={email} onChange={handleChangeForm} name='email' label="E-mail"/>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12} sx={{marginTop:"20px", borderTop:"solid #E1A30B 5px"}}>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <Typography variant='h5'><b>Reference Information</b></Typography>
                        </Grid>
                        <Grid item xs={12} sm={12}>
                            <TextField fullWidth value={howLongKnowApplicant} onChange={handleChangeForm} name='howLongKnowApplicant' label="How long have you known the applicant?"/>
                        </Grid>
                        <Grid item xs={12} sm={12}>
                            <Typography variant='subtitle1'><b>How well do you know the applicant*</b></Typography> 
                            <RadioGroup onChange={handleChangeForm} value={howWellKnowApplicant} sx={{flexDirection:"row"}}>
                                <FormControlLabel name="howWellKnowApplicant" value="slightly" control={<Radio />} label="Slightly" />
                                <FormControlLabel name="howWellKnowApplicant" value="casually" control={<Radio />} label="Casually"/>
                                <FormControlLabel name="howWellKnowApplicant" value="well" control={<Radio />} label="Well"/>
                                <FormControlLabel name="howWellKnowApplicant" value="veryWell" control={<Radio />} label="Very Well"/>
                            </RadioGroup>
                        </Grid>
                        <Grid item xs={12} sm={12}>
                            <TextField required fullWidth value={howLongAttendChurch} onChange={handleChangeForm} name='howLongAttendChurch' label="How long has the applicant attended your church?"/>
                        </Grid>
                        <Grid item xs={12} sm={12}>
                            <Typography variant='subtitle1'><b>How would you describe the applicant's commitment and involvement in church?*</b></Typography> 
                            <RadioGroup onChange={handleChangeForm} value={describeCommitmentInChurch} sx={{flexDirection:"row"}}>
                                <FormControlLabel name="describeCommitmentInChurch" value="veryInvolved" control={<Radio />} label="Attends regularly, and is very involved with church activities." />
                                <FormControlLabel name="describeCommitmentInChurch" value="seldomParticipates" control={<Radio />} label="Attends regularly, but seldom participates in church activities." />
                                <FormControlLabel name="describeCommitmentInChurch" value="irregular" control={<Radio />} label="Irregular in attendance." />
                            </RadioGroup>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12} sx={{marginTop:"20px", borderTop:"solid #E1A30B 5px"}}>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <Typography variant='h5'><b>Applicant Assessment</b></Typography>
                        </Grid>
                        <Grid className="referenceRadio" item xs={12} sm={12}>
                            <Typography variant='subtitle1'><b>Christian Walk*</b></Typography> 
                            <RadioGroup onChange={handleChangeForm} value={christianWalk} sx={{flexDirection:"row"}}>
                                <FormControlLabel name="christianWalk" value="high" control={<Radio />} label="Maintains high moral & spiritual standards" />
                                <FormControlLabel name="christianWalk" value="consistent" control={<Radio />} label="Maintains consistent moral & spiritual standards" />
                                <FormControlLabel name="christianWalk" value="inconsistent" control={<Radio />} label="Inconsistent in their Christian walk" />
                                <FormControlLabel name="christianWalk" value="notAtAllCommited" control={<Radio />} label="Not at all committed to a consistent Christian walk" />
                                <FormControlLabel name="christianWalk" value="notKnow" control={<Radio />} label="Not known" />
                            </RadioGroup>
                        </Grid>
                        <Grid className="referenceRadio" item xs={12} sm={12}>
                            <Typography variant='subtitle1'><b>Christian Attitude*</b></Typography> 
                            <RadioGroup onChange={handleChangeForm} value={christianAttitude} sx={{flexDirection:"row"}}>
                                <FormControlLabel name="christianAttitude" value="consistent" control={<Radio />} label="Consistent Christ-like attitude" />
                                <FormControlLabel name="christianAttitude" value="somewhatConsistent" control={<Radio />} label="Somewhat consistent attitude" />
                                <FormControlLabel name="christianAttitude" value="inconsistent" control={<Radio />} label="Inconsistent attitude" />
                                <FormControlLabel name="christianAttitude" value="notReflectChrist" control={<Radio />} label="Attitude does not reflect Christ" />
                                <FormControlLabel name="christianAttitude" value="notKnow" control={<Radio />} label="Not known" />
                            </RadioGroup>
                        </Grid>
                        <Grid className="referenceRadio" item xs={12} sm={12}>
                            <Typography variant='subtitle1'><b>Choice of Friends*</b></Typography> 
                            <RadioGroup onChange={handleChangeForm} value={choiceOfFriends} sx={{flexDirection:"row"}}>
                                <FormControlLabel name="choiceOfFriends" value="discerning" control={<Radio />} label="Discerning in friendships" />
                                <FormControlLabel name="choiceOfFriends" value="carefully" control={<Radio />} label="Chooses friends carefully" />
                                <FormControlLabel name="choiceOfFriends" value="lacksDiscretion" control={<Radio />} label="Lacks discretion" />
                                <FormControlLabel name="choiceOfFriends" value="questionable" control={<Radio />} label="Questionable" />
                                <FormControlLabel name="choiceOfFriends" value="notKnow" control={<Radio />} label="Not known" />
                            </RadioGroup>
                        </Grid>
                        <Grid className="referenceRadio" item xs={12} sm={12}>
                            <Typography variant='subtitle1'><b>Cooperation*</b></Typography> 
                            <RadioGroup onChange={handleChangeForm} value={cooperation} sx={{flexDirection:"row"}}>
                                <FormControlLabel name="cooperation" value="wellWithOthers" control={<Radio />} label="Works well with others" />
                                <FormControlLabel name="cooperation" value="cooperate" control={<Radio />} label="Generally willing to cooperate" />
                                <FormControlLabel name="cooperation" value="avoidParticipation" control={<Radio />} label="Avoids group participation" />
                                <FormControlLabel name="cooperation" value="notWellWithOthers" control={<Radio />} label="Does not work well with others" />
                                <FormControlLabel name="cooperation" value="notKnow" control={<Radio />} label="Not known" />
                            </RadioGroup>
                        </Grid>
                        <Grid className="referenceRadio" item xs={12} sm={12}>
                            <Typography variant='subtitle1'><b>Desire to Learn*</b></Typography> 
                            <RadioGroup onChange={handleChangeForm} value={desireToLearn} sx={{flexDirection:"row"}}>
                                <FormControlLabel name="desireToLearn" value="strong" control={<Radio />} label="Strong desire" />
                                <FormControlLabel name="desireToLearn" value="showsInterest" control={<Radio />} label="Shows interest" />
                                <FormControlLabel name="desireToLearn" value="needsEncouragement" control={<Radio />} label="Needs encouragement" />
                                <FormControlLabel name="desireToLearn" value="lacksInterest" control={<Radio />} label="Lacks interest" />
                                <FormControlLabel name="desireToLearn" value="notKnow" control={<Radio />} label="Not known" />
                            </RadioGroup>
                        </Grid>
                        <Grid className="referenceRadio" item xs={12} sm={12}>
                            <Typography variant='subtitle1'><b>Diligence*</b></Typography> 
                            <RadioGroup onChange={handleChangeForm} value={diligence} sx={{flexDirection:"row"}}>
                                <FormControlLabel name="diligence" value="very" control={<Radio />} label="Very diligent" />
                                <FormControlLabel name="diligence" value="somewhat" control={<Radio />} label="Somewhat diligent" />
                                <FormControlLabel name="diligence" value="onlyWhatisAsked" control={<Radio />} label="Does only what is asked" />
                                <FormControlLabel name="diligence" value="procrastinates" control={<Radio />} label="Procrastinates often, does not follow through" />
                                <FormControlLabel name="diligence" value="notKnow" control={<Radio />} label="Not known" />
                            </RadioGroup>
                        </Grid>
                        <Grid className="referenceRadio" item xs={12} sm={12}>
                            <Typography variant='subtitle1'><b>Leadership Ability*</b></Typography> 
                            <RadioGroup onChange={handleChangeForm} value={leadersAbility} sx={{flexDirection:"row"}}>
                                <FormControlLabel name="leadersAbility" value="excellent" control={<Radio />} label="Excellent leader" />
                                <FormControlLabel name="leadersAbility" value="initiative" control={<Radio />} label="Takes initiative to lead" />
                                <FormControlLabel name="leadersAbility" value="onlyWhenAsked" control={<Radio />} label="Leads only when asked" />
                                <FormControlLabel name="leadersAbility" value="noEffort" control={<Radio />} label="Makes no effort to lead" />
                                <FormControlLabel name="leadersAbility" value="notKnow" control={<Radio />} label="Not known" />
                            </RadioGroup>
                        </Grid>
                        <Grid className="referenceRadio" item xs={12} sm={12}>
                            <Typography variant='subtitle1'><b>Respect for Authority*</b></Typography> 
                            <RadioGroup onChange={handleChangeForm} value={respectForAutority} sx={{flexDirection:"row"}}>
                                <FormControlLabel name="respectForAutority" value="very" control={<Radio />} label="Very respectful" />
                                <FormControlLabel name="respectForAutority" value="generally" control={<Radio />} label="Generally respectful" />
                                <FormControlLabel name="respectForAutority" value="oftenCritical" control={<Radio />} label="Often critical" />
                                <FormControlLabel name="respectForAutority" value="disrespectful" control={<Radio />} label="Disrespectful" />
                                <FormControlLabel name="respectForAutority" value="notKnow" control={<Radio />} label="Not known" />
                            </RadioGroup>
                        </Grid>
                        <Grid className="referenceRadio" item xs={12} sm={12}>
                            <Typography variant='subtitle1'><b>Social Skills*</b></Typography> 
                            <RadioGroup onChange={handleChangeForm} value={socialSkills} sx={{flexDirection:"row"}}>
                                <FormControlLabel name="socialSkills" value="excellent" control={<Radio />} label="Excellent" />
                                <FormControlLabel name="socialSkills" value="wellAdjusted" control={<Radio />} label="Well adjusted" />
                                <FormControlLabel name="socialSkills" value="withdraw" control={<Radio />} label="Withdrawn" />
                                <FormControlLabel name="socialSkills" value="poor" control={<Radio />} label="Poor social skills" />
                                <FormControlLabel name="socialSkills" value="notKnow" control={<Radio />} label="Not known" />
                            </RadioGroup>
                        </Grid>
                        <Grid className="referenceRadio" item xs={12} sm={12}>
                            <Typography variant='subtitle1'><b>Work Ethic*</b></Typography> 
                            <RadioGroup onChange={handleChangeForm} value={workEthic} sx={{flexDirection:"row"}}>
                                <FormControlLabel name="workEthic" value="outstanding" control={<Radio />} label="Outstanding" />
                                <FormControlLabel name="workEthic" value="veryGood" control={<Radio />} label="Very good" />
                                <FormControlLabel name="workEthic" value="good" control={<Radio />} label="Good" />
                                <FormControlLabel name="workEthic" value="poor" control={<Radio />} label="Poor" />
                                <FormControlLabel name="workEthic" value="notKnow" control={<Radio />} label="Not known" />
                            </RadioGroup>
                        </Grid>
                        <Grid className="referenceRadio" item xs={12} sm={12}>
                            <Typography variant='subtitle1'><b>Emotional Stability*</b></Typography> 
                            <RadioGroup onChange={handleChangeForm} value={emotionalStability} sx={{flexDirection:"row"}}>
                                <FormControlLabel name="emotionalStability" value="veryStable" control={<Radio />} label="Very stable" />
                                <FormControlLabel name="emotionalStability" value="stableButStruggles" control={<Radio />} label="Stable, but struggles at times" />
                                <FormControlLabel name="emotionalStability" value="struggles" control={<Radio />} label="Struggles emotionally" />
                                <FormControlLabel name="emotionalStability" value="unstable" control={<Radio />} label="Emotionally unstable" />
                                <FormControlLabel name="emotionalStability" value="notKnow" control={<Radio />} label="Not known" />
                            </RadioGroup>
                        </Grid>
                        <Grid item xs={12} sm={12}>
                            <Typography variant='subtitle1'><b>Please describe your personal observations about the applicant’s emotional stability?*</b></Typography>
                            <TextField fullWidth onChange={handleChangeForm} value={personalObservations} name="personalObservations" multiline rows={4}/>
                        </Grid>
                        <Grid item xs={12} sm={12}>
                            <Typography variant='subtitle1'><b>What is your assessment of the applicant's ability, motivation and past performance?*</b></Typography>
                            <TextField fullWidth onChange={handleChangeForm} value={assesmentOfApplicant} name="assesmentOfApplicant" multiline rows={4} placeholder="Please give a candid assessment of the applicant's strengths and weaknesses."/>
                        </Grid>
                        <Grid item xs={12} sm={12}>
                            <Typography variant='subtitle1'><b>Are there any factors that might hinder the applicant's academic performance and/or interpersonal relationships?*</b></Typography>
                            <TextField fullWidth onChange={handleChangeForm} value={factors} name="factors" multiline rows={4}/>
                        </Grid>
                        <Grid item xs={12} sm={12}>
                            <Typography variant='subtitle1'><b>Would you recommend this applicant to be enrolled at Summit?*</b></Typography> 
                            <RadioGroup onChange={handleChangeForm} value={recommend} sx={{flexDirection:"row"}}>
                                <FormControlLabel name="recommend" value="higly" control={<Radio />} label="Highly recommended" />
                                <FormControlLabel name="recommend" value="recommended" control={<Radio />} label="Recommended" />
                                <FormControlLabel name="recommend" value="withReservations" control={<Radio />} label="Recommended, but with reservations" />
                                <FormControlLabel name="recommend" value="notRecommended" control={<Radio />} label="Not recommended" />
                            </RadioGroup>
                        </Grid>
                        <Grid item xs={12} sm={12}>
                            <Typography variant='subtitle1'><b>If you have any reservations or do not recommend this applicant, please explain:</b></Typography>
                            <TextField fullWidth onChange={handleChangeForm} value={reservations} name="reservations" multiline rows={4}/>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12}  sx={{marginTop:"20px", borderTop:"solid #E1A30B 5px"}} >
                    <FormGroup onChange={handleChangeForm}>
                        <FormControlLabel checked={certify} name ="certify" value={!certify} required control={<Checkbox/>} label="I hereby certify that all information herein is accurate and true to the best of my knowledge."/>                        
                        <Typography variant="caption">The mission of Summit International School of Ministry is to train Christian young people from national and international backgrounds to become spiritually free, entirely useful to local churches and organizations, and to become a mobilized, spiritual army. We appreciate your time in completing this reference form. If you have any questions, please e-mail us at admissions@summitpa.org.</Typography>
                    </FormGroup>
                </Grid>
                {
                    showMsg && 
                    <Grid item xs={12}>
                        <Alert severity={severity}>
                            {message}
                        </Alert>
                    </Grid>
                }
                <Grid item xs={12}>
                    <Button onClick={handleSubmit} variant="contained" color="customYellow">Submit</Button>
                </Grid>
            </Grid>
        </Container>
    )
}

export default Pastoral
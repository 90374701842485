import { useContext, useEffect, useState } from 'react';
import LinearProgressWithLabel from './LinearProgressWithLabel';
import { Box, Button, Container, Typography } from '@mui/material';
import { UserContext } from '../contexts/user';
import { ApplicationContext } from '../contexts/application';
import { Link } from 'react-router-dom';
import { getApplicationUserYear } from '../axios/axiosFunctions';

const Welcome = () => {
    const {currentUser} = useContext(UserContext);
    const {currentApplication,setCurrentApplication} = useContext(ApplicationContext);
    const [progress, setProgress] = useState(0);

    useEffect(() => {
        const checkApplication = async()=>{
            const resp = await getApplicationUserYear(currentUser._id,2025); // TO DO CHANGE YEAR TO CURRENT YEAR APPLICATION OR TO CUSTOM YEAR TO CHECK
            if (resp){
                const {application} = resp;
                setProgress(application.progress);
                setCurrentApplication(application);
            }
        };
        checkApplication();
    },[currentUser._id,setCurrentApplication]);

    return (
        <Container>
            <Box display="flex" alignItems="center" flexDirection="column" sx={{height:{xs:"calc(100vh - 88px)", sm:"calc(100vh - 96px)"}}} justifyContent="center">
                <Typography variant="h5" sx={{width:{xs:"100%",sm:"50%"},textAlign:{xs:"left",sm:"center"}}}>
                    Hello {currentUser.firstName},<br/><br/>
                    Your application is automatically saved as you work on it. 
                    Don’t worry about losing progress. 
                    If you don’t finish now, come back and pick up where you left off.
                </Typography>
                <Typography variant='h5' align='left' sx={{marginTop:"100px",marginBottom:"20px",width:{xs:"100%",sm:"50%"}}}>Application Process:</Typography>
                <LinearProgressWithLabel value={progress} sx={{width:"100%"}}/> 
                <Link to={currentApplication? "/application":"/program"}>
                    <Button variant='contained' sx={{marginTop:"100px"}}>CONTINUE TO APPLICATION</Button>
                </Link>
            </Box>
        </Container>
    )
}

export default Welcome
import { List, ListItem, ListItemButton, ListItemText, Typography } from '@mui/material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { documentsSteps } from '../../utils/documentsSteps'

const DStepsAdmin = ({application,currentTab,setCurrentTab}) => {

    const handleClick = (tab)=>{
        setCurrentTab(tab);
    }

    return (
        <>
            <Typography variant="h5">Documents:</Typography>
            <List sx={{width:"100%"}}>
                {
                    documentsSteps.map(({label,uploadedLabel,route},i)=>(
                        <ListItem key={i} onClick={()=>handleClick(route)} sx={{padding:{xs:"0px"},borderBottom:"1px solid #9E9E9E",backgroundColor:currentTab ===route?'customYellow.main':'white'}}>
                            <ListItemButton>
                                <ListItemText primary={label}/>
                                <Typography edge="end">{application ? application[uploadedLabel]?.uploaded ? <CheckCircleIcon/> : "" : ""}</Typography>
                            </ListItemButton>
                        </ListItem>
                    ))
                }
            </List>
        </>
    )
}

export default DStepsAdmin
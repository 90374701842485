import { Grid, Step, StepLabel, Stepper, Typography } from "@mui/material";
import LinearProgressWithLabel from "../LinearProgressWithLabel";
import { useEffect, useState } from "react";
import ResumeAdmin from "./ResumeAdmin";
import { questionaireSteps } from "../../utils/questionaireSteps";
import SubmitAdmin from "./SubmitAdmin";
import ScholarshipAdmin from "./ScholarshipAdmin";

const stepsDefault = [
    {
        label:'Application',
        disabled:false,
        completed:false
    },
    {
        label:'Submit',
        disabled:false,
        completed:false
    }
];

const QViewer = ({application,progress,personal,financial}) => {

    const [qSteps, setQSteps] = useState(stepsDefault);
    const [sideBarOpts, setSideBarOpts] = useState(questionaireSteps);
    const [currentStep, setCurrentStep] = useState(0);

    useEffect(() => {
        let newSteps = [...stepsDefault];
        if(financial && financial.scholarship === "yes"){
            newSteps.push({label:"Scholarship",disabled:false,completed:false});
        }
        setQSteps(newSteps);
    }, [financial]);

    useEffect(() => {
        if(personal && personal.f1Visa !== "" && personal.f1Visa !== "no"){
            if(sideBarOpts.length === 10){
                let newArray = [...sideBarOpts];
                newArray.push({
                    label:"Visa",
                    numberSteps: 1,
                    route:"visa",
                    requiredStepsLabel: "visa",
                });
                setSideBarOpts(newArray);
            }
        }
    }, [personal,sideBarOpts])
    

    const handleStepper = (index)=>{
        setCurrentStep(index);
    }
    
    return (
        <Grid container>
            <Grid item xs={12} sx={{display:"flex",flexDirection:"column", justifyContent:"center", alignItems:"center"}}>
                <Typography variant="h5">Application Process:</Typography>
                <LinearProgressWithLabel value={progress}/>
            </Grid>
            <Grid item xs={12} sx={{margin:"50px 0px"}}>
                <Stepper activeStep={currentStep} alternativeLabel sx={{width:"100%",marginTop:{xs:"40px",sm:"0px"}}}>
                    {
                        qSteps.map(({label,completed,disabled},i)=>(
                            <Step key={i} onClick={!disabled ? ()=>handleStepper(i):()=>{}} completed={completed} disabled={disabled} sx={{cursor:"pointer"}}>
                                <StepLabel>{label}</StepLabel>
                            </Step>
                        ))
                    }
                </Stepper>
            </Grid>
            <Grid item xs={12}>
                { 
                    currentStep === 0 && <ResumeAdmin application={application} sideBarOpts={sideBarOpts}/>
                }
                { 
                    currentStep === 1 && <SubmitAdmin application={application}/>
                }
                { 
                    currentStep === 2 && <ScholarshipAdmin application={application}/>
                }
            </Grid>
        </Grid>
    )
}

export default QViewer
import { useContext, useState } from "react";
import { UserContext } from "../../contexts/user";
import { useNavigate } from "react-router-dom";
import { Alert, Box, Button, Container, Divider, FormControl, Grid, IconButton, InputAdornment, InputLabel, OutlinedInput, TextField, Typography } from "@mui/material";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { loginAdminUser } from "../../axios/axiosFunctions";

const defaultLoginForm = {
    username : "",
    password : "",
}
  
const LoginAdmin = () => {

    const navigate = useNavigate();
    const [loginForm, setLoginForm] = useState(defaultLoginForm);
    const [showPassword, setShowPassword] = useState(false);
    const [error, setError] = useState("");
    const [showError, setShowError] = useState(false);
    const {setCurrentUser}  = useContext(UserContext);
    const {username,password} = loginForm;

    const handleClickShowPassword = () => setShowPassword((show) => !show);
    
    const handleLoginChange = (e)=>{
        const {name,value} = e.target;
        setLoginForm({...loginForm,[name]:value});
    };

    const handleLoginSubmit = async(e)=>{
        e.preventDefault();
        const data = {
          email: username,
          password
        };
        
        const {user,msg} = await loginAdminUser(data);  
        
        if(!user){
          setError(msg);
          setShowError(true);
          setTimeout(() => {
            setShowError(false);
          }, 4000);
          return;
        }
  
        
        setLoginForm(defaultLoginForm);
        setCurrentUser(user);
        localStorage.setItem('userSummit',JSON.stringify(user));
        navigate("/home");
    
      }

    return (
        <Container>
          <Box display="flex" alignItems="center" flexDirection="column" sx={{height:{xs:"calc(100vh - 88px)", sm:"calc(100vh - 96px)"}}} justifyContent="center">
            <Typography variant='h4' sx={{marginBottom:{xs:"20px",sm:"5px"},alignSelf:"flex-start"}}>Login</Typography>
            <Divider sx={{width:"100%",marginBottom:"50px"}}/>
            <form onSubmit={handleLoginSubmit}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                    <TextField required fullWidth onChange={handleLoginChange} name='username' label="Email address"/>
                </Grid>
                <Grid item xs={12}>
                  <FormControl fullWidth variant="outlined">
                    <InputLabel>Password*</InputLabel>
                    <OutlinedInput
                      type={showPassword ? 'text' : 'password'}
                      fullWidth
                      name='password'
                      required
                      onChange={handleLoginChange}
                      endAdornment={
                        <InputAdornment position="end">
                          <IconButton
                            onClick={handleClickShowPassword}
                          >
                            {showPassword ? <VisibilityOff /> : <Visibility />}
                          </IconButton>
                        </InputAdornment>
                      }
                      label="Password*"
                    />
                  </FormControl>
                </Grid>
              </Grid>
              <Button type='submit' sx={{marginTop:{xs:"20px",sm:"50px"}, marginBottom:"10px"}} size="large" fullWidth variant="contained">LOG IN </Button>
              {
                showError &&
                <Alert sx={{marginTop:"20px"}} severity="error">
                  {error}
                </Alert>
              }
            </form>
          </Box>
        </Container>
      )
}

export default LoginAdmin
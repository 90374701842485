import { Box, Button, CircularProgress, Grid, Typography, styled } from "@mui/material";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { updateApplication, uploadPhoto } from "../../../axios/axiosFunctions";

const VisuallyHiddenInput = styled('input')({
  clip: 'rect(0 0 0 0)',
  clipPath: 'inset(50%)',
  height: 1,
  overflow: 'hidden',
  position: 'absolute',
  bottom: 0,
  left: 0,
  whiteSpace: 'nowrap',
  width: 1,
});

const PhotoAdmin = ({appId,fileName,setCurrentApplication}) => {

  const BASE_URL = "https://summit-app-edgar.s3.eu-north-1.amazonaws.com/";
  const [fileType, setFileType] = useState();
  const [url, setUrl] = useState(BASE_URL + fileName);


  useEffect(() => {
    const type = fileName.split(".").pop().toLowerCase();
    const folderName = fileName.split("/")[0];
    setFileType(type);
    if (folderName !== "applications") {
      setUrl(BASE_URL + "applicants/" + fileName);
    }
  }, [fileName]);

  const handleUploadFile = (e)=>{
    const {length} = e.target.files;
    if(length !== 0){
        handleSubmit(true,e.target.files[0],"PHOTO");
    }
  }

  const handleSubmit = async(uploaded,submitFile,imgName)=>{ 
    setUrl("");
    let newImageName = imgName;
    if(uploaded && submitFile){
        const formData = new FormData();
        formData.append('fileImage',submitFile);
        const {name} = await uploadPhoto(formData,appId);
        newImageName = name;
    }

    const dataSubmit = {
        photo:{
            uploaded,
            image: newImageName
        }
    }

    const resp = await updateApplication(dataSubmit,appId);
    setCurrentApplication(resp.application);
    setUrl(BASE_URL + fileName);

}
console.log(fileType);

  return (
  <Grid container spacing={2}>
    <Grid item xs={12}>
      <Typography variant='h5' color="customYellow.main" sx={{marginBottom:"30px"}}><b>Photo</b></Typography>
      {
        url === "" ? 
        <CircularProgress/>
        :
        <Box sx={{width:"400px"}}>
          {
            fileType && fileType !== "" && <>
            {
                fileType !== "pdf" && 
                <img src={url} alt="If the element has not loaded, please refresh the page." style={{width:"100%"}}/>
            }
            <Link target="_blank" to={url}>
              <Button variant="contained">
                DOWNLOAD FILE
              </Button>
            </Link> 
            </>
          }
        </Box>
      }
    </Grid>
    <Grid item xs={12}>
      <Button variant="contained" component="label">
        UPLOAD FILE
        <VisuallyHiddenInput onChange={handleUploadFile} type="file" />
      </Button>
      <Typography variant="subtitle2">(By uploading a new image, you will overwrite the existing image.)</Typography>
    </Grid>
  </Grid>
  )
}

export default PhotoAdmin
import { Grid } from '@mui/material'
import { Link } from 'react-router-dom';
import CardCustom from './CardCustom'
import { cardsHome } from '../../utils/cardsHome';

const HomeCards = ({homeInfo}) => {

  return (
    <Grid container spacing={2}>
        {
            cardsHome.map(({title,defaultValue,homeInfoLabel,image},i)=>(
            <Grid key={i} item xs={6}>
                <Link to={`/${homeInfoLabel}`}>
                    <CardCustom title={title} value={homeInfo ? homeInfo[homeInfoLabel] : defaultValue} image={image}/>
                </Link>
            </Grid>
            ))
        }
    </Grid>
)
}

export default HomeCards
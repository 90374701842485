import { useState } from 'react';
import { Alert, Box, Button, Dialog, DialogActions, DialogContent, Grid, TextField, Typography } from '@mui/material';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import PhoneInTalkIcon from '@mui/icons-material/PhoneInTalk';
import MailIcon from '@mui/icons-material/Mail';
import TextSnippetIcon from '@mui/icons-material/TextSnippet';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import HowToVoteIcon from '@mui/icons-material/HowToVote';
import ThumbUpIcon from '@mui/icons-material/ThumbUp';
import ThumbDownIcon from '@mui/icons-material/ThumbDown';
import { deleteActivity, getActivityByApplicationId, updateActivity } from '../../../axios/axiosFunctions';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import SaveIcon from '@mui/icons-material/Save';

const History = ({activities,setCurrentActivities}) => {

    const [openActivity, setOpenActivity] = useState(false);
    const [activitySelected, setActivitySelected] = useState();
    const [editMode, setEditMode] = useState(false);
    const [showMessage, setShowMessage] = useState(false);
    const [severity, setSeverity] = useState("success");
    const [updateMessage, setUpdateMessage] = useState("Activity Updated");

    const handleClosePopUp = ()=>{
        setOpenActivity(false);
        setEditMode(false);
    }
    
    const handleSelectActivity = (activity)=>{
        setActivitySelected(activity);
        setOpenActivity(true);
    }

    const handleChangeForm = (e)=>{
        const {name,value} = e.target;
        setActivitySelected({...activitySelected, [name]:value});
    }

    const handleEditMode = async()=>{
        if(editMode){

            const data = {
                application:activitySelected.application,
                activity:activitySelected.activity,
                type:activitySelected.type,
                date:activitySelected.date,
                hour:activitySelected.hour,
                description:activitySelected.description
            }

            const resp = await updateActivity(activitySelected._id,data);
            setShowMessage(true);
            if(resp){
                setSeverity("success");
                setUpdateMessage("Activity Updated");
                setTimeout(() => {
                    setShowMessage(false);
                }, 2000);
                setEditMode(false);
                const activities = await getActivityByApplicationId(activitySelected.application);
                if(activities){
                    setCurrentActivities(activities.activities);
                }
                return;
            }
            setSeverity("Error");
            setUpdateMessage("Activity NOT Saved (Check internet connection or talk to a super admin)");
            setTimeout(() => {
                setShowMessage(false);
            }, 2000);
            setEditMode(false);
            return;

        }
        setEditMode(true);
    }

    const handleDeleteActivity = async()=>{
        await deleteActivity(activitySelected._id);
        const activities = await getActivityByApplicationId(activitySelected.application);
        if(activities){
            setCurrentActivities(activities.activities);
        }
        handleClosePopUp();
    }

    return (
    <Grid container spacing={2}>
        {
            activities?.map((activity,i)=>(
                    <Grid key={i} item xs={12} sx={{display:"flex",alignItems:"center"}}>
                        {
                            activity.type === "call" && <PhoneInTalkIcon sx={{marginRight:"20px"}}/>
                        }
                        {
                            activity.type === "email" && <MailIcon sx={{marginRight:"20px"}}/>
                        }
                        {
                            activity.type === "notes" && <TextSnippetIcon sx={{marginRight:"20px"}}/>
                        }
                        {
                            activity.type === "vote" && <HowToVoteIcon sx={{marginRight:"20px"}}/>
                        }
                        <Box sx={{border:"solid #E0E0E0",width:"100%",padding:"5px 10px",display:"flex",justifyContent:"space-between"}}>
                            <Box>
                                {
                                    activity.type !== "vote" ?
                                    <Typography variant='subtitle1'><b>{activity.activity}</b></Typography>
                                    :
                                    <Typography variant='subtitle1'><b>{activity.reviewer}</b></Typography>
                                }
                                <Typography color="customYellow.main" variant='subtitle2'>{activity.date}</Typography>
                            </Box>
                            <MoreHorizIcon onClick={()=>handleSelectActivity(activity)} sx={{cursor:"pointer"}}/>
                        </Box>
                    </Grid>
            ))
        }
        <Dialog open={openActivity} onClose={handleClosePopUp}>
            <DialogContent>
                <Grid container spacing={2} sx={{width:"100%"}}>
                    <Grid item xs={12}>
                        <TextField onChange={handleChangeForm} fullWidth disabled={!editMode} value={activitySelected?.activity} name="activity"/>
                    </Grid>
                    {
                        activitySelected?.type !== "vote" ?
                        <Grid item xs={12} sx={{display:"flex",alignItems:"center"}}>
                            <AccessTimeIcon/>
                            <TextField onChange={handleChangeForm} disabled={!editMode} value={activitySelected?.date} sx={{margin:"0px 10px"}} name="date"/>
                            <TextField onChange={handleChangeForm} disabled={!editMode} value={activitySelected?.hour} name="hour"/>
                        </Grid>
                        :
                        <Grid item xs={12} sx={{display:"flex",flexDirection:"column", alignItems:"flex-start"}}>
                            <Box>
                                <Typography variant='h6'>Desicion</Typography>
                                <Button disabled variant={activitySelected?.vote === "Yes" ? "contained":"outlined"} sx={{marginRight:"10px"}} endIcon={<ThumbUpIcon/>}>Vote</Button>
                                <Button disabled variant={activitySelected?.vote === "No" ? "contained":"outlined"} sx={{marginRight:"10px"}} endIcon={<ThumbDownIcon/>}>Vote</Button>
                            </Box>
                        </Grid>
                    }
                    <Grid item xs={12} sx={{display:"flex"}}>
                        <TextSnippetIcon sx={{marginRight:"10px"}}/>
                        <TextField onChange={handleChangeForm} disabled={!editMode} fullWidth value={activitySelected?.description} multiline rows={4}  name="description"/>
                    </Grid>
                    {
                        showMessage && 
                        <Grid item xs={12} sx={{display:"flex"}}>
                            <Alert severity={severity}>
                                {updateMessage}
                            </Alert>
                        </Grid>
                    }                
                </Grid>
            </DialogContent>
            <DialogActions>
                {
                    activitySelected?.type !== "vote" &&
                    <Button onClick={handleEditMode} variant="contained" color="secondary" startIcon={editMode?<SaveIcon/>:<EditIcon/>} >{editMode ? "SAVE" : "EDIT"}</Button>
                }
                {
                    editMode && <Button onClick={handleDeleteActivity} variant="contained" color="error" startIcon={<DeleteIcon/>} >DELETE</Button>
                }
            </DialogActions>
        </Dialog>
    </Grid>
    )
}

export default History
import { Alert, Button, Checkbox, FormControlLabel, FormGroup, Grid, MobileStepper, Radio, RadioGroup, TextField, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import BannerStep from "./BannerStep";

const formDefault = {
    hearAbout:[false,false,false,false,false,false,false],
    churchElaborate: "",
    otherElaborate:"",
    appliedTo:"",
    appliedToExtraInfo :"",
    whyAttend:"",
    godInYourLife:"",
    passions:"",
};

const SummitAndYou = ({data,updateApplication}) => {

    const navigate = useNavigate();
    const [activeStep, setActiveStep] = useState(0);
    const [showError, setShowError] = useState(false)
    const [summitAndYouForm, setSummitAndYouForm] = useState(formDefault);
    const steps = 5;
    const {
        hearAbout,
        churchElaborate,
        otherElaborate,
        appliedTo,
        appliedToExtraInfo,
        whyAttend,
        godInYourLife,
        passions
    } = summitAndYouForm;

    const [
        tsc,
        church,
        friendFamily,
        currentStudent,
        alumni,
        summitStudentAlumniEvent,
        other
    ] = hearAbout;

    useEffect(() => {
      if(data){
        setSummitAndYouForm({
            hearAbout: data.hearAbout,
            churchElaborate: data.churchElaborate || "",
            otherElaborate: data.otherElaborate || "",
            appliedTo: data.appliedTo || "",
            appliedToExtraInfo : data.appliedToExtraInfo || "",
            whyAttend: data.whyAttend || "",
            godInYourLife: data.godInYourLife || "",
            passions: data.passions || "",
        });
      }

    }, [data])
    
    
    const handleNext = () => {
        
        handleSubmit();
        if(!checkRequired()){
            setShowError(true);
            setTimeout(() => {
                setShowError(false);
            }, 2000);
            return;
        }

        if(activeStep === steps-1){
            navigate('/faith');
            return;
        }
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
    };
    
    const handleBack = () => {
        handleSubmit();
        if(activeStep === 0)
        {
            navigate('/personal');
            return;
        }
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const checkRequired = ()=>{
        if(activeStep === 0){
            let check1 =true;
            let check2 =true;
            if(hearAbout[1] || hearAbout[6]){
                check1 = hearAbout[1] && churchElaborate !== "";
                check2 = hearAbout[6] && otherElaborate !== "";
            }
            return (check1 || check2) && hearAbout.some((val)=>val===true)
        }
        if(activeStep === 1){
            return (appliedTo === "no" || (appliedTo === "yes" && appliedToExtraInfo !== ""))
        }
        if(activeStep === 2){
            return whyAttend !== ""
        }
        if(activeStep === 3){
            return godInYourLife !== ""
        }
        if(activeStep === 4){
            return passions !== ""
        }
        return true;
    }

    const handleCheckBox = ({target})=> {
        const {name,value} = target;
        if(name === "churchElaborate"){
            setSummitAndYouForm({...summitAndYouForm,churchElaborate:value})
            return;
        }
        if(name === "otherElaborate"){
            setSummitAndYouForm({...summitAndYouForm,otherElaborate:value})
            return;
        }
        const newHearAbout = [...hearAbout];
        newHearAbout[value] = !newHearAbout[value];
        setSummitAndYouForm({...summitAndYouForm,hearAbout:newHearAbout});
    }

    const handleChangeForm = (e)=>{
        const {name,value} = e.target;
        setSummitAndYouForm({...summitAndYouForm, [name]:value});
    }

    const handleSubmit= ()=>{
        
        const dataSubmit={
            summitAndYou:{
                ...summitAndYouForm
            }
        }
        
        updateApplication(dataSubmit);
    }

    return (
        <Grid container flexDirection="column-reverse">
            <Grid item xs={12}>
                <MobileStepper
                    variant="dots"
                    steps={steps}
                    position="static"
                    activeStep={activeStep}
                    sx={{ width:"100%", flexGrow: 1 , marginTop:"50px"}}
                    nextButton={
                        <Button size="small" onClick={handleNext}>
                            {activeStep === steps-1 ? 'Next' : 'Next' }
                        </Button>
                    }
                    backButton={
                        <Button size="small" onClick={handleBack}>
                            {activeStep === 0 ? 'Back' : 'Back'}
                        </Button>
                    }
                />
            </Grid>
            {
                showError &&
                <Grid item xs={12}>
                    <Alert severity='error' sx={{marginTop:"20px"}}>
                        One or more REQUIRED fields are empty.
                    </Alert>
                </Grid>
            }
            <Grid item xs={12}>
                <>
                    {
                        activeStep === 0 && <>
                            <Typography variant='subtitle1'>How did you hear about Summit?*</Typography>
                            <FormGroup onChange={handleCheckBox}>
                                <FormControlLabel checked={tsc} sx={{margin:{xs:"0px"}}} control={<Checkbox/>} value={0} label="Times Square Church" />
                                <FormControlLabel checked={church} sx={{margin:{xs:"0px"}}} control={<Checkbox/>} value={1} label="Church/Organization" />
                                {
                                    church && <>
                                        <Typography variant='subtitle1'>Name*</Typography>
                                        <TextField fullWidth onChange={handleChangeForm} value={churchElaborate} name="churchElaborate"/>
                                    </>
                                }
                                <FormControlLabel checked={friendFamily} sx={{margin:{xs:"0px"}}} control={<Checkbox/>} value={2} label="Friend/Family" />
                                <FormControlLabel checked={currentStudent} sx={{margin:{xs:"0px"}}} control={<Checkbox/>} value={3} label="Current Student" />
                                <FormControlLabel checked={alumni} sx={{margin:{xs:"0px"}}} control={<Checkbox/>} value={4} label="Alumni" />
                                <FormControlLabel checked={summitStudentAlumniEvent} sx={{margin:{xs:"0px"}}} control={<Checkbox/>} value={5} label="Summit Event" />
                                <FormControlLabel checked={other} sx={{margin:{xs:"0px"}}} control={<Checkbox/>} value={6} label="Other" />
                                {
                                    other && <>
                                        <TextField fullWidth onChange={handleChangeForm} inputProps={{maxLength:500}} helperText={`Max Length ${otherElaborate.length}/500`} value={otherElaborate} name="otherElaborate" multiline rows={4}/>
                                    </>
                                }
                            </FormGroup>
                        </>
                    }
                    {
                        activeStep === 1 && 
                            <Grid container flexDirection="column" spacing={3}>
                                <Grid item>
                                    <Typography variant='subtitle1'>Have you ever applied to Summit before?*</Typography>
                                </Grid>
                                <Grid item>
                                    <RadioGroup value={appliedTo} onChange={handleChangeForm}>
                                        <FormControlLabel value="yes" name="appliedTo" control={<Radio />} label="Yes" />
                                        <FormControlLabel value="no" name="appliedTo" control={<Radio />} label="No" />
                                    </RadioGroup>
                                </Grid>
                                {
                                    appliedTo === "yes" &&
                                    <Grid item>
                                        <Typography variant='subtitle1'>Please Elaborate*</Typography>
                                        <TextField onChange={handleChangeForm} inputProps={{maxLength:500}} helperText={`Max Length ${appliedToExtraInfo.length}/500`}  value={appliedToExtraInfo} name="appliedToExtraInfo" sx={{width:{xs:"100%",sm:"65%"}}} placeholder="Text" multiline rows={4}/>
                                    </Grid>
                                }
                            </Grid>
                    }
                    {
                        activeStep === 2 && 
                        <Grid container flexDirection="column" spacing={2}>
                            <Grid item>
                                <Typography variant='subtitle1'>Tell us why you want to attend Summit*</Typography>
                            </Grid>
                            <Grid item>
                                <TextField onChange={handleChangeForm} inputProps={{maxLength:500}} helperText={`Max Length ${whyAttend.length}/500`} value={whyAttend} name="whyAttend" sx={{width:{xs:"100%",sm:"65%"}}} placeholder="Text" multiline rows={4}/>
                            </Grid>
                        </Grid>   
                    }
                    {
                        activeStep === 3 &&
                        <Grid container flexDirection="column" spacing={2}>
                            <Grid item>
                                <Typography variant='subtitle1'>What do you want God to do in your life?*</Typography>
                            </Grid>
                            <Grid item>
                                <TextField onChange={handleChangeForm} inputProps={{maxLength:500}} helperText={`Max Length ${godInYourLife.length}/500`}  value={godInYourLife} name="godInYourLife" sx={{width:{xs:"100%",sm:"65%"}}} placeholder="Text" multiline rows={4}/>
                            </Grid>
                        </Grid>   
                    }
                    {
                        activeStep === 4 &&
                            <Grid container flexDirection="column" spacing={2}>
                                <Grid item>
                                    <Typography variant='subtitle1'>What are you passionate about?*</Typography>
                                </Grid>
                                <Grid item>
                                    <TextField onChange={handleChangeForm} inputProps={{maxLength:500}} helperText={`Max Length ${passions.length}/500`}  value={passions} name="passions" sx={{width:{xs:"100%",sm:"65%"}}} placeholder="Text" multiline rows={4} />
                                </Grid>
                            </Grid>   
                    }
                </>
            </Grid>
            <Grid item xs={12} sx={{margin:{xs:"0px 0px 50px 0px",sm:"50px 0px"}}}>
                <BannerStep title="Summit and you" stepNumber={activeStep+1} totalSteps={5}/>
            </Grid>
        </Grid>
    )
}

export default SummitAndYou
import { Box, Typography } from "@mui/material"

const BannerStep = ({title,stepNumber,totalSteps}) => {
  return (
    <Box sx={{ backgroundColor:"#E1A30B",padding:{xs:"20px 20px",sm:"20px 50px"}, color:"#FFFFFF",display:"flex",justifyContent:"space-between" }}>
        <Typography variant='h6'>{title}</Typography>
        <Typography variant='h6'>{stepNumber}/{totalSteps}</Typography>
    </Box>
  )
}

export default BannerStep
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import dayjs from 'dayjs';
import { Alert, Button, FormControlLabel, Grid, MobileStepper, Radio, RadioGroup, TextField, Typography } from "@mui/material";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import BannerStep from "./BannerStep";

const defaultWorkForm = {
  employed:"",
  last12Months:"",
  elaborateLast12Months:"",
  jobs:[{
    jobTitle:"",
    jobDescription:"",
    dateStart:"",
    dateEnd:""
  }],
  contactEmployer:"",
};

const Work = ({data,updateApplication}) => {

  const navigate = useNavigate();
  const [workForm, setWorkForm] = useState(defaultWorkForm);
  const [showError, setShowError] = useState(false);
  const [activeStep, setActiveStep] = useState(0);
  const steps = 1;

  const { employed,last12Months,elaborateLast12Months,jobs,contactEmployer} = workForm;

  useEffect(() => {
    if(data){
      setWorkForm({
        employed: data.employed || "",
        last12Months: data.last12Months || "",
        elaborateLast12Months: data.elaborateLast12Months || "",
        jobs: data.jobs.length === 0 ? [{jobTitle:"", jobDescription:"", dateStart:"", dateEnd:""}] : data.jobs,
        contactEmployer: data.contactEmployer || "",
      })
    }
  }, [data])
  

  const handleNext = () => {

    handleSubmit();
    if(!checkRequired()){
      setShowError(true);
      setTimeout(() => {
          setShowError(false);
      }, 2000);
      return;
    }

    if(activeStep === steps-1){
        navigate('/family');
        return;
    }
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
      handleSubmit();
      if(activeStep === 0)
      {
          navigate('/education');
          return;
      }
      setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const checkRequired = ()=>{
    if(activeStep === 0){
      return (employed === "yes" && contactEmployer !== "") || (employed === "no" && last12Months === "yes" && contactEmployer) || (employed === "no" && last12Months === "no" && elaborateLast12Months !== ""  && contactEmployer)
    }
    return true;
  }

  const handleChangeForm = (e)=>{
    const {name,value} = e.target;
    setWorkForm({...workForm, [name]:value});
  }

  const handleDateStart = ({$y,$M,$D},index)=>{
    const m = $M < 9 ? `0${$M+1}` : $M+1; 
    const d = $D < 10 ? `0${$D}` : $D;
    const newJobs = [...jobs];
    newJobs[index] = {
      ...jobs[index],
      dateStart:`${$y}-${m}-${d}`
    };
    setWorkForm({...workForm,jobs:newJobs}); 
  }

  const handleDateEnd = ({$y,$M,$D},index)=>{
    const m = $M < 9 ? `0${$M+1}` : $M+1; 
    const d = $D < 10 ? `0${$D}` : $D;
    const newJobs = [...jobs];
    newJobs[index] = {
      ...jobs[index],
      dateEnd:`${$y}-${m}-${d}`
    };
    setWorkForm({...workForm,jobs:newJobs}); 
  }

  const handleAddJob = ()=>{
    const newJobs = [...jobs];
    const newJob = {
      jobTitle:"",
      jobDescription:"",
      dateStart:"",
      dateEnd:""
    };
    newJobs.push(newJob);
    setWorkForm({...workForm,jobs:newJobs});
  }
  
  const handleRemove = (index)=>{
    const newJobs = [...jobs];
    newJobs.splice(index,1);
    setWorkForm({...workForm,jobs:newJobs});
  }
  
  const handleJobChange = (e,index)=>{
    const newJobs = [...jobs];
    const {name,value} = e.target;
    newJobs[index] = {
      ...jobs[index],
      [name]:value
    };
    setWorkForm({...workForm,jobs:newJobs});
  }

  const handleSubmit = async()=>{
    const dataSubmit={
      work:{
          ...workForm,
      }
    }
    updateApplication(dataSubmit);
  }
  
  return (
    <Grid container flexDirection="column-reverse">
      <Grid item xs={12}>
        <MobileStepper
            variant="dots"
            steps={steps}
            position="static"
            activeStep={activeStep}
            sx={{ width:"100%", flexGrow: 1 , marginTop:"50px"}}
            nextButton={
                <Button size="small" onClick={handleNext}>
                    {activeStep === steps-1 ? 'Next' : 'Next' }
                </Button>
            }
            backButton={
                <Button size="small" onClick={handleBack}>
                    {activeStep === 0 ? 'Back' : 'Back'}
                </Button>
            }
        />
      </Grid>
      {
        showError &&
        <Grid item xs={12}>
            <Alert severity='error' sx={{marginTop:"20px"}}>
                One or more REQUIRED fields are empty.
            </Alert>
        </Grid>
      }
      <Grid item xs={12}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography variant='subtitle1'>Are you currently employed?*</Typography> 
            <RadioGroup sx={{flexDirection:"row",marginBottom:"20px"}} value={employed} onChange={handleChangeForm}>
                <FormControlLabel value="yes" name="employed" control={<Radio />} label="Yes" />
                <FormControlLabel value="no" name="employed" control={<Radio />} label="No" />
            </RadioGroup> 
          </Grid>
          {
            employed === "no" &&
            <>
            <Grid item xs={12}>
              <Typography variant='subtitle1'>Have you been employed in the last 12 months?*</Typography> 
              <RadioGroup sx={{flexDirection:"row",marginBottom:"20px"}} value={last12Months} onChange={handleChangeForm}>
                  <FormControlLabel value="yes" name="last12Months" control={<Radio />} label="Yes" />
                  <FormControlLabel value="no" name="last12Months" control={<Radio />} label="No" />
              </RadioGroup> 
            </Grid>
            {
              last12Months === "no" &&
                <>
                  <Grid item xs={12}>
                      <Typography variant='subtitle1'>Please Elaborate*</Typography>
                  </Grid>
                  <Grid item xs={12}>
                      <TextField onChange={handleChangeForm} inputProps={{maxLength:500}} helperText={`Max Length ${elaborateLast12Months.length}/500`} value={elaborateLast12Months} name="elaborateLast12Months" sx={{width:{xs:"100%",sm:"65%"}}} placeholder="Text" multiline rows={4}/>
                  </Grid>
                </>
            }
            </>
          }
          <Grid item>
            <Typography>Please list your most recent job position or if you prefer, upload your resume containing all your work history.</Typography>
            <Typography variant='h6'>Job</Typography> 
            {
              jobs.map((job,i)=>(<Grid key={i} container spacing={2} sx={{marginTop:"0px"}}>
                <Grid item xs={12} sx={{display:"flex",flexDirection:"column"}}>
                  <Typography variant='subtitle1' >Job Title</Typography> 
                  <TextField required value={job.jobTitle} onChange={(e)=>handleJobChange(e,i)} name='jobTitle'/>
                </Grid>
                <Grid item xs={12}>
                  <Typography variant='subtitle1' >Job Description</Typography> 
                  <TextField value={job.jobDescription} inputProps={{maxLength:500}} helperText={`Max Length ${job.jobDescription.length}/500`} onChange={(e)=>handleJobChange(e,i)} name="jobDescription" fullWidth multiline rows={4}/>
                </Grid>
                <Grid item xs={12}>
                  <Typography variant='h6' >Dates Worked</Typography> 
                </Grid>
                <Grid item xs={12} sm={6}> 
                  <Typography variant='subtitle1' >Start date</Typography> 
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DatePicker onChange={(e)=>handleDateStart(e,i)} value={job.dateStart !== "" ? dayjs(job.dateStart):null} sx={{width:"100%"}}/>
                  </LocalizationProvider>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Typography variant='subtitle1' >End date</Typography> 
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DatePicker onChange={(e)=>handleDateEnd(e,i)} value={job.dateEnd !== "" ? dayjs(job.dateEnd):null} sx={{width:"100%"}}/>
                  </LocalizationProvider>
                </Grid>
                <Grid item>
                  {
                    jobs.length !== 1 &&
                    <Button variant="contained" color="customGray" sx={{marginRight:"20px"}} onClick={()=>handleRemove(i)}>REMOVE</Button>
                  }
                  <Button variant="contained" onClick={handleAddJob}>ADD JOB</Button>
                </Grid>
              </Grid>
              ))
            }
          </Grid>

          <Grid item xs={12}>
            <Typography variant='subtitle1' >May we contact your employer?*</Typography>
            <RadioGroup sx={{flexDirection:"row"}} value={contactEmployer} onChange={handleChangeForm}>
                <FormControlLabel value="yes" name="contactEmployer" control={<Radio />} label="Yes" />
                <FormControlLabel value="no" name="contactEmployer" control={<Radio />} label="No" />
            </RadioGroup>
          </Grid>
          {/* <Grid item sx={{display:"flex",flexDirection:"column"}}>
            <Typography variant='subtitle1' >Resume</Typography>
            {
              !fileUploaded ? 
              <Button variant="contained" component="label" startIcon={<CloudUploadIcon/>}>
                UPLOAD FILE
                <VisuallyHiddenInput onChange={handleUploadFile} type="file" />
              </Button>
              :
              <Button onClick={handleDeleteFile} variant="contained" component="label" startIcon={<AttachFileIcon/>} endIcon={<DeleteIcon/>}>
                FILE UPLOADED
              </Button>
            }
          </Grid> */}
        </Grid>
      </Grid>
      <Grid item xs={12} sx={{margin:{xs:"0px 0px 50px 0px",sm:"50px 0px"}}}>
        <BannerStep title="Work" stepNumber={activeStep+1} totalSteps={1}/>
      </Grid>
    </Grid>
  )
}

export default Work
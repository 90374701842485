import { Grid, Typography } from "@mui/material"
import { upperFirstLetter } from "../../../utils/qViewerFunctions"

const HealthAdmin = ({health}) => {

    return (
        <Grid container spacing={2}>
            <Grid item>
                <Typography variant='h5' color="customYellow.main" sx={{marginBottom:"30px"}}><b>Health</b></Typography>
                <Typography variant='subtitle1'><b>Are you currently on medication (either prescribed or non-prescribed)?</b></Typography>
                <Typography variant='subtitle1' sx={{marginLeft:"20px"}}>{upperFirstLetter(health?.onMedication)}</Typography>
                {
                    health?.onMedication === "yes" && <>
                        <Typography variant='subtitle1'><b>Please list any medications you take</b></Typography>
                        <Typography variant='subtitle1' sx={{marginLeft:"20px"}}>{health?.medicationList}</Typography>
                    </>
                }
                <Typography variant='subtitle1'><b>Do you have any learning limitations such as Dyslexia, ADD, ADHD, etc.?</b></Typography>
                <Typography variant='subtitle1' sx={{marginLeft:"20px"}}>{upperFirstLetter(health?.learningLimitation)}</Typography>
                {
                    health?.learningLimitation === "yes" && <>
                        <Typography variant='subtitle1'><b>Please describe</b></Typography>
                        <Typography variant='subtitle1' sx={{marginLeft:"20px"}}>{health?.explainLimitations}</Typography>
                    </>
                }
                <Typography variant='subtitle1'><b>Have you consumed alcohol in the last 12 months?</b></Typography>
                <Typography variant='subtitle1' sx={{marginLeft:"20px"}}>{upperFirstLetter(health?.consumedAlcohol)}</Typography>
                {
                    health?.consumedAlcohol === "yes" && <>
                        <Typography variant='subtitle1'><b>When was the last time and under what circumstance?</b></Typography>
                        <Typography variant='subtitle1' sx={{marginLeft:"20px"}}>{health?.lastTimeAlcohol}</Typography>
                    </>
                }
                <Typography variant='subtitle1'><b>Have you smoked cigarettes in the last 12 months?</b></Typography>
                <Typography variant='subtitle1' sx={{marginLeft:"20px"}}>{upperFirstLetter(health?.smoked)}</Typography>
                {
                    health?.smoked === "yes" && <>
                        <Typography variant='subtitle1'><b>When was the last time and under what circumstance?</b></Typography>
                        <Typography variant='subtitle1' sx={{marginLeft:"20px"}}>{health?.lastTimeSmoked}</Typography>
                    </>
                }
                <Typography variant='subtitle1'><b>Are you using or have you ever used drugs?</b></Typography>
                <Typography variant='subtitle1' sx={{marginLeft:"20px"}}>{upperFirstLetter(health?.drugs)}</Typography>
                {
                    health?.drugs === "yes" && <>
                        <Typography variant='subtitle1'><b>When was the last time and under what circumstance?</b></Typography>
                        <Typography variant='subtitle1' sx={{marginLeft:"20px"}}>{health?.lastTimeDrugs}</Typography>
                    </>
                }
                <Typography variant='subtitle1'><b>Have you struggled with self-destructive behavior in the last two years?</b></Typography>
                <Typography variant='subtitle1' sx={{marginLeft:"20px"}}>{upperFirstLetter(health?.selfDestructive)}</Typography>
                {
                    health?.selfDestructive === "yes" && <>
                        <Typography variant='subtitle1'><b>When was the last time and under what circumstance?</b></Typography>
                        <Typography variant='subtitle1' sx={{marginLeft:"20px"}}>{health?.explainSelfDestructive}</Typography>
                    </>
                }
                <Typography variant='subtitle1'><b>Have you been treated by a doctor for any emotional or mental health challeges?</b></Typography>
                <Typography variant='subtitle1' sx={{marginLeft:"20px"}}>{upperFirstLetter(health?.emotionalMentalHealth)}</Typography>
                {
                    health?.emotionalMentalHealth === "yes" && <>
                        <Typography variant='subtitle1'><b>Please describe</b></Typography>
                        <Typography variant='subtitle1' sx={{marginLeft:"20px"}}>{health?.explainMentaHealth}</Typography>
                    </>
                }
                <Typography variant='subtitle1'><b>Do you currently have a food allergy?</b></Typography>
                <Typography variant='subtitle1' sx={{marginLeft:"20px"}}>{upperFirstLetter(health?.foodAllergy)}</Typography>
                {
                    health?.foodAllergy === "yes" && <>
                        <Typography variant='subtitle1'><b>Please list all known food allergies</b></Typography>
                        <Typography variant='subtitle1' sx={{marginLeft:"20px"}}>{health?.foodList}</Typography>
                    </>
                }
                <Typography variant='subtitle1'><b>Is there anything else regarding your health you would like us to know?</b></Typography>
                <Typography variant='subtitle1' sx={{marginLeft:"20px"}}>{health?.healthChallenges}</Typography>
            </Grid>
        </Grid>
    )
}

export default HealthAdmin
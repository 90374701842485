import { useState } from "react";
import { Alert, Button, FormControl, Grid, IconButton, InputAdornment, InputLabel, MenuItem, OutlinedInput, Select, TextField, Typography } from "@mui/material";
import { Visibility, VisibilityOff } from '@mui/icons-material'
import { createUser } from "../../axios/axiosFunctions";

const defaultForm = {
  firstName: "",
  lastName : "",
  email:"",
  password:"",
  citizenship:"",
  phoneNumber:"Not set for counselor",
  contactWay:"",
  rol:"counselor"
}


const CreateUser = () => {

  const [createUserForm, setCreateUserForm] = useState(defaultForm);
  const {firstName,lastName,password,email,rol} = createUserForm;
  const [showPassword, setShowPassword] = useState(false);
  const [error, setError] =  useState("");
  const [showError, setShowError] = useState(false);
  const [severity, setSeverity] = useState("success");

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleChangeForm = (e)=>{
    const {name,value} = e.target;
    setCreateUserForm({...createUserForm, [name]:value});
  }

  const handleCreateUser = async()=>{
    const {user,msg} = await createUser(createUserForm);
    if(!user){
      setError(msg);
      setShowError(true);
      setSeverity("error");
      setTimeout(() => {
        setShowError(false);
      }, 2000);
    }else{
      setError("Account Created");
      setShowError(true);
      setSeverity("success");
      setTimeout(() => {
        setShowError(false);
      }, 2000);
      setCreateUserForm(defaultForm);
    }
  }

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Typography variant="h5"><b>Create Counselor/Reviewer</b></Typography>
      </Grid>
      <Grid item xs={12}>
        <form>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Select onChange={handleChangeForm} value={rol} name='rol' fullWidth>
                <MenuItem value="counselor">Counselor</MenuItem>
                <MenuItem value="reviewer">Reviewer</MenuItem>
                <MenuItem value="scholarshipCommitee">Scholarship Committee</MenuItem>
              </Select>
            </Grid>
            <Grid item xs={6}>
              <TextField fullWidth onChange={handleChangeForm} value={firstName} name='firstName' label="First name"/>
            </Grid>
            <Grid item xs={6}>
              <TextField fullWidth onChange={handleChangeForm} value={lastName} name='lastName' label="Last name / Surname"/>
            </Grid>
            <Grid item xs={6}>
              <TextField fullWidth onChange={handleChangeForm} value={email} name='email' label="Email"/>
            </Grid>
            <Grid item xs={6}>
              <FormControl fullWidth variant="outlined"> 
                <InputLabel>Password</InputLabel>
                <OutlinedInput
                  type={showPassword ? 'text' : 'password'} 
                  fullWidth
                  label="Password*"
                  name='password'
                  value={password}
                  required
                  onChange={handleChangeForm}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        onClick={handleClickShowPassword}
                      >
                        {showPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  }
                />
              </FormControl>
            </Grid>
          </Grid>
        </form>
      </Grid>
      {
        showError &&
        <Grid item xs={12}>
          <Alert sx={{marginTop:"20px"}} severity={severity}>
            {error}
          </Alert>
        </Grid>
      }
      <Grid item xs={12}>
        <Button variant="contained" color="customYellow" onClick={handleCreateUser}>CREATE</Button>
      </Grid>
    </Grid>
  )
}

export default CreateUser
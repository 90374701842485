import { useState } from "react";
import { Box, Button, Dialog, DialogContent, Typography } from "@mui/material";
import { updateStatusScholarshipColumn } from "../../axios/axiosFunctions";
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import HighlightOffSharpIcon from '@mui/icons-material/HighlightOffSharp';

const DecisionScholarshipColumn = (props) => {

    const [openDialog, setOpenDialog] = useState(false);
    const [openDialogReject, setOpenDialogReject] = useState(false);

    const handleCloseDialog = ()=>{
        setOpenDialog(false);
    }

    const handleCloseDialogReject = ()=>{
        setOpenDialogReject(false);
    }

    const handleCheck = async()=>{
        await updateStatusScholarshipColumn(props.id,{scholarshipStatus:"Approved"});
        setOpenDialog(false);
    }
    
    const handleReject = async()=>{
        await updateStatusScholarshipColumn(props.id,{scholarshipStatus:"Declined"});
        setOpenDialogReject(false);
    }

    return (
        <Box sx={{width: "100%",height: "100%",display: "flex",justifyContent: "space-evenly", alignItems: "center"}}>
            <CheckCircleOutlineIcon onClick={()=>setOpenDialog(true)} sx={{cursor:"pointer",color:"#00C853"}}/>
            <HighlightOffSharpIcon onClick={()=>setOpenDialogReject(true)} sx={{cursor:"pointer",color:"#FF3838"}}/>
            
            <Dialog open={openDialog} onClose={handleCloseDialog}>
                <DialogContent>
                    <Box>
                        <Typography variant='h6'>Are you sure you want to ACCEPT the scholarship?</Typography>
                        <Box sx={{display:"flex",justifyContent:"center",marginTop:"20px"}}>
                            <Button variant='contained' color="customGray" sx={{marginRight:"20px"}} onClick={handleCheck}>Yes</Button>
                            <Button variant='contained' onClick={handleCloseDialog}>No</Button>
                        </Box>
                    </Box>
                </DialogContent>
            </Dialog>
            
            <Dialog open={openDialogReject} onClose={handleCloseDialogReject}>
                <DialogContent>
                    <Box>
                        <Typography variant='h6'>Are you sure you want to DECLINE the scholarship?</Typography>
                        <Box sx={{display:"flex",justifyContent:"center",marginTop:"20px"}}>
                            <Button variant='contained' color="customGray" sx={{marginRight:"20px"}} onClick={handleReject}>Yes</Button>
                            <Button variant='contained' onClick={handleCloseDialogReject}>No</Button>
                        </Box>
                    </Box>
                </DialogContent>
            </Dialog>
        </Box>
    )
}

export default DecisionScholarshipColumn
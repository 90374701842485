import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Button, Checkbox, FormControlLabel, FormGroup, Grid, MobileStepper, TextField, Typography } from "@mui/material";
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import BannerStep from "./BannerStep";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import dayjs from 'dayjs';

const defaultForm = {
    fullName: "",
    dateOfBirth: "",
    address: "",
    city: "",
    state: "",
    ZIP: "",
    phoneNumber: "",
    contactName: "",
    contactPhone: "",
    medicalRelease: false,
    transpRelease: false,
}

const ConfirmedData = ({medicalAndTransportation,updateApplication,appId}) => {
    
    const steps = 1;
    const navigate = useNavigate();
    const [activeStep, setActiveStep] = useState(0);
    const [medAndTranspForm, setMedAndTranspForm] = useState(defaultForm);
    const { fullName,dateOfBirth,address,city,state,ZIP,phoneNumber,contactName,contactPhone,medicalRelease,transpRelease}  = medAndTranspForm;

    useEffect(() => {
      if(medicalAndTransportation){
        setMedAndTranspForm({
            fullName: medicalAndTransportation.fullName,
            dateOfBirth: medicalAndTransportation.dateOfBirth,
            address: medicalAndTransportation.address,
            city: medicalAndTransportation.city,
            state: medicalAndTransportation.state,
            ZIP: medicalAndTransportation.ZIP,
            phoneNumber: medicalAndTransportation.phoneNumber,
            contactName: medicalAndTransportation.contactName,
            contactPhone: medicalAndTransportation.contactPhone,
            medicalRelease: medicalAndTransportation.medicalRelease,
            transpRelease: medicalAndTransportation.transpRelease,
        })
      }
    }, [medicalAndTransportation])
    

    const saveApplication = async()=>{
        const data = {
            medicalAndTransportation:{
                ...medAndTranspForm
            }
        }
        await updateApplication(data);
    }

    const handleChangeForm = (e)=>{
        const {name,value} = e.target;
        setMedAndTranspForm({...medAndTranspForm, [name]:value});
    }

    const handleDateofBirth = ({$y,$M,$D})=>{
        const m = $M < 9 ? `0${$M+1}` : $M+1; 
        const d = $D < 10 ? `0${$D}` : $D;
        setMedAndTranspForm({...medAndTranspForm, dateOfBirth:`${$y}-${m}-${d}`});
    }

    const handleRelease = ({target})=>{
        const {name} = target;
        setMedAndTranspForm({...medAndTranspForm,[name]:!medAndTranspForm[name]})
    }

    const handleNext = () => {
        if(activeStep === steps-1){
            navigate('/medical_records');
            saveApplication();
            return;
        }
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
    };

    const handleBack = () => {
        if(activeStep === 0)
        {
            navigate('/scholarship');
            return;
        }
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    return (
        <Grid container flexDirection="column-reverse">
            <Grid item xs={12}>
                <MobileStepper
                    variant="dots"
                    steps={steps}
                    position="static"
                    activeStep={activeStep}
                    sx={{ width:"100%", flexGrow: 1 , marginTop:"50px"}}
                    nextButton={
                        <Button size="small" onClick={handleNext}>
                            {activeStep === steps-1 ? 'Next' : 'Next' }
                        </Button>
                    }
                    backButton={
                        <Button size="small" onClick={handleBack}>
                            {activeStep === 0 ? 'Back' : 'Back'}
                        </Button>
                    }
                />
            </Grid>
            <Grid item xs={12}>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <Typography variant='subtitle1'>Full Name*</Typography>
                        <TextField required fullWidth value={fullName} onChange={handleChangeForm} name='fullName'/>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography variant='subtitle1'>Date of Birth*</Typography>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DatePicker onChange={handleDateofBirth} value={ dateOfBirth !== "" ? dayjs(dateOfBirth) : null}  sx={{width:"100%"}}/>
                        </LocalizationProvider>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography variant='subtitle1'>Address*</Typography>
                        <TextField required fullWidth value={address} onChange={handleChangeForm} name='address'/>
                    </Grid>
                    <Grid item xs={6}>
                        <Typography variant='subtitle1'>City*</Typography>
                        <TextField required fullWidth value={city} onChange={handleChangeForm} name='city'/>
                    </Grid>
                    <Grid item xs={6}>
                        <Typography variant='subtitle1'>State*</Typography>
                        <TextField required fullWidth value={state} onChange={handleChangeForm} name='state'/>
                    </Grid>
                    <Grid item xs={6}>
                        <Typography variant='subtitle1'>ZIP*</Typography>
                        <TextField required fullWidth value={ZIP} onChange={handleChangeForm} name='ZIP'/>
                    </Grid>
                    <Grid item xs={6}>
                        <Typography variant='subtitle1'>Phone Number*</Typography>
                        <TextField required fullWidth value={phoneNumber} onChange={handleChangeForm} name='phoneNumber'/>
                    </Grid>
                    <Grid item xs={6}>
                        <Typography variant='subtitle1'>Emergency Contact Name*</Typography>
                        <TextField required fullWidth value={contactName} onChange={handleChangeForm} name='contactName'/>
                    </Grid>
                    <Grid item xs={6}>
                        <Typography variant='subtitle1'>Emergency Contact Phone*</Typography>
                        <TextField required fullWidth value={contactPhone} onChange={handleChangeForm} name='contactPhone'/>
                    </Grid>
                    <Grid item xs={12}>
                        <FormGroup onChange={handleRelease}>
                            <Typography variant='h6'>Medical Release</Typography>
                            <FormControlLabel checked={medicalRelease} name ="medicalRelease" required control={<Checkbox/>} label="I hereby release Summit International School of Ministry from any liability for injuries, accidents, or illnesses that may occur during my participation in school activities. This includes, but is not limited to, participation in on-campus activities, gymnasium and field use, off-campus events, field trips, outings, and any potential exposure to illness while in attendance. I grant consent for medical treatment by a qualified healthcare provider and authorize Summit International School of Ministry to seek emergency medical care on my behalf if I am unconscious or otherwise unable to consent. I understand that the school will make reasonable efforts to contact my designated emergency contact in the event of an emergency."/>
                            <Typography variant='h6'>Transportation Liability Release</Typography>
                            <FormControlLabel checked={transpRelease} name ="transpRelease" required control={<Checkbox/>} label="I understand that Summit International School of Ministry may provide or arrange transportation for off-campus activities, field trips, and other school-related events. I acknowledge that participation in such activities involves inherent risks, including transportation-related accidents or injuries. By checking this box, I voluntarily release and hold harmless Summit International School of Ministry, its staff, representatives, and affiliated organizations from any liability arising from transportation-related incidents, including but not limited to injuries, property damage, or other losses. I acknowledge that I am responsible for my own safety and well-being during all school-related activities and transportation provided by the school. "/>
                        </FormGroup>
                    </Grid>
                </Grid>
            </Grid>
            <Grid item xs={12} sx={{margin:{xs:"0px 0px 50px 0px",sm:"50px 0px"}}}>
                <BannerStep title="Medical and Transportation Liability" stepNumber={activeStep+1} totalSteps={1}/>
            </Grid>
        </Grid>
    )
}

export default ConfirmedData
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Box, Button, Grid, Typography } from "@mui/material"

const MedicalRecordsAdmin = ({immunoInfo,physicalInfo}) => {

    const BASE_URL = "https://summit-app-edgar.s3.eu-north-1.amazonaws.com/";
    const [fileType, setFileType] = useState();
    const [fileType2, setFileType2] = useState();
    const [urlImmuno, setUrlImmuno] = useState(BASE_URL + immunoInfo.image);
    const [urlPhysical, setUrlPhysical] = useState(BASE_URL + physicalInfo.image);

    useEffect(() => {
        if(immunoInfo){
            const type = immunoInfo.image.split(".").pop();
            setFileType(type);
            const folderNameImmuno = immunoInfo.image.split("/")[0];
            if (folderNameImmuno !== "applications") {
                setUrlImmuno(BASE_URL + "applicants/" + immunoInfo.image);
            }
        }
        if(physicalInfo){
            const type2 = physicalInfo.image.split(".").pop();
            setFileType2(type2);
            const folderNamePhysical = physicalInfo.image.split("/")[0];
            if (folderNamePhysical !== "applications") {
                setUrlPhysical(BASE_URL + "applicants/" + physicalInfo.image);
            }
        }
    }, [immunoInfo,physicalInfo]);

    return (
    <Grid container spacing={2}>
        <Grid item>
            <Typography variant='h5' color="customYellow.main" sx={{marginBottom:"30px"}}><b>Medical Records</b></Typography>
            <Box sx={{width:"400px"}}>
            {
                fileType && fileType !== "" && <>
                <Typography variant='subtitle1'><b>Immunization Records (which includes two MMR & three Hepatitis B)</b></Typography>
                {
                    fileType !== "pdf" && fileType !== "docx" && <img src={urlImmuno} alt="Immunization Records" style={{width:"100%"}}/>
                }
                <Link to={urlImmuno} target="_blank">
                    <Button variant="contained">
                    DOWNLOAD FILE
                    </Button>
                </Link>
                </>
            }
            {
                fileType2 && fileType2 !== "" && <>
                <Typography variant='subtitle1'><b>Physical Examination Report</b></Typography>
                {
                    fileType2 !== "pdf" && fileType2 !== "docx" && <img src={urlPhysical} alt="Physical Examination Report" style={{width:"100%"}}/>
                }
                <Link to={urlPhysical} target="_blank">
                    <Button variant="contained">
                    DOWNLOAD FILE
                    </Button>
                </Link>
                </>
            }
            </Box>
        </Grid>
    </Grid>
)
}

export default MedicalRecordsAdmin
import { Grid } from "@mui/material";
import { useState } from "react";
import SStepsAdmin from "./SStepsAdmin";
import ReviewAndSubmitAdmin from "./QViewerTabs/ReviewAndSubmitAdmin";
import FeesAdmin from "./QViewerTabs/FeesAdmin";
import ExtraAdmin from "./QViewerTabs/ExtraAdmin";

const SubmitAdmin = ({application}) => {

    const [currentTab, setCurrentTab] = useState('review_and_submit');
    
    return (
        <Grid container spacing={2}>
            <Grid item xs={3} sx={{borderRight:"solid #999999",paddingRight:"30px"}}>
                <SStepsAdmin application={application} setCurrentTab={setCurrentTab}/>
            </Grid>
            <Grid item xs={9}>
                {
                    currentTab === "review_and_submit" && <ReviewAndSubmitAdmin reviewAndSubmit={application?.reviewAndSubmit} />
                }
                {
                    currentTab === "fees" && <FeesAdmin fees={application?.fees} appId={application?._id}/>
                }
                {
                    currentTab === "extra" && <ExtraAdmin immunoInfo={application?.immunoRecord} physicalInfo={application?.physicalExamination} />
                }
            </Grid>
        </Grid>
    )
}

export default SubmitAdmin
import { Link } from "react-router-dom";
import { List, ListItem, ListItemButton, ListItemText, Typography } from "@mui/material"
import {documentsSteps} from '../utils/documentsSteps.js';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';

const Documents = ({active,app}) => {

    return (
        <>
            <Typography variant="h5">Documents:</Typography>
            <List sx={{width:"100%"}}>
                {
                    documentsSteps.map(({label,uploadedLabel,route},i)=>(
                        <Link to={`/${route}`} key={i}>
                            <ListItem sx={{padding:{xs:"0px"},borderBottom:"1px solid #9E9E9E",backgroundColor:active ===route?'customYellow.main':'white'}}>
                                <ListItemButton>
                                    <ListItemText primary={label}/>
                                    <Typography edge="end">{ app ? app[uploadedLabel]?.uploaded ? <CheckCircleIcon/> : "" : "" }</Typography>
                                </ListItemButton>
                            </ListItem>
                        </Link>
                    ))
                }
            </List>
        </>
    )
}

export default Documents
import { useEffect, useState } from "react"
import { Grid, Typography } from "@mui/material"
import { getAllConsultants } from "../../axios/axiosFunctions";
import UsersListCards from "./UsersListCards";

const UsersList = () => {

    const [usersList, setUsersList] = useState([]);
    
    useEffect(() => {
        const getList = async()=>{
            const resp = await getAllConsultants();
            setUsersList(resp);
        }
        getList();
    }, [])
    

    return (
    <Grid container spacing={2}>
        <Grid item xs={12}>
            <Typography variant="h5"><b>Counselors and Review Committee List:</b></Typography>
        </Grid>
        {
            usersList.filter((u)=>u.rol !== "admin").map((user,i)=>(
                <Grid key={i} item xs={12}>
                    <UsersListCards user={user} setUsersList={setUsersList}/>
                </Grid>
            ))
        }
    </Grid>
)
}

export default UsersList
import { Box, Button, FormLabel, Grid, MobileStepper, Typography } from "@mui/material"
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import BannerStep from "./BannerStep";

const defaultTranscriptButton = false;

const Transcripts = ({data,updateApplication}) => {

    const navigate = useNavigate();
    const [transcriptsButton, setTranscriptsButton] = useState(defaultTranscriptButton);
    const [activeStep, setActiveStep] = useState(0);
    const steps = 1;

    useEffect(() => {
      if(data){
        setTranscriptsButton(data.uploaded);
      }
    }, [data])
    

    const handleNext = () => {
        handleSubmit();
        if(activeStep === steps-1){
            navigate('/references');
            return;
        }
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
    };

    const handleBack = () => {
        handleSubmit();
        if(activeStep === 0)
        {
            navigate('/photo');
            return;
        }
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const handleCheckBox = ()=>{
        setTranscriptsButton(!transcriptsButton);
    }

    const handleSubmit = ()=>{
        const dataSubmit={
            transcripts:{
                uploaded:transcriptsButton 
            }
          }
          updateApplication(dataSubmit);
    }
    

    return (
        <Grid container flexDirection="column-reverse">
            <Grid item xs={12}>
                <MobileStepper
                    variant="dots"
                    steps={steps}
                    position="static"
                    activeStep={activeStep}
                    sx={{ width:"100%", flexGrow: 1 , marginTop:"50px"}}
                    nextButton={
                        <Button size="small" onClick={handleNext}>
                            {activeStep === steps-1 ? 'Next' : 'Next' }
                        </Button>
                    }
                    backButton={
                        <Button size="small" onClick={handleBack}>
                            {activeStep === 0 ? 'Back' : 'Back'}
                        </Button>
                    }
                />
            </Grid>
            <Grid item xs={12}>
                <Box sx={{display:"flex",flexDirection:"column",alignItems:"flex-start"}}>
                    <Typography variant='h6'><b>Submitting a Transcript</b></Typography>
                    <Typography variant="subtitle1" sx={{margin:"10px 0px 30px 0px"}}>Have your most recent institution of education submit an official copy of your transcript or GED equivalent by one of two ways:</Typography>
                    <Grid container spacing={5}>
                        <Grid item xs={1}>
                            <Box sx={{display:"flex",borderRadius:"50%",width:"40px",height:"40px",backgroundColor:"#1A2B50",alignItems:"center",justifyContent:"space-around",color:"white"}}>
                                <Typography variant="h5">1</Typography>
                            </Box>
                        </Grid>
                        <Grid item xs={11} sx={{display:"flex",alignItems:"center"}}>
                            <Typography variant="subtitle1">Mail an official transcript directly from your institution to: <span style={{color:"#FBBD08"}}>74 Harrison School Rd Grantville, PA 17028 | Attn: Admissions Department. </span> </Typography>
                        </Grid>
                        <Grid item xs={1}>
                            <Box sx={{display:"flex",borderRadius:"50%",width:"40px",height:"40px",backgroundColor:"#1A2B50",alignItems:"center",justifyContent:"space-around",color:"white"}}>
                                <Typography variant="h5">2</Typography>
                            </Box>
                        </Grid>
                        <Grid item xs={11} sx={{display:"flex",alignItems:"center"}}>
                            <Typography variant="subtitle1">
                                Send official transcripts electronically as a secure PDF. Your institution can send electronic transcripts directly to Summit, or you may use a secure third-party delivery service such as Parchment Exchange (<span style={{color:"#FBBD08"}}>www.parchment.com</span>).
                                Please use the email <span style={{color:"#FBBD08"}}>admissions@summitpa.org</span>.
                            </Typography>
                        </Grid>
                    </Grid>
                    <Box sx={{marginTop:"20px"}}>
                        <FormLabel>Please do not check the box below until you have received confirmation of your transcript request. <br/><br/>
                            As soon as you have requested your transcript select the checkbox below.<br/><br/>
                            When we receive your transcript we will mark it as received and this step will automatically be complete.
                        </FormLabel>
                    </Box>
                    {
                        transcriptsButton ?
                        <Button onClick={handleCheckBox} variant="text" sx={{padding:"10px 40px 10px 5px",marginTop:"30px"}} startIcon={<CheckBoxIcon/>}>I have requested my transcript</Button>
                        :
                        <Button onClick={handleCheckBox} variant="text" sx={{padding:"10px 40px 10px 5px",marginTop:"30px"}} startIcon={<CheckBoxOutlineBlankIcon/>}>I have requested my transcript</Button>
                    }
                </Box>
            </Grid>
            <Grid item xs={12} sx={{margin:{xs:"0px 0px 50px 0px",sm:"50px 0px"}}}>
                <BannerStep title="Transcripts" stepNumber={activeStep+1} totalSteps={1}/>
            </Grid>
        </Grid>
    )
}

export default Transcripts
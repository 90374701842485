import { Box, Button, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";

const PhotoAdmin = ({appId,fileName}) => {

    const BASE_URL = "https://summit-app-edgar.s3.eu-north-1.amazonaws.com/";
    const [fileType, setFileType] = useState();
    const [url, setUrl] = useState(BASE_URL + fileName);


    useEffect(() => {
      const type = fileName.split(".").pop();
      const folderName = fileName.split("/")[0];
      setFileType(type);
      if (folderName !== "applications") {
        setUrl(BASE_URL + "applicants/" + fileName);
      }
    }, [fileName]);

/*     const handleDownload = (url,fileName)=>{                       BUTTON DOWLOAD FILE
      fetch(url).then(response=>response.blob()).then(blob=>{
        const blobURL = window.URL.createObjectURL(new Blob([blob]));
        const aTag = document.createElement("a");
        aTag.href = blobURL;
        aTag.setAttribute("download",fileName);
        document.body.appendChild(aTag);
        aTag.click();
        aTag.remove();
      })
    }
 */
    return (
        <>
            <Typography variant='h5' color="customYellow.main" sx={{marginBottom:"30px"}}><b>Photo</b></Typography>
            <Box sx={{width:"400px"}}>
              {
                fileType && fileType !== "" && <>
                {
                    fileType !== "pdf" && 
                    <img src={url} alt="School Id" style={{width:"100%"}}/>
                }
                <Link target="_blank" to={url}>
                  <Button variant="contained">
                    DOWNLOAD FILE
                  </Button>
                </Link> 
                </>
              }
            </Box>
        </>
      )
}

export default PhotoAdmin
import { Box, Typography } from "@mui/material"
import { useEffect, useState } from "react"

const ScholarshipColumn = (props) => {
    
    const [backgroundColor, setBackgroundColor] = useState("#8AB5DE");
    const [displayText,] = useState(props.value);

    useEffect(() => {
      switch (props.value) {
        case "N/A":
            setBackgroundColor("#CBD5E1");
            break;
        case "Approved":
            setBackgroundColor("#237FD3");
            break;
        case "Declined":
            setBackgroundColor("#477299");
            break;
        default:
            setBackgroundColor("#8AB5DE");
            break;
      }
    }, [props.value])

    return (
        <Box sx={{display:"flex",width:"100%",height:"100%",alignItems:"center"}}>
            <Box sx={{width:"80%",height:"80%",display:"flex",justifyContent:"center",alignItems:"center", backgroundColor:backgroundColor,color:"white",borderRadius:"12px"}}>
                <Typography variant='subtitle1'>
                    {displayText}
                </Typography>
            </Box>
        </Box>
    )
}

export default ScholarshipColumn
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { styled } from '@mui/material/styles';
import { Box, Button, FormLabel, Grid, List, ListItem, MobileStepper, Typography } from '@mui/material';
import PhotoDefault from '../assets/images/PhotoDefault.png';
import StudenIdExample from '../assets/images/StudenIdExample.png';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import { uploadPhoto } from '../axios/axiosFunctions';
import DocumentCard from './DocumentCard';
import BannerStep from './BannerStep';

const VisuallyHiddenInput = styled('input')({
    clip: 'rect(0 0 0 0)',
    clipPath: 'inset(50%)',
    height: 1,
    overflow: 'hidden',
    position: 'absolute',
    bottom: 0,
    left: 0,
    whiteSpace: 'nowrap',
    width: 1,
});
    
const Photo = ({data,updateApplication,appId}) => {

    const navigate = useNavigate();
    const [activeStep, setActiveStep] = useState(0);
    const [fileUploaded, setFileUploaded] = useState(false);
    const [imageName, setImageName] = useState("");
    const steps = 1;

    useEffect(() => {
        if(data){
          setFileUploaded(data.uploaded || false);
          setImageName(data.image || ""); 
        } 
      }, [data])

    const handleNext = () => {
        if(activeStep === steps-1){
            navigate('/transcripts');
            return;
        }
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
    };

    const handleBack = () => {
        if(activeStep === 0)
        {
            navigate('/id_passport');
            return;
        }
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const handleUploadFile = (e)=>{
        const {length} = e.target.files;
        if(length !== 0){
            handleSubmit(true,e.target.files[0],imageName);
            setFileUploaded(true);
        }
    }

    const handleDeleteFile = ()=>{
        setFileUploaded(false);
        setImageName("");
        handleSubmit(false,undefined,"");
    }

    const handleSubmit = async(uploaded,submitFile,imgName)=>{ 
        let newImageName = imgName;
        if(uploaded && submitFile){
            const formData = new FormData();
            formData.append('fileImage',submitFile);
            const {name} = await uploadPhoto(formData,appId);
            newImageName = name;
        }

        const dataSubmit = {
            photo:{
                uploaded,
                image: newImageName
            }
        }

        updateApplication(dataSubmit);

    }

    return (
        <Grid container flexDirection="column-reverse">
            <Grid item xs={12}>
                <MobileStepper
                    variant="dots"
                    steps={steps}
                    position="static"
                    activeStep={activeStep}
                    sx={{ width:"100%", flexGrow: 1 , marginTop:"50px"}}
                    nextButton={
                        <Button size="small" onClick={handleNext}>
                            {activeStep === steps-1 ? 'Next' : 'Next' }
                        </Button>
                    }
                    backButton={
                        <Button size="small" onClick={handleBack}>
                            {activeStep === 0 ? 'Back' : 'Back'}
                        </Button>
                    }
                />
            </Grid>
            <Grid item xs={12}>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <Typography variant='h6'>Requirements for a Profile Photos</Typography>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <Box sx={{display:"flex",alignItems:"flex-start",marginTop:"30px"}}>
                            <img src={PhotoDefault} alt='defaultGrayPhoto' style={{marginRight:"30px"}} />
                            <List sx={{ listStyleType: 'disc',padding:"0px" }}>
                                <ListItem sx={{ display: 'list-item',padding:"0px 16px",color:"#1B1F26B8",fontSize:"0.9rem"}}>
                                    Submit a high resolution photo that is not blurry, grainy or pixelated.
                                </ListItem>
                                <ListItem sx={{ display: 'list-item',padding:"5px 16px",color:"#1B1F26B8",fontSize:"0.9rem"}}>
                                    Face the camera directly with full face in view.
                                </ListItem>
                                <ListItem sx={{ display: 'list-item',padding:"5px 16px",color:"#1B1F26B8",fontSize:"0.9rem"}}>
                                    Make sure the face is in the center of the photo.
                                </ListItem>
                                <ListItem sx={{ display: 'list-item',padding:"5px 16px",color:"#1B1F26B8",fontSize:"0.9rem"}}>
                                    Preferably the photo is taken against a light background.
                                </ListItem>
                                <ListItem sx={{ display: 'list-item',padding:"5px 16px",color:"#1B1F26B8",fontSize:"0.9rem"}}>
                                    No sunglasses in photo.
                                </ListItem>
                            </List>
                        </Box>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <Box sx={{display:"flex",alignItems:"flex-start",marginTop:"30px"}}>
                            <img src={StudenIdExample} alt="Studen Id (Example)" style={{marginRight:"30px"}}/>
                            <FormLabel sx={{marginBottom:"20px"}} >One file only.<br/>
                                325 MB limit.<br/>
                                Allowed types: png, gif, jpg, jpeg.<br/>
                                Images must be larger than 400x400 pixels.
                            </FormLabel>
                        </Box>
                    </Grid>
                    <Grid item xs={12}>
                        {
                            !fileUploaded ? 
                            <Button variant="contained" component="label" startIcon={<CloudUploadIcon/>}>
                                UPLOAD FILE
                                <VisuallyHiddenInput onChange={handleUploadFile} type="file" />
                            </Button>
                            :
                            <DocumentCard name="PHOTO UPLOADED" deleteFile={handleDeleteFile}/>
                        }
                    </Grid>
                </Grid>
            </Grid>
            <Grid item xs={12} sx={{margin:{xs:"0px 0px 50px 0px",sm:"50px 0px"}}}>
                <BannerStep title="Photo" stepNumber={activeStep+1} totalSteps={1}/>
            </Grid>
        </Grid>
    )
}

export default Photo
import { useEffect, useState } from 'react';
import { Box, Button, Dialog, DialogContent, MenuItem, Select, Typography } from '@mui/material';
import { updateStatusApp, updateVisaColumn } from '../../axios/axiosFunctions';
import { useNavigate } from 'react-router-dom';

const VisaColumn = (props) => {
    
    const navigate = useNavigate();
    const [currentValue, setCurrentValue] = useState(props.value);
    const [openDialog, setOpenDialog] = useState(false);
    const [openDialogReject, setOpenDialogReject] = useState(false);

    useEffect(() => {
        if(props.row.fee && currentValue === "issued"){
          setOpenDialog(true);
        }
        if(currentValue === "declined"){
            setOpenDialogReject(true);
        }
    }, [props.row,currentValue]);

    const handleCloseDialog = ()=>{
        setOpenDialog(false);
    }

    const handleCloseDialogReject = ()=>{
        setOpenDialogReject(false);
    }

    const handleSelectChange = async(e)=>{
        const {value} = e.target;
        setCurrentValue(value);
        const data = {
            visa: value
        }
        await updateVisaColumn(props.row.id,data);
        props.api.updateRows([{id:props.row.id,visa:value}]);
    }

    const handleUpdateStatus = async(status)=>{
        await updateStatusApp(props.id,{statusApp:status});
        setOpenDialog(false);
        setOpenDialogReject(false);
        navigate(0);
    }

    return (
        <Box>
            {
                currentValue !== "NOT VISA" &&<>
                    <Select label="Select" onChange={handleSelectChange} value={currentValue} fullWidth sx={{boxShadow: 'none', '.MuiOutlinedInput-notchedOutline': { border: 0 }}} >
                        <MenuItem value="issued">Issued</MenuItem>
                        <MenuItem value="declined">Declined</MenuItem>
                    </Select>
                    <Dialog open={openDialog} onClose={handleCloseDialog}>
                        <DialogContent>
                            <Box>
                                <Typography variant='h6'>Are you sure you want to CONFIRM the applicant?</Typography>
                                <Box sx={{display:"flex",justifyContent:"center",marginTop:"20px"}}>
                                    <Button variant='contained' color="customGray" sx={{marginRight:"20px"}} onClick={()=>handleUpdateStatus("confirmed")}>Yes</Button>
                                    <Button variant='contained' onClick={handleCloseDialog}>No</Button>
                                </Box>
                            </Box>
                        </DialogContent>
                    </Dialog>
                    <Dialog open={openDialogReject} onClose={handleCloseDialogReject}>
                        <DialogContent>
                            <Box>
                                <Typography variant='h6'>Are you sure you want to DECLINE the applicant?</Typography>
                                <Box sx={{display:"flex",justifyContent:"center",marginTop:"20px"}}>
                                    <Button variant='contained' color="customGray" sx={{marginRight:"20px"}} onClick={()=>handleUpdateStatus("declined")}>Yes</Button>
                                    <Button variant='contained' onClick={handleCloseDialogReject}>No</Button>
                                </Box>
                            </Box>
                        </DialogContent>
                    </Dialog>
                </>
            }
        </Box>
    )
}

export default VisaColumn
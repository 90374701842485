import { Box, Typography } from '@mui/material'
import { Link } from 'react-router-dom'

const Arrow = ({active}) => {

    return (
        <>
        <Box sx={{display:"grid",gridTemplateColumns: 'repeat(5, 1fr)',width:"100%",marginTop:"50px"}}>
            {/* polygon(topRight, centerRight,bottomRight, BottomLeft, topLeft) */}
            <Link to="/prospects">
                <Box sx={{height:"50px",padding:"0px 20px",clipPath:"polygon(95% 0, 100% 50%, 95% 100%, 0 100%, 0 0)",backgroundColor:active === "prospects" ?"customYellow.main":"#CBD5E1",display:"flex",alignItems:"center"}}>
                    <Typography>Prospects</Typography>
                </Box>
            </Link>
            {/* polygon(topLeft, topRight, centerRight, bottomRight, bottonLeeft,centerLeft) */}
            <Link to="/reviewed">
                <Box sx={{height:"50px",padding:"0px 0px 0px 40px",clipPath:"polygon(0 0, 95% 0%, 100% 50%, 95% 100%, 0 100%, 4% 50%)",backgroundColor:active === "reviewed" ?"customYellow.main":"#CBD5E1",display:"flex",alignItems:"center"}}>
                    <Typography>To Be Reviewed</Typography>
                </Box>
            </Link>
            <Link to="/accepted">
                <Box sx={{height:"50px",padding:"0px 0px 0px 40px",clipPath:"polygon(0 0, 95% 0%, 100% 50%, 95% 100%, 0 100%, 4% 50%)",backgroundColor:active === "accepted" ?"customYellow.main":"#CBD5E1",display:"flex",alignItems:"center"}}>
                    <Typography>Accepted</Typography>
                </Box>
            </Link>
            <Link to="/confirmed">
                <Box sx={{height:"50px",padding:"0px 0px 0px 40px",clipPath:"polygon(0 0, 95% 0%, 100% 50%, 95% 100%, 0 100%, 4% 50%)",backgroundColor:active === "confirmed" ?"customYellow.main":"#CBD5E1",display:"flex",alignItems:"center"}}>
                    <Typography>Confirmed</Typography>
                </Box>
            </Link>
            {/* polygon(topLeft, topRight, bottomRight, bottomLeft, centerLeft) */}
            <Link to="/enrolled">
                <Box sx={{height:"50px",padding:"0px 0px 0px 40px",clipPath:"polygon(0 0, 100% 0, 100% 100%, 0 100%, 4% 50%)",backgroundColor:active === "enrolled" ?"customYellow.main":"#CBD5E1",display:"flex",alignItems:"center"}}>
                    <Typography>Enrolled</Typography>
                </Box>
            </Link>
        </Box>
        </>
    )
}

export default Arrow
import { useEffect, useState } from "react";
import { Box, Button, Grid, Typography } from "@mui/material"
import { upperFirstLetter } from "../../../utils/qViewerFunctions"
import { Link } from "react-router-dom";

const ScholarshipTabAdmin = ({scholarship,appId}) => {
  
  const BASE_URL = "https://summit-app-edgar.s3.eu-north-1.amazonaws.com/";
  const [fileType, setFileType] = useState();
  const [fileType2, setFileType2] = useState();
  const [urlEssay, setUrlEssay] = useState(BASE_URL + scholarship.essay);
  const [urlOther, setUrlOther] = useState(BASE_URL + scholarship.otherDocuments);

  useEffect(() => {
    if(scholarship){
      const type = scholarship.essay.split(".").pop();
      setFileType(type);
      const type2 = scholarship.otherDocuments.split(".").pop();
      setFileType2(type2);
      const folderNameEssay = scholarship.essay.split("/")[0];
      const folderNameOther = scholarship.otherDocuments.split("/")[0];
      if (folderNameEssay !== "applications") {
        setUrlEssay(BASE_URL + "applicants/" + scholarship.essay);
      }
      if (folderNameOther !== "applications") {
        setUrlOther(BASE_URL + "applicants/" + scholarship.otherDocuments);
      }
    }
  }, [scholarship]);
  
  return (
    <Grid container spacing={2}>
        <Grid item>
          <Typography variant='h5' color="customYellow.main" sx={{marginBottom:"30px"}}><b>Scholarship</b></Typography>
          <Typography variant='subtitle1'><b>Do you have any financial responsibilities such as: student debt, deferment, etc?</b></Typography>
          <Typography variant='subtitle1' sx={{marginLeft:"20px"}}>{upperFirstLetter(scholarship?.responsabilities)}</Typography>
          <Typography variant='subtitle1'><b>Amount of Debt</b></Typography>
          <Typography variant='subtitle1' sx={{marginLeft:"20px"}}>{upperFirstLetter(scholarship?.amountDebt)}</Typography>
          <Typography variant='subtitle1'><b>Do you have any other sources of income?</b></Typography>
          <Typography variant='subtitle1' sx={{marginLeft:"20px"}}>{upperFirstLetter(scholarship?.sourceIncome)}</Typography>
          <Typography variant='subtitle1'><b>Who will be responsible to pay your tuition at Summit?*</b></Typography>
          <Typography variant='subtitle1' sx={{marginLeft:"20px"}}>{upperFirstLetter(scholarship?.responsible)}</Typography>
          <Typography variant='subtitle1'><b>Will you be receiving financial assistance from parents, church, friends, etc.?</b></Typography>
          <Typography variant='subtitle1' sx={{marginLeft:"20px"}}>{upperFirstLetter(scholarship?.financialAssistance)}</Typography>
          <Box sx={{width:"400px"}}>
          {
            fileType && fileType !== "" && <>
              <Typography variant='subtitle1'><b>Essay</b></Typography>
              {
                fileType !== "pdf" && fileType !== "docx" && <img src={urlEssay} alt="Essay" style={{width:"100%"}}/>
              }
              <Link to={urlEssay} target="_blank">
                <Button variant="contained">
                  DOWNLOAD FILE
                </Button>
              </Link>
            </>
          }
          {
            fileType2 && fileType2 !== "" && <>
              <Typography variant='subtitle1'><b>Financial documents showing proof of need</b></Typography>
              {
                fileType2 !== "pdf" && fileType2 !== "docx" && <img src={urlOther} alt="Id or Passport" style={{width:"100%"}}/>
              }
              <Link to={urlOther} target="_blank">
                <Button variant="contained">
                  DOWNLOAD FILE
                </Button>
              </Link>
            </>
          }
          </Box>
        </Grid>
    </Grid>
  )
}

export default ScholarshipTabAdmin
import { Box, Typography } from "@mui/material"
import { useEffect, useState } from "react"

const ProgressColumn = (props) => {
    
    const today = new Date();
    const last = new Date(props.row.accountCreated);
    const diffTime = Math.abs(last-today);
    const diff = Math.floor(diffTime / (1000 * 60 * 60 * 24));
    const [color, setColor] = useState("");
    
    useEffect(() => { 
        if(diff >= 10){
            setColor("#FFABAB"); //RED
        }else if(diff >= 3 && diff <= 9){
            setColor("#FEF1CB"); //YELLOW
        }else{
            setColor("#C3E4C5"); //GREEN
        }
    }, [diff])

    return (
        <Box sx={{width:"100%",height:"90%",position:"relative",display:"flex",alignItems:"center",paddingLeft:"5%",marginTop:"2%"}}>
            <Box sx={{position:"absolute", width:`${props.value}%`,height:"100%",left:0,top:0, backgroundColor:color,zIndex:-1}}>
            </Box>
            <Typography>{props.value}%</Typography>
        </Box>
    )
}

export default ProgressColumn
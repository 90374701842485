import { useContext, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom';
import { UserContext } from '../contexts/user';
import { getApplicationUserYear, updateApplication } from '../axios/axiosFunctions';
import { Box, Container, Grid, Step, StepLabel, Stepper, Typography } from '@mui/material';
import LinearProgressWithLabel from './LinearProgressWithLabel';
import LockIcon from '@mui/icons-material/Lock';
import QuestionaireStep from './Questionaire';
import PersonalInformation from './PersonalInformation';
import SummitAndYou from './SummitAndYou';
import Faith from './Faith';
import Education from './Education';
import Work from './Work';
import Family from './Family';
import Health from './Health';
import Financial from './Financial';
import Agreement from './Agreement';
import ReviewAndSubmit from './ReviewAndSubmit';
import Documents from './Documents';
import IDPassport from './IDPassport';
import Photo from './Photo';
import Transcripts from './Transcripts';
import References from './References';
import { questionaireSteps } from '../utils/questionaireSteps';
import Visa from './Visa';
import FeeSteps from './FeeSteps';
import Fees from './Fees';
import ScholarshipSteps from './ScholarshipSteps';
import Scholarship from './Scholarship';
import ResumeStep from './ResumeStep';
import Legal from './Legal';
import ConfirmedData from './ConfirmedData';
import MedicalRecords from './MedicalRecords';
import ConfirmationFee from './ConfirmationFee';

const stepsDefault = [
    {
        label:'Application',
        disabled:false,
        completed:false,
    },
    {
        label:'Submit',
        disabled:false,
        completed:false
    }
];

const Resume = ({active}) => {
 
    const navigate = useNavigate();
    const [currentApplication, setCurrentApplication] = useState();
    const [currentStep,setCurrentStep] = useState(0);
    const [progress,setProgress] = useState(10);
    const [location, setLocation] = useState(active);
    const {currentUser} = useContext(UserContext);
    const [steps, setSteps] = useState(stepsDefault);
    const [questionaireStepsArray, setQuestionaireStepsArray] = useState(questionaireSteps);

    useEffect(() => {
        setLocation(active);
        if(active === "review_and_submit" || active === "fees"){
            setCurrentStep(1);
        }
        if(active === "scholarship"){
            setCurrentStep(2);
        }
    }, [active]);
    
    useEffect(() => {
        const getApplication = async()=>{
            const resp = await getApplicationUserYear(currentUser._id,2025); // TO DO CHANGE YEAR TO CURRENT YEAR APPLICATION OR TO CUSTOM YEAR TO CHECK
            if (resp){
                const {application} = resp;
                let newSteps = [...stepsDefault];
                if(application.personal.f1Visa !== "" && application.personal.f1Visa !== "no"){
                    if(questionaireStepsArray.length === 10){
                        let newArray = [...questionaireStepsArray];
                        newArray.push({
                            label:"Visa",
                            numberSteps: 1,
                            route:"visa",
                            requiredStepsLabel: "visa",
                        });
                        setQuestionaireStepsArray(newArray);
                    }
                }
                if(application.reviewAndSubmit.submitted){
                    newSteps = [{
                        label:'Application',
                        disabled:true,
                        completed:true
                    },
                    {
                        label:'Submit',
                        disabled:false,
                        completed:false
                    }]
                    setCurrentStep(1);
                    setLocation("review_and_submit")
                }
                if(application.financial.scholarship === "yes"){
                    newSteps.push({label:"Scholarship",disabled:!currentApplication?.fees.payed,completed:false});
                }
                setSteps(newSteps);
                setProgress(application.progress);
                setCurrentApplication(application);
            }
        }
        getApplication();
    },[currentUser._id,currentApplication?.fees.payed]);

    const handleStepper = (index)=>{
        setCurrentStep(index);
        index === 0 && navigate('/personal')
        index === 1 && navigate('/review_and_submit')
        index === 2 && navigate('/scholarship')
    }
    
    const updateApp = async(data)=>{
        const resp = await updateApplication(data,currentApplication._id);
        const {application} = resp;
        if(application?.financial?.scholarship !== "" && application?.financial?.scholarship !== "no"){
            const newSteps = [...steps];
            if(newSteps.length === 3){
                newSteps.pop();
                newSteps.push({label:"Scholarship",disabled:!application?.fees.payed,completed:false});
                setSteps(newSteps);
            }
            if(newSteps.length === 2){
                newSteps.push({label:"Scholarship",disabled:!application?.fees.payed,completed:false});
                setSteps(newSteps);
            }
        }
        setCurrentApplication(application);
    }

    return <Container maxWidth="xl">
        <Box display="flex" alignItems="center" flexDirection="column" justifyContent="flex-start">
            {
                !location &&
                <Grid container spacing={2} height="100%" sx={{flexDirection:{xs:"column-reverse",sm:"row"},flexWrap:{xs:"nowrap",sm:"wrap"},paddingTop:"40px"}}>
                    <Grid item xs={12} sm={3} sx={{borderRight:{sm:"solid #999999"}}}>
                        <Box display="flex" flexDirection="column" sx={{paddingRight:{sm:"30px"}}}>                                
                            {
                                currentStep === 0 && <>
                                    <QuestionaireStep active={location} requiredCount={currentApplication ? currentApplication : undefined} questionaireSteps={questionaireStepsArray}/>
                                    <Documents active={location} app={currentApplication}/>
                                </>
                            }
                            {
                                currentStep === 1 &&
                                <FeeSteps active={location} app={currentApplication}/>
                            }
                            {
                                currentStep === 2 &&
                                <ScholarshipSteps active={location} app={currentApplication}/>
                            }
                        </Box>
                    </Grid>
                    <Grid item xs={12} sm={9} height="100%" >
                        <Box display="flex" flexDirection="column">
                            <Typography variant="h5" sx={{display:{xs:"block",sm:"none"}}}>Application Process:</Typography>
                            <LinearProgressWithLabel sx={{display:{xs:"block",sm:"none"}}} value={progress}/>
                            <Stepper activeStep={currentStep} alternativeLabel sx={{width:"100%",marginTop:{xs:"40px",sm:"0px"}}}>
                                {
                                    steps.map(({label,completed,disabled},i)=>(
                                        <Step key={i} onClick={!disabled ? ()=>handleStepper(i):()=>{}} completed={completed} disabled={disabled} sx={{cursor:"pointer"}}>
                                            <StepLabel>{label}</StepLabel>
                                        </Step>
                                    ))
                                }
                            </Stepper>
                        </Box>
                    </Grid>
                </Grid>
            }
            {
                location &&
                <Grid container spacing={2} height="100%" sx={{flexDirection:{xs:"column-reverse",sm:"row"},flexWrap:{xs:"nowrap",sm:"wrap"},paddingTop:"40px"}}>
                    <Grid item xs={12} sm={3} sx={{borderRight:{sm:"solid #999999"},display:{xs:"none",sm:"block"}}}>
                        <Box display="flex" flexDirection="column" sx={{paddingRight:"30px"}}>
                            {
                                currentStep === 0 &&<>
                                <QuestionaireStep active={location} requiredCount={currentApplication} questionaireSteps={questionaireStepsArray}/>
                                <Documents active={location} app={currentApplication}/>
                                </>
                            }
                            {
                                currentStep === 1 &&
                                <FeeSteps active={location} app={currentApplication}/>
                            }
                            {
                                currentStep === 2 &&
                                <ScholarshipSteps active={location} app={currentApplication}/>
                            }
                        </Box>
                    </Grid>
                    <Grid item xs={12} sm={9} height="100%" >
                        <Box display="flex" flexDirection="column">
                            <Typography variant="h5" sx={{display:{xs:"none"}}}>Application Process:</Typography>
                            <LinearProgressWithLabel sx={{display:{xs:"none"}}} value={progress}/>
                            <Stepper activeStep={currentStep} alternativeLabel sx={{width:"100%",marginTop:{xs:"40px",sm:"0px"},display:{xs:"none",sm:"flex"}}}>
                                {
                                    steps.map(({label,completed,disabled},i)=>{
                                        if(currentApplication?.fees.payed === false && label === "Scholarship"){
                                            return(
                                                    <Step key={i} onClick={!disabled ? ()=>handleStepper(i):()=>{}} completed={completed} disabled={disabled} sx={{cursor:"pointer"}}>
                                                        <StepLabel icon={<LockIcon/>}>{label}</StepLabel>
                                                    </Step>
                                            )
                                        }
                                        return(
                                                <Step key={i} onClick={!disabled ? ()=>handleStepper(i):()=>{}} completed={completed} disabled={disabled} sx={{cursor:"pointer",color:currentStep===i?"#E1A30B":"primary"}}>
                                                    <StepLabel>{label}</StepLabel>
                                                </Step>
                                        )
                                    })
                                }
                            </Stepper>
                            {
                                location === "personal" && <>
                                    <PersonalInformation data={currentApplication?.personal} userInfo={currentUser} updateApplication={updateApp} questionaireSteps={questionaireStepsArray} setQuestionaireSteps={setQuestionaireStepsArray}/>
                                </>
                            }
                            {
                                location === "summit_and_you" && <>
                                    <SummitAndYou data={currentApplication?.summitAndYou} updateApplication={updateApp}/>
                                </>
                            }
                            {
                                location === "faith" && <>
                                    <Faith data={currentApplication?.faith} updateApplication={updateApp}/>
                                </>
                            }
                            {
                                location === "education" && <>
                                    <Education data={currentApplication?.education} updateApplication={updateApp}/>
                                </>
                            }
                            {
                                location === "work" && <>
                                    <Work data={currentApplication?.work} updateApplication={updateApp} appId = {currentApplication?._id} />
                                </>
                            }
                            {
                                location === "family" && <>
                                    <Family data={currentApplication?.family} updateApplication={updateApp}/>
                                </>
                            }
                            {
                                location === "health" && <>
                                    <Health data={currentApplication?.health} updateApplication={updateApp}/>
                                </>
                            }
                            {
                                location === "legal" && <>
                                    <Legal data={currentApplication?.legal} updateApplication={updateApp}/>
                                </>
                            }
                            {
                                location === "financial" && <>
                                    <Financial data={currentApplication?.financial} fee={currentApplication?.fees.payed} updateApplication={updateApp} setSteps={setSteps} defaultSteps={steps}/>
                                </>
                            }
                            {
                                location === "agreement" && <>
                                    <Agreement data={currentApplication?.agreement} updateApplication={updateApp} currentApplication={currentApplication}/>
                                </>
                            }
                            {
                                location === "visa" && <>
                                    <Visa data={currentApplication?.visa} updateApplication={updateApp}/>
                                </>
                            }
                            {
                                location === "review_and_submit" && <>
                                    <ReviewAndSubmit data={currentApplication?.reviewAndSubmit} fees={currentApplication?.fees} scholarship={currentApplication?.financial.scholarship} updateApplication={updateApp} setCurrentStep={setCurrentStep} appId ={currentApplication?._id}/>
                                </>
                            }
                            {
                                location === "id_passport" && <>
                                    <IDPassport data={currentApplication?.idPassport} updateApplication={updateApp} appId = {currentApplication?._id} currentApplication={currentApplication}/>
                                </>
                            }
                            {
                                location === "photo" && <>
                                    <Photo data={currentApplication?.photo} updateApplication={updateApp} appId = {currentApplication?._id}/>
                                </>
                            }
                            {
                                location === "transcripts" && <>
                                    <Transcripts data={currentApplication?.transcripts} updateApplication={updateApp}/>
                                </>
                            }
                            {
                                location === "references" && <>
                                    <References appId={currentApplication?._id} updateApplication={updateApp} family={currentApplication?.family} references={currentApplication?.references}/>
                                </>
                            }
                            {
                                location === "resume" && <>
                                    <ResumeStep data={currentApplication?.resume} updateApplication={updateApp} appId = {currentApplication?._id}/>
                                </>
                            }
                            {
                                location === "confirmation_fee" && <>
                                    <ConfirmationFee data={currentApplication?.confirmationFee} updateApplication={updateApp} appId = {currentApplication?._id} citizenship={currentApplication?.personal}/>
                                </>
                            }
                            {
                                location === "scholarship" && <>
                                    <Scholarship data={currentApplication?.scholarship} updateApplication={updateApp} appId = {currentApplication?._id}/>
                                </>
                            }
                            {
                                location === "fees" && <>
                                    <Fees data={currentApplication?.fees} scholarship={currentApplication?.financial.scholarship} updateApplication={updateApp} stepsNumber={steps.length}/>
                                </>
                            }
                            {
                                location === "extra" && <>
                                    <ConfirmedData medicalAndTransportation={currentApplication?.medicalAndTransportation} updateApplication={updateApp} appId={currentApplication?._id}/>
                                </>
                            }
                            {
                                location === "medical" && <>
                                    <MedicalRecords dataImmuno={currentApplication?.immunoRecord} dataPhysical={currentApplication?.physicalExamination} updateApplication={updateApp} appId = {currentApplication?._id}/>
                                </>
                            }
                        </Box>
                    </Grid>
                </Grid>
            }
        </Box>
    </Container>   
}

export default Resume
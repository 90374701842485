import { Typography } from '@mui/material'
import React from 'react'

const ReviewAndSubmitAdmin = ({reviewAndSubmit}) => {
  return (<>
  <Typography variant='h5' color="customYellow.main" sx={{marginBottom:"30px"}}><b>Review and Submit</b></Typography>
  {
      reviewAndSubmit.submitted ? <Typography variant='h4'>Application is under review</Typography>
      : <Typography variant='h4'>Application in progress</Typography>
  }
  </>
  )
}

export default ReviewAndSubmitAdmin
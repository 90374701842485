export const feeSteps = [
    {
        label:"Review, Pay Fee And Submit",
        route:"review_and_submit",
        requiredStepsLabel: "review_and_submit",
        uploadedLabel:"reviewAndSubmit"
    }/* ,
    {
        label:"Fees",
        route:"fees",
        uploadedLabel:"fees",
    } */
];
import { Box, Grid, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography, styled } from "@mui/material";
import { LineChart, PieChart, useDrawingArea } from "@mui/x-charts";
import { useState } from "react";

const StyledText = styled('text')(({ theme }) => ({
    textAnchor: 'middle',
    dominantBaseline: 'central',
    fontSize: 18,
}));

function PieCenterLabel({ children }) {
    const { width, height, left, top } = useDrawingArea();
    return (<StyledText x={left + width / 2} y={top + height / 2}>
        {children}
    </StyledText>
    );
}


const HomeCharts = ({currentYear,homeInfo}) => {
    
    const defaultLineChar = [0,0,0,0,0,0,0,0,0,0,0,0];
    const xLabels = ["Jan","Feb","Mar","Apr","May","Jun","Jul","Aug","Sep","Oct","Nov","Dec"];
    //---------------------------------------------------------------------------------------------------LINEAR CHART
    const traditionalData = homeInfo?.monthT || defaultLineChar;
    const sabbaticalData = homeInfo?.monthS || defaultLineChar;
    const gapYearData = homeInfo?.monthG || defaultLineChar;
    //---------------------------------------------------------------------------------------------------PIE CHART
    const pieData = [{value:homeInfo?.traditional,color:"#2196F3"},{value:homeInfo?.sabbatical,color:"#FBBD08"},{value:homeInfo?.gapYear,color:"#1A2B50"}];
    const [centerLabel, setCenterLabel] = useState("TRADITIONAL");
    const [fillPieColor, setFillPieColor] = useState("#2196F3");
    
    const handlePieChartClick = (_,itemId)=>{
        const {dataIndex} = itemId;
        if(dataIndex === 0){
            setCenterLabel("TRADITIONAL");
            setFillPieColor("#2196F3");
        }
        if(dataIndex === 1){
            setCenterLabel("SABBATICAL");
            setFillPieColor("#FBBD08");
        }
        if(dataIndex === 2){
            setCenterLabel("GAP YEAR");
            setFillPieColor("#1A2B50");
        }
    }

    return (
    <Grid container>
        <Grid item xs={8}>
            <Box>
                <Typography variant="h6">{currentYear}-{Number(currentYear)+1} Applicants per Month</Typography>
                <LineChart height={500} series={[{data:traditionalData,color:"#2196F3"},{data:sabbaticalData,color:"#FBBD08"},{data:gapYearData,color:"#1A2B50"}]} xAxis={[{scaleType:"point",data:xLabels}]}/>
            </Box>
        </Grid>
        <Grid itme xs={4}>
            <Box>
                <Typography variant="h6">Applicants per programme</Typography>
                <PieChart onItemClick={handlePieChartClick} height={250} series={[{data:pieData,innerRadius:80,outerRadius:90,paddingAngle:3}]} sx={{fill:fillPieColor}}>
                    <PieCenterLabel>{centerLabel}</PieCenterLabel>
                </PieChart>
                <Grid container spacing={2} sx={{marginTop:"20px"}}>
                    <Grid item xs={4} sx={{borderBottom:"solid #E0E0E0"}}>
                        <Typography variant="subtitle2"><b>Program</b></Typography>
                    </Grid>
                    <Grid item xs={4} sx={{borderBottom:"solid #E0E0E0"}}>
                        <Typography variant="subtitle2"><b>Prospects</b></Typography>
                    </Grid>
                    <Grid item xs={2.2} sx={{borderBottom:"solid #E0E0E0"}}>
                        <Typography variant="subtitle2"><b>Enrolled</b></Typography>
                    </Grid>
                    <Grid item xs={4} sx={{borderBottom:"solid #E0E0E0"}}>
                        <Typography variant="subtitle2">Traditional</Typography>
                    </Grid>
                    <Grid item xs={4} sx={{borderBottom:"solid #E0E0E0"}}>
                        <Typography variant="subtitle2">{homeInfo?.traditionalP}</Typography>
                    </Grid>
                    <Grid item xs={2.2} sx={{borderBottom:"solid #E0E0E0"}}>
                        <Typography variant="subtitle2">{homeInfo?.traditionalE}</Typography>
                    </Grid>
                    <Grid item xs={4} sx={{borderBottom:"solid #E0E0E0"}}>
                        <Typography variant="subtitle2">Sabbatical</Typography>
                    </Grid>
                    <Grid item xs={4} sx={{borderBottom:"solid #E0E0E0"}}>
                        <Typography variant="subtitle2">{homeInfo?.sabbaticalP}</Typography>
                    </Grid>
                    <Grid item xs={2.2} sx={{borderBottom:"solid #E0E0E0"}}>
                        <Typography variant="subtitle2">{homeInfo?.sabbaticalE}</Typography>
                    </Grid>
                    <Grid item xs={4} sx={{borderBottom:"solid #E0E0E0"}}>
                        <Typography variant="subtitle2">Gap Year</Typography>
                    </Grid>
                    <Grid item xs={4} sx={{borderBottom:"solid #E0E0E0"}}>
                        <Typography variant="subtitle2">{homeInfo?.gapYearP}</Typography>
                    </Grid>
                    <Grid item xs={2.2} sx={{borderBottom:"solid #E0E0E0"}}>
                        <Typography variant="subtitle2">{homeInfo?.gapYearE}</Typography>
                    </Grid>
                </Grid>
            </Box>
        </Grid>
        <Grid item xs={12}>
        <Typography variant="h6">Counselors</Typography>
            <TableContainer sx={{margin:"0px 0px 100px 0px"}}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell><b>Name</b></TableCell>
                            <TableCell><b>Last Activity</b></TableCell>
                            <TableCell><b>Prospect</b></TableCell>
                            <TableCell><b>To be Reviewed</b></TableCell>
                            <TableCell><b>Accepted</b></TableCell>
                            <TableCell><b>Confirmed</b></TableCell>
                            <TableCell><b>Enrolled</b></TableCell>
                            <TableCell><b>Scholarship Review</b></TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {
                            homeInfo && homeInfo.couselors.map((c,i)=>(
                                <TableRow key={i}>
                                    <TableCell>{c.fullName}</TableCell>
                                    <TableCell>{c.lastActivity || "--/--/--"}</TableCell>
                                    <TableCell>{c.prospect}</TableCell>
                                    <TableCell>{c.toBeReviewed}</TableCell>
                                    <TableCell>{c.accepted}</TableCell>
                                    <TableCell>{c.confirmed}</TableCell>
                                    <TableCell>{c.enrolled}</TableCell>
                                    <TableCell>{c.scholarship}</TableCell>
                                </TableRow>
                            ))
                        }
                    </TableBody>
                </Table>
            </TableContainer>
        </Grid>
    </Grid>
    )
}

export default HomeCharts
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { styled } from '@mui/material/styles';
import { Button, Grid, MobileStepper, Typography } from '@mui/material';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import { uploadIdPassport } from '../axios/axiosFunctions';
import DocumentCard from './DocumentCard';
import BannerStep from './BannerStep';

const VisuallyHiddenInput = styled('input')({
    clip: 'rect(0 0 0 0)',
    clipPath: 'inset(50%)',
    height: 1,
    overflow: 'hidden',
    position: 'absolute',
    bottom: 0,
    left: 0,
    whiteSpace: 'nowrap',
    width: 1,
});

const IDPassport = ({data,updateApplication,appId,currentApplication}) => {

    const navigate = useNavigate();
    const [activeStep, setActiveStep] = useState(0);
    const [fileUploaded, setFileUploaded] = useState(false);
    const [imageName, setImageName] = useState("");
    const steps = 1;

    useEffect(() => {
      if(data){
        setFileUploaded(data.uploaded || false);
        setImageName(data.image || ""); 
      } 
    }, [data])
    

    const handleNext = () => {
        if(activeStep === steps-1){
            navigate('/photo');
            return;
        }
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
    };

    const handleBack = () => {
        if(activeStep === 0)
        {
            if(currentApplication.personal.f1Visa !== "" && currentApplication.personal.f1Visa === "yes"){
                navigate('/visa');
            }else{
                navigate('/agreement'); 
                return;
            }
        }
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const handleUploadFile = (e)=>{
        const {length} = e.target.files;
        if(length !== 0){
            handleSubmit(true,e.target.files[0],imageName);
            setFileUploaded(true);
        }
    }

    const handleDeleteFile = ()=>{
        setFileUploaded(false);
        setImageName("");
        handleSubmit(false,undefined,"");
    }

    const handleSubmit = async(uploaded,submitFile,imgName)=>{ 
        
        let newImageName = imgName;

        if(uploaded && submitFile){
            const formData = new FormData();
            formData.append('fileImage',submitFile);
            const {name} = await uploadIdPassport(formData,appId);
            newImageName = name;
        }

        const dataSubmit = {
            idPassport:{
                uploaded,
                image: newImageName
            }
        }

        updateApplication(dataSubmit);

    }
    
    return (
        <Grid container flexDirection="column-reverse">
            <Grid item xs={12}>
                <MobileStepper
                    variant="dots"
                    steps={steps}
                    position="static"
                    activeStep={activeStep}
                    sx={{ width:"100%", flexGrow: 1 , marginTop:"50px"}}
                    nextButton={
                        <Button size="small" onClick={handleNext}>
                            {activeStep === steps-1 ? 'Next' : 'Next' }
                        </Button>
                    }
                    backButton={
                        <Button size="small" onClick={handleBack}>
                            {activeStep === 0 ? 'Back' : 'Back'}
                        </Button>
                    }
                />
            </Grid>
            <Grid item sx={{display:"flex",flexDirection:"column"}}>
                <Typography variant='subtitle1' sx={{marginBottom:"20px"}}>Please upload a copy of your Government Issued ID such as a passport or driver’s license.</Typography>
                {
                    !fileUploaded ? 
                    <Button variant="contained" component="label" startIcon={<CloudUploadIcon/>}>
                        UPLOAD FILE
                        <VisuallyHiddenInput onChange={handleUploadFile} type="file" />
                    </Button>
                    :
                    <DocumentCard name="ID/PASSPORT UPLOADED" deleteFile={handleDeleteFile} />
                }
            </Grid>
            <Grid item xs={12} sx={{margin:{xs:"0px 0px 50px 0px",sm:"50px 0px"}}}>
                <BannerStep title="ID/Passport" stepNumber={activeStep+1} totalSteps={1}/>
            </Grid>
        </Grid>
    )
}

export default IDPassport
import { Alert, Button, Container, Grid, TextField, Typography } from "@mui/material"
import BannerStep from "../BannerStep"
import { useEffect, useState } from "react";
import { getApplicationById, updateApplication } from "../../axios/axiosFunctions";
import { useNavigate, useParams } from "react-router-dom";

const defaultMarriageForm = {
    firstName:"",
    lastName:"",
    letter:"",
}

const Marriage = () => {

    const {id}=useParams();
    const navigate = useNavigate();
    const [fullName, setFullName] = useState("User");
    const [showMsg, setShowMsg] = useState(false);
    const [severity, setSeverity] = useState('error');
    const [message, setMessage] = useState(" REQUIRED FIELDS MISSING")
    const [marriageForm, setMarriageForm] = useState(defaultMarriageForm);
    const {firstName,lastName,letter} = marriageForm;

    useEffect(() => {
        const checkApplication = async()=>{
            const resp = await getApplicationById(id);
            if(!resp){
                navigate('/');
                return
            }
            const {application} = resp;
            if(application.marriage?.uploaded){
                navigate('/');
                return
            }
            const appName = `${application.personal.lastName} ${application.personal.firstName}`;
            setFullName(appName);
        }
        checkApplication()
    }, [id,navigate]);

    const handleChangeForm = (e)=>{
        const {name,value} = e.target;
        setMarriageForm({...marriageForm, [name]:value});
    }

    const checkRequired = ()=>{
        return firstName !== "" && lastName !== "" && letter !== ""
    }

    const handleSubmit = async()=>{
        
        if(!checkRequired()){
            setShowMsg(true);
            setTimeout(() => {
                setShowMsg(false);
            }, 2000);
            return;
        } 
        
        const data = {
            marriage:{
                ...marriageForm,
                uploaded:true
            }
        }

        await updateApplication(data,id);
        setShowMsg(true)
        setMessage("THANK YOU, YOU'LL BE REDIRECTED TO THE MAIN PAGE.")
        setSeverity("success")
        setTimeout(() => {
            navigate('/')
        }, 5000);

    }

    return (
        <Container>
            <Grid container spacing={2} sx={{marginBottom:"50px"}}>
                <Grid item xs={12} sx={{margin:{xs:"0px 0px 50px 0px",sm:"50px 0px 0px 0px"}}}>
                    <BannerStep title={`Marriage Reference for ${fullName}`} stepNumber={1} totalSteps={1}/>
                </Grid>
                <Grid item xs={12}>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <Typography variant='h5'><b>Contact Information</b></Typography>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField required fullWidth value={firstName} onChange={handleChangeForm} name='firstName' label="First name"/>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField required fullWidth value={lastName} onChange={handleChangeForm} name='lastName' label="Last name"/>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12} sm={12}>
                    <Typography variant='subtitle1'><b>Letter Stating Marriage is Stable*</b></Typography>
                    <TextField fullWidth onChange={handleChangeForm} value={letter} name="letter" multiline rows={10}/>
                </Grid>
                {
                    showMsg && 
                    <Grid item xs={12}>
                        <Alert severity={severity}>
                            {message}
                        </Alert>
                    </Grid>
                }
                <Grid item xs={12}>
                    <Button onClick={handleSubmit} variant="contained" color="customYellow">Submit</Button>
                </Grid>
            </Grid>
        </Container>
    )
}

export default Marriage
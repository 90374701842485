import { Box, Button, Container, Dialog, DialogTitle, Typography } from "@mui/material";  
import { Link } from "react-router-dom";
import HandymanIcon from '@mui/icons-material/Handyman';

const Applicant = () => {

  return (
    <Container>
      <Box display="flex" alignItems="center" flexDirection="column" sx={{height:{xs:"calc(100vh - 88px)", sm:"calc(100vh - 96px)"}}} justifyContent="center">
        <Typography variant="h5" align="center" sx={{marginBottom:"50px"}}>We are so glad you are considering applying to Summit,<br/> please select an option below:</Typography>
        <Box>
          <Link to="/createAccount">
            <Button sx={{marginRight:{sm:"20px"}, marginBottom:{xs:"30px",sm:"0px"}, minWidth:{xs:"100%",sm:"400px"}}} size="large" variant="contained">Create a new Application</Button>
          </Link>
          <Link to="/login">
            <Button variant="contained" sx={{ minWidth:{xs:"100%", sm:"400px"}}} size="large">Continue an existing Application</Button>
          </Link>
        </Box>
      </Box>
      {/* <Dialog open={true}>
        <DialogTitle sx={{display:"flex",flexDirection:"column"}}>
          <HandymanIcon sx={{fontSize:"150px", color:"customYellow.main",alignSelf:"center"}}/>
          <Typography variant="h5" sx={{textAlign:"center"}}>We’re currently updating our application system to serve you better. <br/><br/> Please check back soon. Thank you for your patience!</Typography>
        </DialogTitle>
      </Dialog> */}
    </Container>
  )
}

export default Applicant
import { Fragment, useContext, useEffect, useState } from 'react';
import { Link } from "react-router-dom";
import { UserContext } from "../../contexts/user"
import {Grid, List, ListItemButton, ListItemIcon, ListItemText} from '@mui/material';
import { navItems } from '../../utils/adminDashboard';
import { scholarshipCommiteeMenu } from '../../utils/scholarshipCommitee';

const Sidebar = ({location,activeTab}) => {
  
  const {currentUser}  = useContext(UserContext);
  const [applicationMenuOpen, setApplicationMenuOpen] = useState(true);
  const [menuItems, setMenuItems] = useState(navItems);

  useEffect(() => { 
    if(currentUser.rol === "scholarshipCommitee"){
      setMenuItems(scholarshipCommiteeMenu);
    }
  }, [currentUser])
  

  const handleMenuOpen = ()=>{
    setApplicationMenuOpen(!applicationMenuOpen);
  }

  return (
    <Grid sx={{backgroundColor:"#1B2430",height:"100%",color:"white"}}>
      <List>
        {
          menuItems.map((item,i)=>(<Fragment key={i}>
            <Link to={item.href}>
              <ListItemButton sx={{color:"white", backgroundColor:location === item.locationRef ? "#10151C" : "#1B2430"}} onClick={item.pages?handleMenuOpen:null}>
                <ListItemIcon>
                  {item.icon}
                </ListItemIcon>
                <ListItemText primary={item.title} primaryTypographyProps={{variant:"subtitle2"}}/>
                {item.pages && applicationMenuOpen ? item.openIcon : item.closeIcon}
              </ListItemButton>          
            </Link>
            {
              item.pages && applicationMenuOpen && item.pages.map((subpages,j)=>(
                <Link key={j} to={subpages.href}>
                  <ListItemButton sx={{marginLeft:"20px",color:"white",backgroundColor:(location === subpages.locationRef || activeTab === subpages.locationRef) ? "#10151C" : "#1B2430"}}>
                    <ListItemText primary={subpages.title} primaryTypographyProps={{variant:"subtitle2"}}/>
                  </ListItemButton>
                </Link>
              ))
            }
          </Fragment>
          ))
        }
      </List>
    </Grid>
  )
  };

export default Sidebar
import { Box, Button, Dialog, DialogContent, MenuItem, Select, Typography } from "@mui/material";
import { updateImmunizationColumn, updateStatusApp } from "../../axios/axiosFunctions";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

const ImmunizationRecordsColumn = (props) => {

    const navigate = useNavigate();
    const [currentValue, setCurrentValue] = useState(props.value);
    const [openDialog, setOpenDialog] = useState(false)

    useEffect(() => {
        if(props.row.physicalExamination === "received" && currentValue === "received"){
            setOpenDialog(true);
        }
    }, [props.row,currentValue]);

    const handleSelectChange = async(e)=>{
        const {value} = e.target;
        setCurrentValue(value);
        const data = {
            immunizationRecords: value
        }
        await updateImmunizationColumn(props.row.id,data);
        props.api.updateRows([{id:props.row.id,immunizationRecords:value}]);
    }

    const handleCloseDialog = ()=>{
        setOpenDialog(false);
    }

    const handleUpdateStatus = async()=>{
        await updateStatusApp(props.id,{statusApp:"enrolled"});
        setOpenDialog(false);
        navigate(0);
    }

    return (
        <Box>
            <Select onChange={handleSelectChange} value={currentValue} fullWidth sx={{boxShadow: 'none', '.MuiOutlinedInput-notchedOutline': { border: 0 }}} >
                <MenuItem value="notReceived">Not Received</MenuItem>
                <MenuItem value="received">Received</MenuItem>
            </Select>
            <Dialog open={openDialog} onClose={handleCloseDialog}>
                <DialogContent>
                    <Box>
                        <Typography variant='h6'>Are you sure you want to ENROLLED the applicant?</Typography>
                        <Box sx={{display:"flex",justifyContent:"center",marginTop:"20px"}}>
                            <Button variant='contained' color="customGray" sx={{marginRight:"20px"}} onClick={handleUpdateStatus}>Yes</Button>
                            <Button variant='contained' onClick={handleCloseDialog}>No</Button>
                        </Box>
                    </Box>
                </DialogContent>
            </Dialog>
        </Box>
    )    
}

export default ImmunizationRecordsColumn
import { useEffect, useState } from 'react';
import { Box, Typography } from '@mui/material';

const StatusColumn = ({row}) => {

    const [color, setColor] = useState("#00C853");
    const [textStatus, setTextStatus] = useState("Up to date");

    useEffect(() => {
        switch (row.status) {
            case "Up to date":
                setColor("#00C853");
                setTextStatus("Up to date");
                break;        
            case "New":
                setColor("#2196F3");
                setTextStatus("New");
                break;        
            case "Attention Yellow":
                setColor("#FBBD08");
                setTextStatus("Attention");
                break;        
            case "Attention Orange":
                setColor("#FF8B38");
                setTextStatus("Attention");
                break;        
            case "Warning 15":
                setColor("#FF0000");
                setTextStatus("Warning");
                break;        
            case "Warning 20":
                setColor("#FF0000");
                setTextStatus("Warning");
                break;        
            case "Archive":
                setColor("#864FCC");
                setTextStatus("Archive");
                break;        
            default:
                setColor("#00C853");
                setTextStatus("Up to date");
                break;
        }
    }, [row])
    


    return (
        <Box sx={{display:"flex",width:"100%",height:"100%",alignItems:"center"}}>
            <Box sx={{width:"80%",height:"80%",display:"flex",justifyContent:"center",alignItems:"center", color:"white", backgroundColor:color,borderRadius:"12px"}}>
                <Typography variant='subtitle2'>
                    {textStatus}
                </Typography>
            </Box>
        </Box>
    )
}

export default StatusColumn
import TuneIcon from '@mui/icons-material/Tune';
import SpaceDashboardOutlinedIcon from '@mui/icons-material/SpaceDashboardOutlined';
import PersonIcon from '@mui/icons-material/Person';
import WorkIcon from '@mui/icons-material/Work';
import CreditCardIcon from '@mui/icons-material/CreditCard';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';

export const navItems = [
    {
      title: "Home",
      icon: <TuneIcon sx={{color:"white"}}/>,
      locationRef : "dashboard",
      href: "/",
    },
    {
      title: "All Aplications", 
      icon: <SpaceDashboardOutlinedIcon sx={{color:"white"}}/>,
      openIcon: <KeyboardArrowUpIcon sx={{color:"white"}}/>,
      closeIcon: <KeyboardArrowDownIcon sx={{color:"white"}}/>,
      href: "",
      pages: [{
          title: "Prospects",
          locationRef : "prospects",
          href:"/prospects"
        },
        {
          title: "To be reviewed",
          locationRef : "reviewed",
          href:"/reviewed"
        },
        {
          title: "Accepted",
          locationRef : "accepted",
          href:"/accepted"
        },
        {
          title: "Confirmed",
          locationRef : "confirmed",
          href:"/confirmed"
        },
        {
          title: "Enrolled",
          locationRef : "enrolled",
          href:"/enrolled"
        },
        {
          title: "Declined",
          locationRef : "declined",
          href:"/declined"
        },
        {
          title: "Waitlist",
          locationRef : "waitlist",
          href:"/waitlist"
        },
        {
          title: "Deferred Acceptance",
          locationRef : "deferrred",
          href:"/deferred"
        },
        {
          title: "Archived",
          locationRef : "archived",
          href:"/archived"
        },
        {
          title: "Scholarship",
          locationRef: "scholarship",
          href:"/scholarship"
        }
      ], //pagesSection
    },
    /* {
      title: "Notification",
      locationRef : "notification",
      icon: <PersonIcon sx={{color:"white"}}/>,
      href: "/notification",    
    }, */
    {
      title: "Reporting",
      locationRef : "reporting",
      icon: <WorkIcon sx={{color:"white"}}/>,
      href: "/reporting",
    },
    {
      title: "User",
      locationRef : "user",
      icon: <CreditCardIcon sx={{color:"white"}}/>,
      href: "/user",
    },
];
import { Checkbox, FormControlLabel, Grid, Typography } from "@mui/material";

const ExtraAdmin = ({data}) => {
    
  return (
    <Grid container spacing={2}>
      <Grid item>
        <Typography variant='h5' color="customYellow.main" sx={{marginBottom:"30px"}}><b>Medical and Transportation Liability</b></Typography>
        <Typography variant='subtitle1'><b>Full Name</b></Typography>
        <Typography variant='subtitle1' sx={{marginLeft:"20px"}}>{data.fullName}</Typography>
        <Typography variant='subtitle1'><b>Date of Birth</b></Typography>
        <Typography variant='subtitle1' sx={{marginLeft:"20px"}}>{data.dateOfBirth}</Typography>
        <Typography variant='subtitle1'><b>Address</b></Typography>
        <Typography variant='subtitle1' sx={{marginLeft:"20px"}}>{data.address}</Typography>
        <Typography variant='subtitle1'><b>City</b></Typography>
        <Typography variant='subtitle1' sx={{marginLeft:"20px"}}>{data.city}</Typography>
        <Typography variant='subtitle1'><b>State</b></Typography>
        <Typography variant='subtitle1' sx={{marginLeft:"20px"}}>{data.state}</Typography>
        <Typography variant='subtitle1'><b>ZIP</b></Typography>
        <Typography variant='subtitle1' sx={{marginLeft:"20px"}}>{data.ZIP}</Typography>
        <Typography variant='subtitle1'><b>Phone Number</b></Typography>
        <Typography variant='subtitle1' sx={{marginLeft:"20px"}}>{data.phoneNumber}</Typography>
        <Typography variant='subtitle1'><b>Emergency Contact Name</b></Typography>
        <Typography variant='subtitle1' sx={{marginLeft:"20px"}}>{data.contactName}</Typography>
        <Typography variant='subtitle1'><b>Emergency Contact Phone</b></Typography>
        <Typography variant='subtitle1' sx={{marginLeft:"20px"}}>{data.contactPhone}</Typography>
        <FormControlLabel checked={data.medicalRelease} control={<Checkbox/>} label={<Typography variant="subtitle1"><b>Medical Release</b></Typography>}/>
        <FormControlLabel checked={data.transpRelease} control={<Checkbox/>} label={<Typography variant="subtitle1"><b>Transportation Liability Release</b></Typography>}/>
      </Grid>
  </Grid>
  )
}

export default ExtraAdmin
import { Grid, Typography } from "@mui/material";
import { upperFirstLetter } from "../../../utils/qViewerFunctions";

const VisaAdmin = ({visa}) => {   
    return (
        <Grid container spacing={2}>
            <Grid item>
                <Typography variant='h5' color="customYellow.main" sx={{marginBottom:"30px"}}><b>Visa</b></Typography>
                <Typography variant='subtitle1'><b>Do you hold a valid passport with at least 6 months validity?</b></Typography>
                <Typography variant='subtitle1' sx={{marginLeft:"20px"}}>{upperFirstLetter(visa?.validPassport)}</Typography>
                <Typography variant='subtitle1'><b>Total Personal Funds (in US Dollars)</b></Typography>
                <Typography variant='subtitle1' sx={{marginLeft:"20px"}}>{visa?.funds}</Typography>
            </Grid>
        </Grid>
    )
}

export default VisaAdmin
import { List, ListItem, ListItemButton, ListItemText, Typography } from "@mui/material";
import { scholarshipSteps } from "../../utils/scholarshipSteps";
import CheckCircleIcon from '@mui/icons-material/CheckCircle';

const ScholarshipStepsAdmin = ({application,setCurrentTab,currentTab}) => {

    const handleClick = (tab)=>{
        setCurrentTab(tab);
    }

    return (
        <>
            <Typography variant="h5">Questionnaire:</Typography>
            <List sx={{width:"100%"}}>
                {
                    scholarshipSteps.map(({label,requiredSteps,route,requiredStepsLabel},i)=>(
                        <ListItem key={i} onClick={()=>handleClick(route)} sx={{padding:{xs:"0px"}, borderBottom:"1px solid #9E9E9E",backgroundColor:currentTab ===route?'customYellow.main':'white'}}>
                            <ListItemButton>
                                <ListItemText primary={label}/>
                                <Typography edge="end">{ application && application[requiredStepsLabel]?.progress ? application[requiredStepsLabel]?.progress === requiredSteps ? <CheckCircleIcon/> : `${application[requiredStepsLabel]?.progress}/${requiredSteps}`: `0/${requiredSteps}` }</Typography>
                            </ListItemButton>
                        </ListItem>
                    ))
                }
            </List>
        </>
    )
}

export default ScholarshipStepsAdmin
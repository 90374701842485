import { useState } from "react";
import { Accordion, AccordionDetails, AccordionSummary, Alert, Box, Button, Dialog, DialogContent, FormControl, Grid, IconButton, InputAdornment, InputLabel, MenuItem, OutlinedInput, Select, TextField, Typography } from "@mui/material";
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { upperFirstLetter } from "../../utils/qViewerFunctions";
import { deleteUser, getAllConsultants, updateUser } from "../../axios/axiosFunctions";
import { Visibility, VisibilityOff } from '@mui/icons-material';

const UsersListCards = ({user,setUsersList}) => {

    const [currentUser, setCurrentUser] = useState(user);
    const [openDialog, setOpenDialog] = useState(false);
    const [openDialogDelete, setOpenDialogDelete] = useState(false);
    const [editMode, setEditMode] = useState(true);
    const [activityMessage, setActivityMessage] = useState(false);
    const [severity, setSeverity] = useState('success');
    const [message, setMessage] = useState("");
    const [showPassword, setShowPassword] = useState(false);
    const {rol,firstName,lastName,email,rawPassword=""} = currentUser;

    const handleClickShowPassword = () => setShowPassword((show) => !show);

    const handleChangeForm = (e)=>{
        const {name,value} = e.target;
        setCurrentUser({...currentUser, [name]:value});
    }

    const handleEditMode = ()=>{
        if(!editMode){
            setOpenDialog(true);      
        }
        setEditMode(!editMode);
    }

    const handleCancelEditMode = ()=>{
        setEditMode(true);
        setCurrentUser(user);
    }

    const handleDialogClose = ()=>{
        setOpenDialog(false);
    }

    const handleDialogDeleteClose = ()=>{
        setOpenDialogDelete(false);
    }

    const handleSaveUser = async()=>{
        const resp = await updateUser(currentUser._id,{...currentUser,password:rawPassword});
        if(resp){
            setSeverity("success");
            setMessage("User uploaded correctly");
            setActivityMessage(true);
            setCurrentUser(resp.user);
            setTimeout(() => {
                setActivityMessage(false);
            }, 3000);
            const listItems = await getAllConsultants();
            setUsersList(listItems);
        }else{
        setSeverity("error");
        setMessage("User NOT Saved (Check internet connection or talk to a super admin)");
        setActivityMessage(true);
        setTimeout(() => {
            setActivityMessage(false)
        },3000);
        }
        setOpenDialog(false);
    }

    const handleDeleteUser = async()=>{
        await deleteUser(currentUser._id);
        const resp = await getAllConsultants();
        setUsersList(resp);
    }

    return (
    <Accordion>
        <AccordionSummary expandIcon={<ArrowDropDownIcon/>}>
            {lastName} {firstName} ({upperFirstLetter(rol)})
        </AccordionSummary>
        <AccordionDetails>
            <Box>
                <form>
                <Grid container spacing={2} sx={{marginBottom:"20px"}}>
                    <Grid item xs={12}>
                    <Select disabled={editMode} onChange={handleChangeForm} value={rol} name='rol' fullWidth>
                        <MenuItem value="counselor">Counselor</MenuItem>
                        <MenuItem value="reviewer">Reviewer</MenuItem>
                        <MenuItem value="scholarshipCommitee">Scholarship Committee</MenuItem>
                    </Select>
                    </Grid>
                    <Grid item xs={6}>
                        <TextField disabled={editMode} fullWidth onChange={handleChangeForm} value={firstName} name='firstName' label="First name"/>
                    </Grid>
                    <Grid item xs={6}>
                        <TextField disabled={editMode} fullWidth onChange={handleChangeForm} value={lastName} name='lastName' label="Last name / Surname"/>
                    </Grid>
                    <Grid item xs={6}>
                        <TextField disabled={editMode} fullWidth onChange={handleChangeForm} value={email} name='email' label="Email"/>
                    </Grid>
                    <Grid item xs={6}>
                        <FormControl disabled={editMode} fullWidth variant="outlined"> 
                            <InputLabel>Password</InputLabel>
                            <OutlinedInput
                            type={showPassword ? 'text' : 'password'} 
                            fullWidth
                            label="Password"
                            name='rawPassword'
                            value={rawPassword}
                            onChange={handleChangeForm}
                            endAdornment={
                                <InputAdornment position="end">
                                <IconButton
                                    onClick={handleClickShowPassword}
                                >
                                    {showPassword ? <VisibilityOff /> : <Visibility />}
                                </IconButton>
                                </InputAdornment>
                            }
                            />
                        </FormControl>
                    </Grid>
                </Grid>
                </form>
                {
                    activityMessage && 
                    <Grid item xs={12} sx={{display:"flex"}}>
                        <Alert severity={severity}>
                            {message}
                        </Alert>
                    </Grid>
                }
                <Button onClick={handleEditMode} variant="contained" color="customYellow">{!editMode ? "SAVE" : "EDIT" }</Button>
                <Button onClick={()=>setOpenDialogDelete(true)} variant="contained" color="error" sx={{margin:"0px 10px"}}>Delete</Button>
                {
                    !editMode && 
                    <Button onClick={handleCancelEditMode} variant="contained">CANCEL</Button>
                }
                <Dialog open={openDialog} onClose={handleDialogClose}>
                    <DialogContent>
                        <Typography>Are you sure to edit this user?</Typography>
                        <Box sx={{display:"flex",margin:"20px",justifyContent:"center"}}>
                            <Button onClick={handleSaveUser} variant='contained'sx={{marginRight:"20px",justifyContent:"space-around"}}>SAVE</Button>
                            <Button variant='contained' onClick={handleDialogClose} color="customGray">CANCEL</Button>
                        </Box>
                    </DialogContent>
                </Dialog>
                <Dialog open={openDialogDelete} onClose={handleDialogDeleteClose}>
                    <DialogContent>
                        <Typography sx={{textAlign:"center"}}>Are you sure to DELETE this user ({firstName} {lastName})?<br/>(This action cannot be undone)</Typography>
                        <Box sx={{display:"flex",margin:"20px",justifyContent:"center"}}>
                            <Button onClick={handleDeleteUser} variant='contained' color="error" sx={{marginRight:"20px",justifyContent:"space-around"}}>DELETE</Button>
                            <Button variant='contained' onClick={handleDialogDeleteClose} color="customGray">CANCEL</Button>
                        </Box>
                    </DialogContent>
                </Dialog>
            </Box>
        </AccordionDetails>
    </Accordion>
    )
}

export default UsersListCards
import { Container, Grid } from '@mui/material';
import Sidebar from './Sidebar';
import { useEffect, useState } from 'react';
import Home from './Home';
import Prospects from './Prospects';
import Reviewed from './Reviewed';
import Accepted from './Accepted';
import Confirmed from './Confirmed';
import Enrolled from './Enrolled';
import Profile from './Profile';
import Arrow from './Arrow';
import Declined from './Declined';
import Archived from './Archived';
import Scholarship from './Scholarship';
import UserTab from './UserTab';
import Filter from './Filter';
import Waitlist from './Waitlist';
import Deferred from './Deferred';
import Reporting from './Reporting/Reporting';

const Dashboard = ({active}) => {

  const [location, setLocation] = useState();
  const [activeTab, setActiveTab] = useState();
  const [currentYear, setCurrentYear] = useState(new Date().getFullYear());
  
  useEffect(() => {
    const subMenuTabs = ["prospects","reviewed","accepted","confirmed","enrolled","declined","archived","scholarship","profile"];
    if(!subMenuTabs.includes(active)){
      setActiveTab("");
    }
    setLocation(active);
  }, [active]);
  
  return <Container maxWidth={false} sx={{padding:"0px!important"}}>
    <Grid container sx={{height:"calc(100vh - 96px)"}}>
      <Grid item xs={2}>
        <Sidebar location={location} activeTab={activeTab}/>
      </Grid>
      <Grid item xs={10}>
        <Container maxWidth={false}>
          {
            location === "dashboard" && 
            <Home currentYear={currentYear} setCurrentYear={setCurrentYear}/>
          }
          {
            location === "prospects" && <>
              <Filter currentYear={currentYear} setCurrentYear={setCurrentYear}/>
              <Arrow active={location}/>
              <Prospects setActiveTab={setActiveTab} currentYear={currentYear}/>
            </>
          }
          {
            location === "reviewed" && <>
              <Filter currentYear={currentYear} setCurrentYear={setCurrentYear}/>
              <Arrow active={location}/>
              <Reviewed setActiveTab={setActiveTab} currentYear={currentYear}/>
            </>
          }
          {
            location === "accepted" && <>
              <Filter currentYear={currentYear} setCurrentYear={setCurrentYear}/>
              <Arrow active={location}/>
              <Accepted setActiveTab={setActiveTab} currentYear={currentYear}/>
            </>
          }
          {
            location === "confirmed" && <>
              <Filter currentYear={currentYear} setCurrentYear={setCurrentYear}/>
              <Arrow active={location}/>
              <Confirmed setActiveTab={setActiveTab} currentYear={currentYear}/>
            </>
          }
          {
            location === "enrolled" && <>
              <Filter currentYear={currentYear} setCurrentYear={setCurrentYear}/>
              <Arrow active={location}/>
              <Enrolled setActiveTab={setActiveTab} currentYear={currentYear}/>
            </>
          }
          {
            location === "declined" && <>
              <Filter currentYear={currentYear} setCurrentYear={setCurrentYear}/>
              <Declined setActiveTab={setActiveTab} currentYear={currentYear}/>
            </>
          }
          {
            location === "waitlist" && <>
              <Filter currentYear={currentYear} setCurrentYear={setCurrentYear}/>
              <Waitlist setActiveTab={setActiveTab} currentYear={currentYear}/>
            </>
          }
          {
            location === "deferred" && <>
              <Filter currentYear={currentYear} setCurrentYear={setCurrentYear}/>
              <Deferred setActiveTab={setActiveTab} currentYear={currentYear}/>
            </>
          }
          {
            location === "archived" && <>
              <Filter currentYear={currentYear} setCurrentYear={setCurrentYear}/>
              <Archived setActiveTab={setActiveTab} currentYear={currentYear}/>
            </>
          }
          {
            location === "scholarship" && <>
              <Filter currentYear={currentYear} setCurrentYear={setCurrentYear}/> 
              <Scholarship setActiveTab={setActiveTab} currentYear={currentYear}/>
            </>
          }
          {
            location === "notification" && 
            <>
            Notification
            </>
          }
          {
            location === "reporting" && 
            <Reporting setActiveTab={setActiveTab}/>
          }
          {
            location === "user" && <UserTab/>
          }
          {
            location === "profile" &&<>
              <Arrow active={activeTab}/>
              <Profile/>
            </>
          }
        </Container>
      </Grid>
    </Grid>
  </Container>
}

export default Dashboard
import { Alert, Button, FormControlLabel, FormLabel, Grid, MenuItem, MobileStepper, Radio, RadioGroup, Select, TextField, Typography } from "@mui/material";
import { Fragment, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import BannerStep from "./BannerStep";
import ClearIcon from '@mui/icons-material/Clear';

const defaultFamilyForm = {
  maritalStatus: "",
  yearsMarried:"",
  spouseApplying:"",
  spouseFeel:"",
  nameSpouse:"",
  emailSpouse:"",
  hasChildren: "",
  numberChildren :"",
  children: [{ childName:"",childAge:""}],  
  carePlan: ""
}

const Family = ({data,updateApplication}) => {

  const navigate = useNavigate();
  const [familyForm, setFamilyForm] = useState(defaultFamilyForm);
  const [showError, setShowError] = useState(false);
  const [activeStep, setActiveStep] = useState(0);
  const steps = 1;
  const { maritalStatus,yearsMarried,spouseApplying,spouseFeel,nameSpouse,emailSpouse,hasChildren,numberChildren,children,carePlan} = familyForm;

  useEffect(() => {
    if(data){
      setFamilyForm({
        maritalStatus : data.maritalStatus || "",
        yearsMarried: data.yearsMarried || "",
        spouseApplying: data.spouseApplying || "",
        spouseFeel: data.spouseFeel || "",
        nameSpouse: data.nameSpouse || "",
        emailSpouse: data.emailSpouse || "",
        hasChildren : data.hasChildren || "",
        numberChildren : data.numberChildren || "",
        children: data.children?.length === 0 || data.children === undefined ? [{ childName:"",childAge:""}] : data.children,
        carePlan : data.carePlan || "",
      });
    }
  }, [data])

  const handleNext = () => {

    handleSubmit();
    if(!checkRequired()){
      setShowError(true);
      setTimeout(() => {
          setShowError(false);
      }, 2000);
      return;
    }

    if(activeStep === steps-1){
        navigate('/health');
        return;
    }
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
      handleSubmit();
      if(activeStep === 0)
      {
          navigate('/work');
          return;
      }
      setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const checkRequired = ()=>{
    if(activeStep === 0){
      return (((maritalStatus === "married" && yearsMarried !=="" && ((spouseApplying === "yes" && nameSpouse !== "" && emailSpouse !== "") || (spouseApplying === "no" && spouseFeel !== ""))) || maritalStatus !== "") && hasChildren === "no") || (((maritalStatus === "married" && yearsMarried !=="" && ((spouseApplying === "yes" && nameSpouse !== "" && emailSpouse !== "") || (spouseApplying === "no" && spouseFeel !== ""))) || maritalStatus !== "" ) && hasChildren === "yes" && numberChildren !== "" && carePlan !== "")
    }
    return true;
  }

  const handleChangeForm = (e)=>{
    const {name,value} = e.target;
    setFamilyForm({...familyForm, [name]:value});
  }

  const handleChangeChildInfo = (e,i)=>{
    const newChildren = [...children];
    const {name, value} = e.target;
    newChildren[i]= { ...children[i],[name]:value}
    setFamilyForm({...familyForm,children:newChildren});
  }

  const handleAddChild = ()=>{
    const newChildren = [...children];
    const newChild = { childName:"",childAge:""};
    newChildren.push(newChild);
    setFamilyForm({...familyForm,children:newChildren});
  }

  const handleDeleteChild =(index)=>{ 
    const newChildren = [...children];
    newChildren.splice(index,1);
    setFamilyForm({...familyForm,children:newChildren})
  }

  const handleSubmit = ()=>{
    const dataSubmit={
      family:{
          ...familyForm
      }
    }
  
    updateApplication(dataSubmit);
  }

  return (
    <Grid container flexDirection="column-reverse">
      <Grid item xs={12}>
        <MobileStepper
          variant="dots"
          steps={steps}
          position="static"
          activeStep={activeStep}
          sx={{ width:"100%", flexGrow: 1 , marginTop:"50px"}}
          nextButton={
              <Button size="small" onClick={handleNext}>
                  {activeStep === steps-1 ? 'Next' : 'Next' }
              </Button>
          }
          backButton={
              <Button size="small" onClick={handleBack}>
                  {activeStep === 0 ? 'Back' : 'Back'}
              </Button>
          }
        />
      </Grid>
      {
        showError &&
        <Grid item xs={12}>
            <Alert severity='error' sx={{marginTop:"20px"}}>
                One or more REQUIRED fields are empty.
            </Alert>
        </Grid>
      }
      <Grid item xs={12}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={8}>
              <Typography variant='subtitle1' >Marital Status*</Typography>
              <Select onChange={handleChangeForm} value={maritalStatus} name='maritalStatus' fullWidth label="maritalStatus">
                  <MenuItem value="single">Single</MenuItem>
                  <MenuItem value="inARelationship">In a Relationship</MenuItem>
                  <MenuItem value="Engaged">Engaged</MenuItem>
                  <MenuItem value="married">Married</MenuItem>
                  <MenuItem value="separated">Separated</MenuItem>
                  <MenuItem value="divorced">Divorced</MenuItem>
                  <MenuItem value="widowed">Widowed</MenuItem>
              </Select>
          </Grid>
          <Grid item>
            <FormLabel sx={{fontSize:"0.85rem"}}>
              The purpose of Summit is to provide an environment where students can cultivate an intimate relationship with God in an unhindered way. For this reason, we ask students to forego pursuing romantic relationships during their time at Summit. Our desire is to grant students the opportunity to focus on their relationships with Christ first. Applicants who are engaged to be married or soon-to-be-engaged should consider applying to Summit after they are married.
            </FormLabel>
          </Grid>
          {
            maritalStatus === "married" && <>
              <Grid item xs={12} sm={6}>
                <Typography variant='subtitle1' sx={{marginTop:"20px"}}>Spouse Full Name*</Typography>
                <TextField fullWidth required value={nameSpouse} onChange={handleChangeForm} name='nameSpouse'/>
              </Grid>
              <Grid item xs={12}>
                <Typography variant='subtitle1' sx={{marginTop:"20px"}}>Years Married*</Typography>
                <TextField fullWidth required value={yearsMarried} onChange={handleChangeForm} name='yearsMarried'/>
              </Grid>
              <Grid item xs={12}>
                <Typography variant='subtitle1' sx={{marginTop:"20px"}}>Is your spouse also applying to Summit?*</Typography>
                <RadioGroup sx={{flexDirection:"row"}} value={spouseApplying} onChange={handleChangeForm}>
                    <FormControlLabel value="yes" name="spouseApplying" control={<Radio />} label="Yes" />
                    <FormControlLabel value="no" name="spouseApplying" control={<Radio />} label="No" />
                </RadioGroup>
              </Grid>
              {
                spouseApplying === "yes" && <>
                  <Grid item xs={12} sm={6}>
                    <Typography variant='subtitle1' sx={{marginTop:"20px"}}>Spouse's Email*</Typography>
                    <TextField fullWidth required value={emailSpouse} onChange={handleChangeForm} name='emailSpouse'/>
                  </Grid>
                </>
              }
              {
                spouseApplying === "no" && <>
                  <Grid item xs={12}>
                    <Typography variant='subtitle1'>How does your spouse feel about you attending Summit?*</Typography>
                    <TextField fullWidth onChange={handleChangeForm} value={spouseFeel} name="spouseFeel" multiline rows={4}/>
                  </Grid>
                </>
              }
            </>
          }
          <Grid item xs={12}>
            <Typography variant='subtitle1' sx={{marginTop:"20px"}}>Do you have children?*</Typography>
            <RadioGroup sx={{flexDirection:"row"}} value={hasChildren} onChange={handleChangeForm}>
                <FormControlLabel value="yes" name="hasChildren" control={<Radio />} label="Yes" />
                <FormControlLabel value="no" name="hasChildren" control={<Radio />} label="No" />
            </RadioGroup>
          </Grid>
          {
            hasChildren === "yes" && <>
              <Grid item xs={12} sm={8}>
                <Typography variant='subtitle1'>Number of Children*</Typography>
                <TextField fullWidth required value={numberChildren} onChange={handleChangeForm} name='numberChildren'/>
              </Grid>
              {
                children && children.map((child,i)=>(<Fragment key={i}>
                  <Grid item xs={12} sm={5}>
                    <Typography variant='subtitle1'>Child Name*</Typography>
                    <TextField fullWidth required value={child.childName} onChange={(e)=>handleChangeChildInfo(e,i)} name='childName'/>
                  </Grid>
                  <Grid item xs={12} sm={5}>
                    <Typography variant='subtitle1'>Child Age*</Typography>
                    <TextField fullWidth required value={child.childAge} onChange={(e)=>handleChangeChildInfo(e,i)} name='childAge'/>
                  </Grid>
                  <Grid item xs={12} sm={2}>
                    <ClearIcon onClick={()=>handleDeleteChild(i)} sx={{color:"red",cursor:"pointer"}}/>
                  </Grid>
                </Fragment>))
              }
              <Grid item xs={12} sm={8}>
                <Button variant="contained" onClick={handleAddChild}>ADD CHILD</Button>
              </Grid>
              <Grid item xs={12} sm={8}>
                  <Typography variant='subtitle1'>For any dependent children, what is your plan for their care while you're attending Summit?*</Typography>
                  <TextField fullWidth onChange={handleChangeForm} inputProps={{maxLength:500}} helperText={`Max Length ${carePlan.length}/500`} value={carePlan} name="carePlan" sx={{width:{xs:"100%",sm:"100%"}}} placeholder="Text" multiline rows={4}/>
              </Grid>
            </>
          }
        </Grid>
      </Grid>
      <Grid item xs={12} sx={{margin:{xs:"0px 0px 50px 0px",sm:"50px 0px"}}}>
        <BannerStep title="Family" stepNumber={activeStep+1} totalSteps={1}/>
      </Grid>
    </Grid>
  )
}

export default Family
import { Grid } from "@mui/material";
import { useState } from "react";
import SStepsAdmin from "./SStepsAdmin";
import ReviewAndSubmitAdmin from "./QViewerTabs/ReviewAndSubmitAdmin";
import ConfirmationFeeAdmin from "./QViewerTabs/ConfirmationFeeAdmin";

const SubmitAdmin = ({application}) => {

    const [currentTab, setCurrentTab] = useState('review_and_submit');
    
    return (
        <Grid container spacing={2}>
            <Grid item xs={3} sx={{borderRight:"solid #999999",paddingRight:"30px"}}>
                <SStepsAdmin application={application} setCurrentTab={setCurrentTab} currentTab={currentTab}/>
            </Grid>
            <Grid item xs={9}>
                {
                    currentTab === "review_and_submit" && <ReviewAndSubmitAdmin reviewAndSubmit={application?.reviewAndSubmit} />
                }
                {
                    currentTab === "confirmation_fee" && <ConfirmationFeeAdmin confirmationFee={application?.confirmationFee} />
                }
                {/* {
                    currentTab === "fees" && <FeesAdmin fees={application?.fees} appId={application?._id}/>
                } */}
                
            </Grid>
        </Grid>
    )
}

export default SubmitAdmin
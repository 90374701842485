import AssignToColumn from "../components/GridTableUtils/AssignToColumn";
import FeeColumn from "../components/GridTableUtils/FeeColumn";
import ProgramColumn from "../components/GridTableUtils/ProgramColumn";
import ScholarshipColumn from "../components/GridTableUtils/ScholarshipColumn";
import VisaColumn from "../components/GridTableUtils/VisaColumn";
import clsx from 'clsx';

export const columnsAccepted = [
    {
        field:'lastName',
        headerName: 'LAST NAME',
        flex:1
    },
    {
        field:'firstName',
        headerName: 'FIRST NAME',
        flex:1
    },
    {
        field:'assignedTo',
        headerName: 'ASSIGNED TO',
        flex:1,
        renderCell: AssignToColumn,
        cellClassName:()=>{
            return clsx('nopadding');
        }
    },
    {
        field:'program',
        headerName: 'PROGRAM',
        flex:1,
        renderCell: ProgramColumn
    },
    {
        field:'citizenship',
        headerName: 'CITIZENSHIP',
        flex:1
    },
    {
        field:'fee',
        headerName: 'FEE',
        flex:1,
        renderCell: FeeColumn,
        cellClassName:()=>{
            return clsx('nopadding');
        }
    },
    {
        field:'visa',
        headerName: 'VISA F1',
        flex:1,
        renderCell: VisaColumn,
        cellClassName:()=>{
            return clsx('nopadding');
        }
    },
    {
        field:'scholarship',
        headerName: 'SCHOLARSHIP',
        flex:1,
        renderCell: ScholarshipColumn
    },
]
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Box, Button, Typography } from "@mui/material";

const ResumeStepAdmin = ({fileName}) => {

  const BASE_URL = "https://summit-app-edgar.s3.eu-north-1.amazonaws.com/";
  const [fileType, setFileType] = useState();
  const [url, setUrl] = useState(BASE_URL + fileName);

  useEffect(() => {
      const type = fileName.split(".").pop().toLowerCase();
      const folderName = fileName.split("/")[0];
      setFileType(type);
      if (folderName !== "applications") {
        setUrl(BASE_URL + "applicants/" + fileName);
      }
  }, [fileName]);

  return (
      <>
        <Typography variant='h5' color="customYellow.main" sx={{marginBottom:"30px"}}><b>Resume</b></Typography>
        <Box sx={{width:"400px"}}>
          {
            fileType && fileType !== "" && <>
            {
              fileType !== "pdf" && <img src={url} alt="Resume File" style={{width:"100%"}}/>
            }
            <Link to={url} target="_blank">
              <Button variant="contained">
                DOWNLOAD FILE
              </Button>
            </Link>
            </>
          }
        </Box>
      </>
  )
}

export default ResumeStepAdmin
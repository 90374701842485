import clsx from 'clsx';
import AssignToColumn from "../components/GridTableUtils/AssignToColumn";
import DecisionScholarshipColumn from "../components/GridTableUtils/DecisionScholarshipColumn";
import EssayColumn from "../components/GridTableUtils/EssayColumn";
import FinancialDocumentsColumn from "../components/GridTableUtils/FinancialDocumentsColumn";
import ProgramColumn from '../components/GridTableUtils/ProgramColumn';

export const columnsScholarship = [
    {
        field:'lastName',
        headerName: 'LAST NAME',
        flex:1
    },
    {
        field:'firstName',
        headerName: 'FIRST NAME',
        flex:1
    },
    {
        field:'assignedTo',
        headerName: 'ASSIGNED TO',
        flex:1,
        renderCell: AssignToColumn,
        cellClassName:()=>{
            return clsx('nopadding');
        }
    },
    {
        field:'program',
        headerName: 'PROGRAM',
        flex:1,
        renderCell: ProgramColumn
    },
    {
        field:'essay',
        headerName: 'ESSAY',
        flex:1,
        renderCell: EssayColumn,
        cellClassName:()=>{
            return clsx('nopadding');
        }
    },
    {
        field:'financial',
        headerName: 'FINANCIAL DOCUMENTS',
        flex:1,
        renderCell: FinancialDocumentsColumn,
        cellClassName:()=>{
            return clsx('nopadding');
        }
    },
    {
        field:'decision',
        headerName: 'DECISION',
        flex:1,
        renderCell: DecisionScholarshipColumn
    },
]
import { createContext, useState } from "react";

export const ApplicationContext = createContext({
    currentApplication:null,
    setCurrentApplication:()=>null,
});

export const ApplicationProvider = ({children})=>{
    const [currentApplication,setCurrentApplication] = useState();
    const value = {currentApplication,setCurrentApplication};

    return <ApplicationContext.Provider value={value}>{children}</ApplicationContext.Provider>
}
import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { getStripeKey, getStripeClientSecretKey, reviewAndSubmit, updateStatusApp } from "../axios/axiosFunctions";
import { Alert, Button, Checkbox, Dialog, DialogContent, DialogTitle, FormControlLabel, FormGroup, Grid, Typography } from "@mui/material";
import BannerStep from "./BannerStep";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import StripeForm from "./StripeForm";

const defaultFeesForm = {
  payed: false,
}

const ReviewAndSubmit = ({setCurrentStep,scholarship,updateApplication,appId,fees}) => {

  const navigate = useNavigate();
  const [msg, setMsg] = useState("");
  const [requiredFieldsOpen, setRequiredFieldsOpen] = useState(false);
  const [disclaimerOpen, setDisclaimerOpen] = useState(false);
  const [disclaimerCheckbox, setDisclaimerCheckbox] = useState(false);
  const [reviewOk, setReviewOk] = useState(false);
  const [feesForm, setFeesForm] = useState(defaultFeesForm);
  const [stripePromise, setStripePromise] = useState();
  const [stripeKey, setStripeKey] = useState("");
  const [clientSecret, setClientSecret] = useState("");
  const { payed } = feesForm;

  useEffect(() => {
    const getPublicKey = async()=>{
      const resp = await getStripeKey();
      const {publicKey} = resp;
      setStripePromise(loadStripe(publicKey));
      setStripeKey({clientSecret: publicKey});
    }
    const paymentIntent = async()=>{
      const amount = 2500; // TO DO CHANGE THIS VALUE TO THE COST OF THE PAYMENT
      const resp = await getStripeClientSecretKey({amount});
      const { clientSecret } = resp;
      setClientSecret(clientSecret);
    }

    paymentIntent();
    getPublicKey();
  }, []);

  useEffect(() => { 
    if(fees){
      setFeesForm(fees);
    }
  }, [fees])
  
  

  const handleButtonBack = ()=>{
    setCurrentStep(0);
  }

  const handlePopUpOpen = ()=>{
    setRequiredFieldsOpen(true);
  }
  
  const handlePopUpClose = ()=>{
    setRequiredFieldsOpen(false);
  }

  const handleDisclaimerClose = ()=>{
    setDisclaimerOpen(false);
  }

  const handleCheckBox = ()=>{
    if(!disclaimerCheckbox){
      setDisclaimerOpen(false);
      handleSubmit();
    }
    setDisclaimerCheckbox(!disclaimerCheckbox);   
  }


  const handlePopUpReviewOpen = ()=>{
    setReviewOk(true);
  }
  
  const handlePopUpReviewClose = ()=>{
    setReviewOk(false);
    navigate(0);
  }

  const handleDisclaimer = ()=>{
    if(disclaimerCheckbox){
      handleSubmit();
      return
    }
    setDisclaimerOpen(true);
  }

  const handleSubmit = async()=>{

    const dataSubmit={
      reviewAndSubmit:{
        submitted:true
      }
    }
    const {missingFields} = await reviewAndSubmit(appId);
    setMsg(missingFields);
    if(missingFields === ""){
      updateApplication(dataSubmit);
      await updateStatusApp(appId,{statusApp:"reviewed"});
      handlePopUpReviewOpen();
      if(scholarship === "yes" && payed){
        navigate("/scholarship");
      }
      return;
    }
    handlePopUpOpen();

  }

  return (
    <Grid container flexDirection="column-reverse">
      <Grid item xs={12}>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={10}>
            <Typography variant="subtitle1">Well done! Before submitting your application, please ensure you have answered all required questions and uploaded documents.
            Next, you will pay your 25$ application fee and submit your application! Woohoo!</Typography>
          </Grid>
          <Grid item xs={12}>
            {/* <FormGroup onChange={handlePayment}>
                <FormControlLabel checked={payed} control={<Switch/>} label="Pay"/>
            </FormGroup> */}
            {
              stripePromise && clientSecret && !payed &&
              <Elements stripe={stripePromise} options={{clientSecret}}>
                <StripeForm updateApplication={updateApplication} setFeesForm={setFeesForm}/>
              </Elements>
            }
            { payed && 
              <Alert severity="success">
                Payment Received
              </Alert>

            }
          </Grid>
          <Grid item xs={12} sm={12}>
            <Link to="/application">
              <Button onClick={handleButtonBack} variant="contained" color="customGray" sx={{marginRight:"20px",padding:"10px 40px"}} >GO BACK</Button>
            </Link>
            <Button variant="contained" onClick={handleDisclaimer} sx={{padding:"10px 40px"}}>SUBMIT STEP</Button>
            <Dialog open={disclaimerOpen} onClose={handleDisclaimerClose}>
              <DialogTitle>
                <Typography variant='h5'>
                  Application Disclaimer
                </Typography>
              </DialogTitle>
              <DialogContent>
                <FormGroup onChange={handleCheckBox}>
                    <FormControlLabel checked={disclaimerCheckbox} required control={<Checkbox/>} value={0} label="By submitting this application, I certify that the information provided is true, complete, and accurate to the best of my knowledge. I understand that any false or misleading statements may result in disqualification from the admissions process or revocation of admission. 
                I acknowledge that submission of this application does not guarantee acceptance, and that the institution reserves the right to request additional documentation or information. I also consent to the use of my provided information for admissions evaluation and communication purposes in accordance with institutional policies." />
                </FormGroup>
              </DialogContent>
            </Dialog>
            <Dialog open={requiredFieldsOpen} onClose={handlePopUpClose}>
              <DialogTitle>
                <Typography variant='h5'>
                    You need to complete the next fields:
                </Typography>
              </DialogTitle>
              <DialogContent>
                <Typography sx={{whiteSpace:"break-spaces"}}>
                  {msg}
                </Typography>
              </DialogContent>
            </Dialog>
            <Dialog open={reviewOk} onClose={handlePopUpReviewClose}>
              <DialogContent>
                <Typography variant="h3" sx={{whiteSpace:"break-spaces",textAlign:"center"}}>
                  Congratulations, Application Submitted!
                </Typography>
              </DialogContent>
            </Dialog>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12} sx={{margin:{xs:"0px 0px 50px 0px",sm:"50px 0px"}}}>
        <BannerStep title="Review, Pay Fee and Submit" stepNumber={1} totalSteps={1}/>
      </Grid>
    </Grid>
  )
}

export default ReviewAndSubmit
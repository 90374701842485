import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Box } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import { columnsProspects } from '../../utils/prospectsTable';
import { getApplicationByStatus } from '../../axios/axiosFunctions';
import { changeDateFormat } from '../../utils/dateFormat';

const Prospects = ({setActiveTab,currentYear}) => {

    const [rowsApplications, setRowsApplications] = useState([{id:1}]);
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();

    useEffect(() => {
        const getApplications = async()=>{
            setLoading(true);
            const res = await getApplicationByStatus("prospect",currentYear);
            const tempRows = res.map((app,i)=>({
                id:app._id,
                lastName:app.user?.lastName || "",
                firstName:app.user?.firstName || "",
                assignedTo:app.assignTo || "",
                program: app.program || "Not set",
                citizenship:app.personal.citizenship,
                accountCreated: changeDateFormat(app.user?.accountCreatedDate) || "",
                lastSignInDate: changeDateFormat(app.user?.lastLogIn) || "",
                status:app.statusColumn || "",
                progress:app.progress    
            }));
            setRowsApplications(tempRows);
            setLoading(false);
        }
      getApplications();
    }, [currentYear]);


    const handleCellClick = (e)=>{
        const {row,colDef} = e;
        if(colDef.field === "lastName" || colDef.field === "firstName"){
            setActiveTab('prospects');
            navigate(`${row.id}`);
        }
    }
    
    return (
    <Box sx={{margin:"20px",'& .nopadding': {padding:"0px !important"}}}>
        <DataGrid initialState={{pagination:{paginationModel: {pageSize:15,page:0}},sorting:{sortModel:[{field:"status",sort:"desc"}]}}} loading={loading} 
        columns={columnsProspects} rows={rowsApplications} rowSpacingType='border' disableMultipleRowSelection={true} onCellClick={handleCellClick}>
        </DataGrid>
    </Box>
    )
}

export default Prospects
import { Typography } from "@mui/material"

const FeesAdmin = ({fees}) => {
  return (<>
  <Typography variant='h5' color="customYellow.main" sx={{marginBottom:"30px"}}><b>Fees</b></Typography>
  {
    fees.payed ? <Typography variant="h4"> Application payed</Typography>
    : <Typography variant="h4"> Application payment pending </Typography>
  }
  </>)
}

export default FeesAdmin
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom"
import { getActivityByApplicationId, getApplicationById, getUserById } from "../../axios/axiosFunctions";
import { Grid } from "@mui/material";
import UserInfoProfile from "./UserInfoProfile";
import QViewer from "./QViewer";
import Activities from "./Activities";

const Profile = () => {
    
    const {id} = useParams();
    const [currentApplication, setCurrentApplication] = useState();
    const [currentUserInfo, setCurrentUserInfo] = useState();
    const [currentActivities, setCurrentActivities] = useState([]);

    useEffect(() => {
        const getApplicationInfo = async()=>{
            const resp = await getApplicationById(id);
            if(resp){
                const userInfo = await getUserById(resp.application.user);
                setCurrentApplication(resp.application);
                if(userInfo){
                    setCurrentUserInfo(userInfo.userInfo);
                }
                const activities = await getActivityByApplicationId(resp.application._id);
                if(activities){
                    setCurrentActivities(activities.activities);
                }
            }
        }
        getApplicationInfo(id);
    }, [id]);

    return (
        <Grid container spacing={2} sx={{margin:"20px",width:"100%"}}>
            <Grid item xs={6} sx={{borderRight:"solid #999999",paddingRight:"20px"}}>
                <UserInfoProfile name={`${currentUserInfo?.firstName || ""} ${currentUserInfo?.lastName || ""}`} 
                                accountDate={currentUserInfo?.accountCreatedDate || ""} email={currentUserInfo?.email || ""} phone={`(+${currentUserInfo?.countryCode || ""}) ${currentUserInfo?.phoneNumber || ""}`}
                                wayOfContact={currentApplication?.personal.preferredWayOfContact || ""} year={currentApplication?.year ||""} 
                                imageLetters={`${currentUserInfo?.firstName[0] || ""}${currentUserInfo?.lastName[0] || ""}`} photo={currentApplication?.photo?.image} appId={currentApplication?._id}
                                birthDate={currentApplication?.personal?.dateOfBirth}/>
            </Grid>
            <Grid item xs={6}>
                <Activities activities={currentActivities} counselor={currentApplication?.assignTo} 
                            appId={currentApplication?._id} setCurrentActivities={setCurrentActivities} 
                            statusApp={currentApplication?.statusApp} statusColumn={currentApplication?.statusColumn}/>
            </Grid>
            <Grid item xs={12} sx={{margin:"50px 0px"}}>
                <QViewer application={currentApplication} progress={currentApplication?.progress} personal={currentApplication?.personal} financial={currentApplication?.financial}/>
            </Grid>
        </Grid>
    )
}

export default Profile
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import dayjs from 'dayjs';
import { Alert, Button, Grid, MenuItem, MobileStepper, Select, TextField, Typography } from "@mui/material";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider/LocalizationProvider";
import BannerStep from "./BannerStep";

const defaultEducationForm = {
    educationLevel:"",
    degreeMajor:"",
    institution:"",
    otherInstitution:"",
    startDate:"",
    endDate:"",
    gradePointAverage:""
}

const Education = ({data,updateApplication}) => {

    const navigate = useNavigate();
    const [activeStep, setActiveStep] = useState(0);
    const [showError, setShowError] = useState(false);
    const [educationForm, setEducationForm] = useState(defaultEducationForm);
    const steps = 1;

    const {
        educationLevel,
        degreeMajor,
        institution,
        otherInstitution,
        startDate,
        endDate,
        gradePointAverage
    } = educationForm;

    useEffect(() => {
      if(data){
        setEducationForm({
            educationLevel: data.educationLevel || "",
            degreeMajor: data.degreeMajor || "",
            institution: data.institution || "",
            otherInstitution: data.otherInstitution || "",
            startDate: data.startDate || "",
            endDate: data.endDate || "",
            gradePointAverage: data.gradePointAverage || ""
        })
      }
      
    }, [data])
    

    const handleNext = () => {

        handleSubmit();
        if(!checkRequired()){
            setShowError(true);
            setTimeout(() => {
                setShowError(false);
            }, 2000);
            return;
        }

        if(activeStep === steps-1){
            navigate('/work');
            return;
        }
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
    };
    
    const handleBack = () => {
        handleSubmit();
        if(activeStep === 0)
        {
            navigate('/faith');
            return;
        }
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const checkRequired = ()=>{
        if(activeStep === 0){
            return ((educationLevel === "None" || educationLevel === "High School Diploma") && institution !== "" && gradePointAverage !== "") || (educationLevel === "GED" && gradePointAverage !== "") ||
            (educationLevel === "Others" && otherInstitution !== "" && institution !== "" && gradePointAverage !== "") || ((educationLevel !== "" && educationLevel !== "none" && educationLevel !== "High School Diploma" && educationLevel !== "GED" && educationLevel !== "Others") && degreeMajor !== "" && institution !== "" && gradePointAverage !== "")
        }
        return true;
    }

    const handleChangeForm = (e)=>{
        const {name,value} = e.target;
        setEducationForm({...educationForm, [name]:value});
    }

    const handleStartDate = ({$y,$M,$D})=>{
        const m = $M < 9 ? `0${$M+1}` : $M+1; 
        const d = $D < 10 ? `0${$D}` : $D;
        setEducationForm({...educationForm, startDate:`${$y}-${m}-${d}`});
    }

    const handleEndDate = ({$y,$M,$D})=>{
        const m = $M < 9 ? `0${$M+1}` : $M+1; 
        const d = $D < 10 ? `0${$D}` : $D;
        setEducationForm({...educationForm, endDate:`${$y}-${m}-${d}`});
    }


    const handleSubmit = ()=>{
        const dataSubmit={
            education:{
                ...educationForm
            }
        }
        
        updateApplication(dataSubmit);
    }

    return (
        <Grid container flexDirection="column-reverse">
            <Grid item xs={12}>
                <MobileStepper
                    variant="dots"
                    steps={steps}
                    position="static"
                    activeStep={activeStep}
                    sx={{ width:"100%", flexGrow: 1 , marginTop:"50px"}}
                    nextButton={
                        <Button size="small" onClick={handleNext}>
                            {activeStep === steps-1 ? 'Next' : 'Next' }
                        </Button>
                    }
                    backButton={
                        <Button size="small" onClick={handleBack}>
                            {activeStep === 0 ? 'Back' : 'Back'}
                        </Button>
                    }
                />
            </Grid>
            {
                showError &&
                <Grid item xs={12}>
                    <Alert severity='error' sx={{marginTop:"20px"}}>
                        One or more REQUIRED fields are empty.
                    </Alert>
                </Grid>
            }
            <Grid item xs={12}>
                <Grid container spacing={2}>
                    <Grid item xs={12} sm={6}>
                        <Typography variant='subtitle1'>Highest Level of Education Completed*</Typography>
                        <Select onChange={handleChangeForm} value={educationLevel} name='educationLevel' fullWidth>
                            <MenuItem value="None">-- None --</MenuItem>
                            <MenuItem value="GED">GED</MenuItem>
                            <MenuItem value="High School Diploma">High School Diploma</MenuItem>
                            <MenuItem value="Some College/University">Some College/University</MenuItem>
                            <MenuItem value="Associate Degree">Associate Degree</MenuItem>
                            <MenuItem value="Bachelors">Bachelors</MenuItem>
                            <MenuItem value="Masters">Masters</MenuItem>
                            <MenuItem value="Others">Others</MenuItem>
                        </Select>
                    </Grid>
                    {
                        (educationLevel === "Some College/University" || educationLevel === "Associate Degree" || educationLevel === "Bachelors" || educationLevel === "Masters") &&
                        <Grid item xs={12} sm={6} sx={{display:"flex",flexDirection:"column"}}>
                            <Typography variant='subtitle1'>Degree / Major*</Typography>
                            <TextField required value={degreeMajor} onChange={handleChangeForm} name='degreeMajor'/>
                        </Grid>
                    }
                    {
                        educationLevel === "Others" && 
                        <Grid item xs={12} sm={6} sx={{display:"flex",flexDirection:"column"}}>
                            <Typography variant='subtitle1'>Please Specify*</Typography>
                            <TextField required value={otherInstitution} onChange={handleChangeForm} name='otherInstitution'/>
                        </Grid>
                    }
                    {
                        educationLevel !== "GED" && 
                        <Grid item xs={12} sm={6} sx={{display:"flex",flexDirection:"column"}}>
                            <Typography variant='subtitle1'>Name of Institution*</Typography>
                            <TextField required value={institution} onChange={handleChangeForm} name='institution'/>
                        </Grid>
                    }
                    <Grid item xs={12} sm={6} sx={{display:"flex",flexDirection:"column"}}>
                        <Typography variant='subtitle1'>Start date</Typography>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DatePicker onChange={handleStartDate} value={ startDate !== "" ? dayjs(startDate):null}/>
                        </LocalizationProvider>
                    </Grid>
                    <Grid item xs={12} sm={6} sx={{display:"flex",flexDirection:"column"}}>
                        <Typography variant='subtitle1'>End date</Typography>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DatePicker onChange={handleEndDate} value={ endDate !== "" ? dayjs(endDate) : null}/>
                        </LocalizationProvider>
                    </Grid>
                    <Grid item xs={12} sm={6} sx={{display:"flex",flexDirection:"column"}}>
                        <Typography variant='subtitle1'>Grade Point Average*</Typography>
                        <TextField required value={gradePointAverage} onChange={handleChangeForm} name='gradePointAverage'/>
                    </Grid>
                </Grid>
            </Grid>
            <Grid item xs={12} sx={{margin:{xs:"0px 0px 50px 0px",sm:"50px 0px"}}}>
            <BannerStep title="Education" stepNumber={activeStep+1} totalSteps={1}/>
            </Grid>
        </Grid>
    )
}

export default Education
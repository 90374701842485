import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { updateApplication, uploadTranscripts } from "../../../axios/axiosFunctions";
import { Box, Button, Checkbox, FormControlLabel, Grid, Typography, styled } from "@mui/material";
import DeleteIcon from '@mui/icons-material/Delete';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';

const VisuallyHiddenInput = styled('input')({
    clip: 'rect(0 0 0 0)',
    clipPath: 'inset(50%)',
    height: 1,
    overflow: 'hidden',
    position: 'absolute',
    bottom: 0,
    left: 0,
    whiteSpace: 'nowrap',
    width: 1,
});

const TranscriptsAdmin = ({appId,transcripts,fileName}) => {
    
    const BASE_URL = "https://summit-app-edgar.s3.eu-north-1.amazonaws.com/";
    const [fileType, setFileType] = useState();
    const [url, setUrl] = useState(BASE_URL + fileName);

    useEffect(() => {
        handleFileType(fileName);
    }, [fileName])

    const handleFileType=(f)=>{
        if(f === ""){
            setFileType("");
            return
        }

        const type = f.split(".").pop().toLowerCase();
        const folderName = f.split("/")[0];
        setFileType(type);
        if (folderName !== "applications") {
          setUrl(BASE_URL + "applicants/" + f);
        }
    }
    

    const handleUploadFile = (e)=>{
        const {length} = e.target.files;
        if(length !== 0){
            handleSubmit(true,e.target.files[0],fileName);
        }
    }

    const handleSubmit = async(uploaded,submitFile,fName)=>{ 
        let newFileName = fName;
        if(uploaded && submitFile){
            const formData = new FormData();
            formData.append('fileImage',submitFile);
            const {name} = await uploadTranscripts(formData,appId);
            newFileName = name;
        }

        const dataSubmit = {
            transcripts:{
                uploaded: transcripts?.uploaded,
                image: newFileName
            }
        }

        await updateApplication(dataSubmit,appId);
        handleFileType(newFileName);
        

    }

    const handleDeleteFile = ()=>{
        handleSubmit(false,undefined,"");
    }

    return (
        <Grid container spacing={2}>
            <Grid item xs={12}>
                <FormControlLabel checked={transcripts?.uploaded} control={<Checkbox/>} label={<Typography variant="subtitle1"><b>I HAVE REQUESTED MY TRANSCRIPT</b></Typography>}/>
            </Grid>
            <Grid item xs={12}>
            {
                fileType && fileType !== "" ? <>
                    {
                        fileType !== "pdf" && <img src={url} alt="Transcript" style={{width:"100%"}}/>
                    }
                    <Box sx={{position:"relative",width:`220px`}}>
                        <Box sx={{position:"absolute", left:"100%"}}>
                            <Typography sx={{cursor:"pointer"}} onClick={handleDeleteFile}> <DeleteIcon sx={{color:"red"}}/> </Typography>
                        </Box>
                        <Link to={url} target="_blank">
                            <Button variant="contained">
                                DOWNLOAD TRANSCRIPT
                            </Button>
                        </Link>
                    </Box>
                </>
                :
                    <Button variant="contained" component="label" startIcon={<CloudUploadIcon/>}>
                        UPLOAD TRANSCRIPT
                        <VisuallyHiddenInput onChange={handleUploadFile} type="file"/>
                    </Button>
            }
            </Grid>
        </Grid>
    )
}

export default TranscriptsAdmin
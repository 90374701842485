import { useEffect, useState } from "react";
import { Alert, Grid, Typography } from "@mui/material";
import BannerStep from "./BannerStep";
import StripeForm from "./StripeForm";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import { getStripeClientSecretKey, getStripeKey } from "../axios/axiosFunctions";


const defaultConfirmationFeeForm = {
    payed: false,
}  

const ConfirmationFee = ({data,updateApplication,appId,citizenship}) => {

    const [confirmationFeeForm, setConfirmationFeeForm] = useState(defaultConfirmationFeeForm);
    const [stripePromise, setStripePromise] = useState();
    const [stripeKey, setStripeKey] = useState("");
    const [clientSecret, setClientSecret] = useState("");
    const {payed} = confirmationFeeForm;
    
    useEffect(() => { 
        if(data){
            setConfirmationFeeForm({payed: data.payed});
        }
    }, [data]);

    useEffect(() => {
        const getPublicKey = async()=>{
          const resp = await getStripeKey();
          const {publicKey} = resp;
          setStripePromise(loadStripe(publicKey));
          setStripeKey({clientSecret: publicKey});
        }
        const paymentIntent = async()=>{
          const amount = citizenship !== "United States" ? 22500 : 10000; // CHANGE THIS VALUE TO THE COST OF THE PAYMENT
          const resp = await getStripeClientSecretKey({amount});
          const { clientSecret } = resp;
          setClientSecret(clientSecret);
        }
    
        paymentIntent();
        getPublicKey();
    }, []);
    
    return (
    <Grid container flexDirection="column-reverse">
        <Grid item xs={12}>
            <Grid container spacing={3}>
                <Grid item xs={12} sm={10}>
                    {
                        !payed && <>
                            <Typography variant="subtitle1">Pay your confirmation fee: </Typography>
                            {
                                citizenship !== "United States" ? 
                                <Typography variant="subtitle1">$225</Typography>
                                :
                                <Typography variant="subtitle1">$100</Typography>
                            }
                        </>
                    }
                </Grid>
                <Grid item xs={12}>
                    {
                        stripePromise && clientSecret && !payed &&
                        <Elements stripe={stripePromise} options={{clientSecret}}>
                            <StripeForm formToUpdate="confirmation_fee" updateApplication={updateApplication} setFeesForm={setConfirmationFeeForm}/>
                        </Elements>
                    }
                    { payed && 
                        <Alert severity="success">
                            Payment Received
                        </Alert>
                    }
                </Grid>
            </Grid>
        </Grid>
        <Grid item xs={12} sx={{margin:{xs:"0px 0px 50px 0px",sm:"50px 0px"}}}>
            <BannerStep title="Confirmation Fee" stepNumber={1} totalSteps={1}/>
        </Grid>
    </Grid>
    )
}

export default ConfirmationFee
import { Grid, Typography } from "@mui/material"
import { Fragment } from "react";
import { upperFirstLetter } from "../../../utils/qViewerFunctions";

const WorkAdmin = ({work}) => {

    console.log(work);

    return (
        <Grid container spacing={2}>
            <Grid item>
                <Typography variant='h5' color="customYellow.main" sx={{marginBottom:"30px"}}><b>Work</b></Typography>
                <Typography variant='subtitle1'><b>Are you currently employed?</b></Typography>
                <Typography variant='subtitle1' sx={{marginLeft:"20px"}}>{upperFirstLetter(work?.employed)}</Typography>
                {
                    work?.employed === "no" && <>
                        <Typography variant='subtitle1'><b>Have you been employed in the last 12 months?</b></Typography>
                        <Typography variant='subtitle1' sx={{marginLeft:"20px"}}>{upperFirstLetter(work?.last12Months)}</Typography>
                        {
                            work?.last12Months === "no" && <>
                                <Typography variant='subtitle1'><b>Please Elaborate</b></Typography>
                                <Typography variant='subtitle1' sx={{marginLeft:"20px"}}>{work?.elaborateLast12Months}</Typography>
                            </>
                        }
                    </>
                }
                {
                    work.jobs.length !== 0 && work?.jobs[0].jobTitle !== "" && work?.jobs.map((w,i)=>(
                        <Fragment key={i}>
                            <Typography variant='subtitle1'><b>Job Title</b></Typography>
                            <Typography variant='subtitle1' sx={{marginLeft:"20px"}}>{w.jobTitle}</Typography>
                            <Typography variant='subtitle1'><b>Job Description</b></Typography>
                            <Typography variant='subtitle1' sx={{marginLeft:"20px"}}>{w.jobDescription}</Typography>
                            <Typography variant='subtitle1'><b>Start date</b></Typography>
                            <Typography variant='subtitle1' sx={{marginLeft:"20px"}}>{w.dateStart}</Typography>
                            <Typography variant='subtitle1'><b>End date</b></Typography>
                            <Typography variant='subtitle1' sx={{marginLeft:"20px"}}>{w.dateEnd}</Typography>
                        </Fragment>
                    ))
                }
                <Typography variant='subtitle1'><b>May we contact your employer?</b></Typography>
                <Typography variant='subtitle1' sx={{marginLeft:"20px"}}>{upperFirstLetter(work?.contactEmployer)}</Typography>
            </Grid>
        </Grid>
    )
}

export default WorkAdmin
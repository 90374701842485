import AssignToColumn from "../components/GridTableUtils/AssignToColumn";
import ProgramColumn from "../components/GridTableUtils/ProgramColumn";
import ProgressColumn from "../components/GridTableUtils/ProgressColumn";
import StatusColumn from "../components/GridTableUtils/StatusColumn";
import clsx from 'clsx';

export const columnsProspects = [
    {
        field:'lastName',
        headerName: 'LAST NAME',
        flex:1
    },
    {
        field:'firstName',
        headerName: 'FIRST NAME',
        flex:1
    },
    {
        field:'assignedTo',
        headerName: 'ASSIGNED TO',
        flex:1,
        renderCell: AssignToColumn,
        cellClassName:()=>{
            return clsx('nopadding');
        }
    },
    {
        field:'program',
        headerName: 'PROGRAM',
        flex:1,
        renderCell: ProgramColumn
    },
    {
        field:'citizenship',
        headerName: 'CITIZENSHIP',
        flex:1
    },
    {
        field:'accountCreated',
        headerName: 'ACCOUNT CREATED',
        flex:1
    },
    {
        field:'lastSignInDate',
        headerName: 'LAST SIGN IN DATE',
        flex:1,
        /* valueGetter:(value)=>{
            const today = new Date();
            const last = new Date(value);
            const diffTime = Math.abs(last-today);
            const diff = Math.floor(diffTime / (1000 * 60 * 60 * 24));
            return `${diff} days ago.` 
        } */
    },
    {
        field:'status',
        headerName: 'STATUS',
        flex:1,
        renderCell : StatusColumn
    },
    {
        field:'progress',
        headerName: 'PROGRESS',
        flex:1,
        renderCell: ProgressColumn,
    },
]
import { Grid, Typography } from "@mui/material";
import { Link } from "react-router-dom";    
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import NewReleasesIcon from '@mui/icons-material/NewReleases';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';

const HomeNotification = ({notifications}) => {

    return (<>
    <Typography variant="h5" sx={{marginBottom:"10px"}}>Notifications</Typography>
    {
        notifications && notifications[0] && notifications.map((n,i)=>(
            <Link key={i} to={`/prospects/${n?.application}`} >
                <Grid container sx={{backgroundColor:n?.severity,padding:"20px 0px 20px 20px",marginBottom:"10px"}}>
                    <Grid item xs={1}>
                        {
                            n?.severity === "#FFF5DA" && <WarningAmberIcon color="#FBBD08"/>
                        }
                        {
                            n?.severity === "#FFF4E5" && <WarningAmberIcon color="#FFF4E5"/>
                        }
                        {
                            n?.severity === "#FDEDED" && <ErrorOutlineIcon sx={{color:"#FF3838"}}/>
                        }
                        {
                            n?.severity === "#EFE5F9" && <NewReleasesIcon sx={{color:"#6400C8"}}/>
                        }
                        {
                            n?.severity === "#E5F6FD" && <InfoOutlinedIcon sx={{color:"#0288D1"}}/>
                        }
                    </Grid>
                    <Grid item xs={11}>
                        <Typography variant="subtitle1"><b>{n?.title}</b></Typography>
                        <Typography variant="subtitle2">{n?.description}</Typography>
                    </Grid>
                </Grid>
            </Link>
        ))
    }
    {
        notifications && (notifications[0] === null || notifications.length === 0) && <Grid container sx={{backgroundColor:"#EDF7ED",padding:"20px 0px 20px 20px"}}>
        <Grid item xs={1}>
            <CheckCircleOutlineIcon sx={{color:"#2E7D32"}}/>
        </Grid>
        <Grid item xs={11}>
            <Typography variant="h6">All applicants are up to date</Typography>
            <Typography variant="subtitle2">See full applicant list</Typography>
        </Grid>
    </Grid>
    }
    </>)
}

export default HomeNotification
import { useContext, useEffect, useState } from "react";
import { Alert, Button, FormControl, Grid, IconButton, InputAdornment, InputLabel, OutlinedInput, TextField, Typography } from "@mui/material";
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { UserContext } from "../../contexts/user";
import { updateUser } from "../../axios/axiosFunctions";

const EditProfile = () => {

  const {currentUser,setCurrentUser} = useContext(UserContext);
  const [updateUserForm, setUpdateUserForm] = useState(currentUser);
  const [showPassword, setShowPassword] = useState(false);
  const {firstName,lastName,email} = updateUserForm;
  const [messageOpen, setMessageOpen] = useState(false);
  const [severity, setSeverity] = useState('success');
  const [message, setMessage] = useState("");
  
  useEffect(() => {
    setUpdateUserForm(currentUser)
  }, [currentUser])
  
  
  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleChangeForm = (e)=>{
    const {name,value} = e.target;
    setUpdateUserForm({...updateUserForm, [name]:value});
  }
  
  const handleUpdateUser = async()=>{
    const resp = await updateUser(currentUser._id,updateUserForm);
    if(resp){
      setSeverity("success");
      setMessage("User uploaded correctly");
      setMessageOpen(true);
      setCurrentUser(resp.user);
      setTimeout(() => {
        setMessageOpen(false);
      }, 3000);
    }else{
      setSeverity("error");
      setMessage("User NOT Saved (Check internet connection or talk to a super admin)");
      setMessageOpen(true);
      setTimeout(() => {
        setMessageOpen(false)
      },3000);
    }
  }

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Typography variant="h5"><b>User Information</b></Typography>
        {/* <Typography variant="subtitle2">(After updating your information, you'll need to sign in again)</Typography> */}
      </Grid>
      <Grid item xs={12}>
        <form>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <TextField fullWidth onChange={handleChangeForm} value={firstName} name='firstName' label="First name"/>
            </Grid>
            <Grid item xs={6}>
              <TextField fullWidth onChange={handleChangeForm} value={lastName} name='lastName' label="Last name / Surname"/>
            </Grid>
            <Grid item xs={6}>
              <TextField fullWidth onChange={handleChangeForm} value={email} name='email' label="Email"/>
            </Grid>
            <Grid item xs={6}>
              <FormControl fullWidth variant="outlined"> 
                <InputLabel>Set new password(leave blank to keep it)</InputLabel>
                <OutlinedInput
                  type={showPassword ? 'text' : 'password'} 
                  fullWidth
                  label="Password*"
                  name='password'
                  required
                  onChange={handleChangeForm}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        onClick={handleClickShowPassword}
                      >
                        {showPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  }
                />
              </FormControl>
            </Grid>
          </Grid>
        </form>
      </Grid>
      {
        messageOpen &&
        <Grid item xs={12}>
          <Alert fullWidth severity={severity}>
            {message}
          </Alert>
        </Grid>
      }
      <Grid item xs={12}>
        <Button variant="contained" color="customYellow" onClick={handleUpdateUser}>UPDATE</Button>
      </Grid>
    </Grid>
  )
}

export default EditProfile
import { Link } from "react-router-dom";
import { List, ListItem, ListItemButton, ListItemText, Typography } from "@mui/material"
import {feeSteps} from '../utils/feeSteps';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';

const FeeSteps = ({active,app}) => {
     
    return (
        <>
            <Typography variant="h5">Submit:</Typography>
            <List sx={{width:"100%"}}>
                {
                    feeSteps.map(({label,uploadedLabel,route},i)=>(
                        <Link to={`/${route}`} key={i}>
                            <ListItem sx={{padding:{xs:"0px"},borderBottom:"1px solid #9E9E9E",backgroundColor:active ===route?'customYellow.main':'white'}}>
                                <ListItemButton>
                                    <ListItemText primary={label}/>
                                    <Typography edge="end">{ app ? app[uploadedLabel]?.payed || app[uploadedLabel]?.submitted ? <CheckCircleIcon/> : Object.keys(app[uploadedLabel]).includes("payed")? "Pay" : Object.keys(app[uploadedLabel]).includes("submitted")? "0/1" : "" : "" }</Typography>
                                </ListItemButton>
                            </ListItem>
                        </Link>
                    ))
                }
                {
                    (app?.statusApp === "accepted" || app?.statusApp === "confirmed" || app?.statusApp === "enrolled") && 
                    <Link to="/confirmation_fee">
                        <ListItem sx={{padding:{xs:"0px"},borderBottom:"1px solid #9E9E9E",backgroundColor:active ==="confirmation_fee"?'customYellow.main':'white'}}>
                            <ListItemButton>
                                <ListItemText primary={"Confirmation Fee"}/>
                                <Typography edge="end"></Typography>
                            </ListItemButton>
                        </ListItem>
                    </Link>
                }
            </List>
        </>
    )
}

export default FeeSteps